import { Grid } from "@mui/material";
import { CSSProperties } from "react";
import { useSticky } from "../../../../hooks/useSticky";
import { SellerRubrics } from "./SellerRubrics/SellerRubrics";
import { SellerContacts } from "./SellerContacts/SellerContacts";
import { SellerInfo } from "./SellerInfo/SellerInfo";

export const SellerContent = () => {
	const [navBarRef, sticky] = useSticky();

	const style = {
		position: "sticky",
		top: "95px",
		gap: "24px",
		width: "100%",
	} as CSSProperties;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={3}>
				<div ref={navBarRef} style={style}>
					<SellerRubrics />
				</div>
			</Grid>
			<Grid item xs={12} md={9}>
				<SellerInfo />
				<SellerContacts />
			</Grid>
		</Grid>
	);
};
