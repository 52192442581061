import React, { useRef } from "react";
import s from "./RedactionAdsCabinet.module.css";
import MobileAdGallery from "../../ImageGallery/MobileAdGallery";
import OutputADSvgSelector from "../../OutputBoardAdComponents/OutputADSvgSelector";
import style from "../../../pages/OutputBordAD/OutputBoardAD.module.css";
import VerticalScrollBar from "../../OutputBoardAdComponents/VerticalScrollBar";
import ControlPanelMobile from "../../OutputBoardAdComponents/ControlPanelMobile";

const RedactionAdsCabinetMobile = () => {
	const targetRef = useRef<HTMLDivElement>(null);
	return (
		<div className={s.redactionAdsCabinetMobileContainer}>
			<div className={style.topRelativeWrapper}>
				<div className={s.galleryContainer}>
					<MobileAdGallery />
				</div>
				<div className={style.topTitleContainer}>
					<div className={style.titlePrice}>
						<span className={style.price}>2610 грн.</span>
						{/* <span className={style.orangeHeart}>
                            <OutputADSvgSelector id={'orangeHeart'}/>
                        </span>*/}
					</div>
					<div className={style.titleName}>
						<span className={style.name}>Заглавие объявления H1</span>
					</div>
				</div>
			</div>
			<div ref={targetRef} className={style.scrollBarMobileContainer}>
				<VerticalScrollBar marginTop={"80px"} />
			</div>
			<div className={s.controlPanelContainer}>
				<ControlPanelMobile />
			</div>
		</div>
	);
};

export default RedactionAdsCabinetMobile;
