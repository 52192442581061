import React from "react";
import s from "../My Ads/MyAdsActive.module.css";
import TopNavigateMobileCabinet from "../TopNavigateMobileCabinet/TopNavigateMobileCabinet";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import MyOrdersCardMobile from "./MyOrdersCardMobile/MyOrdersCardMobile";
import NoOrdersCardMobile from "./NoOrdersCard/NoOrdersCardMobile";

const MyOrdersMobile = () => {
	const elements = [
		{
			link: "new",
			name: "Нові",
			elementData: "120",
		},
		{
			link: "sent",
			name: "Відправлені",
			elementData: "120",
		},
		{
			link: "closed",
			name: "Закриті",
			elementData: "120",
		},
	];
	return (
		<div className={s.myAdsMobileContainer}>
			<div className={s.topHead}>
				<TopNavigateMobileCabinet
					titleMobile={"Мої замовлення"}
					headers={elements}
				/>
			</div>
			{/* <div className={s.rubricsSelectContainer}>
                <CabinetRubricsSelectMobile />
            </div> */}
			<div className={s.cardsContainer}>
				<Routes>
					<Route path={""} element={<Navigate to="new" />} />
					<Route path={"new"} element={<MyOrdersCardMobile type={"new"} />} />
					<Route path={"sent"} element={<MyOrdersCardMobile type={"sent"} />} />
					<Route
						path={"closed"}
						element={<MyOrdersCardMobile type={"closed"} />}
					/>
				</Routes>
				<NoOrdersCardMobile />
			</div>
		</div>
	);
};

export default MyOrdersMobile;
