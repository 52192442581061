import s from "../../pages/PersonalCabinet/PersonalCabinet.module.css";
import CheckBoxContentPanel from "./CheckBoxContentPanel";
import { StatByCategory } from "../../types/noticeTypes";
import CheckboxCategorySelect from "../CheckboxCategorySelect/CheckboxCategorySelect";
import { useTranslation } from "react-i18next";

type Props = {
	categories: StatByCategory[];
	catIds: number[];
	handleCatIds: (id: number) => void;
};
function PersonalCabinetCheckBoxPanel({
	categories,
	handleCatIds,
	catIds,
}: Props) {
	const { i18n, t } = useTranslation();
	if (categories?.length <= 4) {
		return (
			<div className={s.cabinetContent_CheckBoxPanel}>
				<div className={s.checkboxEl}>
					<CheckBoxContentPanel
						id={0}
						labelTxt={t("Всі категорії")}
						onChange={handleCatIds}
						checkedIds={catIds}
					/>
				</div>
				{categories.map((c: StatByCategory) => (
					<div className={s.checkboxEl} key={c.id}>
						<CheckBoxContentPanel
							key={c.id}
							id={c.id}
							labelTxt={i18n.language === "ru" ? c.name_ru : c.name}
							checkedIds={catIds}
							onChange={handleCatIds}
						/>
					</div>
				))}
			</div>
		);
	} else {
		return (
			<div className={s.cabinetContent_CheckBoxPanel}>
				<CheckboxCategorySelect
					categories={categories}
					catIds={catIds}
					handleCatIds={handleCatIds}
				/>
			</div>
		);
	}
}

export default PersonalCabinetCheckBoxPanel;
