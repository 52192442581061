import { useEffect, useRef, useState } from "react";

export function useSticky() {
	const stickyRef = useRef<HTMLDivElement | null>(null);
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		if (!stickyRef.current) return;

		const handleScroll = () => {
			if (stickyRef.current) {
				const { top } = stickyRef.current.getBoundingClientRect();
				const stickyThreshold = 95; // Відстань, при якій елемент стане sticky

				if (top <= stickyThreshold) {
					setIsSticky(true);
				} else {
					setIsSticky(false);
				}
			}
		};

		// Додаємо слухач подій прокрутки
		window.addEventListener("scroll", handleScroll);

		// Викликаємо функцію один раз для початкового стану
		handleScroll();

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return [stickyRef, isSticky] as const;
}
