import React from "react";
import s from "./PersonalCabinetSettings.module.css";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
const LanguageSwitch = () => {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const currentLanguage = i18n.language;
	if (currentLanguage !== "uk" && currentLanguage !== "ru") {
		changeLanguage("uk");
	}

	const handleLanguage = (lang: string) => {
		if (lang === "ru") {
			changeLanguage("ru");
		}
		if (lang === "uk") {
			changeLanguage("uk");
		}
	};

	return (
		<div className={s.switcherContainer}>
			<div className={s.switcherTitle}>{t("Мова")}</div>
			<div className={s.switcherButtonsContainer}>
				<button
					className={`${currentLanguage == "ru" ? s.switcherButton : s.switcherButtonActive}`}
					onClick={() => handleLanguage("ru")}
				>
					RU
				</button>
				<button
					className={`${currentLanguage == "uk" ? s.switcherButton : s.switcherButtonActive}`}
					onClick={() => handleLanguage("uk")}
				>
					UA
				</button>
			</div>
		</div>
	);
};

export default LanguageSwitch;
