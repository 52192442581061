import React from "react";
import { useTranslation } from "react-i18next";

const SecondsToDate = ({
	seconds,
	full,
}: {
	seconds: number | null;
	full: boolean;
}) => {
	const { i18n } = useTranslation();

	const monthsRU = [
		"янв",
		"февр",
		"март",
		"апр",
		"май",
		"июнь",
		"июль",
		"авг",
		"сент",
		"окт",
		"нояб",
		"дек",
	];
	const monthsUa = [
		"січ",
		"лют",
		"берез",
		"квіт",
		"трав",
		"черв",
		"лип",
		"серп",
		"верес",
		"жовт",
		"листоп",
		"груд",
	];
	const monthsRUFull = [
		"января",
		"февраля",
		"марта",
		"апреля",
		"мая",
		"июня",
		"июля",
		"августа",
		"сентября",
		"октября",
		"ноября",
		"декабря",
	];
	const monthsUaFull = [
		"січня",
		"лютого",
		"березня",
		"квітня",
		"травня",
		"червня",
		"липня",
		"серпня",
		"вересня",
		"жовтня",
		"листопада",
		"грудня",
	];
	const months =
		i18n.language === "ru"
			? full
				? monthsRUFull
				: monthsRU
			: full
				? monthsUaFull
				: monthsUa;

	if (seconds) {
		const date = new Date(seconds && seconds * 1000); // Преобразование секунд в миллисекунды
		const day = date.getDate(); // Получение дня месяца
		const month = months[date.getMonth()]; // Получение месяца (январь - 0, февраль - 1, ...)

		return (
			<>
				<>{day}</> <>{month}</>
			</>
		);
	}
	return <> </>;
};

export default SecondsToDate;
