import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import ApprovedEmailPopup from "../../Auth&RegNewPopups/ApprovedEmailPopup";
import ApproveEmailPage from "../../Auth&RegNewPopups/ApproveEmailPage";
import RestoreForgottenPassByEmailPage from "../../Auth&RegNewPopups/RestoreForgottenPassByEmailPage";
import HomePage from "../../pages/Home/HomePage";
import MainCategory from "../../pages/HomeMainCategory/MainCategory";
import OutputBoardAd from "../../pages/OutputBordAD/OutputBoardAD";
import NewPage404 from "../../pages/Page404/NewPage404";
import NoConnectToInet from "../../pages/Page404/NoConnectToInet";
import PersonalCabinet from "../../pages/PersonalCabinet/PersonalCabinet";
import PersonalCabinetMobile from "../../pages/PersonalCabinet/PersonalCabinetMobile";
import PoliticsPrivacy from "../../pages/PoliticsPrivacy/PoliticsPrivacy";
import TermsOfService from "../../pages/PoliticsPrivacy/TermsOfService";
import SearchResult from "../../pages/SearchResults/SearchResult";
import ShoppingCart from "../../pages/ShoppingCart/ShoppingCart";
import StaticPageTemplate from "../../pages/StaticPage/StaticPageTemplate";
import { Desktop, Mobile } from "../../responsive";
import { stateUserType } from "../../types/reduxStoreTypes";
import MainCategoryCatalogMobile from "../CategoryComponents/MainCategoryCatalogMobile";
import { PopupFonContainer } from "../MainSearch/PopupFonContainer";
import MobileRegistrationAuthForms from "../Registration&Auth/MobileRegistration&AuthForms";
import s from "./Main.module.css";
import BusinessPage from "../../pages/BusinessPage/BusinessPage";

function Main({ setHeaderSee }: { setHeaderSee: (b: boolean) => void }) {
	const isDesktop = useMediaQuery({ minWidth: 1025 });
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const [open, setOpen] = useState<boolean>(false);
	const [scrollTop, setScrollTop] = useState<number>(0);
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const [currentPath, setCurrentPath] = useState("");

	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location.pathname]);

	const handleOnline = () => {
		setIsOnline(true);
		// Дополнительные действия при восстановлении соединения
	};

	const handleOffline = () => {
		setIsOnline(false);
		// Дополнительные действия при потере соединения
	};

	useEffect(() => {
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > scrollTop && window.scrollY > 0) {
				// i`m add "&& window.scrollY >0" for Safari
				setHeaderSee(false);
				setScrollTop(window.scrollY);
			} else if (window.scrollY < scrollTop) {
				setHeaderSee(true);
				setScrollTop(window.scrollY);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollTop]);

	if (isOnline) {
		return (
			<main
				className={`main ${!isDesktop && currentPath.includes("/o/") ? `${s.main}` : ""}`}
			>
				<Routes>
					<Route
						path={"/search_result"}
						element={
							<>
								<SearchResult category={false} />
							</>
						}
					/>
					<Route
						path={"/c/:firstCategory/c2/:c2Slag/:categorySlag/*"}
						element={
							<>
								<SearchResult category={true} />
							</>
						}
					/>
					<Route
						path={"/c/:firstCategory/c2/:c2Slag/:categorySlag/search_result"}
						element={
							<>
								<SearchResult category={true} />
							</>
						}
					/>

					<Route
						path={"/"}
						element={
							<>
								{open && <PopupFonContainer onClick={() => setOpen(false)} />}
								<HomePage open={open} setOpen={setOpen} />
							</>
						}
					/>
					<Route
						path={`/user/set-password`}
						element={
							<>
								<HomePage open={open} setOpen={setOpen} />
								<RestoreForgottenPassByEmailPage />
							</>
						}
					/>
					<Route
						path={"user/activate"}
						element={
							<>
								<ApproveEmailPage />
								<HomePage open={open} setOpen={setOpen} />
							</>
						}
					/>
					<Route path={"privacy"} element={<PoliticsPrivacy />} />
					<Route path={"termsofservice"} element={<TermsOfService />} />
					<Route path={"shopping_cart"} element={<ShoppingCart />} />
					{/* {auth && <>
                    <Route path={"create_ad/*"} element={
                        <CreateBoardAd/>
                    }/>
                    <Route path={"create_ad/:noticeId"} element={
                        <CreateBoardAd/>
                    }/>
                </>}*/}

					<Route path={"*"} element={<Navigate to={"404"} />} />

					{/*Костиль через невдалу індексацію*/}
					<Route path={"/ua/ru/"} element={<Navigate to={"/"} />} />
					<Route path={"/ru/"} element={<Navigate to={"/"} />} />
					{/*=================================*/}

					<Route path={"o"} element={<OutputBoardAd type={"view"} />} />
					<Route path={"404"} element={<NewPage404 />} />
					<Route
						path={"o/:itemSlug/*"}
						element={<OutputBoardAd type={"view"} />}
					/>
					<Route
						path={"registration_auth"}
						element={
							<>
								<Mobile>
									<MobileRegistrationAuthForms />
								</Mobile>
								<Desktop>
									<HomePage open={open} setOpen={setOpen} />
								</Desktop>
							</>
						}
					/>
					<Route
						path={"attention_info"}
						element={
							<>
								<HomePage open={open} setOpen={setOpen} />
								{open && <PopupFonContainer onClick={() => setOpen(false)} />}
							</>
						}
					/>
					<Route path={"/user/activate"} element={<ApprovedEmailPopup />} />
					<Route
						path={"c/:slug/*"}
						element={
							<>
								<MainCategory open={open} setOpen={setOpen} />
								{open && <PopupFonContainer onClick={() => setOpen(false)} />}
							</>
						}
					/>
					{/*<Route path={"c/:slug/*"}
                       element={<>
                           <HomeMainCategoryNextLevel />
                           {open && <PopupFonContainer onClick={() => setOpen(false)} />}
                       </>
                       }/>*/}
					<Route
						path={"/main_category/catalog"}
						element={
							<>
								<Desktop>
									<div>Main category CATALOG</div>
								</Desktop>
								<Mobile>
									<MainCategoryCatalogMobile />
								</Mobile>
							</>
						}
					/>
					<Route
						path={`/users/auth/network?authclient=apple`}
						element={<>Auth Apple</>}
					/>
					<Route
						path={"/personal_cabinet/*"}
						element={
							auth && (
								<>
									<Desktop>
										<PersonalCabinet />
									</Desktop>
									<Mobile>
										<PersonalCabinetMobile />
									</Mobile>
								</>
							)
						}
					/>
					<Route
						path={`/static-page/:slug`}
						element={
							<>
								<StaticPageTemplate />
							</>
						}
					/>
					<Route path={"/business-page/:userId"} element={<BusinessPage />} />
				</Routes>
			</main>
		);
	} else {
		return (
			<main className={`main`}>
				<NoConnectToInet />
			</main>
		);
	}
}

export default Main;
