import React from "react";

type Props = {
	id:
		| "backArrow"
		| "closeCross"
		| "closeCrossForSelect"
		| "arrowForSelect"
		| "attention"
		| "searchLittleLupa"
		| "closeCrossForFilterSelect"
		| "locationForBestSelect"
		| "backArrowSearchMobile"
		| "arrowForBreadcrumbs";
	color?: string;
};

const ArrowsAndCross = ({ id, color }: Props) => {
	switch (id) {
		case "backArrow":
			return (
				<svg
					style={{ cursor: "pointer" }}
					xmlns="http://www.w3.org/2000/svg"
					width="13"
					height="20"
					viewBox="0 0 13 20"
					fill="none"
				>
					<path d="M11 2L3 9.65217L10.5789 18" stroke={color} strokeWidth="3" />
				</svg>
			);
		case "closeCross":
			return (
				<svg
					style={{ cursor: "pointer" }}
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
				>
					<path
						d="M9.17564 7.98187L15.8076 1.36761C15.9387 1.21497 16.0072 1.01864 15.9994 0.817837C15.9916 0.617035 15.9082 0.426555 15.7657 0.28446C15.6232 0.142366 15.4322 0.0591225 15.2309 0.0513664C15.0295 0.0436103 14.8327 0.111912 14.6796 0.242623L8.04764 6.85688L1.41563 0.234644C1.26499 0.084404 1.06068 0 0.847635 0C0.634593 0 0.430278 0.084404 0.279635 0.234644C0.128992 0.384884 0.0443614 0.588653 0.0443614 0.801125C0.0443614 1.0136 0.128992 1.21737 0.279635 1.36761L6.91964 7.98187L0.279635 14.5961C0.195889 14.6677 0.127873 14.7557 0.0798545 14.8547C0.0318359 14.9536 0.00485175 15.0615 0.000596153 15.1713C-0.00365944 15.2812 0.0149049 15.3908 0.0551246 15.4932C0.0953442 15.5956 0.156351 15.6886 0.234315 15.7663C0.312278 15.8441 0.405516 15.9049 0.508176 15.945C0.610836 15.9851 0.720702 16.0036 0.830878 15.9994C0.941053 15.9952 1.04916 15.9682 1.14841 15.9204C1.24766 15.8725 1.33592 15.8046 1.40763 15.7211L8.04764 9.10685L14.6796 15.7211C14.8327 15.8518 15.0295 15.9201 15.2309 15.9124C15.4322 15.9046 15.6232 15.8214 15.7657 15.6793C15.9082 15.5372 15.9916 15.3467 15.9994 15.1459C16.0072 14.9451 15.9387 14.7488 15.8076 14.5961L9.17564 7.98187Z"
						fill={color ? color : ""}
					/>
				</svg>
			);
		case "closeCrossForSelect":
			return (
				<svg
					style={{ cursor: "pointer" }}
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.09938 5.99991L11.7719 10.6727C12.076 10.9767 12.076 11.4681 11.7719 11.772C11.468 12.076 10.9766 12.076 10.6726 11.772L5.99993 7.09924L1.32737 11.772C1.02329 12.076 0.532001 12.076 0.228062 11.772C-0.0760205 11.4681 -0.0760205 10.9767 0.228062 10.6727L4.90062 5.99991L0.228062 1.32711C-0.0760205 1.02317 -0.0760205 0.531728 0.228062 0.227782C0.379533 0.0761642 0.578696 0 0.777716 0C0.976736 0 1.17576 0.0761642 1.32737 0.227782L5.99993 4.90058L10.6726 0.227782C10.8242 0.0761642 11.0233 0 11.2223 0C11.4213 0 11.6203 0.0761642 11.7719 0.227782C12.076 0.531728 12.076 1.02317 11.7719 1.32711L7.09938 5.99991Z"
						fill="#797979"
					/>
				</svg>
			);
		case "arrowForSelect":
			return (
				<svg
					style={{ cursor: "pointer" }}
					width="13"
					height="7"
					viewBox="0 0 13 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M1.01562 1L6.56936 5.69441L12.1427 1" stroke="#797979" />
				</svg>
			);
		case "attention":
			return (
				<svg
					style={{ cursor: "pointer" }}
					width="13"
					height="12"
					viewBox="0 0 13 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.9005 10.8331L7.14321 0.388932C7.01054 0.148273 6.76536 0 6.49997 0C6.23457 0 5.98946 0.148273 5.85673 0.388932L0.0994985 10.8331C-0.0331662 11.0738 -0.0331662 11.3703 0.0994985 11.611C0.232163 11.8517 0.477342 12 0.742737 12H12.2573C12.5227 12 12.7678 11.8517 12.9005 11.611C13.0332 11.3703 13.0332 11.0738 12.9005 10.8331ZM6.49997 10.9135C6.03067 10.9135 5.65029 10.5151 5.65029 10.0236C5.65029 9.532 6.03067 9.13367 6.49997 9.13367C6.96933 9.13367 7.34965 9.53207 7.34965 10.0236C7.34965 10.5151 6.96933 10.9135 6.49997 10.9135ZM7.44767 3.76395L6.94112 8.00302C6.91064 8.25818 6.68834 8.4392 6.44472 8.40727C6.2388 8.38031 6.08351 8.2093 6.05875 8.00302L5.55221 3.76395C5.48675 3.21571 5.8579 2.71574 6.38128 2.64711C6.90472 2.57856 7.38208 2.96729 7.4476 3.51553C7.45754 3.59817 7.4567 3.68477 7.44767 3.76395Z"
						fill="white"
					/>
				</svg>
			);
		case "searchLittleLupa":
			return (
				<svg
					style={{ cursor: "pointer" }}
					width="10"
					height="10"
					viewBox="0 0 10 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.16415 0C1.86929 0 0 1.86929 0 4.16415C0 6.45902 1.86929 8.3283 4.16415 8.3283C5.16209 8.3283 6.07857 7.97393 6.79684 7.38568L9.28313 9.87197C9.3215 9.91193 9.36746 9.94384 9.41832 9.96582C9.46917 9.9878 9.5239 9.99941 9.5793 9.99998C9.6347 10.0005 9.68966 9.99005 9.74095 9.9691C9.79225 9.94816 9.83885 9.9172 9.87802 9.87802C9.9172 9.83885 9.94816 9.79225 9.9691 9.74095C9.99005 9.68966 10.0005 9.6347 9.99998 9.5793C9.99941 9.5239 9.9878 9.46917 9.96582 9.41832C9.94384 9.36746 9.91193 9.3215 9.87197 9.28313L7.38568 6.79684C7.97393 6.07857 8.3283 5.16209 8.3283 4.16415C8.3283 1.86929 6.45902 0 4.16415 0ZM4.16415 0.83283C6.00892 0.83283 7.49547 2.31938 7.49547 4.16415C7.49547 6.00892 6.00892 7.49547 4.16415 7.49547C2.31938 7.49547 0.83283 6.00892 0.83283 4.16415C0.83283 2.31938 2.31938 0.83283 4.16415 0.83283Z"
						fill="#939393"
					/>
				</svg>
			);
		case "closeCrossForFilterSelect":
			return (
				<svg
					style={{ cursor: "pointer" }}
					width="11"
					height="11"
					viewBox="0 0 11 11"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M6.50776 5.49992L10.7909 9.78332C11.0697 10.0619 11.0697 10.5124 10.7909 10.791C10.5123 11.0697 10.0619 11.0697 9.78324 10.791L5.49993 6.50764L1.21676 10.791C0.938014 11.0697 0.487668 11.0697 0.209056 10.791C-0.0696855 10.5124 -0.0696855 10.0619 0.209056 9.78332L4.49224 5.49992L0.209056 1.21652C-0.0696855 0.937903 -0.0696855 0.487417 0.209056 0.2088C0.347906 0.0698175 0.530471 0 0.712906 0C0.895341 0 1.07778 0.0698175 1.21676 0.2088L5.49993 4.4922L9.78324 0.2088C9.92222 0.0698175 10.1047 0 10.2871 0C10.4695 0 10.652 0.0698175 10.7909 0.2088C11.0697 0.487417 11.0697 0.937903 10.7909 1.21652L6.50776 5.49992Z" />
				</svg>
			);
		case "locationForBestSelect":
			return (
				<svg
					style={{ cursor: "pointer" }}
					width="15"
					height="20"
					viewBox="0 0 15 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.72922 19.5965C1.05352 11.3684 0 10.5239 0 7.5C0 3.35785 3.35785 0 7.5 0C11.6421 0 15 3.35785 15 7.5C15 10.5239 13.9465 11.3684 8.27078 19.5965C7.89832 20.1345 7.10164 20.1345 6.72922 19.5965ZM7.5 10.625C9.2259 10.625 10.625 9.2259 10.625 7.5C10.625 5.7741 9.2259 4.375 7.5 4.375C5.7741 4.375 4.375 5.7741 4.375 7.5C4.375 9.2259 5.7741 10.625 7.5 10.625Z"
						fill={color}
					/>
				</svg>
			);
		case "arrowForBreadcrumbs":
			return (
				<svg
					width="7"
					height="11"
					viewBox="0 0 7 11"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M1 10L6 5.5L1 1" stroke={color} />
				</svg>
			);
		case "backArrowSearchMobile":
			return (
				<svg
					width="9"
					height="19"
					viewBox="0 0 9 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.32031 1L1.04741 9.69983L8.41473 18.3506"
						stroke="#797979"
					/>
				</svg>
			);
		default:
			return <svg></svg>;
	}
};

export default ArrowsAndCross;
