import React, { useState, useEffect } from "react";
import s from "./FilterComponent.module.css";
import style from "../../pages/CreateBoardAD/CreateBoardAD.module.css";
import { useTranslation } from "react-i18next";
import { valuesCreateAD } from "../../types/userTypes";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import useFilterHandle from "../../CustomHooks/useFilterHandle";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { NoticeFilterType } from "../../types/noticeTypes";
type Props = {
	filter: NoticeFilterType | any;
	values?: valuesCreateAD;
	filtersAll: [];
};

const FilterTypeCheckbox = ({ filter, values }: Props) => {
	const { i18n } = useTranslation();
	const filtersMany = useSelector(
		(state: StateType) => state.noticeData.filtersMany
	);
	const filtersOneIs = useSelector(
		(state: StateType) => state.noticeData.filtersOneIs
	);
	const { handleFilter } = useFilterHandle();
	const [collapsed, setCollapsed] = useState(true);
	useEffect(() => {
		if (filter?.showOpened) {
			setCollapsed(false);
		}
	}, [filter]);

	return (
		<div className={s.container}>
			<div className={`${s.name} ${s.nameAndCross}`}>
				{!filter?.needs ? (
					filter?.propertyName
				) : (
					<p>
						{filter?.propertyName} <span style={{ color: "red" }}>*</span>
					</p>
				)}
				{filter && (
					<span
						style={{
							transform: collapsed ? "" : "rotate(180deg)",
							transition: "0.5s",
						}}
						className={s.colorFilterArrow}
						onClick={() => setCollapsed(!collapsed)}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				)}
			</div>
			{!collapsed &&
				values &&
				filter?.propertyValues.map((checkbox: any) => {
					return (
						<div key={checkbox.id} className={style.createAdCheckboxContainer}>
							<input
								id={`checkBox${checkbox.id}`}
								className={`${style.createAdCheckbox}`}
								checked={
									filtersMany.findIndex(
										(item: any) =>
											item.id === checkbox.id &&
											item.filter === filter.inputName
									) !== -1 ||
									filtersOneIs.findIndex(
										(item: any) =>
											item.id === checkbox.id &&
											item.filter === filter.inputName
									) !== -1
								}
								type="checkbox"
								onChange={() => {
									handleFilter({ element: checkbox, filter });
								}}
							/>
							<label htmlFor={`checkBox${checkbox.id}`}>
								<span>
									{i18n.language === "ru" ? checkbox.name_ru : checkbox.name}
								</span>
							</label>
						</div>
					);
				})}
		</div>
	);
};

export default FilterTypeCheckbox;
