import React from "react";
import s from "./LanguageSelector.module.css";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

interface Props {
	position: string;
	open: boolean;
	setOpenLanguage: (d: boolean) => void;
}

function LanguageSelector({ position, open, setOpenLanguage }: Props) {
	const location = useLocation();
	const { i18n } = useTranslation();
	const currentLanguage = i18n.language;

	if (currentLanguage !== "uk" && currentLanguage !== "ru") {
		changeLanguage("uk");
	}
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const handleLanguage = (lang: string) => {
		if (lang === "ru") {
			changeLanguage("ru");
			setOpenLanguage(false);
			let pathname = location.pathname;
			if (Array.isArray(segmentsArray) && segmentsArray[0] === "ua") {
				const newSegments = segmentsArray.slice(1);
				pathname = newSegments.join("/");
			}
			window.history.replaceState({}, "", `${pathname}`);
		}
		if (lang === "uk") {
			changeLanguage("uk");
			setOpenLanguage(false);
			let pathname = "";
			if (
				location.pathname !== "/" &&
				Array.isArray(segmentsArray) &&
				segmentsArray[0] !== "ua"
			) {
				pathname = location.pathname;
			}
			window.history.replaceState({}, "", `ua${pathname}`);
		}
	};
	return (
		<div onClick={() => setOpenLanguage(!open)} className={s.container}>
			<h5>
				{currentLanguage === "uk"
					? "ua"
					: currentLanguage !== "uk" && currentLanguage !== "ru"
						? "ua"
						: currentLanguage}
			</h5>
			<svg
				width="7"
				height="3"
				viewBox="0 0 7 3"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M3.5 3L6.53109 0H0.468911L3.5 3Z" fill="#939393" />
			</svg>
			{open && (
				<div
					className={
						position === "top"
							? s.languageSelectPopupTop
							: s.languageSelectPopupBottom
					}
				>
					<div className={s.popupWrapper} />
					<span
						className={currentLanguage === "ru" ? s.active : ""}
						onClick={() => handleLanguage("ru")}
					>
						ru
					</span>
					<span
						className={currentLanguage === "uk" ? s.active : ""}
						onClick={() => handleLanguage("uk")}
					>
						ua
					</span>
				</div>
			)}
		</div>
	);
}

export default LanguageSelector;
