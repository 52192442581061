import React from "react";
import s from "../MainSearch.module.css";
import MainSearchSvgSelector from "../MainSearchSvgSelector";
import { useTranslation } from "react-i18next";
import { Mobile } from "../../../responsive";

const MobileSearchButton: React.FC<{
	searchInputValue: string;
	handleClickButton?: any;
}> = ({ searchInputValue, handleClickButton }) => {
	const { t } = useTranslation();
	return (
		<Mobile>
			<button className={s.button} type={"submit"} onClick={handleClickButton}>
				<MainSearchSvgSelector id={"lupa"} />
				<p>{t(`Шукати `) + `"${searchInputValue}"` + t(` у всіх рубриках`)}</p>
			</button>
		</Mobile>
	);
};

export default MobileSearchButton;
