import React, { useEffect, useState } from "react";
import s from "./SearchFilters.module.css";
//import {useTranslation} from "react-i18next";
import {
	Filter,
	FiltersValue,
	PropertyValues,
} from "../../types/publicNoticeTypes";
import FilterFrontHead from "./FilterFrontHead/FilterFrontHead";
import { handleClickSearchFilter } from "../../supporting";
import i18n from "i18next";

type Props = {
	filter: Filter;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	showOpened: number;
	setLastChangedFilter: (b: number) => void;
	lastChangedFilter: number;
};

const FilterFrontColor = ({
	filter,
	filtersValues,
	setFiltersValues,
	showOpened,
	setLastChangedFilter,
	lastChangedFilter,
}: Props) => {
	// const {i18n,t} = useTranslation()
	const [isExpanded, setExpanded] = useState(showOpened === 1);
	const clearFilter = () => {
		const updateFiltersValues = [...filtersValues].filter((f: FiltersValue) => {
			return f.filter !== filter.inputName;
		});
		setFiltersValues(updateFiltersValues);
	};
	const [isNeedClear, setIsNeedClear] = useState(false);
	const checked = filtersValues.findIndex(
		(f: FiltersValue) => f.filter === filter.inputName
	);
	useEffect(() => {
		if (checked !== -1) {
			setIsNeedClear(true);
		} else {
			setIsNeedClear(false);
		}
	}, [checked]);
	return (
		<div
			className={s.filterContainer}
			style={{
				background: isExpanded ? "#F8F8F8" : "",
			}}
		>
			<FilterFrontHead
				name={filter.propertyName}
				isExpanded={isExpanded}
				setExpanded={setExpanded}
				isNeedClear={isNeedClear}
				clearFilter={clearFilter}
			/>
			{isExpanded && (
				<div className={s.filerContent}>
					<ul className={s.colorsListBig}>
						{filter &&
							filter?.propertyValues.map((color: PropertyValues) => {
								const isActive =
									filtersValues.findIndex(
										(item: any) =>
											item.value === color.id.toString() &&
											item.filter === filter.inputName
									) !== -1;
								//if (+color.countItem.countItems > 0 || filter.id ===lastChangedFilter)
								return (
									<li
										className={s.bigListElement}
										key={color.id}
										onClick={() => {
											setLastChangedFilter(filter.id);
											handleClickSearchFilter(
												+color.id,
												filtersValues,
												filter,
												setFiltersValues
											);
										}}
									>
										{color.colorFileUrl !== null ? (
											<span className={`${s.colorExampleImage}`}>
												<img
													className={`${s.colorExample}
                                 ${isActive ? s.active : ""}`}
													src={color.colorFileUrl}
													alt="color"
												/>
											</span>
										) : (
											<span
												className={`${s.colorExample}
                                 ${isActive ? s.active : ""}`}
												style={{
													background:
														color?.colorHex !== null
															? color.colorHex.toString()
															: color?.colorFileUrl
																? color.colorFileUrl
																: "inherit",
												}}
											></span>
										)}
										<div
											className={s.colorNameContainer}
											style={{ color: "#3D3D3D" }}
										>
											<div className={s.colorName}>
												{i18n.language === "ru"
													? color?.name_ru
													: color.name || ""}
											</div>
											<div className={s.colorCounter}>
												{!isActive ? color.countItem.countItems : ""}
											</div>
										</div>{" "}
									</li>
								);
							})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FilterFrontColor;
