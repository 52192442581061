import React, { useState, useEffect } from "react";
import s from "./SearchFilters.module.css";
import { NoticeFilterType } from "../../types/noticeTypes";
import { valuesCreateAD } from "../../types/userTypes";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useTranslation } from "react-i18next";
import useFilterHandle from "../../CustomHooks/useFilterHandle";
import { PropertyValues } from "../../types/publicNoticeTypes";

type Props = {
	filter: NoticeFilterType | any;
	values?: valuesCreateAD;
	filtersMany: [];
	filtersOneIs: [];
};

const FilterColor = ({ filter, filtersMany, filtersOneIs }: Props) => {
	const { i18n, t } = useTranslation();
	const [collapsed, setCollapsed] = useState(true);
	//const allFilters = [...filtersMany,...filtersOneIs]
	const { handleFilter } = useFilterHandle();
	useEffect(() => {
		if (filter?.showOpened) {
			setCollapsed(false);
		}
	}, [filter]);
	return (
		<div className={s.filterContainer}>
			<div className={s.filterTitle}>
				<h5>
					{!filter?.needs ? (
						filter?.propertyName
					) : (
						<p>
							{filter?.propertyName} <span style={{ color: "red" }}>*</span>
						</p>
					)}
					{!filter && (
						<span
							className={s.colorFilterArrow}
							onClick={() => setCollapsed(!collapsed)}
						>
							<svg
								style={{
									transform: collapsed ? "" : "rotate(180deg)",
									transition: "0.5s",
								}}
								width="11"
								height="7"
								viewBox="0 0 11 7"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1 1L5.5 6L10 1" stroke="#3D3D3D" />
							</svg>
						</span>
					)}
				</h5>
				{!filter && <span>{t("Скинути")}</span>}
				{filter && (
					<span
						style={{
							transform: collapsed ? "" : "rotate(180deg)",
							transition: "0.5s",
						}}
						className={s.colorFilterArrow}
						onClick={() => setCollapsed(!collapsed)}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				)}
			</div>
			{!collapsed && (
				<div
					className={s.filerContent}
					style={{ borderTop: filter ? "1px solid #909090" : "" }}
				>
					<ul className={s.colorsListBig}>
						{filter
							? filter?.propertyValues.map((color: PropertyValues) => {
									return (
										<li
											className={s.bigListElement}
											key={color.id}
											onClick={() => {
												handleFilter({ element: color, filter });
											}}
										>
											{color.colorFileUrl !== null ? (
												<span className={`${s.colorExampleImage}`}>
													<img
														className={`${s.colorExample} 
                                                ${
																									filtersMany.findIndex(
																										(item: any) =>
																											item.id === color.id &&
																											item.filter ===
																												filter.inputName
																									) !== -1 ||
																									filtersOneIs.findIndex(
																										(item: any) =>
																											item.id === color.id &&
																											item.filter ===
																												filter.inputName
																									) !== -1
																										? s.active
																										: ""
																								}`}
														src={color.colorFileUrl}
														alt={"color"}
													/>
												</span>
											) : (
												<span
													className={`${s.colorExample}
                                                ${
																									filtersMany.findIndex(
																										(item: any) =>
																											item.id === color.id &&
																											item.filter ===
																												filter.inputName
																									) !== -1 ||
																									filtersOneIs.findIndex(
																										(item: any) =>
																											item.id === color.id &&
																											item.filter ===
																												filter.inputName
																									) !== -1
																										? s.active
																										: ""
																								}`}
													style={{
														background: color?.colorHex
															? color.colorHex.toString()
															: "inherit",
													}}
												></span>
											)}
											<span
												className={s.colorName}
												style={{ color: "#3D3D3D" }}
											>
												{i18n.language === "ru"
													? color?.name_ru
													: color.name || ""}
											</span>
										</li>
									);
								})
							: filter.propertyValues.map((filter: any) => {
									return (
										<li className={s.bigListElement}>
											<span
												className={s.colorExample}
												style={{
													background:
														filter?.colorHex !== null
															? filter.colorHex.toString()
															: filter?.colorFileUrl
																? filter.colorFileUrl
																: "inherit",
												}}
											>
												{JSON.parse(filter?.params).colorHex
													? ""
													: filter?.params}{" "}
											</span>
											<span className={s.colorName}>
												{i18n.language === "ru"
													? filter?.name_ru
													: filter?.name}
											</span>
										</li>
									);
								})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FilterColor;
