import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, useParams } from "react-router-dom";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import useAutoCategorySelection from "../../CustomHooks/useAutoCategorySelection";
import {
	getAutoSelection,
	setDefaultAutoSelection,
} from "../../redux/autoSelection_Reducer";
import {
	categoryStateType,
	setClearFiltersList,
} from "../../redux/category_Reducer";
import {
	getNewNotice,
	getNoticeByIdForEdit,
	setClearTMP,
	setTmpNotice,
} from "../../redux/notice_Reducer";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { scrollToTop } from "../../supporting";
import { NoticeForPerView } from "../../types/noticeTypes";
import { OneCategoryType } from "../../types/reduxStoreTypes";
import { valuesCreateAD } from "../../types/userTypes";
import AutoContinueCreate from "./Components/AutoContinueCreate";
import BigRubricsChanger from "./Components/BigRubricsChanger";
import BlockState from "./Components/BlockState";
import ChangePhotoDropZone from "./Components/ChangePhotoDropZone";
import ChangePhotoDropZoneMobile from "./Components/ChangePhotoDropZoneMobile";
import ChangeSettlement from "./Components/ChangeSettlement";
import ContactsSellerBlockCreate from "./Components/ContactsSellerBlockCreate";
import CreateBoardADSvg from "./Components/CreateBoardADSvg";
import DescriptionField from "./Components/DescriptionField";
import DontShowOtherAds from "./Components/DontShowOtherAds";
import PriceField from "./Components/PriceField";
import s from "./CreateBoardAD.module.css";
import style from "./CreateBoardADMobile.module.css";
import NameField from "./Components/NameField";

/*type FunctionType = (...args: any[]) => void;*/

/*interface DebounceData {
	lastCall: number;
	lastCallTimer?: ReturnType<typeof setTimeout>;
}*/

const CreateBoardAd = () => {
	const dispatch: any = useDispatch();
	const [isShowAutomaticSelection, setIsShowAutomaticSelection] =
		useState(false);
	const [
		isShowDefaultWindowDropZoneMobile,
		setIsShowDefaultWindowDropZoneMobile,
	] = useState(false);
	const [errorRef, setErrorRef] = useState(null);
	const { noticeId } = useParams();
	const createNotice = useSelector(() => getNewNotice);
	const setNoticeToTemp = useSelector(() => setTmpNotice);
	const getNoticeView = useSelector(() => getNoticeByIdForEdit);
	//const getAutoSelectionStore = useSelector(() => getAutoSelection);
	const removeAllTMP = useSelector(() => setClearTMP);
	const setDefaultAutoSelectionStore = useSelector(
		() => setDefaultAutoSelection
	);
	const createSuccessfully = useSelector(
		(state: StateType) => state.noticeData.createdSuccess
	);
	const autoSelectionOne = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionOne
	);
	const autoSelectionChoosing = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionOneChoosing
	);
	const isCloseAutoSelectionStore = useSelector(
		(state: StateType) => state.autoSelection.isCloseAutoSelection
	);
	const filters = useSelector(
		(state: categoryStateType) => state.categoryData.filters
	);
	const clearFilters = useSelector(() => setClearFiltersList);
	const filtersVariable: Record<string, string | null> = useSelector(
		(state: categoryStateType) => state.categoryData.filtersVariables
	);
	const userView = useSelector((state: StateType) => state.userData.user);
	const tmpNotice = useSelector(
		(state: StateType) => state.noticeData.tmpNoticeForPerView
	);
	const noticeView = useSelector(
		(state: StateType) => state.noticeData.noticeView
	);
	const blockState = useSelector(
		(state: StateType) => state.categoryData.blockState
	);
	const blockDontShowOtherAds = useSelector(
		(state: StateType) => state.categoryData.blockDontShowOtherAds
	);
	const blockAutoContinue = useSelector(
		(state: StateType) => state.categoryData.blockAutoContinue
	);
	const blockAddFilters = useSelector(
		(state: StateType) => state.categoryData.blockAddFilters
	);
	const currentCategory: OneCategoryType = useSelector(
		(state: StateType) => state.categoryData.view
	);
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const noticePhotoRef = useRef<HTMLDivElement>(null);
	const noticeDescriptionRef = useRef<HTMLDivElement>(null);
	const noticeStateRef = useRef<HTMLDivElement>(null);
	const noticePriceRef = useRef<HTMLDivElement>(null);
	const noticeSettlementRef = useRef<HTMLDivElement>(null);
	const noticeFiltersRef = useRef<HTMLDivElement>(null);
	const submitButton = document.activeElement?.getAttribute("name");

	type Item = {
		name: string;
		value: string;
		id: string;
		filter: string;
	};
	const filtersReduce = (filters: Item[]) => {
		const properties: any[] = [];
		filters.forEach((item: Item) => {
			if (item.name) {
				const existingIndex = properties.findIndex(
					(entry: any) => entry.propertyName === item.name
				);
				if (existingIndex !== -1) {
					// If propertyName exists, update propertyValues
					properties[existingIndex] = {
						...properties[existingIndex],
						propertyValues: `${
							properties[existingIndex].propertyValues
						},${String(item.value === "" ? item.id : item.value)}`,
					};
				} else {
					// If propertyName does not exist, add a new entry
					properties.push({
						filterPosition: item.filter,
						propertyName: item.name,
						propertyValues: String(item.value === "" ? item.id : item.value),
					});
				}
			}
		});
		return properties;
	};

	const handlePerView = (values: valuesCreateAD) => {
		const infoData: NoticeForPerView = {
			title: values.name,
			price: values.price,
			sellerName: userView?.name || "",
			description: values.description,
			state: values.state,
			settlementId: values?.settlement_value || "",
			settlementName: values?.settlement_label || "",
			categoryId: values?.category_id_value,
			categoryName: values?.category_id_label,
			price_type: values?.price_type || "",
			ownerData: { online: false, time_last_visit: null, photoUrl: null },
			notice_properties: filtersReduce(values?.filters) || [],
			filters: values.filters || [],
		};
		dispatch(setNoticeToTemp(infoData));
	};

	const updatedFilters = filters.map((filter: any) => {
		const filterId = `filter_${filter.id}`;
		if (filtersVariable.hasOwnProperty(filterId)) {
			filter.value = filtersVariable[filterId];
		}
		return filter;
	});
	useEffect(() => {
		if (noticeId) {
			dispatch(getNoticeView(+noticeId));
		}
	}, [noticeId, i18n.language]);

	useEffect(() => {
		return () => {
			dispatch(setDefaultAutoSelectionStore());
		};
	}, []);

	useEffect(() => {
		if (errorRef)
			if (Object.keys(errorRef).length > 0) {
				const keys = Object.keys(errorRef);
				if (keys.length > 0) {
					const firstKey = keys[0];
					switch (true) {
						case ["name", "category_id", "description", "image"].includes(
							firstKey
						):
							scrollToTop();
							break;
						case ["state", "price"].includes(firstKey):
							if (noticeDescriptionRef && noticeDescriptionRef.current) {
								noticeDescriptionRef.current.scrollIntoView({
									behavior: "smooth",
									block: "start",
								});
							}
							break;
						case firstKey.startsWith("filter_"):
							if (noticeFiltersRef && noticeFiltersRef.current) {
								noticeFiltersRef.current.scrollIntoView({
									behavior: "smooth",
									block: "start",
								});
							}
							break;
						case firstKey === "settlement_id":
							if (noticeSettlementRef && noticeSettlementRef.current) {
								noticeSettlementRef.current.scrollIntoView({
									behavior: "smooth",
									block: "start",
								});
							}
							break;
					}
				}
			}
	}, [errorRef]);

	const initialValues: valuesCreateAD = {
		name: tmpNotice?.title || noticeView?.name || "",
		description: tmpNotice?.description || noticeView?.description || "",
		category_id_value:
			currentCategory.id || noticeView?.category_id?.toString() || "",
		category_id_label: currentCategory.name || noticeView?.category_name || "",
		price: tmpNotice?.price || noticeView?.price || "",
		price_type: noticeView?.price_type || "",
		quantityStock: "",
		state: noticeView?.state || "",
		settlement_value: noticeView?.settlement_id?.toString() || "",
		settlement_label: noticeView?.settlement_name || "",
		contactName: userView?.name || "",
		contactPhone: userView?.phone || "",
		not_show_other: +noticeView.not_show_other || 0,
		auto_continue: noticeView.auto_continue || 0,
		auto_lift_days: noticeView?.auto_lift_days?.toString() || null,
		top_days: noticeView.top_days?.toString() || "",
		noticeImagesString: "",
		whatsapp: userView?.whatsapp || null,
		viber: userView?.viber || null,
		telegram: userView?.telegram || null,
		filters: tmpNotice.filters || [],
		notice_properties: {},
		publish: null,
	};

	const initialValuesCreate: valuesCreateAD = {
		name: tmpNotice?.title || "",
		description: tmpNotice?.description || "",
		category_id_value: currentCategory.id || tmpNotice?.categoryId || "",
		category_id_label: currentCategory.name || tmpNotice?.categoryName || "",
		price: tmpNotice?.price || "",
		price_type: tmpNotice?.price_type || "",
		quantityStock: "",
		state: tmpNotice?.state || "",
		settlement_value: tmpNotice?.settlementId || "",
		settlement_label: tmpNotice?.settlementName || "",
		contactName: userView?.name || "",
		contactPhone: userView?.phone || "",
		not_show_other: +noticeView.not_show_other || 0,
		auto_continue: noticeView.auto_continue || 0,
		auto_lift_days: noticeView?.auto_lift_days?.toString() || null,
		top_days: noticeView.top_days?.toString() || "",
		noticeImagesString: "",
		whatsapp: userView?.whatsapp || null,
		viber: userView?.viber || null,
		telegram: userView?.telegram || null,
		filters: tmpNotice.filters || [],
		notice_properties: tmpNotice.notice_properties,
		publish: null,
	};

	const goBack = () => {
		navigate(-1);
	};

	/*const obj = useRef<DebounceData>({ lastCall: 0 });*/

	/*const request = (e: any) => {
		if (!noticeId) {
			dispatch(getAutoSelectionStore(e));
		}
	};*/

/*	const debounce = (fn: FunctionType, timeoutMs: number) => {
		return function perform(...args: any[]) {
			const previousCall = obj.current.lastCall;
			obj.current.lastCall = Date.now();
			if (previousCall && obj.current.lastCall - previousCall <= timeoutMs) {
				clearTimeout(obj.current.lastCallTimer);
			}
			obj.current.lastCallTimer = setTimeout(() => fn(...args), timeoutMs);
		};
	};*/

	/*const debounceRequest = debounce(request, 1000);*/

/*	const validate = (values: any) => {
		const errors: any = { name: "" };
		if (values.name.length > 150) {
			// setErrorRef(errors)
			//scrollToTop("smooth")
			errors.name = t("Не більше 150 символів");
		}
		if (values.name.length < 16) {
			//scrollToTop("smooth")
			//setErrorRef(errors)
			errors.name = t("Не менше 16 символів");
		}

		// Другие правила валидации по необходимости
		return errors.name;
	};*/
	/*const validateName = (values: any) => {
		const errors: any = { name: "" };

		if (values.name.length > 15 && values.name.length < 150) {
			debounceRequest(values?.name);
		} else if (values.name.length < 15) {
			errors.name = t("Не менше 15 символів");
		} else if (values.name.length > 150) {
			errors.name = t("Не більше 150 символів");
		}
		return errors.name;
	};*/
	const submitCreateNotice = (values: any, submitProps: FormikHelpers<any>) => {
		if (createSuccessfully && submitButton !== "preview") {
			submitProps?.resetForm();
			dispatch(clearFilters());
			dispatch(removeAllTMP());
		}

		let type = "";
		if (!noticeId && submitButton !== "preview") {
			type = `create`;
		} else if (noticeId && submitButton !== "preview") {
			type = `update?id=${noticeId}`;
		} else if (noticeId && submitButton === "preview") {
			type = `update?onlyValidate=1&id=${noticeId}`;
		} else if (!noticeId && submitButton === "preview") {
			type = "create?onlyValidate=1";
		}

		dispatch(createNotice(values, submitProps.setStatus, type, navigate));
	};

	/* */
	if (createSuccessfully) {
		return <Navigate to={"/personal_cabinet/ads/active"} />;
	} else {
		return (
			<>
				<Desktop>
					<div className={"wrapper"}>
						<Header
							headerSee={true}
							openEnterDialog={() => {
								console.log("");
							}}
						/>
						<div className={s.wrapper}>
							<div
								className={`${s.createADContainer}`}
								style={{ overflow: "hidden" }}
							>
								<h1>
									{!noticeId
										? t(`Створити оголошення`)
										: t(`Редагувати оголошення`)}
								</h1>
								<div className={s.formContainer}>
									<Formik
										enableReinitialize
										initialValues={
											noticeId ? initialValues : initialValuesCreate
										}
										onSubmit={(values, submitProps) => {
											submitCreateNotice(values, submitProps);
										}}
									>
										{({
											values,
											status: formikStatus,
											handleSubmit,
											setFieldValue,
											setStatus,
										}) => {
											useEffect(() => {
												setStatus(formikStatus);
												setErrorRef(formikStatus);
											}, [formikStatus]);
											// no best! В этом месте сетается подобранная автоматом рубрика
											useAutoCategorySelection(
												values,
												autoSelectionOne,
												setFieldValue
											);

											return (
												<Form className={s.form} onSubmit={handleSubmit}>
													<NameField
														formikStatus={formikStatus}
														setStatus={setStatus}
														values={values}
													/>
													<BigRubricsChanger
														values={values}
														setStatus={setStatus}
														status={formikStatus}
														setFieldValue={setFieldValue}
														autoSelectionChoosing={autoSelectionChoosing}
														autoSelectionOne={autoSelectionOne}
														isCloseAutoSelectionStore={
															isCloseAutoSelectionStore
														}
														isShowAutomaticSelection={isShowAutomaticSelection}
														noticeId={noticeId}
														setIsShowAutomaticSelection={
															setIsShowAutomaticSelection
														}
													/>
													<div className={`${s.formElement} ${s.photo}`}>
														<div className={s.nameSide} ref={noticePhotoRef}>
															<span className={s.elementName}>{t(`Фото`)}</span>
															<span className={s.required}> * </span>
														</div>
														{/*<ChangeLayotZone  setFieldValue={setFieldValue}/>*/}
														<ChangePhotoDropZone
															setFieldValue={setFieldValue}
														/>
														{/*<TestDropZone setFieldValue={setFieldValue} />*/}
														{/* {status && status.maxPhotosNumber &&
                                            <span className={s.error}>{status.maxPhotosNumber}</span>}*/}
														{formikStatus && formikStatus.image && (
															<span className={s.error}>
																{formikStatus.image}
															</span>
														)}
													</div>
													<DescriptionField
														setFieldValue={setFieldValue}
														values={values}
														status={formikStatus}
														noticeDescriptionRef={noticeDescriptionRef}
														setStatus={setStatus}
													/>
													{blockState && (
														<BlockState
															status={formikStatus}
															setFieldValue={setFieldValue}
															values={values}
															setStatus={setStatus}
															ref={noticeStateRef}
														/>
													)}
													<div className={`${s.formElement} ${s.priceField}`}>
														<PriceField
															ref={noticePriceRef}
															status={formikStatus}
															setFieldValue={setFieldValue}
															values={values}
															setStatus={setStatus}
														/>
													</div>

													{filters?.length !== 0 && blockAddFilters && (
														<div
															className={`${s.formElement} ${s.filtersList}`}
															ref={noticeFiltersRef}
														>
															<div className={s.filterListName}>
																<span className={s.elementName}>
																	{t(`Додаткові характеристики`)}
																</span>
															</div>
															<div className={s.filtersList_List}>
																{updatedFilters.map((filter: any) => {
																	return (
																		<div key={filter.id}>
																			<FilterComponent
																				values={values}
																				filter={filter}
																				status={formikStatus}
																				setFieldValue={setFieldValue}
																			/>
																		</div>
																	);
																})}
																{formikStatus && formikStatus.filters && (
																	<span className={s.error}>
																		{formikStatus.filters}
																	</span>
																)}
															</div>
														</div>
													)}
													{blockAutoContinue && (
														<AutoContinueCreate
															values={values}
															setFieldValue={setFieldValue}
														/>
													)}
													<div className={s.formElement}>
														<ChangeSettlement
															ref={noticeSettlementRef}
															values={values}
															setStatus={setStatus}
															status={formikStatus}
															setFieldValue={setFieldValue}
														/>
													</div>
													<ContactsSellerBlockCreate
														values={values}
														setFieldValue={setFieldValue}
													/>
													{/*{blockDontShowOtherAds &&
                                            <DontShowOtherAds setFieldValue={setFieldValue} values={values}/>
                                        }*/}
													<div className={s.buttons}>
														<button
															type={"submit"}
															name={"preview"}
															onClick={() => handlePerView(values)}
														>
															{t(`Переглянути`)}
														</button>
														<button
															type={"submit"}
															name={"public"}
															onClick={() => setFieldValue("publish", 1)}
															className={
																values.name !== "" ||
																values.description !== "" ||
																+values.category_id_value !== 0 ||
																values.price !== "0" ||
																+values.settlement_value !== 0
																	? s.touched
																	: ""
															}
														>
															{noticeId ? t("Зберегти") : t(`Публікувати`)}
														</button>
													</div>
												</Form>
											);
										}}
									</Formik>
								</div>
								<div className={s.descriptionFields}>
									<span className={s.required}>* </span>
									{t("поля обов'язкові для заповненя")}
								</div>
							</div>
						</div>
						<Footer />
					</div>
				</Desktop>
				<Mobile>
					<div className={style.wrapper}>
						<div className={`${style.createADContainer}`}>
							<div className={style.titleWrap} onClick={goBack}>
								<CreateBoardADSvg id="arrowBack" />
								<h1>
									{!noticeId
										? t(`Створити оголошення`)
										: t(`Редагувати оголошення`)}
								</h1>
							</div>
							<Formik
								enableReinitialize
								initialValues={noticeId ? initialValues : initialValuesCreate}
								onSubmit={(values, submitProps) => {
									submitCreateNotice(values, submitProps);
								}}
							>
								{({
									values,
									status: formikStatus,
									handleSubmit,
									setFieldValue,
									setStatus,
								}) => {
									useEffect(() => {
										setErrorRef(formikStatus);
										setStatus(formikStatus);
									}, [formikStatus]);
									// no best! В этом месте сетается подобранная автоматом рубрика
									useAutoCategorySelection(
										values,
										autoSelectionOne,
										setFieldValue
									);
									return (
										<Form className={`${s.form}`} onSubmit={handleSubmit}>
											<div
												className={`${style.formElement} ${s.photo}`}
												ref={noticePhotoRef}
											>
												{!isShowDefaultWindowDropZoneMobile && (
													<div className={s.nameSide}>
														<span className={s.elementName}>
															{t(`Додати фото`)}
														</span>
													</div>
												)}
												<ChangePhotoDropZoneMobile
													setFieldValue={setFieldValue}
													isShowDefaultWindow={
														isShowDefaultWindowDropZoneMobile
													}
													setIsShowDefaultWindow={
														setIsShowDefaultWindowDropZoneMobile
													}
												/>
												{formikStatus && formikStatus.maxPhotosNumber && (
													<span className={s.error}>
														{formikStatus.maxPhotosNumber}
													</span>
												)}
												{formikStatus && formikStatus.image && (
													<span className={s.error}>{formikStatus.image}</span>
												)}
											</div>
											{/*<div className={style.formElement}>
												<div className={s.nameSide}>
													<span className={`${s.elementName}`}>
														{t(`Заголовок оголошення`)}
														<span className={style.required}> * </span>
													</span>
												</div>
												<div
													className={`${style.fieldSide} ${s.adTitle} ${
														formikStatus?.name ? s.error : ""
													}`}
												>
													<Field
														onFocus={() => setStatus({})}
														name={"name"}
														value={values.name}
														onBlur={() => validate(values)}
														validate={() => validateName(values)}
														placeholder={`${t("Введіть назву оголошення")}...`}
														autoComplete="off"
														//    validateOnBlur
													/>
												</div>
												<ErrorMessage
													name="name"
													component="span"
													className={s.error}
												/>
												{formikStatus && formikStatus.name && (
													<span className={s.error}>{formikStatus.name}</span>
												)}
											</div>*/}
											<NameField
												formikStatus={formikStatus}
												setStatus={setStatus}
												values={values}
											/>
											<BigRubricsChanger
												values={values}
												setStatus={setStatus}
												status={formikStatus}
												setFieldValue={setFieldValue}
												autoSelectionChoosing={autoSelectionChoosing}
												autoSelectionOne={autoSelectionOne}
												isCloseAutoSelectionStore={isCloseAutoSelectionStore}
												isShowAutomaticSelection={isShowAutomaticSelection}
												noticeId={noticeId}
												setIsShowAutomaticSelection={
													setIsShowAutomaticSelection
												}
											/>
											<DescriptionField
												setFieldValue={setFieldValue}
												values={values}
												status={formikStatus}
												noticeDescriptionRef={noticeDescriptionRef}
												setStatus={setStatus}
											/>
											{blockState && (
												<BlockState
													status={formikStatus}
													setFieldValue={setFieldValue}
													values={values}
													setStatus={setStatus}
													ref={noticeStateRef}
												/>
											)}
											<div className={`${style.formElement} ${s.priceField}`}>
												<PriceField
													ref={noticePriceRef}
													status={formikStatus}
													setFieldValue={setFieldValue}
													values={values}
													setStatus={setStatus}
												/>
											</div>
											{filters?.length !== 0 && blockAddFilters && (
												<div className={`${s.formElement} ${s.filtersList}`}>
													<div className={s.filterListName}>
														<span
															className={s.elementName}
															ref={noticeFiltersRef}
														>
															{t(`Додаткові характеристики`)}
														</span>
													</div>
													<div className={s.filtersList_List}>
														{updatedFilters.map((filter: any) => {
															return (
																<div key={filter.id}>
																	<FilterComponent
																		values={values}
																		filter={filter}
																		status={formikStatus}
																		setFieldValue={setFieldValue}
																	/>
																</div>
															);
														})}
														{formikStatus && formikStatus.filters && (
															<span className={s.error}>
																{formikStatus.filters}
															</span>
														)}
													</div>
												</div>
											)}
											<div className={style.formElement}>
												<ChangeSettlement
													ref={noticeSettlementRef}
													values={values}
													setStatus={setStatus}
													status={formikStatus}
													setFieldValue={setFieldValue}
												/>
											</div>
											<ContactsSellerBlockCreate
												values={values}
												setFieldValue={setFieldValue}
											/>
											{blockDontShowOtherAds && (
												<DontShowOtherAds
													setFieldValue={setFieldValue}
													values={values}
												/>
											)}
											{blockAutoContinue && (
												<AutoContinueCreate
													values={values}
													setFieldValue={setFieldValue}
												/>
											)}
											<div className={style.createboardFooter}></div>
											<div
												className={`${style.bottomContainer} mui-fixed`}
											>
												<div className={style.buttonsBlock}>
													<button
														className={style.bottomButton}
														type={"submit"}
														id={"prew"}
														onClick={() => handlePerView(values)}
													>
														{t(`Переглянути`)}
													</button>
													<button
														type={"submit"}
														onClick={() => setFieldValue("publish", 1)}
														className={`${style.bottomButton} ${style.buttonActive}`}
													>
														{noticeId ? t("Зберегти") : t(`Публікувати`)}
													</button>
												</div>
											</div>
										</Form>
									);
								}}
							</Formik>
						</div>
					</div>
				</Mobile>
			</>
		);
	}
};

export default CreateBoardAd;
