import React from "react";

interface Props {
	id: string;
}

function SearchResultSvgSelector({ id }: Props) {
	switch (id) {
		case "menuCircle":
			return (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="12" cy="12" r="11.5" fill="none" stroke="#B1B1B1" />
					<line
						x1="7"
						y1="12"
						x2="18"
						y2="12"
						stroke="#B1B1B1"
						strokeWidth="2"
					/>
				</svg>
			);

		case "gridButton":
			return (
				<svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="5.55553" height="5.55553" />
					<rect x="9.71875" width="5.55553" height="5.55553" />
					<rect x="19.4453" width="5.55553" height="5.55553" />
					<rect y="9.72266" width="5.55553" height="5.55553" />
					<rect x="9.71875" y="9.72266" width="5.55553" height="5.55553" />
					<rect x="19.4453" y="9.72266" width="5.55553" height="5.55553" />
					<rect y="19.4453" width="5.55553" height="5.55553" />
					<rect x="9.71875" y="19.4453" width="5.55553" height="5.55553" />
					<rect x="19.4453" y="19.4453" width="5.55553" height="5.55553" />
				</svg>
			);
		case "gridManuButton":
			return (
				<svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="4" height="4" />
					<rect x="7" width="4" height="4" />
					<rect x="14" width="4" height="4" />
					<rect x="21" width="4" height="4" />
					<rect y="7" width="4" height="4" />
					<rect x="7" y="7" width="4" height="4" />
					<rect x="14" y="7" width="4" height="4" />
					<rect x="21" y="7" width="4" height="4" />
					<rect y="14" width="4" height="4" />
					<rect x="7" y="14" width="4" height="4" />
					<rect x="14" y="14" width="4" height="4" />
					<rect x="21" y="14" width="4" height="4" />
					<rect y="21" width="4" height="4" />
					<rect x="7" y="21" width="4" height="4" />
					<rect x="14" y="21" width="4" height="4" />
					<rect x="21" y="21" width="4" height="4" />
				</svg>
			);

		case "menu":
			return (
				<svg
					width="28"
					height="25"
					viewBox="0 0 28 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect x="0.21875" width="27.7776" height="4.16665" />
					<rect x="0.21875" y="10.418" width="27.7776" height="4.16665" />
					<rect x="0.21875" y="20.832" width="27.7776" height="4.16665" />
				</svg>
			);
		case "menuCircleCard":
			return (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="10" cy="10" r="9.5" stroke="#CECECE" />
					<line
						x1="5.83398"
						y1="9.83594"
						x2="15.0007"
						y2="9.83594"
						stroke="#CECECE"
						strokeWidth="2"
					/>
				</svg>
			);
		case "reset":
			return (
				<svg
					width="20"
					height="21"
					viewBox="0 0 20 21"
					fill="#c52c2c"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M18 6.36719C16.597 3.40719 13.537 1.36719 10 1.36719C8.8181 1.36719 7.64778 1.59998 6.55585 2.05227C5.46392 2.50456 4.47177 3.1675 3.63604 4.00323C2.80031 4.83895 2.13738 5.83111 1.68508 6.92304C1.23279 8.01497 1 9.18529 1 10.3672C1 11.5491 1.23279 12.7194 1.68508 13.8113C2.13738 14.9033 2.80031 15.8954 3.63604 16.7311C4.47177 17.5669 5.46392 18.2298 6.55585 18.6821C7.64778 19.1344 8.8181 19.3672 10 19.3672C12.3869 19.3672 14.6761 18.419 16.364 16.7311C18.0518 15.0433 19 12.7541 19 10.3672M19 1.36719V7.36719H13"
						fill="white"
						/*stroke="#c52c2c"*/ strokeWidth="2"
					/>
				</svg>
			);
		case "searchOptionArrow":
			return (
				<svg
					width="13"
					height="7"
					viewBox="0 0 13 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.125 5.69531L6.57126 1.00091L0.997884 5.69531"
						stroke="#797979"
					/>
				</svg>
			);
		case "groupedRect":
			return (
				<svg
					width="15"
					height="15"
					viewBox="0 0 15 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M0 0H5V5H0V0Z" fill="#3D3D3D" />
					<rect x="10" y="9.40234" width="5" height="5" fill="#3D3D3D" />
					<rect y="9.40234" width="5" height="5" fill="#3D3D3D" />
					<rect x="10" width="5" height="5" fill="#3D3D3D" />
				</svg>
			);
		case "filters":
			return (
				<svg
					width="12"
					height="10"
					viewBox="0 0 12 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12 1.66667H10.2429C10.0286 0.708333 9.17143 0 8.14286 0C7.11429 0 6.25714 0.708333 6.04286 1.66667H0V2.5H6.04286C6.25714 3.45833 7.11429 4.16667 8.14286 4.16667C9.17143 4.16667 10.0286 3.45833 10.2429 2.5H12V1.66667ZM8.14286 3.33333C7.41429 3.33333 6.85714 2.79167 6.85714 2.08333C6.85714 1.375 7.41429 0.833333 8.14286 0.833333C8.87143 0.833333 9.42857 1.375 9.42857 2.08333C9.42857 2.79167 8.87143 3.33333 8.14286 3.33333ZM0 8.33333H1.75714C1.97143 9.29167 2.82857 10 3.85714 10C4.88571 10 5.74286 9.29167 5.95714 8.33333H12V7.5H5.95714C5.74286 6.54167 4.88571 5.83333 3.85714 5.83333C2.82857 5.83333 1.97143 6.54167 1.75714 7.5H0V8.33333ZM3.85714 6.66667C4.58571 6.66667 5.14286 7.20833 5.14286 7.91667C5.14286 8.625 4.58571 9.16667 3.85714 9.16667C3.12857 9.16667 2.57143 8.625 2.57143 7.91667C2.57143 7.20833 3.12857 6.66667 3.85714 6.66667Z"
						fill="#727272"
					/>
				</svg>
			);
		case "sortingArrow":
			return (
				<svg
					width="17"
					height="16"
					viewBox="0 0 17 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3.5 14.8438C3.5 15.1199 3.72386 15.3438 4 15.3438C4.27614 15.3438 4.5 15.1199 4.5 14.8438H3.5ZM4.35355 0.647942C4.15829 0.45268 3.84171 0.45268 3.64645 0.647942L0.464466 3.82992C0.269204 4.02518 0.269204 4.34177 0.464466 4.53703C0.659728 4.73229 0.976311 4.73229 1.17157 4.53703L4 1.7086L6.82843 4.53703C7.02369 4.73229 7.34027 4.73229 7.53553 4.53703C7.7308 4.34177 7.7308 4.02518 7.53553 3.82992L4.35355 0.647942ZM4.5 14.8438L4.5 1.0015H3.5L3.5 14.8438H4.5Z"
						fill="#383748"
					/>
					<path
						d="M12.5 1.46875C12.5 1.19261 12.7239 0.96875 13 0.96875C13.2761 0.96875 13.5 1.19261 13.5 1.46875L12.5 1.46875ZM13.3536 15.6646C13.1583 15.8598 12.8417 15.8598 12.6464 15.6646L9.46447 12.4826C9.2692 12.2873 9.2692 11.9707 9.46447 11.7755C9.65973 11.5802 9.97631 11.5802 10.1716 11.7755L13 14.6039L15.8284 11.7755C16.0237 11.5802 16.3403 11.5802 16.5355 11.7755C16.7308 11.9707 16.7308 12.2873 16.5355 12.4826L13.3536 15.6646ZM13.5 1.46875L13.5 15.311H12.5L12.5 1.46875L13.5 1.46875Z"
						fill="#383748"
					/>
				</svg>
			);
		case "rowLine":
			return (
				<svg
					width="15"
					height="14"
					viewBox="0 0 15 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<line y1="1" x2="15" y2="1" stroke="#3D3D3D" strokeWidth="2" />
					<line y1="7" x2="15" y2="7" stroke="#3D3D3D" strokeWidth="2" />
					<line y1="13" x2="15" y2="13" stroke="#3D3D3D" strokeWidth="2" />
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default SearchResultSvgSelector;
