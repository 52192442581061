import React from "react";
import { useTranslation } from "react-i18next";
import { formattingPrice } from "../../../supporting";
import { NoticeDataForModal } from "../../../types/noticeTypes";
import s from "./ModalAutoRenewal/ModalAutoRenewal.module.css";

type Props = {
	noticeData: NoticeDataForModal;
};

const TopModalCardComponent: React.FC<Props> = ({ noticeData }) => {
	const { t } = useTranslation();
	return (
		<div className={s.topCardInfo}>
			<div className={s.topCardInfo_Left}>
				<div className={s.topCardInfoImage}>
					<img src={noticeData?.img} alt={noticeData?.name} />
				</div>
			</div>
			<div className={s.topCardInfo_Right}>
				<div className={s.rightInfoTop}>
					<div className={s.topCardInfoName_Title}>
						<h5>{noticeData?.name}</h5>
					</div>
					<div className={s.topCardInfoPrice_Sum}>
						{formattingPrice(noticeData?.price ?? "")} грн
					</div>
				</div>
				<div className={s.rightInfoMiddle}>
					<div className={s.topCardInfoName_Location}>
						{noticeData?.settlement}
					</div>
				</div>
				<div className={s.rightInfoBottom}>
					<div className={s.topCardInfoPrice_Balance}>
						{t("Ваш баланс")}: {noticeData?.balance ?? ""} грн
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopModalCardComponent;
