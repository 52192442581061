import React from "react";
import s from "./ErrorPages.module.css";
import ErrorPagesButton from "./ErrorPagesButton";
import ErrorPagesTitle from "./ErrorPagesTitle";
import ErrorPagesImage from "./ErrorPagesImage";

const NewPage404 = () => {
	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				<div className={s.title}>
					<ErrorPagesTitle type={"400&500"} />
				</div>
				<div className={s.image}>
					<ErrorPagesImage type={"400&500"} />
				</div>
				<div className={s.button}>
					<ErrorPagesButton type={"goHome"} />
				</div>
			</div>
		</div>
	);
};

export default NewPage404;
