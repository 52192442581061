import React from "react";
import { useTranslation } from "react-i18next";
import noInternet from "../../images/404 .jpg";
import Lottie from "lottie-react";
import noInternetLottie from "../../common/Animations/NoInternet.json";
import s from "./ErrorPages.module.css";
import noActiveNotice from "../../images/не активное объявление.png";
import newErrorImage from "../../images/404 иллюстрация.png";
import { DesktopInErrorPage, MobileInErrorPage } from "../../responsive";
import imageMobile from "../../images/LogoRedBago.jpg";

type TitleType = "noInternet" | "400&500" | "noActiveAD";
type Props = {
	type: TitleType;
};
const ErrorPagesImage = ({ type }: Props) => {
	const { t } = useTranslation();
	let image;
	switch (type) {
		case "noInternet":
			image = noInternet;
			break;
		case "400&500":
			image = newErrorImage;
			break;
		case "noActiveAD":
			image = noActiveNotice;
			break;
	}
	if (type !== "noInternet") {
		return (
			<div className={s.imageContainer}>
				{type === "noActiveAD" && (
					<div className={s.veryFunnyText}>
						{t("- Я все знайшла,")}
						<br />
						{t("як ти і хотів,")}
						<br />
						{t("але хтось нас випередив")}
					</div>
				)}
				<DesktopInErrorPage>
					<img src={image} alt={"error image"} />
				</DesktopInErrorPage>
				<MobileInErrorPage>
					<div className={s.imageMobile}>
						<img src={imageMobile} alt={"error image"} />
					</div>
				</MobileInErrorPage>
			</div>
		);
	} else {
		return (
			<div className={s.animationContainer}>
				<Lottie animationData={noInternetLottie} />
			</div>
		);
	}
};

export default ErrorPagesImage;
