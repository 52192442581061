import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ExpandModernButton from "../../../../../Components/ExpandButton/ExpandModernButton";
import Paginator from "../../../../../Components/Paginator/Paginator";
import useCatIdsHandler from "../../../../../CustomHooks/useCatIdsHandler";
import { getNoticeList } from "../../../../../redux/notice_Reducer";
import { StateType } from "../../../../../redux/redux-store";
import { Desktop, Mobile } from "../../../../../responsive";
import { scrollToTop } from "../../../../../supporting";
import { NoticeCardRow } from "../NoticeCardRow/NoticeCardRow";
import s from "./SellerInfo.module.css";

export const SellerInfo = () => {
	const { t, i18n } = useTranslation();
	const [currentPage, setCurrentPage] = useState(0);
	const dispatch: any = useDispatch();
	const getNotices = useSelector(() => getNoticeList);
	const [status, setStatus] = useState(30);
	const [page, setPage] = useState(1);
	const notices = useSelector(
		(state: StateType) => state.noticeData?.noticeList
	);
	const noticesMeta = useSelector(
		(state: StateType) => state.noticeData.noticeListMeta
	);
	const catIdsForHook = useSelector(
		(state: StateType) => state.noticeData.userStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);
	const pagination = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);

	useEffect(() => {
		dispatch(
			getNotices({ perPage: 48, status: status, page: page, catIds: catIds })
		);
		scrollToTop();
	}, [status, page, noticesMeta.pageCount, catIds, i18n.language]);

	const row = true;
	return (
		<>
			{row && (
				<>
					{notices.map((notice) => (
						<>
							<NoticeCardRow key={notice.id} notice={notice} />
							<hr className={s.line} />
						</>
					))}
				</>
			)}
			<Mobile>
				<div className={s.paginatorContainer}>
					<ExpandModernButton onClickCallback={() => void 0} />
				</div>
			</Mobile>
			<Desktop>
				<div className={s.paginatorContainer}>
					{pagination.totalCount && (
						<Paginator
							page={currentPage == 0 ? 1 : currentPage}
							count={pagination?.pageCount || 1}
							setPage={setCurrentPage}
						/>
					)}
				</div>
			</Desktop>
		</>
	);
};
