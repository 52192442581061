import { FilterForCreateNotice } from "../types/noticeTypes";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	setFiltersManyAction,
	setFiltersOneIsAction,
} from "../redux/notice_Reducer";
import { StateType } from "../redux/redux-store";

type Props = {
	element: { id: string; name: string; name_ru: string };
	filter: any;
};
const useCatIdsHandler = () => {
	const dispatch = useDispatch();
	const setFiltersMany = useSelector(() => setFiltersManyAction);
	const setFiltersOneIs = useSelector(() => setFiltersOneIsAction);
	const filtersMany = useSelector(
		(state: StateType) => state.noticeData.filtersMany
	);
	const filtersOneIs = useSelector(
		(state: StateType) => state.noticeData.filtersOneIs
	);

	const handleFilter = ({ element, filter }: Props) => {
		if (filter?.type === 1 || filter?.type === 3 || filter?.type === 4) {
			const existingIndex = filtersOneIs?.findIndex(
				(item: any) =>
					item.id === element.id && item.filter === filter.inputName
			);
			if (existingIndex !== -1) {
				const updatedValue = [...filtersOneIs];
				updatedValue.splice(existingIndex, 1);
				dispatch(setFiltersOneIs(updatedValue));
			} else {
				const existingFilter = filtersOneIs?.findIndex(
					(item: any) => item.filter === filter.inputName
				);
				if (existingFilter === -1) {
					const newValue = {
						filter: filter?.inputName,
						id: element.id,
						name: filter.propertyName,
						value: i18n.language === "ru" ? element.name_ru : element.name,
					};
					dispatch(setFiltersOneIs([...filtersOneIs, newValue]));
				} else {
					const updatedValue: any = [...filtersOneIs];
					updatedValue[existingFilter].id = element.id;
					dispatch(setFiltersOneIs([...updatedValue]));
				}
			}
		} else {
			const existingIndex = filtersMany?.findIndex(
				(item: any) =>
					item.id === element.id && item.filter === filter.inputName
			);
			if (existingIndex !== -1) {
				const updatedValue: any = [...filtersMany];
				updatedValue.splice(existingIndex, 1);
				dispatch(setFiltersMany(updatedValue));
			} else {
				const newValue: FilterForCreateNotice = {
					filter: filter?.inputName,
					id: element.id,
					name: filter.propertyName,
					value: i18n.language === "ru" ? element.name_ru : element.name,
				};
				dispatch(setFiltersMany([...filtersMany, newValue]));
			}
		}
	};

	return { handleFilter };
};

export default useCatIdsHandler;
