import React from "react";

interface Props {
	id: string;
}

function CompainModalWindowSvg({ id }: Props) {
	switch (id) {
		case "noAvatar":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="35"
					height="35"
					viewBox="0 0 35 35"
					fill="none"
				>
					<rect width="35" height="35" rx="4" fill="#E7E7E7" />
					<path
						d="M22.1548 14.5227C22.1548 15.8558 21.6252 17.1344 20.6825 18.077C19.7398 19.0197 18.4613 19.5493 17.1282 19.5493C15.795 19.5493 14.5165 19.0197 13.5738 18.077C12.6311 17.1344 12.1016 15.8558 12.1016 14.5227C12.1016 13.1896 12.6311 11.911 13.5738 10.9683C14.5165 10.0257 15.795 9.49609 17.1282 9.49609C18.4613 9.49609 19.7398 10.0257 20.6825 10.9683C21.6252 11.911 22.1548 13.1896 22.1548 14.5227Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3.72266 17.873C3.72266 14.318 5.13489 10.9086 7.64867 8.39477C10.1625 5.88098 13.5719 4.46875 17.1269 4.46875C20.6819 4.46875 24.0914 5.88098 26.6052 8.39477C29.1189 10.9086 30.5312 14.318 30.5312 17.873C30.5312 21.428 29.1189 24.8375 26.6052 27.3512C24.0914 29.865 20.6819 31.2773 17.1269 31.2773C13.5719 31.2773 10.1625 29.865 7.64867 27.3512C5.13489 24.8375 3.72266 21.428 3.72266 17.873ZM17.1269 6.14428C14.9182 6.1444 12.7544 6.76819 10.8846 7.94385C9.01472 9.11951 7.51486 10.7993 6.5576 12.7898C5.60033 14.7803 5.22458 17.0006 5.47358 19.1953C5.72259 21.3899 6.58623 23.4696 7.9651 25.1951C9.15473 23.2783 11.7736 21.2241 17.1269 21.2241C22.4802 21.2241 25.0974 23.2766 26.2887 25.1951C27.6676 23.4696 28.5312 21.3899 28.7802 19.1953C29.0292 17.0006 28.6535 14.7803 27.6962 12.7898C26.739 10.7993 25.2391 9.11951 23.3693 7.94385C21.4994 6.76819 19.3356 6.1444 17.1269 6.14428Z"
						fill="white"
					/>
				</svg>
			);
		case "checkMark":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="26"
					height="20"
					viewBox="0 0 26 20"
					fill="none"
				>
					<path
						d="M8.03642 19.2238C7.87979 19.2238 7.723 19.1507 7.60368 19.0052L0.17944 10.555C-0.0598782 10.2633 -0.0598782 9.79169 0.17944 9.5C0.418647 9.20831 0.805603 9.20831 1.04492 9.5L8.03642 17.4225L24.5897 0.218532C24.829 -0.0731555 25.3504 -0.0726879 25.5897 0.218999C25.829 0.510823 25.829 0.925098 25.5897 1.21692L8.46927 19.0052C8.34995 19.1507 8.19305 19.2238 8.03642 19.2238Z"
						fill="#00CEEA"
					/>
				</svg>
			);
		case "arrowBack":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="11"
					height="19"
					viewBox="0 0 11 19"
					fill="none"
				>
					<path
						d="M10 1L2.0463 9.52667L10.0925 17.9997"
						stroke="#646464"
						strokeWidth="2"
					/>
				</svg>
			);
		case "bago":
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="217"
					height="49"
					viewBox="0 0 217 49"
					fill="none"
				>
					<line y1="37.5" x2="217" y2="37.5" stroke="#FFC700" />
					<path
						d="M30.524 38.514C27.976 38.514 25.6893 37.926 23.664 36.75C21.6387 35.5413 20.0217 33.9243 18.813 31.899C17.637 29.841 17.0327 27.538 17 24.99V2.107C17 1.45367 17.1797 0.947333 17.539 0.587998C17.931 0.195999 18.4537 0 19.107 0C19.7277 0 20.2177 0.195999 20.577 0.587998C20.969 0.947333 21.165 1.45367 21.165 2.107V16.121C22.243 14.651 23.6477 13.475 25.379 12.593C27.1103 11.6783 29.0377 11.221 31.161 11.221C33.6437 11.221 35.865 11.8253 37.825 13.034C39.785 14.21 41.3203 15.827 42.431 17.885C43.5743 19.943 44.146 22.2623 44.146 24.843C44.146 27.4563 43.5417 29.792 42.333 31.85C41.157 33.908 39.54 35.5413 37.482 36.75C35.4567 37.926 33.1373 38.514 30.524 38.514ZM30.524 34.79C32.3533 34.79 33.9867 34.349 35.424 33.467C36.894 32.585 38.0373 31.409 38.854 29.939C39.7033 28.4363 40.128 26.7377 40.128 24.843C40.128 22.981 39.7033 21.2987 38.854 19.796C38.0373 18.2933 36.894 17.1173 35.424 16.268C33.9867 15.386 32.3533 14.945 30.524 14.945C28.76 14.945 27.143 15.386 25.673 16.268C24.2357 17.1173 23.1087 18.2933 22.292 19.796C21.4753 21.2987 21.067 22.981 21.067 24.843C21.067 26.7377 21.4753 28.4363 22.292 29.939C23.1087 31.409 24.2357 32.585 25.673 33.467C27.143 34.349 28.76 34.79 30.524 34.79Z"
						fill="#FFC700"
					/>
					<path
						d="M106.276 38.514C103.728 38.514 101.474 37.9423 99.5141 36.799C97.5541 35.623 96.0188 34.006 94.9081 31.948C93.7974 29.89 93.2421 27.538 93.2421 24.892C93.2421 22.2133 93.8301 19.845 95.0061 17.787C96.1821 15.729 97.7828 14.1283 99.8081 12.985C101.866 11.809 104.218 11.221 106.864 11.221C109.477 11.221 111.797 11.809 113.822 12.985C115.88 14.1283 117.481 15.729 118.624 17.787C119.8 19.845 120.404 22.2133 120.437 24.892L117.987 25.725C117.987 28.2077 117.481 30.4127 116.468 32.34C115.488 34.2673 114.116 35.7863 112.352 36.897C110.588 37.975 108.563 38.514 106.276 38.514ZM106.864 49C104.381 49 102.144 48.5263 100.151 47.579C98.1911 46.6317 96.5414 45.3087 95.2021 43.61C94.8428 43.1853 94.6958 42.7117 94.7611 42.189C94.8264 41.699 95.0878 41.307 95.5451 41.013C96.0351 40.6863 96.5578 40.572 97.1131 40.67C97.6684 40.768 98.1094 41.0457 98.4361 41.503C99.3508 42.6463 100.527 43.561 101.964 44.247C103.401 44.933 105.067 45.276 106.962 45.276C108.693 45.276 110.278 44.8513 111.715 44.002C113.152 43.1853 114.279 41.993 115.096 40.425C115.945 38.857 116.37 36.995 116.37 34.839V27.587L117.84 24.255L120.437 24.892V35.084C120.437 37.6973 119.849 40.0493 118.673 42.14C117.497 44.2633 115.896 45.9293 113.871 47.138C111.846 48.3793 109.51 49 106.864 49ZM106.864 34.79C108.726 34.79 110.359 34.3653 111.764 33.516C113.201 32.6667 114.328 31.507 115.145 30.037C115.962 28.5343 116.37 26.8193 116.37 24.892C116.37 22.9647 115.962 21.2497 115.145 19.747C114.328 18.2443 113.201 17.0683 111.764 16.219C110.359 15.3697 108.726 14.945 106.864 14.945C105.002 14.945 103.352 15.3697 101.915 16.219C100.478 17.0683 99.3344 18.2443 98.4851 19.747C97.6684 21.2497 97.2601 22.9647 97.2601 24.892C97.2601 26.8193 97.6684 28.5343 98.4851 30.037C99.3344 31.507 100.478 32.6667 101.915 33.516C103.352 34.3653 105.002 34.79 106.864 34.79Z"
						fill="#FFC700"
					/>
					<path
						d="M139.977 38.514C137.331 38.514 134.979 37.926 132.921 36.75C130.896 35.574 129.295 33.957 128.119 31.899C126.943 29.841 126.355 27.5053 126.355 24.892C126.355 22.246 126.943 19.894 128.119 17.836C129.295 15.778 130.896 14.161 132.921 12.985C134.979 11.809 137.331 11.221 139.977 11.221C142.591 11.221 144.91 11.809 146.935 12.985C148.961 14.161 150.561 15.778 151.737 17.836C152.913 19.894 153.518 22.246 153.55 24.892C153.55 27.5053 152.962 29.841 151.786 31.899C150.61 33.957 148.993 35.574 146.935 36.75C144.91 37.926 142.591 38.514 139.977 38.514ZM139.977 34.79C141.807 34.79 143.44 34.3653 144.877 33.516C146.315 32.634 147.442 31.458 148.258 29.988C149.075 28.4853 149.483 26.7867 149.483 24.892C149.483 22.9973 149.075 21.2987 148.258 19.796C147.442 18.2933 146.315 17.1173 144.877 16.268C143.44 15.386 141.807 14.945 139.977 14.945C138.148 14.945 136.498 15.386 135.028 16.268C133.591 17.1173 132.448 18.2933 131.598 19.796C130.782 21.2987 130.373 22.9973 130.373 24.892C130.373 26.7867 130.782 28.4853 131.598 29.988C132.448 31.458 133.591 32.634 135.028 33.516C136.498 34.3653 138.148 34.79 139.977 34.79Z"
						fill="#FFC700"
					/>
					<path
						d="M28.5169 21.5277C28.5169 22.6553 27.6028 23.5694 26.4753 23.5694C25.3477 23.5694 24.4336 22.6553 24.4336 21.5277C24.4336 20.4002 25.3477 20.3027 26.4753 20.3027C27.6028 20.3027 28.5169 20.4002 28.5169 21.5277Z"
						fill="#E0E0E0"
					/>
					<path
						d="M28.5169 21.5277C28.5169 22.6553 27.6028 23.5694 26.4753 23.5694C25.3477 23.5694 24.4336 22.6553 24.4336 21.5277C24.4336 20.4002 25.3477 20.3027 26.4753 20.3027C27.6028 20.3027 28.5169 20.4002 28.5169 21.5277Z"
						fill="#E0E0E0"
					/>
					<path
						d="M36.681 21.5277C36.681 22.6553 35.7669 23.5694 34.6393 23.5694C33.5117 23.5694 32.5977 22.6553 32.5977 21.5277C32.5977 20.4002 33.5117 20.3027 34.6393 20.3027C35.7669 20.3027 36.681 20.4002 36.681 21.5277Z"
						fill="#E0E0E0"
					/>
					<path
						d="M36.681 21.5277C36.681 22.6553 35.7669 23.5694 34.6393 23.5694C33.5117 23.5694 32.5977 22.6553 32.5977 21.5277C32.5977 20.4002 33.5117 20.3027 34.6393 20.3027C35.7669 20.3027 36.681 20.4002 36.681 21.5277Z"
						fill="#E0E0E0"
					/>
					<path
						d="M113.471 20.5494C113.792 21.3926 111.269 23.1336 110.425 23.454C109.582 23.7744 108.639 23.3506 108.318 22.5073C107.998 21.6641 108.422 20.7207 109.265 20.4003C110.108 20.08 113.151 19.7061 113.471 20.5494Z"
						fill="#E0E0E0"
					/>
					<path
						d="M99.4114 20.4001C99.7611 19.5686 102.789 20.0488 103.62 20.3986C104.452 20.7483 104.842 21.7059 104.493 22.5374C104.143 23.3689 103.185 23.7594 102.354 23.4097C101.522 23.0599 99.0616 21.2316 99.4114 20.4001Z"
						fill="#E0E0E0"
					/>
					<path
						d="M146.09 21.719C146.09 22.7266 145.274 23.5435 144.266 23.5435C143.258 23.5435 142.441 22.7266 142.441 21.719C142.441 20.7114 143.258 19.8945 144.266 19.8945C145.274 19.8945 146.09 20.7114 146.09 21.719Z"
						fill="#E0E0E0"
					/>
					<path
						d="M146.09 21.719C146.09 22.7266 145.274 23.5435 144.266 23.5435C143.258 23.5435 142.441 22.7266 142.441 21.719C142.441 20.7114 143.258 19.8945 144.266 19.8945C145.274 19.8945 146.09 20.7114 146.09 21.719Z"
						fill="#E0E0E0"
					/>
					<path
						d="M64.4224 21.3108C64.4224 22.3184 63.6055 23.1353 62.5979 23.1353C61.5903 23.1353 60.7734 22.3184 60.7734 21.3108C60.7734 20.3032 61.5903 19.4863 62.5979 19.4863C63.6055 19.4863 64.4224 20.3032 64.4224 21.3108Z"
						fill="#0BB82E"
					/>
					<path
						d="M64.4224 21.3108C64.4224 22.3184 63.6055 23.1353 62.5979 23.1353C61.5903 23.1353 60.7734 22.3184 60.7734 21.3108C60.7734 20.3032 61.5903 19.4863 62.5979 19.4863C63.6055 19.4863 64.4224 20.3032 64.4224 21.3108Z"
						fill="#0BB82E"
					/>
					<path
						d="M73.4067 21.3108C73.4067 22.3184 72.5899 23.1353 71.5823 23.1353C70.5747 23.1353 69.7578 22.3184 69.7578 21.3108C69.7578 20.3032 70.5747 19.4863 71.5823 19.4863C72.5899 19.4863 73.4067 20.3032 73.4067 21.3108Z"
						fill="#0BB82E"
					/>
					<path
						d="M73.4067 21.3108C73.4067 22.3184 72.5899 23.1353 71.5823 23.1353C70.5747 23.1353 69.7578 22.3184 69.7578 21.3108C69.7578 20.3032 70.5747 19.4863 71.5823 19.4863C72.5899 19.4863 73.4067 20.3032 73.4067 21.3108Z"
						fill="#0BB82E"
					/>
					<circle cx="136.508" cy="21.719" r="1.82447" fill="#E0E0E0" />
					<path
						d="M67.0248 11.3203C59.5282 11.3203 53.4219 17.4266 53.4219 24.9232C53.4219 32.4198 59.5282 38.5261 67.0248 38.5261C70.2847 38.5261 73.2785 37.3685 75.6249 35.4469L83.7468 43.5687C83.8721 43.6993 84.0222 43.8035 84.1884 43.8753C84.3545 43.9471 84.5333 43.9851 84.7143 43.9869C84.8952 43.9888 85.0748 43.9545 85.2423 43.8861C85.4099 43.8176 85.5621 43.7165 85.6901 43.5885C85.8181 43.4605 85.9192 43.3083 85.9876 43.1408C86.056 42.9732 86.0903 42.7937 86.0885 42.6127C86.0866 42.4317 86.0487 42.2529 85.9769 42.0868C85.9051 41.9207 85.8009 41.7706 85.6703 41.6452L77.5484 33.5233C79.47 31.177 80.6277 28.1832 80.6277 24.9232C80.6277 17.4266 74.5213 11.3203 67.0248 11.3203ZM67.0248 14.0409C73.051 14.0409 77.9071 18.897 77.9071 24.9232C77.9071 30.9495 73.051 35.8055 67.0248 35.8055C60.9985 35.8055 56.1425 30.9495 56.1425 24.9232C56.1425 18.897 60.9985 14.0409 67.0248 14.0409Z"
						fill="#0BB82E"
					/>
				</svg>
			);
		default:
			return <svg></svg>;
	}
}

export default CompainModalWindowSvg;
