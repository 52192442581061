const handleSortPhrases = (phrasesArray: any, keyName: string) => {
	try {
		const storedPhrasesString = window.localStorage.getItem(keyName);

		const storedPhrases = storedPhrasesString
			? JSON.parse(storedPhrasesString)
			: phrasesArray;

		if (storedPhrases.length > 0) {
			const randomPhrasesIndex = Math.floor(
				Math.random() * storedPhrases.length
			);
			const updatedPhrases = storedPhrases.filter(
				(_: any, index: number) => index !== randomPhrasesIndex
			);

			window.localStorage.setItem(keyName, JSON.stringify(updatedPhrases));

			return storedPhrases[randomPhrasesIndex];
		} else {
			window.localStorage.setItem(keyName, JSON.stringify(phrasesArray));
			return phrasesArray[0];
		}
	} catch (error) {
		console.log("error", error);
	}
};

export default handleSortPhrases;
