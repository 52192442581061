import React from "react";
import s from "./CustomCheckBox.module.css";

const CustomCheckbox = ({
	labelTxt,
	id,
	handleCheckBox,
	saveMe,
}: {
	labelTxt: string;
	id: string;
	handleCheckBox: any;
	saveMe: boolean;
}) => {
	return (
		<div className={s.checkBoxContainer}>
			<input
				className={s.checkboxInput}
				checked={saveMe}
				onChange={handleCheckBox}
				id={id}
				type="checkbox"
			/>
			<label htmlFor={id}>{labelTxt}</label>
		</div>
	);
};

export default CustomCheckbox;
