import React, { forwardRef } from "react";
import s from "../CreateBoardAD.module.css";
import style from "../CreateBoardADMobile.module.css";
import { useTranslation } from "react-i18next";

interface Props {
	status: any;
	setStatus: any;
	setFieldValue: any;
	values: any;
}

const BlockState = forwardRef<HTMLDivElement, Props>(
	({ status, setStatus, setFieldValue, values }, ref) => {
		const { t } = useTranslation();
		return (
			<div ref={ref} className={`${s.formElement} ${s.stateFieldEl}`}>
				<div className={`${s.nameSide} ${s.stateField}`}>
					<span className={s.elementName}>
						{t(`Стан`)}
						<span className={style.required}> * </span>
					</span>
				</div>
				{status && status.state && (
					<span className={s.error}>{status.state}</span>
				)}
				<div className={`${s.fieldSide}`}>
					<div className={s.stateButtons}>
						<button
							type="button"
							onClick={() => {
								setStatus({});
								setFieldValue("state", "1");
							}}
							className={+values.state === 1 ? s.active : ""}
						>
							{t(`Нове`)}
						</button>
						<button
							type="button"
							onClick={() => {
								setStatus({});
								setFieldValue("state", "2");
							}}
							className={+values.state === 2 ? s.active : ""}
						>
							{t(`Вживане`)}
						</button>
					</div>
				</div>
			</div>
		);
	}
);

export default BlockState;
