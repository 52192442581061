import React, { useEffect, useState } from "react";
import s from "./SearchFilters.module.css";
import Range from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useTranslation } from "react-i18next";
import { setPriceRangeFilter } from "../../redux/publicNotice_Reducer";
import { FiltersValue } from "../../types/publicNoticeTypes";
import { useLocation } from "react-router";

type Props = {
	sliderPrice: number | number[];
	categoryRange: {
		maxPrice: string;
		minPrice: string;
	};
	filtersValues: FiltersValue[];
	setFiltersValues: any;
};

const FilterPrice: React.FC<Props> = ({
	sliderPrice,
	categoryRange,
	filtersValues,
	setFiltersValues,
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const setPriceToSend = useSelector(() => setPriceRangeFilter);
	const dispatch: any = useDispatch();
	const [expand, setExpand] = useState(true);
	const [range, setRange] = useState<number | number[]>([0, 0]);
	const [activeButton, setActiveButton] = useState<boolean>(false);
	const [minMax, setMinMax] = useState<number | number[]>([0, 0]);
	const max = Array.isArray(minMax) ? minMax[1] : 0;

	useEffect(() => {
		const strRange = searchParams.get("price");
		const range = strRange?.split(",").map(Number);
		if (range) {
			setRange(range);
			dispatch(setPriceToSend(range));
		} else {
			setRange(sliderPrice);
		}
	}, [filtersValues, location]);

	useEffect(() => {
		setMinMax(sliderPrice);
	}, []);
	useEffect(() => {
		return () => {
			dispatch(setPriceToSend([]));
		};
	}, []);
	const handleChangePrice = (
		event: React.ChangeEvent<HTMLInputElement>,
		priceType: string
	) => {
		const value = +event.currentTarget.value;
		const maxPrice = +categoryRange?.maxPrice;
		setActiveButton(true);
		if (value >= 0 && value <= maxPrice) {
			priceType === "min"
				? setRange([value, Array.isArray(range) ? range[1] : 0])
				: setRange([Array.isArray(range) ? range[0] : 0, value]);
		} else {
			priceType === "min"
				? setRange([0, Array.isArray(range) ? range[1] : 0])
				: setRange([Array.isArray(range) ? range[0] : 0, max]);
		}
	};

	const handleButtonClick = () => {
		dispatch(setPriceToSend(range));
		setActiveButton(false);

		let priceFilterFound = false;

		const currentValues = [...filtersValues];
		const updateCurrentValues = currentValues.map((f: FiltersValue) => {
			if (f.filter === "price") {
				priceFilterFound = true;
				return {
					filter: "price", // Сохраняем остальные свойства объекта
					value: range, // Заменяем значение, если `range` является массивом
				};
			}
			return f; // Возвращаем неизмененный объект, если `filter` не равен 'price'
		});
		if (!priceFilterFound) {
			updateCurrentValues.push({
				filter: "price",
				value: range,
			});
		}
		setFiltersValues(updateCurrentValues);
	};

	return (
		<div
			className={`${s.filterContainer} ${s.filterPrice}`}
			style={{
				background: expand ? "#F8F8F8" : "",
			}}
		>
			<div className={s.filterTitle} onClick={() => setExpand(!expand)}>
				<h5>
					{t("Ціна")} грн <span />
				</h5>
				<span
					onClick={() => setExpand(!expand)}
					style={{ rotate: expand ? "180deg" : "" }}
				>
					<ArrowsAndCross id={"arrowForSelect"} />
				</span>

				{/*<span>{t('Скинути')}</span>*/}
			</div>
			{expand && (
				<div className={s.priceFilterParams}>
					<div className={s.infoSliderPanel}>
						<span>{t("від")}</span>
						<input
							/* value={numberAddSpace(Array.isArray(range) ? range[0]?.toString() : "0")}*/
							value={Array.isArray(range) ? range[0] : "0"}
							onChange={(event) => handleChangePrice(event, "min")}
						/>

						<span>{t("до")}</span>
						<input
							className={s.out}
							value={Array.isArray(range) ? range[1] : "0"}
							onChange={(event) => {
								handleChangePrice(event, "max");
							}}
						/>

						<button
							disabled={!activeButton}
							className={activeButton ? s.active : ""}
							onClick={handleButtonClick}
						>
							ok
						</button>
					</div>
					<div className={s.filterSlider}>
						<Range
							draggableTrack={true}
							className={s.sliderPrice}
							range
							value={range}
							defaultValue={sliderPrice}
							onChange={(value) => {
								setActiveButton(true);
								setRange(value);
							}}
							allowCross={true}
							min={+categoryRange?.minPrice}
							max={+categoryRange?.maxPrice}
							step={10}
							trackStyle={[
								{
									height: "3px !Important",
									backgroundColor: "#00BCE5",
								},
							]}
							railStyle={{
								background: "#E0E0E0",
								height: "3px !Important",
							}}
							handleStyle={{
								width: "15px",
								height: "15px",
								marginTop: "-6px",
								backgroundColor: "white",
								cursor: "pointer",
								outline: "1px solid #00BCE6",
								border: "3px solid #fff",
								opacity: 1,
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default FilterPrice;
