import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getActivateUserByEmail } from "../redux/user_Reducer";
import styleFile from "../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import ArrowsAndCross from "../common/ArrowsAndCross/ArrowsAndCross";
import classes from "./ForgotPassPopup/ForgotPassPopup.module.css";
import { StateType } from "../redux/redux-store";
import { Navigate } from "react-router-dom";

const ApproveEmailPage = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const codeParam = searchParams.get("code");
	const dispatch: any = useDispatch();
	const getApprove = useSelector(() => getActivateUserByEmail);
	const approveInfo = useSelector(
		(state: StateType) => state.userData.approveEmailInfo
	);
	const navigate = useNavigate();

	useEffect(() => {
		codeParam && dispatch(getApprove(codeParam));
	}, []);
	if (approveInfo !== " ") {
		return (
			<div className={styleFile.wrapper}>
				<div className={styleFile.container}>
					<div className={styleFile.topNavigate}>
						<span onClick={() => navigate("/")}>
							<ArrowsAndCross id={"closeCross"} color={"#727272"} />
						</span>
					</div>
					<div className={`${styleFile.title} ${classes.title}`}>
						{approveInfo}
					</div>
					<div className={styleFile.info}></div>
				</div>
			</div>
		);
	} else {
		return <Navigate to={"/personal_cabinet/settings"} />;
	}
	/*(

    )*/
};

export default ApproveEmailPage;
