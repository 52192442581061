import React from "react";
import FunnyLogoSvgSelector from "../FunnyLogoSvgSelector";
import s from "./ClearSearchHistoryImg.module.css";
import { useTranslation } from "react-i18next";
import { Mobile } from "../../../responsive";

const ClearSearchHistoryImg = () => {
	const { t } = useTranslation();
	return (
		<Mobile>
			<div className={s.container}>
				<div className={s.cloudContainer}>
					<div className={s.cloud}>
						<FunnyLogoSvgSelector id={"blueCloud"} />
					</div>
					<div className={s.textInsideCloud}>
						- Я очистила <br />
						{t("історію")},
						<br />
						{t("як ти і хотів")}
					</div>
				</div>
				<div className={s.logoContainer}>
					<FunnyLogoSvgSelector id={"bagoLogoUnderCloud"} />
				</div>
			</div>
		</Mobile>
	);
};

export default ClearSearchHistoryImg;
