import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CreateBoardADSvg from "../../pages/CreateBoardAD/Components/CreateBoardADSvg";
import { setBooleanAutoChoosing } from "../../redux/autoSelection_Reducer";
import {
	getAllRubricsList,
	getCategoryBlocks,
	getFiltersList,
} from "../../redux/category_Reducer";
import { StateType } from "../../redux/redux-store";
import { OneRubricType } from "./NewRubricsBoardSelector";
import s from "./NewRubricsBoardSelectorMobile.module.css";

interface Props {
	setIsOpen: (value: boolean) => void;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
}
const NewRubricsBoardSelectorMobile = ({ setIsOpen, setFieldValue }: Props) => {
	const { t } = useTranslation();
	const [firstStepElements, setFirstStepElements] = useState([]);
	const [secondStepElements, setSecondStepElements] = useState([]);
	const [thirdStepElements, setThirdStepElements] = useState([]);
	const [firstCategoryName, setFirstCategoryName] = useState("");
	const [secondCategoryName, setSecondCategoryName] = useState("");
	const [openList, setOpenList] = useState("");
	const arrayRubrics = useSelector(
		(state: StateType) => state.categoryData.allRubrics
	);
	const getArrayRubrics = useSelector(() => getAllRubricsList);
	const getFilters = useSelector(() => getFiltersList);
	const getBlocks = useSelector(() => getCategoryBlocks);
	const setAutoChose = useSelector(() => setBooleanAutoChoosing);
	const dispatch: any = useDispatch();

	useEffect(() => {
		dispatch(getArrayRubrics("0"));
	}, []);

	/*const handelClose = () => {
        setIsOpen(false)
    }*/

	const handleFirstStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level2.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);

		setFirstCategoryName(rubric?.name);
		setFirstStepElements(data);
		setSecondStepElements([]);
		setThirdStepElements([]);
		checkedLastCategory(rubric?.id, rubric?.name, +rubric?.isForNotice);
	};

	const handleSecondStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level3.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setSecondCategoryName(rubric?.name);
		setSecondStepElements(data);
		setThirdStepElements([]);
		checkedLastCategory(rubric?.id, rubric?.name, +rubric?.isForNotice);
	};
	const handleThirdStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level4.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setThirdStepElements(data);

		checkedLastCategory(rubric?.id, rubric?.name, +rubric?.isForNotice);

		if (openList == rubric?.id) {
			setOpenList("0");
		} else {
			setOpenList(rubric?.id);
		}
	};

	const checkedLastCategory = (id: string, name: string, isLast: number) => {
		if (isLast) {
			dispatch(getFilters(id));
			dispatch(getBlocks(id));
			dispatch(setAutoChose(false));
			setFieldValue("category_id_value", id);
			setFieldValue("category_id_label", name);
			setIsOpen(false);
		}
	};

	if (secondStepElements.length) {
		return (
			<div className={s.choseRubricModalWrap}>
				<div
					className={s.choseRubricModalSecondHeader}
					onClick={() => setSecondStepElements([])}
				>
					<CreateBoardADSvg id="arrowBack" />
					<p>{secondCategoryName}</p>
				</div>
				<div className={s.thirdWindowWrap}>
					{secondStepElements.map((item: OneRubricType) => (
						<div key={item?.id}>
							<div
								className={s.compainListItemWrap}
								onClick={() => handleThirdStep(item)}
							>
								<p className={s.compainListItem_text}>{item.name}</p>
								<div
									className={`${s.arrowSvg} ${
										openList == item.id && s.arrowSvgActive
									}`}
								>
									{item?.isForNotice !== "1" && (
										<CreateBoardADSvg id="arrowDown" />
									)}
								</div>
							</div>
							<div>
								{openList == item.id &&
									thirdStepElements.map((list: OneRubricType) => (
										<div
											key={list.id}
											className={s.openListItemWrap}
											onClick={() =>
												checkedLastCategory(
													list?.id,
													list?.name,
													+list?.isForNotice
												)
											}
										>
											<p className={s.openListItem}>{list.name}</p>
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	if (firstStepElements.length) {
		return (
			<div className={s.choseRubricModalWrap}>
				<div
					className={s.choseRubricModalSecondHeader}
					onClick={() => setFirstStepElements([])}
				>
					<CreateBoardADSvg id="arrowBack" />
					<p>{firstCategoryName}</p>
				</div>
				<div className={s.choseRubricModalContainer}>
					<div className={s.choseRubricModalContent}>
						<div className={s.secondCategoryMobileWrap}>
							{firstStepElements.map((item: OneRubricType) => (
								<div
									className={s.secondCategoryMobileItemBlock}
									key={item.id}
									onClick={() => handleSecondStep(item)}
								>
									<div className={s.secondCategoryMobileImageContainer}>
										<img src={item?.iconUrl || ""} alt="icon" />
									</div>
									<p>{item?.name || ""}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={s.choseRubricModalWrap}>
			<div className={s.choseRubricModalContainer}>
				<div className={s.choseRubricModalContent}>
					<div className={s.choseRubricModalHeader}>
						{/*<CreateBoardADSvg id='closeIcon'/>*/}
					</div>
					<div>
						<p className={s.hoseRubricModalTitle}>{t(`Оберіть рубрику`)}</p>
					</div>
					<div className={`${s.mainCategoryMobileWrap}`}>
						{arrayRubrics?.level1.map((item: OneRubricType) => (
							<div
								key={item.id}
								className={s.mainCategoryMobileBlock}
								onClick={() => handleFirstStep(item)}
							>
								<div className={s.mainCategoryMobileItem}>
									<div className={s.mainCategoryMobileImageBlock}>
										<img
											src={item?.iconUrl || ""}
											alt="icon"
											className={s.mainCategoryMobileImage}
										/>
									</div>
									<p className={s.mainCategoryMobileText}>{item?.name || ""}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewRubricsBoardSelectorMobile;
