import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { publicNotice } from "../../api/api";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { publicOneNotice } from "../../types/publicNoticeTypes";
import { stateUserType } from "../../types/reduxStoreTypes";
import NoRegisterAdCardAdaptive from "../AdCard/NoRegisterAdCardAdaptive";
import MobileProductCard from "../Cards/ProductCard/MobileProductCard";
import ProductCard from "../Cards/ProductCard/ProductCard";
import ExpandButton from "../ExpandButton/ExpandButton";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import PreLoaderComponent from "../PreLoaderComponent/PreLoaderComponent";
import s from "./Bestsellers.module.css";
import BestsellersTabs from "./BestsellersTabs";
import LocationSelectorBestsellers from "./LocationSelectorBestsellers";

interface Props {
	categoryId?: number;
}

type Data = {
	items: any;
	_links: any;
	_meta: any;
};

const Bestsellers = ({ categoryId }: Props) => {
	const userLocation = useSelector(
		(state: StateType) => state.settlementData.settlementByLocation
	);
	const { i18n, t } = useTranslation();
	const [isExpanded, setExpanded] = useState(false);
	const [type, setType] = useState<number>(2);
	const [onlyTop, setOnlyTop] = useState(1);
	const userDistrict = useSelector(
		(state: StateType) => state.settlementData.settlementByLocationOnlyDistrict
	);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const [settlement, setSettlement] = useState(userLocation[0]?.value || "");
	const [areaId, setAreaId] = useState(userDistrict.id || "");
	const queryClient = useQueryClient();

	const fetchNotices = async (page = 1, perPage = 12) => {
		if (page > 1) {
			perPage = 24;
		}

		const { data } = await publicNotice.getFilteredNotices({
			type,
			page: 1,
			perPage,
			onlyTop,
			category_id: categoryId,
			settlement_id: settlement,
			areaId: areaId,
		});
		return data?.data;
	};

	const { data, fetchNextPage, hasNextPage, refetch, remove, isLoading } =
		useInfiniteQuery<Data, Error, any>(
			["bestNotices"],
			({ pageParam = 1 }) => fetchNotices(pageParam),
			{
				getNextPageParam: (lastPage: any, pages) => {
					const totalItems = lastPage?._meta?.totalCount || 0;
					const currentPerPage = pages.length * 12;
					if (currentPerPage < totalItems) {
						return currentPerPage + 12;
					} else {
						return undefined;
					}
				},
			}
		);
	const loadMore = () => {
		fetchNextPage();
	};
	useEffect(() => {
		remove();
		refetch();
	}, [type, settlement, onlyTop, areaId, queryClient, i18n.language]);

	const allItems = data?.pages?.[data.pages.length - 1]?.items || [];

	return (
		<div className={`${s.bestsellersContainer} __container`}>
			<Desktop>
				<div className={s.bestsellersContainerTopPanel}>
					<div className={s.bestsellersLocNameContainer}>
						<h5>{t("Популярний продукт")}</h5>
						<div className={s.locationSelectWrapper}>
							<LocationSelectorBestsellers
								setSettlement={setSettlement}
								setArea={setAreaId}
							/>
						</div>
					</div>

					<div className={s.tabsWrapper}>
						<BestsellersTabs
							setType={setType}
							type={type}
							setOnlyTop={setOnlyTop}
						/>
					</div>
				</div>
			</Desktop>
			<Mobile>
				<div className={s.bestsellersContainerTopPanelMobile}>
					<div className={s.bestsellersPopularProductBlock}>
						<h5 className={s.bestsellersTopPanelTitleMobile}>
							{t("Популярний продукт")}
						</h5>
						<div className={s.locationSelectWrapperMobile}>
							<LocationSelectorBestsellers
								setArea={setAreaId}
								setSettlement={setSettlement}
							/>
						</div>
					</div>
					<div className={s.tabsWrapperMobile}>
						<BestsellersTabs
							type={type}
							setType={setType}
							setOnlyTop={setOnlyTop}
						/>
					</div>
				</div>
			</Mobile>
			{isLoading ? (
				<PreLoaderComponent type={"category"} size={"medium"} />
			) : (
				<div>
					<div className={s.bestsellersContent}>
						<Desktop>
							{allItems.map((item: publicOneNotice) => (
								<div key={item.id}>
									<ProductCard inSwiper={true} notice={item} />
								</div>
							))}
						</Desktop>
					</div>
					<div className={s.bestsellersMobileContent}>
						<Mobile>
							{allItems.map((item: publicOneNotice) => (
								<MobileProductCard notice={item} key={item.id} />
							))}
						</Mobile>
					</div>
				</div>
			)}
			{hasNextPage && (
				<div className={s.bestsellersBottomPanel}>
					<Desktop>
						<ExpandButton
							isExpanded={isExpanded}
							setExpanded={setExpanded}
							onClick={loadMore}
						/>
					</Desktop>
					<Mobile>
						<div className={s.bannerWrap}>
							<ExpandModernButton onClickCallback={loadMore} />
							<div style={{ marginTop: "40px" }}>
								{!auth && <NoRegisterAdCardAdaptive />}
							</div>
						</div>
					</Mobile>
				</div>
			)}
		</div>
	);
};

export default Bestsellers;
