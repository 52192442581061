import React, { useEffect, useState } from "react";
import s from "./SearchFilters.module.css";
import StandartCheckbox from "../StandartCheckbox/StandartCheckbox";
import {
	Filter,
	FiltersValue,
	PropertyValues,
} from "../../types/publicNoticeTypes";
import FilterFrontHead from "./FilterFrontHead/FilterFrontHead";

type Props = {
	filter: Filter;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	showOpened: number;
	setLastChangedFilter: (b: number) => void;
	lastChangedFilter: number;
};
const FilterFrontCheckbox = ({
	filter,
	filtersValues,
	setFiltersValues,
	showOpened,
	setLastChangedFilter,
	lastChangedFilter,
}: Props) => {
	const checked = filtersValues.findIndex(
		(f: FiltersValue) => f.filter === filter.inputName
	);
	const [isExpanded, setExpanded] = useState(showOpened === 1);
	const [isNeedClear, setIsNeedClear] = useState(false);

	const clearFilter = () => {
		const updateFiltersValues = [...filtersValues].filter((f: FiltersValue) => {
			return f.filter !== filter.inputName;
		});
		setFiltersValues(updateFiltersValues);
	};
	useEffect(() => {
		if (checked !== -1) {
			setIsNeedClear(true);
		} else {
			setIsNeedClear(false);
		}
	}, [checked]);
	return (
		<div
			className={s.filterContainer}
			style={{
				background: isExpanded ? "#F8F8F8" : "",
			}}
		>
			<FilterFrontHead
				name={filter.propertyName}
				isExpanded={isExpanded}
				setExpanded={setExpanded}
				isNeedClear={isNeedClear}
				clearFilter={clearFilter}
			/>
			{isExpanded && (
				<div className={s.filerContent}>
					<ul
						className={`${s.sizeList} ${s.grid}`}
						style={{ gridTemplateColumns: `repeat(${filter.columns},1fr)` }}
					>
						{filter.propertyValues?.map((property: PropertyValues) => {
							const checked = filtersValues.findIndex(
								(f: FiltersValue) =>
									f.filter === filter.inputName &&
									f.value === property.id.toString()
							);
							//if(+f.countItem.countItems > 0 || filter.id ===lastChangedFilter){
							return (
								<li key={property.id}>
									<div className={s.sizeListItem}>
										<StandartCheckbox
											property={property}
											filtersValues={filtersValues}
											filter={filter}
											setLastChangedFilter={setLastChangedFilter}
											setFiltersValues={setFiltersValues}
											isFilterMatch={false}
										/>
										<span className={s.buttonCounterCheckBox}>
											{" "}
											{checked === -1 ? property.countItem.countItems : ""}
										</span>
									</div>
								</li>
							);
							/*  } else {
                                  return null
                              }*/
						})}

						{/*<li className={`${s.sizeListElement}`}>

                    </li>*/}
						{/*<li className={`${s.sizeListElement} ${s.disabled}`}>
                        41
                    </li>
                    <li className={`${s.sizeListElement} ${s.active}`}>
                        41
                    </li>
                    <li className={`${s.sizeListElement}`}>
                        41
                    </li>*/}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FilterFrontCheckbox;
