import React, { useEffect, useState } from "react";
import s from "./Registration&AuthForms.module.css";
import { useNavigate } from "react-router";
import FastEnter from "./FastEnter";
import FormRegAuth from "./FormReg&Auth";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";

const MobileRegistrationAuthForms = () => {
	const [isAuth, setIsAuth] = useState(true);
	const { t } = useTranslation();
	const navigate: any = useNavigate();
	const isRegister = useSelector((state: any) => state.userData.isRegistration);
	const [errorApple, setErrorApple] = useState("");
	useEffect(() => {
		if (isRegister) {
			setIsAuth(false);
		}
	}, [isRegister]);

	return (
		<>
			<div className={s.mobileContainer}>
				<div className={s.prewArrow}>
					<span onClick={() => navigate(-1)}>
						<svg
							width="8"
							height="17"
							viewBox="0 0 7 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5.69531 1L1.0312 6.5792L5.75586 12.127"
								stroke="#797979"
							/>
						</svg>
					</span>
				</div>
				<div className={s.titlePanelButton}>
					<h5
						onClick={() => setIsAuth(true)}
						className={isAuth ? s.active : ""}
					>
						{t("вхід")}
					</h5>
					<h5
						onClick={() => setIsAuth(false)}
						className={!isAuth ? s.active : ""}
					>
						{t("Реєстрація")}
					</h5>
				</div>
				<FastEnter
					errorApple={errorApple}
					setErrorApple={setErrorApple}
					closeModal={() => {
						console.log("close modal");
					}}
				/>
				<div className={s.borderSimulation}>
					<span className={s.leftLine} />
					<span className={s.orElement}>{t("або")}</span>
					<span className={s.rightLine} />
				</div>
				<FormRegAuth
					isAuth={isAuth}
					setIsAuth={setIsAuth}
					errorApple={errorApple}
					closeModal={() => {
						console.log("close modal");
					}}
				/>
			</div>
			{errorApple !== "" && errorApple !== "popup_closed_by_user" && (
				<div className={s.socialNetworkErrorPopupWrapper}>
					<div className={s.socialNetworkErrorPopupContainer}>
						<div onClick={() => setErrorApple("")}>
							<ArrowsAndCross
								id={"closeCross"}
								color={"rgba(114, 114, 114, 1)"}
							/>
						</div>
						<div>{errorApple}</div>
					</div>
				</div>
			)}
		</>
	);
};

export default MobileRegistrationAuthForms;
