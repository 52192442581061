import React, { useRef } from "react";
import s from "../CreateBoardAD.module.css";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAutoSelection } from "../../../redux/autoSelection_Reducer";
import { useParams } from "react-router-dom";


type FunctionType = (...args: any[]) => void;

interface DebounceData {
	lastCall: number;
	lastCallTimer?: ReturnType<typeof setTimeout>;
}

interface Props {
	formikStatus: any;
	values: any;
	setStatus: (status: any) => void;
}

const NameField: React.FC<Props> = ({ formikStatus, values, setStatus }) => {
	const { t } = useTranslation();
	const { noticeId } = useParams();
	const dispatch: any = useDispatch();
	const getAutoSelectionStore = useSelector(() => getAutoSelection);

	const request = (e: any) => {
		if (!noticeId) {
			dispatch(getAutoSelectionStore(e));
		}
	};
	const obj = useRef<DebounceData>({ lastCall: 0 });

	const debounce = (fn: FunctionType, timeoutMs: number) => {
		return function perform(...args: any[]) {
			const previousCall = obj.current.lastCall;
			obj.current.lastCall = Date.now();
			if (previousCall && obj.current.lastCall - previousCall <= timeoutMs) {
				clearTimeout(obj.current.lastCallTimer);
			}
			obj.current.lastCallTimer = setTimeout(() => fn(...args), timeoutMs);
		};
	};

	const debounceRequest = debounce(request, 1000);

	const validateName = (values: any) => {
		const errors: any = { name: "" };

		if (values.name.length >= 15 && values.name.length <= 150) {
			debounceRequest(values?.name);
		} else if (values.name.length < 16) {
			errors.name = t("Не менше 16 символів");
		} else if (values.name.length > 150) {
			errors.name = t("Не більше 150 символів");
		}
		return errors.name;
	};
	return (
		<div className={`${s.formElement} ${s.nameField}`}>
			<div className={s.nameSide}>
				<span className={`${s.elementName}`}>
					{t(`Заголовок оголошення`)}
					<span className={s.required}> * </span>
				</span>
			</div>
			<div
				className={`${s.fieldSide} ${s.adTitle} ${
					formikStatus?.name ? s.error : ""
				}`}
			>
				<Field
					onFocus={() => setStatus({})}
					name={"name"}
					value={values.name}
					/*onBlur={() => validate(values)}*/
					validate={() => validateName(values)}
					placeholder={`${t("Введіть назву оголошення")}...`}
					autoComplete="off"
				/>
				<div className={s.nameOptionsContainer}>
					<div className={s.nameOptions_Size}>
						{values.name.length < 16 &&
							t("Введіть назву не менш ніж 16 символів")}
					</div>
					<div className={s.nameOptions_Size}>{values.name.length}/150</div>
				</div>
			</div>

			<ErrorMessage name="name" component="span" className={s.error} />
			{formikStatus && formikStatus.name && (
				<span className={s.error}>{formikStatus.name}</span>
			)}
		</div>
	);
};

export default NameField;
