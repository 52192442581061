import React from "react";

interface Props {
	id: string;
}

function MainSearchSvgSelector({ id }: Props) {
	switch (id) {
		case "location":
			return (
				<svg
					width="18"
					height="23"
					viewBox="0 0 18 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M8.07506 22.536C1.26422 13.0737 0 12.1025 0 8.625C0 3.86153 4.02942 0 9 0C13.9706 0 18 3.86153 18 8.625C18 12.1025 16.7358 13.0737 9.92494 22.536C9.47798 23.1547 8.52197 23.1547 8.07506 22.536ZM9 12.2187C11.0711 12.2187 12.75 10.6098 12.75 8.625C12.75 6.64022 11.0711 5.03125 9 5.03125C6.92892 5.03125 5.25 6.64022 5.25 8.625C5.25 10.6098 6.92892 12.2187 9 12.2187Z" />
				</svg>
			);
		case "locationTransparent":
			return (
				<svg
					width="12"
					height="16"
					viewBox="0 0 12 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.79495 15.3933C4.97274 14.2013 4.27515 13.2076 3.68316 12.3643C2.6286 10.8621 1.90918 9.83729 1.41746 9.01966C1.03989 8.39185 0.815431 7.91538 0.682055 7.465C0.549816 7.01846 0.5 6.57354 0.5 6C0.5 2.96242 2.96242 0.5 6 0.5C9.03758 0.5 11.5 2.96242 11.5 6C11.5 6.57354 11.4502 7.01846 11.3179 7.465C11.1846 7.91538 10.9601 8.39185 10.5825 9.01966C10.0908 9.83729 9.3714 10.8621 8.31683 12.3643C7.72493 13.2075 7.02743 14.2011 6.20536 15.3928C6.10615 15.5358 5.89356 15.5357 5.79449 15.3926L5.38338 15.6772L5.79495 15.3933ZM6 9C7.65686 9 9 7.65686 9 6C9 4.34314 7.65686 3 6 3C4.34314 3 3 4.34314 3 6C3 7.65686 4.34314 9 6 9Z"
						stroke="#DADADA"
					/>
				</svg>
			);

		case "arrow":
			return (
				<svg
					width="13"
					height="7"
					viewBox="0 0 13 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M1.01172 1L6.56546 5.69441L12.1388 1" stroke="#797979" />
				</svg>
			);
		case "lupa":
			return (
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.49547 0C3.36472 0 0 3.36472 0 7.49547C0 11.6262 3.36472 14.9909 7.49547 14.9909C9.29177 14.9909 10.9414 14.3531 12.2343 13.2942L16.7096 17.7695C16.7787 17.8415 16.8614 17.8989 16.953 17.9385C17.0445 17.978 17.143 17.9989 17.2427 18C17.3425 18.001 17.4414 17.9821 17.5337 17.9444C17.626 17.9067 17.7099 17.851 17.7804 17.7804C17.851 17.7099 17.9067 17.626 17.9444 17.5337C17.9821 17.4414 18.001 17.3425 18 17.2427C17.9989 17.143 17.978 17.0445 17.9385 16.953C17.8989 16.8614 17.8415 16.7787 17.7695 16.7096L13.2942 12.2343C14.3531 10.9414 14.9909 9.29177 14.9909 7.49547C14.9909 3.36472 11.6262 0 7.49547 0ZM7.49547 1.49909C10.8161 1.49909 13.4919 4.17489 13.4919 7.49547C13.4919 10.8161 10.8161 13.4919 7.49547 13.4919C4.17489 13.4919 1.49909 10.8161 1.49909 7.49547C1.49909 4.17489 4.17489 1.49909 7.49547 1.49909Z"
						fill="#2B2A38"
					/>
				</svg>
			);
		case "popupLupa":
			return (
				<svg
					width="17"
					height="17"
					viewBox="0 0 17 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.07906 0C3.17779 0 0 3.17779 0 7.07906C0 10.9803 3.17779 14.1581 7.07906 14.1581C8.77556 14.1581 10.3336 13.5557 11.5546 12.5556L15.7813 16.7823C15.8466 16.8503 15.9247 16.9045 16.0111 16.9419C16.0976 16.9793 16.1906 16.999 16.2848 17C16.379 17.0009 16.4724 16.9831 16.5596 16.9475C16.6468 16.9119 16.726 16.8592 16.7926 16.7926C16.8592 16.726 16.9119 16.6468 16.9475 16.5596C16.9831 16.4724 17.0009 16.379 17 16.2848C16.999 16.1906 16.9793 16.0976 16.9419 16.0111C16.9045 15.9247 16.8503 15.8466 16.7823 15.7813L12.5556 11.5546C13.5557 10.3336 14.1581 8.77556 14.1581 7.07906C14.1581 3.17779 10.9803 0 7.07906 0ZM7.07906 1.41581C10.2152 1.41581 12.7423 3.94295 12.7423 7.07906C12.7423 10.2152 10.2152 12.7423 7.07906 12.7423C3.94295 12.7423 1.41581 10.2152 1.41581 7.07906C1.41581 3.94295 3.94295 1.41581 7.07906 1.41581Z"
						fill="#CBCBCB"
					/>
				</svg>
			);
		default:
			return <svg></svg>;
	}
}

export default MainSearchSvgSelector;
