import React from "react";

interface Props {
	id: string;
}

function GallerySvgSelector({ id }: Props) {
	switch (id) {
		case "backArrow":
			return (
				<svg
					width="13"
					height="20"
					viewBox="0 0 13 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M11 2L3 9.65217L10.5789 18" strokeWidth="3" />
				</svg>
			);
		case "closeCross":
			return (
				<svg
					width="22"
					height="22"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M17.7484 15.0002L29.4298 3.31823C30.1901 2.55836 30.1901 1.32976 29.4298 0.569899C28.67 -0.189966 27.4414 -0.189966 26.6816 0.569899L14.9998 12.2519L3.31843 0.569899C2.55822 -0.189966 1.33 -0.189966 0.570154 0.569899C-0.190051 1.32976 -0.190051 2.55836 0.570154 3.31823L12.2516 15.0002L0.570154 26.6822C-0.190051 27.4421 -0.190051 28.6707 0.570154 29.4305C0.948833 29.8096 1.44674 30 1.94429 30C2.44184 30 2.93939 29.8096 3.31843 29.4305L14.9998 17.7486L26.6816 29.4305C27.0606 29.8096 27.5582 30 28.0557 30C28.5533 30 29.0508 29.8096 29.4298 29.4305C30.1901 28.6707 30.1901 27.4421 29.4298 26.6822L17.7484 15.0002Z" />
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default GallerySvgSelector;
