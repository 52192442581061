import React from "react";
import s from "./ExpandModernButton.module.css";
import { useTranslation } from "react-i18next";

const ExpandModernButton = ({
	onClickCallback,
}: {
	onClickCallback: () => void;
}) => {
	const { t } = useTranslation();
	return (
		<div
			className={s.modernButtonContainer}
			onClick={() => {
				onClickCallback();
			}}
		>
			<button>
				{t("Дивитися ще")}
				<span>
					<svg
						width="8"
						height="25"
						viewBox="0 0 8 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.64645 24.3536C3.84171 24.5488 4.15829 24.5488 4.35355 24.3536L7.53553 21.1716C7.7308 20.9763 7.7308 20.6597 7.53553 20.4645C7.34027 20.2692 7.02369 20.2692 6.82843 20.4645L4 23.2929L1.17157 20.4645C0.976311 20.2692 0.659728 20.2692 0.464466 20.4645C0.269204 20.6597 0.269204 20.9763 0.464466 21.1716L3.64645 24.3536ZM3.5 0L3.5 24H4.5L4.5 0L3.5 0Z"
							fill="#727272"
						/>
					</svg>
				</span>
			</button>
		</div>
	);
};

export default ExpandModernButton;
