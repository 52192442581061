import React from "react";

interface Props {
	id: string;
	fill?: string;
}

function SellerCardSvgSelector({ id, fill }: Props) {
	switch (id) {
		case "complain":
			return (
				<svg
					width="16"
					height="14"
					viewBox="0 0 16 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M15.787 11.6882L9.36074 0.770637C9.07674 0.288136 8.56804 0 7.99998 0C7.43191 0 6.92321 0.288072 6.63921 0.770637L0.21305 11.6882C-0.0710167 12.1707 -0.0710167 12.7468 0.21305 13.2294C0.497117 13.7119 1.00574 14 1.57381 14H14.4263C14.9943 14 15.503 13.7119 15.787 13.2294C16.071 12.7469 16.071 12.1707 15.787 11.6882ZM8.00004 12.2239C7.43607 12.2239 6.97726 11.7738 6.97726 11.2206C6.97726 10.6675 7.43607 10.2175 8.00004 10.2175C8.56401 10.2175 9.02282 10.6675 9.02282 11.2206C9.02276 11.7739 8.56395 12.2239 8.00004 12.2239ZM9.02972 7.93556C9.02972 8.49244 8.56778 8.94554 8.00004 8.94554C7.4323 8.94554 6.97036 8.49244 6.97036 7.93556V3.3002C6.97036 3.03598 7.18875 2.82178 7.45812 2.82178H8.54203C8.81147 2.82178 9.02978 3.03598 9.02978 3.3002V7.93556H9.02972Z" />
				</svg>
			);
		case "close":
			return (
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M10.6491 9.00013L17.6579 1.99094C18.114 1.53502 18.114 0.797859 17.6579 0.341939C17.202 -0.11398 16.4649 -0.11398 16.0089 0.341939L8.99989 7.35114L1.99106 0.341939C1.53493 -0.11398 0.798002 -0.11398 0.342092 0.341939C-0.114031 0.797859 -0.114031 1.53502 0.342092 1.99094L7.35093 9.00013L0.342092 16.0093C-0.114031 16.4653 -0.114031 17.2024 0.342092 17.6583C0.5693 17.8858 0.868044 18 1.16657 18C1.4651 18 1.76363 17.8858 1.99106 17.6583L8.99989 10.6491L16.0089 17.6583C16.2364 17.8858 16.5349 18 16.8334 18C17.132 18 17.4305 17.8858 17.6579 17.6583C18.114 17.2024 18.114 16.4653 17.6579 16.0093L10.6491 9.00013Z" />
				</svg>
			);
		case "greenDone":
			return (
				<svg
					width="18"
					height="22"
					viewBox="0 0 18 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.8993 8.29106C11.7573 8.14912 11.5595 8.06771 11.3564 8.06771C11.1505 8.06771 10.9578 8.14697 10.8136 8.29106L8.12568 10.9799L7.19747 10.0512C7.05252 9.90666 6.8599 9.82703 6.65518 9.82703C6.45027 9.82703 6.25753 9.90678 6.11239 10.0518C5.96737 10.197 5.88746 10.3899 5.88746 10.595C5.88746 10.8003 5.96743 10.9932 6.11233 11.1381L7.58333 12.6097C7.72712 12.7533 7.91974 12.8323 8.12556 12.8323C8.33151 12.8323 8.52413 12.7532 8.6686 12.6092L11.8994 9.37732C12.1987 9.07797 12.1987 8.59078 11.8993 8.29106Z"
						fill="#31AA52"
					/>
					<path
						d="M17.9634 4.53402C17.9513 4.39411 17.8666 4.27086 17.7403 4.20948L9.17465 0.0402763C9.0644 -0.0134254 8.93554 -0.0134254 8.82528 0.0402763L0.259651 4.20941C0.133428 4.27086 0.0486656 4.39405 0.0365654 4.53396C0.0256323 4.65967 -0.219995 7.6553 0.726275 11.3141C1.28368 13.4693 2.142 15.4028 3.27732 17.0607C4.70127 19.14 6.56476 20.7868 8.81619 21.9551C8.87381 21.9851 8.93695 22 9.00003 22C9.06311 22 9.12625 21.9851 9.18387 21.9551C11.4352 20.7868 13.2988 19.1401 14.7228 17.0607C15.8581 15.4028 16.7164 13.4693 17.2738 11.3141C18.22 7.6553 17.9744 4.65974 17.9634 4.53402ZM8.99997 15.4655C6.19985 15.4655 3.92183 13.1866 3.92183 10.3855C3.92183 7.58445 6.19985 5.30563 8.99997 5.30563C11.8001 5.30563 14.0781 7.58445 14.0781 10.3855C14.0781 13.1866 11.8001 15.4655 8.99997 15.4655Z"
						fill="#31AA52"
					/>
				</svg>
			);
		case "useSafeDeal":
			return (
				<svg
					width="19"
					height="21"
					viewBox="0 0 19 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="9.5" cy="9.5" r="8.5" stroke="#0BB82E" strokeWidth="2" />
					<path
						d="M10.25 11.955H9.2L9.095 4.5H10.355L10.25 11.955ZM10.265 13.845C10.415 13.975 10.49 14.16 10.49 14.4C10.49 14.64 10.415 14.825 10.265 14.955C10.115 15.085 9.935 15.15 9.725 15.15C9.515 15.15 9.335 15.085 9.185 14.955C9.035 14.825 8.96 14.64 8.96 14.4C8.96 14.16 9.035 13.975 9.185 13.845C9.335 13.715 9.515 13.65 9.725 13.65C9.935 13.65 10.115 13.715 10.265 13.845Z"
						fill="#0BB82E"
					/>
				</svg>
			);
		case "attention":
			return (
				<svg
					width="19"
					height="21"
					viewBox="0 0 19 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="9.5" cy="9.5" r="8.5" stroke="#FF2929" strokeWidth="2" />
					<path
						d="M10.25 11.955H9.2L9.095 4.5H10.355L10.25 11.955ZM10.265 13.845C10.415 13.975 10.49 14.16 10.49 14.4C10.49 14.64 10.415 14.825 10.265 14.955C10.115 15.085 9.935 15.15 9.725 15.15C9.515 15.15 9.335 15.085 9.185 14.955C9.035 14.825 8.96 14.64 8.96 14.4C8.96 14.16 9.035 13.975 9.185 13.845C9.335 13.715 9.515 13.65 9.725 13.65C9.935 13.65 10.115 13.715 10.265 13.845Z"
						fill="#FF2929"
					/>
				</svg>
			);
		case "viber":
			return (
				<svg
					width="26"
					height="26"
					viewBox="0 0 26 26"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M21.8524 0H4C1.79086 0 0 1.79086 0 4V21.8523C0 24.0615 1.79086 25.8523 4 25.8523H21.8523C24.0615 25.8523 25.8523 24.0615 25.8523 21.8524V4C25.8523 1.79086 24.0615 0 21.8524 0Z"
						fill={fill}
					/>
					<path
						d="M21.0476 8.56228L21.0425 8.54186C20.6295 6.87238 18.7677 5.08093 17.058 4.70824L17.0387 4.70427C14.2732 4.1767 11.4692 4.1767 8.70432 4.70427L8.68447 4.70824C6.97528 5.08093 5.11349 6.87238 4.69995 8.54186L4.69541 8.56228C4.18486 10.8938 4.18486 13.2582 4.69541 15.5897L4.69995 15.6101C4.96164 16.6665 5.80327 17.7714 6.81918 18.5349C7.70099 19.1976 8.46493 20.148 8.46493 21.2511C8.46493 21.9256 9.28691 22.2569 9.75434 21.7701L10.79 20.6936C11.3312 20.131 12.0909 19.8431 12.8715 19.8431C14.2636 19.8431 15.6562 19.7115 17.0387 19.4477L17.058 19.4438C18.7677 19.0711 20.6295 17.2796 21.0425 15.6101L21.0476 15.5897C21.5581 13.2582 21.5581 10.8938 21.0476 8.56228ZM19.5534 15.2516C19.2777 16.3407 17.8641 17.6948 16.7409 17.945C15.2704 18.2246 13.7884 18.3441 12.3078 18.3031C12.2784 18.3023 12.2501 18.3137 12.2295 18.3348C12.0197 18.5501 10.8546 19.7462 10.8509 19.75C10.8508 19.7501 10.8508 19.7501 10.8507 19.7502L9.38448 21.255C9.27727 21.3668 9.08893 21.2907 9.08893 21.1364V18.0492C9.08893 17.9982 9.05251 17.9549 9.00242 17.9451C9.00214 17.945 9.00185 17.945 9.00157 17.9449C7.87837 17.6947 6.4653 16.3407 6.18904 15.2515C5.72955 13.1441 5.72955 11.0077 6.18904 8.90032C6.4653 7.81116 7.87837 6.45708 9.00157 6.20691C11.5696 5.71849 14.1734 5.71849 16.7409 6.20691C17.8646 6.45708 19.2777 7.81116 19.5534 8.90032C20.0134 11.0078 20.0134 13.1441 19.5534 15.2516Z"
						fill="white"
					/>
					<path
						d="M15.5166 16.4027C15.344 16.3503 15.1794 16.315 15.0266 16.2516C13.443 15.5946 11.9855 14.747 10.8311 13.4476C10.1746 12.7088 9.66075 11.8746 9.22639 10.9918C9.02042 10.5732 8.84683 10.1381 8.6699 9.70591C8.50857 9.31177 8.7462 8.90458 8.99642 8.60762C9.23122 8.32891 9.53334 8.11562 9.86055 7.95843C10.1159 7.83573 10.3679 7.90647 10.5544 8.12294C10.9576 8.59094 11.328 9.08288 11.6279 9.62536C11.8123 9.95903 11.7617 10.3669 11.4275 10.594C11.3462 10.6492 11.2722 10.714 11.1965 10.7763C11.1302 10.8309 11.0677 10.8861 11.0222 10.9601C10.939 11.0954 10.9351 11.2551 10.9886 11.4022C11.4008 12.535 12.0955 13.4158 13.2356 13.8902C13.4181 13.9661 13.6013 14.0544 13.8115 14.0299C14.1635 13.9888 14.2774 13.6027 14.5241 13.401C14.7652 13.2039 15.0733 13.2013 15.333 13.3656C15.5927 13.53 15.8445 13.7065 16.0948 13.8848C16.3405 14.0599 16.5851 14.2312 16.8117 14.4307C17.0296 14.6226 17.1047 14.8744 16.982 15.1348C16.7573 15.6118 16.4305 16.0086 15.959 16.2619C15.8258 16.3333 15.6668 16.3564 15.5166 16.4027C15.6668 16.3564 15.344 16.3502 15.5166 16.4027Z"
						fill="white"
					/>
					<path
						d="M12.5844 7.8993C14.6558 7.95733 16.3571 9.332 16.7217 11.3799C16.7838 11.7288 16.8059 12.0855 16.8335 12.4397C16.8452 12.5887 16.7608 12.7302 16.6 12.7321C16.434 12.7341 16.3593 12.5952 16.3485 12.4463C16.3272 12.1515 16.3124 11.8555 16.2718 11.5631C16.0573 10.0201 14.8269 8.74351 13.291 8.46958C13.0599 8.42834 12.8234 8.4175 12.5893 8.39294C12.4413 8.3774 12.2474 8.36843 12.2146 8.18447C12.1871 8.03023 12.3173 7.90741 12.4642 7.89953C12.5041 7.89731 12.5443 7.89913 12.5844 7.8993C14.6558 7.95733 12.5443 7.89913 12.5844 7.8993Z"
						fill="white"
					/>
					<path
						d="M16.0706 11.5917C16.0672 11.6176 16.0654 11.6784 16.0502 11.7357C15.9952 11.9436 15.68 11.9697 15.6073 11.7598C15.5858 11.6975 15.5826 11.6267 15.5825 11.5596C15.5818 11.1209 15.4864 10.6826 15.2652 10.3009C15.0377 9.90856 14.6903 9.57875 14.2828 9.37918C14.0363 9.25852 13.7698 9.18353 13.4998 9.13883C13.3817 9.11931 13.2624 9.10746 13.1438 9.09095C13 9.07098 12.9233 8.97937 12.9301 8.83772C12.9365 8.70498 13.0335 8.60951 13.1781 8.61767C13.6535 8.64468 14.1126 8.74741 14.5353 8.9712C15.3947 9.42632 15.8856 10.1447 16.0289 11.104C16.0354 11.1474 16.0458 11.1905 16.0491 11.2341C16.0572 11.3418 16.0623 11.4497 16.0706 11.5917C16.0672 11.6176 16.0623 11.4497 16.0706 11.5917Z"
						fill="white"
					/>
					<path
						d="M14.4675 11.8443C14.2942 11.8474 14.2015 11.7515 14.1836 11.5926C14.1712 11.4818 14.1614 11.3695 14.1349 11.2618C14.0829 11.0495 13.97 10.8528 13.7914 10.7227C13.7072 10.6612 13.6116 10.6165 13.5116 10.5876C13.3844 10.5509 13.2524 10.561 13.1256 10.5299C12.9879 10.4962 12.9117 10.3846 12.9333 10.2554C12.953 10.1378 13.0674 10.046 13.1959 10.0553C13.999 10.1133 14.573 10.5285 14.6549 11.4739C14.6607 11.5406 14.6675 11.6111 14.6527 11.675C14.6273 11.7842 14.5464 11.839 14.4675 11.8443C14.5464 11.839 14.2942 11.8474 14.4675 11.8443Z"
						fill="white"
					/>
				</svg>
			);
		case "telegram":
			return (
				<svg
					width="27"
					height="26"
					viewBox="0 0 27 26"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M22.0633 0H4.21094C2.0018 0 0.210938 1.79086 0.210938 4V21.8523C0.210938 24.0615 2.0018 25.8523 4.21094 25.8523H22.0633C24.2724 25.8523 26.0633 24.0615 26.0633 21.8524V4C26.0633 1.79086 24.2724 0 22.0633 0Z"
						fill={fill}
					/>
					<path
						d="M20.7753 5.80826L3.48747 12.5451C2.92752 12.7634 2.96144 13.5669 3.53785 13.7371L5.88868 14.4313C7.16532 14.8083 8.17078 15.7956 8.57089 17.0652L9.57033 20.2365C9.74159 20.7799 10.4302 20.9447 10.8288 20.5377C12.132 19.207 14.2155 19.0372 15.7169 20.1393L17.5566 21.4897C18.102 21.89 18.8787 21.5926 19.0172 20.9304L21.9687 6.81897C22.1133 6.12775 21.4332 5.55185 20.7753 5.80826ZM18.6078 8.93994L10.5777 16.0409C10.4977 16.1117 10.4468 16.2097 10.4349 16.3159L10.1257 19.0642C10.1156 19.1538 9.99017 19.1657 9.96339 19.0796L8.6914 14.9809C8.63314 14.7932 8.7091 14.5899 8.87605 14.4864L18.3587 8.60514C18.5768 8.46979 18.8001 8.76999 18.6078 8.93994Z"
						fill="white"
					/>
				</svg>
			);
		case "whatsApp":
			return (
				<svg
					width="27"
					height="27"
					viewBox="0 0 27 27"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M23.0002 0H4.42969C2.22055 0 0.429688 1.79086 0.429688 4V22.5705C0.429688 24.7796 2.22054 26.5705 4.42968 26.5705H23.0002C25.2093 26.5705 27.0002 24.7796 27.0002 22.5705V4C27.0002 1.79086 25.2093 0 23.0002 0Z"
						fill={fill}
					/>
					<path
						d="M8.46828 21.9513C6.81145 22.3778 5.31565 20.8479 5.77933 19.2011C5.94321 18.619 5.85009 18.0008 5.59824 17.451C5.04607 16.2457 4.75828 14.9315 4.75828 13.5885C4.75828 8.47154 8.92123 4.30859 14.0382 4.30859C19.1551 4.30859 23.318 8.47154 23.318 13.5885C23.318 18.7055 19.1551 22.8684 14.0382 22.8684C12.7544 22.8684 11.4922 22.6038 10.3281 22.0962C9.78478 21.8593 9.18102 21.7678 8.60702 21.9156L8.46828 21.9513ZM9.42279 20.08C9.62884 20.027 9.84758 20.0596 10.0292 20.1705C11.2333 20.9056 12.6197 21.2942 14.0382 21.2942C18.2871 21.2942 21.7439 17.8374 21.7439 13.5885C21.7439 9.33956 18.2871 5.88277 14.0382 5.88277C9.78924 5.88277 6.33246 9.33956 6.33246 13.5885C6.33246 15.069 6.75323 16.5067 7.54918 17.7461C7.67084 17.9356 7.70555 18.1681 7.64454 18.3848L7.58637 18.5914C7.32219 19.5297 8.17447 20.4014 9.1185 20.1583L9.42279 20.08Z"
						fill="white"
					/>
					<path
						d="M11.1318 9.36985L10.5297 9.33703C10.3406 9.32671 10.155 9.38991 10.0121 9.51409C9.72016 9.7676 9.25339 10.2577 9.11002 10.8963C8.89622 11.8486 9.22662 13.0147 10.0818 14.1807C10.9369 15.3468 12.5305 17.2125 15.3484 18.0093C16.2565 18.2661 16.9708 18.093 17.522 17.7404C17.9585 17.4612 18.2594 17.013 18.3678 16.5064L18.464 16.0573C18.4945 15.9146 18.4221 15.7698 18.2895 15.7087L16.2544 14.7706C16.1223 14.7098 15.9656 14.7482 15.8768 14.8634L15.0779 15.8991C15.0175 15.9773 14.9142 16.0083 14.8209 15.9756C14.2738 15.7833 12.4411 15.0157 11.4355 13.0787C11.3919 12.9947 11.4027 12.8927 11.4646 12.821L12.2281 11.9377C12.3061 11.8476 12.3258 11.7207 12.279 11.6111L11.4017 9.55881C11.355 9.44955 11.2504 9.37632 11.1318 9.36985Z"
						fill="white"
					/>
				</svg>
			);
		case "yellowHeart":
			return (
				<svg
					width="21"
					height="19"
					viewBox="0 0 21 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.003 1.53053C11.1455 0.507728 12.6365 -0.0386034 14.1694 0.00380146C15.7023 0.0462063 17.1607 0.674127 18.245 1.75853C19.3283 2.84172 19.9563 4.29827 20.0002 5.82961C20.0441 7.36096 19.5005 8.85108 18.481 9.99453L10.001 18.4865L1.52298 9.99453C0.502231 8.85049 -0.0418219 7.35909 0.00251128 5.82651C0.0468444 4.29394 0.6762 2.83648 1.76137 1.75336C2.84654 0.670234 4.30518 0.0436327 5.83784 0.00219405C7.37049 -0.0392446 8.86087 0.507624 10.003 1.53053ZM16.829 3.17153C16.1066 2.44965 15.1353 2.03161 14.1144 2.00316C13.0935 1.97471 12.1005 2.33801 11.339 3.01853L10.004 4.21653L8.66798 3.01953C7.90965 2.34142 6.92144 1.97772 5.90444 2.00245C4.88744 2.02717 3.91808 2.43846 3.1936 3.15262C2.46912 3.86678 2.04396 4.83015 2.00465 5.84668C1.96534 6.86322 2.31482 7.85655 2.98198 8.62453L10.002 15.6555L17.022 8.62553C17.6863 7.86093 18.0359 6.87277 18.0001 5.8605C17.9642 4.84823 17.5457 3.88725 16.829 3.17153Z"
						fill="#FFD74A"
					/>
				</svg>
			);

		case "yellowHeartSelected":
			return (
				<svg
					width="21"
					height="19"
					viewBox="0 0 21 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.99182 0.0852425C5.87688 -0.0655593 6.78451 -0.0158135 7.64779 0.230813C8.51107 0.47744 9.30799 0.914659 9.97982 1.51024L10.0168 1.54324L10.0508 1.51324C10.692 0.950539 11.4458 0.531038 12.262 0.282725C13.0782 0.0344121 13.9379 -0.0370035 14.7838 0.0732426L15.0298 0.109243C16.0961 0.293352 17.0928 0.762399 17.9143 1.46671C18.7358 2.17103 19.3515 3.08439 19.6963 4.11008C20.041 5.13577 20.102 6.2356 19.8727 7.29311C19.6434 8.35062 19.1324 9.32645 18.3938 10.1172L18.2138 10.3022L18.1658 10.3432L10.7158 17.7222C10.5439 17.8924 10.3161 17.9945 10.0747 18.0096C9.83328 18.0246 9.59458 17.9517 9.40282 17.8042L9.30882 17.7222L1.81582 10.3002C1.02204 9.5279 0.457522 8.55092 0.184781 7.47751C-0.0879596 6.4041 -0.058277 5.27614 0.270541 4.21856C0.59936 3.16098 1.21448 2.21504 2.04778 1.48551C2.88109 0.755987 3.90005 0.271339 4.99182 0.0852425Z"
						fill="#FFD74A"
					/>
				</svg>
			);

		case "heartInHover":
			return (
				<svg
					width="20"
					height="19"
					viewBox="0 0 20 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.1 15.55L10 15.65L9.89 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 3 9.07 4.36H10.93C11.46 3 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55ZM14.5 0C12.76 0 11.09 0.81 10 2.08C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.41 0 5.5C0 9.27 3.4 12.36 8.55 17.03L10 18.35L11.45 17.03C16.6 12.36 20 9.27 20 5.5C20 2.41 17.58 0 14.5 0Z"
						fill="#FFD74A"
					/>
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default SellerCardSvgSelector;
