import i18n from "i18next";
import { Dispatch } from "redux";
import { userAPI } from "../api/api";
import { reduxActionTypes } from "../types/reduxStoreTypes";
import {
	OnePaymentInfoElement,
	PaymentGetData,
	userCredentialsType,
	userDataType,
	userRegisterType,
	valuesForChangeUserContactsData,
} from "../types/userTypes";
import { setRecentlyWatchedList } from "./publicNotice_Reducer";

declare global {
	interface Window {
		LiqPayCheckout: any; // Объявляем LiqPayCheckout как any
		LiqPayCheckoutCallback: () => void; // Объявляем тип для LiqPayCheckoutCallback
		adsbygoogle: any;
	}
}

const SET_AUTH_USER = "bago/userReducer/SET_AUTH_USER";
const SET_NETWORK_AUTH_USER = "bago/userReducer/SET_NETWORK_AUTH_USER";
const SET_LOGOUT_USER = "bago/userReducer/SET_LOGOUT_USER";
const SET_USER_VIEW = "bago/userReducer/SET_USER_VIEW";
const SET_USER_STATIC_VIEW = "bago/userReducer/SET_USER_STATIC_VIEW";
const SET_RECENTLY_WATCH_NOTICES =
	"bago/userReducer/SET_RECENTLY_WATCH_NOTICES";
const SET_SUCCESS_APPROVE_PHONE = "bago/userReducer/SET_SUCCESS_APPROVE_PHONE";

/* =================New reg ===============*/
const SET_HANDLE_APPROVE_EMAIL_POPUP =
	"bago/userReducer/SET_HANDLE_APPROVE_EMAIL_POPUP";
const SET_APPROVE_EMAIL_INFO = "bago/userReducer/SET_APPROVE_EMAIL_INFO";
const SET_PHONE_NOT_EXITS = "bago/userReducer/SET_PHONE_NOT_EXITS";
const SET_SMS_LIMIT_ERROR = "bago/userReducer/SET_SMS_LIMIT_ERROR";
const SET_HANDLE_APPROVE_PHONE_POPUP =
	"bago/userReducer/SET_HANDLE_APPROVE_PHONE_POPUP";
const SET_HANDLE_ERROR_APPROVE_PHONE_POPUP =
	"bago/userReducer/SET_HANDLE_ERROR_APPROVE_PHONE_POPUP";
const SET_HANDLE_PHONE_ENTER_POPUP =
	"bago/userReducer/SET_HANDLE_PHONE_ENTER_POPUP";

/*===============================================*/
const SET_NEW_USER_INFO = "bago/userReducer/SET_NEW_USER_INFO";
const SET_SAFE_PASSWORD = "bago/userReducer/SET_SAFE_PASSWORD";
const SET_SAVE_ME = "bago/userReducer/SET_SAVE_ME";
const SET_APPROVED_PHONE = "bago/userReducer/SET_APPROVED_PHONE";
const SET_ERROR_APPROVED_PHONE = "bago/userReducer/SET_ERROR_APPROVED_PHONE";
const SET_RESTORE_PASS = "bago/userReducer/SET_RESTORE_PASS";
const SET_NEW_PASS = "bago/userReducer/SET_NEW_PASS";
const SET_FAVORITE_COUNT = "bago/userReducer/SET_FAVORITE_COUNT";
const SET_RESULT = "bago/userReducer/SET_RESULT";
const SET_TEMP_PHONE_NUMBER = "bago/userReducer/SET_TEMP_PHONE_NUMBER";

const SET_USER_ADULT = "bago/userReducer/SET_USER_ADULT";
const SET_USER_IS_REGISTRATION = "bago/userReducer/SET_USER_IS_REGISTRATION";
const SET_USER_PAYMENT_LIST = "bago/userReducer/SET_USER_PAYMENT_LIST";

const initialState: userDataType = {
	user: {
		id: null,
		email: "",
		for_change_email: null,
		phone: "",
		name: "",
		last_name: null,
		parent_name: null,
		language: "",
		time_registration: "",
		time_last_visit: "",
		ban_exists: null,
		ban_description: null,
		telegram: 0,
		viber: 0,
		whatsapp: 0,
		is_approved: 0,
		is_approve_phone: null,
		additional_data: null,
		photo_document_url: null,
		photo: {
			photoUrl: "",
			photo_approved: false,
		},
		is_restricted: null,
		_links: {
			self: {
				href: "",
			},
		},
		network: "",
	},
	phoneNotExits: false,
	userStatic: {
		userBalance: "",
		userBonus: null,
		totalBalance: 0,
		userCountFavorites: "",
		userCountNotices: [
			{
				summary: "",
				waitModer: "",
				rejected: "",
				active: "",
				noActive: "",
			},
		],
		userViewNotice: [
			{
				notice_id: "",
				created_at: "",
			},
		],
	},
	successApprovePhone: false,
	isAuth: false,
	safePass: false,
	//openApproved: true,
	saveMe: true,
	//restorePassMessage: "",
	sendType: "",
	result: "",
	recovery_code: "",
	approveEmailPopup: false,
	approveEmailInfo: "",
	changePhoneErrors: "",
	changePhoneSuccess: "",
	tempRegPhone: "",
	smsLimitError: false,
	phoneEnterPopup: false,
	openApprovePhonePopup: false,
	errorApprovePhonePopup: false,
	isUserAdult: false,
	isRegistration: false,
	paymentList: [],
};

const user_Reducer = (state = initialState, action: reduxActionTypes) => {
	switch (action.type) {
		case SET_SAVE_ME:
			return {
				...state,
				saveMe: !state.saveMe,
			};
		case SET_AUTH_USER:
		case SET_USER_VIEW:
		case SET_NETWORK_AUTH_USER:
		case SET_NEW_USER_INFO:
			return {
				...state,
				user: action.data.user,
				isAuth: true,
			};
		case SET_LOGOUT_USER:
			localStorage.removeItem("JWTBago");
			sessionStorage.removeItem("JWTBago");
			return {
				...state,
				user: {},
				isAuth: false,
				userStatic: {
					userBalance: "",
					userCountFavorites: "",
					userCountNotices: [
						{
							summary: "",
							waitModer: "",
							rejected: "",
							active: "",
							noActive: "",
						},
					],
					userViewNotice: [
						{
							notice_id: "",
							created_at: "",
						},
					],
				},
				openApproved: false,
			};
		case SET_SAFE_PASSWORD:
			return {
				...state,
				safePass: action.data,
			};
		case SET_USER_STATIC_VIEW:
			const currencyBalance = +action.data.userBalance ?? 0;
			const bonusBalance = +action.data.userBonus ?? 0;
			const totalBalance = currencyBalance + bonusBalance;
			return {
				...state,
				userStatic: { ...action.data, totalBalance: totalBalance.toString() },
			};
		case SET_RECENTLY_WATCH_NOTICES:
			return {
				...state,
				recentlyWatchedNotices: action.data,
			};
		case SET_RESULT:
			return {
				...state,
				result: action.data,
			};
		case SET_APPROVED_PHONE:
			return {
				...state,
				result: action.data?.message,
				user: action?.data?.user || state.user,
				//successApprovePhone:true
			};
		case SET_APPROVE_EMAIL_INFO:
			return {
				...state,
				approveEmailInfo: action.data,
			};
		case SET_RESTORE_PASS:
			return {
				...state,
				sendType: action.data.sendType,
				recovery_code: action.data?.recovery_code || "",
				success: action.data?.success,
				result: action.data?.message,
			};
		case SET_NEW_PASS:
			return {
				...state,
				user: action.data.user,
			};
		/*case HANDLE_APPROVE_PHONE_POPUP:
            return {
                ...state,
                approvePhonePopup: action.data
            }*/
		case SET_HANDLE_APPROVE_EMAIL_POPUP:
			return {
				...state,
				approveEmailPopup: action.data,
			};
		case SET_PHONE_NOT_EXITS:
			return {
				...state,
				phoneNotExits: action.data,
			};
		case SET_SMS_LIMIT_ERROR:
			return {
				...state,
				smsLimitError: action.data,
			};
		case SET_HANDLE_APPROVE_PHONE_POPUP:
			return {
				...state,
				openApprovePhonePopup: action.data,
			};
		case SET_HANDLE_ERROR_APPROVE_PHONE_POPUP:
			return {
				...state,
				errorApprovePhonePopup: action.data,
			};
		case SET_HANDLE_PHONE_ENTER_POPUP:
			return {
				...state,
				phoneEnterPopup: action.data,
			};
		case SET_FAVORITE_COUNT:
			return {
				...state,
				userStatic: { ...state.userStatic, userCountFavorites: action.data },
			};
		case SET_TEMP_PHONE_NUMBER:
			return {
				...state,
				tempRegPhone: action.data,
			};
		case SET_SUCCESS_APPROVE_PHONE:
			return {
				...state,
				successApprovePhone: action.data,
			};
		case SET_USER_ADULT:
			return {
				...state,
				isUserAdult: action.data,
			};
		case SET_USER_IS_REGISTRATION:
			return {
				...state,
				isRegistration: action.data,
			};
		case SET_USER_PAYMENT_LIST:
			const getMonthTimestamp = (timestamp: number): number => {
				const date = new Date(timestamp * 1000); // Преобразуем в миллисекунды
				date.setDate(1); // Устанавливаем день месяца на 1
				date.setHours(0, 0, 0, 0); // Устанавливаем время на полночь первого дня месяца
				return date.getTime() / 1000; // Возвращаем timestamp первого дня месяца
			};
			// Объект для хранения данных разбитых по месяцам с ключами в виде timestamp первого дня месяца
			const dataByMonth: { [monthTimestamp: number]: any[] } = {};
			// Разбиваем исходные данные по месяцам
			action?.data?.pages?.forEach((item: any) => {
				item?.items?.forEach((item: any) => {
					const monthTimestamp = getMonthTimestamp(item.created_at);
					if (!dataByMonth[monthTimestamp]) {
						dataByMonth[monthTimestamp] = [];
					}
					dataByMonth[monthTimestamp].push(item);
				});
			});
			const monthEntries = dataByMonth && Object.entries(dataByMonth).reverse();
			return {
				...state,
				paymentList: monthEntries,
			};
		default:
			return state;
	}
};

export const setAuthUser = (data: any) => ({ type: SET_AUTH_USER, data });
export const setLogoutUser = () => ({ type: SET_LOGOUT_USER });
export const setUserView = (data: object) => ({ type: SET_USER_VIEW, data });
export const setUserStaticView = (data: object) => ({
	type: SET_USER_STATIC_VIEW,
	data,
});
export const setNewUserInfo = (data: object) => ({
	type: SET_NEW_USER_INFO,
	data,
});
export const setSafePass = (data: boolean) => ({
	type: SET_SAFE_PASSWORD,
	data,
});
export const setSaveMe = () => ({ type: SET_SAVE_ME });

export const setApproveUserPhone = (data: any) => ({
	type: SET_APPROVED_PHONE,
	data,
});
export const setApproveUserPhoneError = (data: string) => ({
	type: SET_ERROR_APPROVED_PHONE,
	data,
});
export const setRestorePass = (data: string) => ({
	type: SET_RESTORE_PASS,
	data,
});
export const setResult = (data: string) => ({ type: SET_RESULT, data });

//export const handleApprovePopup = (data: boolean) => ({type: HANDLE_APPROVE_PHONE_POPUP, data});
//export const setChangePhoneErrors = (data: any) => ({type: SET_ERRORS_CHANGE_PHONE, data});
//export const setChangePhoneSuccess = (data: any) => ({type: SET_SUCCESS_CHANGE_PHONE, data});
export const setTempPhoneNumber = (data: string) => ({
	type: SET_TEMP_PHONE_NUMBER,
	data,
});
export const setSuccessApproveNumber = (data: boolean) => ({
	type: SET_SUCCESS_APPROVE_PHONE,
	data,
});

/*=============New reg =================*/
export const setHandleApproveEmailPopup = (data: boolean) => ({
	type: SET_HANDLE_APPROVE_EMAIL_POPUP,
	data,
});
export const setApproveEmailInfo = (data: boolean) => ({
	type: SET_APPROVE_EMAIL_INFO,
	data,
});
export const setPhoneNotExist = (data: boolean) => ({
	type: SET_PHONE_NOT_EXITS,
	data,
});
export const setSmsLimitError = (data: boolean) => ({
	type: SET_SMS_LIMIT_ERROR,
	data,
});

export const setErrorApprovePhonePopup = (data: boolean) => ({
	type: SET_HANDLE_ERROR_APPROVE_PHONE_POPUP,
	data,
});
export const setApprovePhonePopup = (data: boolean) => ({
	type: SET_HANDLE_APPROVE_PHONE_POPUP,
	data,
});

export const setPhoneEnterPopup = (data: boolean) => ({
	type: SET_HANDLE_PHONE_ENTER_POPUP,
	data,
});
export const setUserAdult = (data: boolean) => ({ type: SET_USER_ADULT, data });

/*=================================================*/
export const setFavoriteCount = (data: number) => ({
	type: SET_FAVORITE_COUNT,
	data,
});

export const setIsRegistration = (data: boolean) => ({
	type: SET_USER_IS_REGISTRATION,
	data,
});
export const setUserPaymentList = (data: OnePaymentInfoElement[] | any) => ({
	type: SET_USER_PAYMENT_LIST,
	data,
});

export const getRegistrationUser =
	(
		data: userRegisterType,
		submitSetStatus: any,
		closeModal: (b: boolean) => void,
		setForgotPass: any
	) =>
	async (dispatch: any) => {
		try {
			const response: any = await userAPI.registration(data);

			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
			} else if (response.data?.requireSMS) {
				setForgotPass(true);
				dispatch(getRestorePass(data.phone, data.captcha));
			} else if (response.data?.user && !response.data?.requireSMS) {
				dispatch(setTempPhoneNumber(data.phone));
				dispatch(getSmsCode(response.data?.user.phone));
				closeModal(true);
				dispatch(setApprovePhonePopup(true));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getSmsCode = (phone: string) => async (dispatch: Dispatch) => {
	try {
		const response: any = await userAPI.getSmsCode(phone);
		if (response.data?.errors) {
			dispatch(setApproveUserPhoneError(response?.data.errors.error));
		} else if (response.data?.success) {
			dispatch(setApprovePhonePopup(true));
			dispatch(setApproveUserPhone(response?.data.success));
			dispatch(setSuccessApproveNumber(false));
		}
	} catch (error: any) {
		const code = error.code;
		if (code === "ERR_NETWORK") {
			console.log(error.message);
		}
	}
};
export const getEnterSmsCode =
	(code: number, phone: string, setStatus: any) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.getEnterSmsCode(code, phone);
			if (response.data?.errors) {
				setStatus(response.data?.errors);
				dispatch(setApproveUserPhoneError(response?.data.errors.error));
			} else if (response.data?.token) {
				dispatch(setSuccessApproveNumber(true));
				dispatch(setApproveUserPhone(response?.data));
				dispatch(setAuthUser(response?.data));
				//dispatch(setApprovePhonePopup(false))
				sessionStorage.setItem("JWTBago", response.data.token);
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};

export const getAuthUser =
	(
		credentials: userCredentialsType,
		submitSetStatus: any,
		closeModal: (b: boolean) => void,
		saveMe: boolean,
		setForgotPass: any,
		handleRecaptchaRefresh: () => void
	) =>
	async (dispatch: (response: object) => void) => {
		try {
			const response: any = await userAPI.login(credentials);
			if (response.data?.errors && !response.data?.requireSMS) {
				submitSetStatus(response.data?.errors);
				handleRecaptchaRefresh();
			} else if (response.data?.requireSMS && response.data?.errors) {
				dispatch(setTempPhoneNumber(credentials.phone));
				dispatch(getSmsCode(credentials.phone));
				closeModal(true);
				dispatch(setApprovePhonePopup(true));
			} else if (response.data?.requireSMS) {
				setForgotPass(true);
				handleRecaptchaRefresh();
				dispatch(getRestorePass(credentials.phone, credentials.captcha));
			} else if (response.data?.token) {
				dispatch(setAuthUser(response?.data));
				if (saveMe) {
					localStorage.setItem("JWTBago", response.data.token);
				} else {
					sessionStorage.setItem("JWTBago", response.data.token);
				}
				dispatch(getUserStaticView());
				dispatch(setUserAdult(response.data?.user?.plus18 === 1));
				closeModal(true);
			}
			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
				handleRecaptchaRefresh();
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getNetworkAuthUser =
	(
		params: {
			code: string;
			clientName: string;
		},
		closeModal: (b: boolean) => void,
		setErrorApple: (b: string) => void
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.authNetwork(params);
			if (response.data?.errors) {
				setErrorApple(response.data?.errors.error);
			} else if (response.data?.token) {
				dispatch(setAuthUser(response?.data));
				localStorage.setItem("JWTBago", response.data.token);
				closeModal(true);
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getNetworkAuthUserApple =
	(
		code: string,
		id_token: string,
		closeModal: (b: boolean) => void,
		setErrorNetworkAuth: (b: string) => void,
		user?: { name: { firstName: string; lastName: string }; email: string }
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.authNetworkApple(
				code,
				id_token,
				user
			);
			if (response.data?.errors) {
				setErrorNetworkAuth(response.data?.errors.error);
				//console.log(response.data?.errors)
			} else if (response.data?.token) {
				dispatch(setAuthUser(response?.data));
				localStorage.setItem("JWTBago", response.data.token);
				closeModal(true);
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getNetworkAuthUserLink =
	(
		params: {
			code: string;
			clientName: string;
		},
		setErrorNetworkAuth: (b: string) => void
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.authNetworkLink(params);
			if (response.data?.errors) {
				setErrorNetworkAuth(response.data?.errors.error);
			} else if (response.data?.token) {
				dispatch(setAuthUser(response?.data));
				localStorage.setItem("JWTBago", response.data.token);
				setErrorNetworkAuth("");
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getNetworkAuthUserLinkApple =
	(params: { code: string }, setErrorNetworkAuth: (b: string) => void) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.authNetworkLinkApple(params);
			if (response.data?.errors) {
				setErrorNetworkAuth(response.data?.errors.error);
			} else if (response.data?.token) {
				dispatch(setAuthUser(response?.data));
				localStorage.setItem("JWTBago", response.data.token);
				setErrorNetworkAuth("");
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getUserView = () => async (dispatch: Dispatch) => {
	try {
		const response: any = await userAPI.userView();
		dispatch(setUserView(response?.data));
	} catch (error: any) {
		const code = error.code;
		if (code === "ERR_NETWORK") {
			console.log(error.message);
		}
	}
};
export const getUserStaticView = () => async (dispatch: Dispatch) => {
	try {
		const response: any = await userAPI.getUserStatistic();
		if (response?.data) {
			dispatch(setUserStaticView(response?.data.usersData));
			dispatch(setRecentlyWatchedList(response.data.usersData.userViewNotice));
		}
	} catch (error: any) {
		const code = error.code;
		if (code === "ERR_NETWORK") {
			console.log(error.message);
		}
	}
};
export const changeUserInfoName =
	(newInfo: valuesForChangeUserContactsData, submitSetStatus: any) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.changeUserName(newInfo);
			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
			} else if (response.data?.user) {
				dispatch(setNewUserInfo(response?.data));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const changeUserInfoPassword =
	(
		values: {
			password: string;
		},
		submitSetStatus: any
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.changeUserPassword(values);
			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
			} else if (response.data?.user) {
				dispatch(setNewUserInfo(response?.data));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const changeUserInfoEmail =
	(
		values: {
			new_email: string;
		},
		submitSetStatus: any
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.changeUserEmail(values);

			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
			} else if (response.data?.user) {
				dispatch(setNewUserInfo(response?.data));
				dispatch(setHandleApproveEmailPopup(true));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const changeUserInfoPhone =
	(
		values: {
			new_phone: string;
		},
		submitSetStatus?: any
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.changeUserPhone(values);
			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
				if (response.data?.errors?.error) {
					dispatch(setErrorApprovePhonePopup(true));
					dispatch(setResult(response.data?.errors.error));
				}
			} else if (response.data?.success) {
				// dispatch(setChangePhoneSuccess(response.data?.success?.message))
				//dispatch(handleApprovePopup(true))
				dispatch(setPhoneEnterPopup(false));
				//dispatch(setTempPhoneNumber(values.new_phone))
				//dispatch(setApprovePhonePopup(false))
				dispatch(setResult(response.data?.success?.message || ""));
				dispatch(setApprovePhonePopup(true));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const approveNewPhoneAfterChange =
	(code: string) => async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.approveNewPhone(code);
			if (response.data?.errors) {
				if (response.data?.errors?.error) {
					dispatch(setResult(response.data?.errors.error));
				}
			} else if (response.data?.user) {
				debugger;
				dispatch(setApproveUserPhone(response.data));
				dispatch(setApprovePhonePopup(false));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const changeUserInfoRestricted =
	(
		values: {
			is_restricted: number;
		},
		submitSetStatus: any
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.changeUserRestricted(values);
			if (response.data?.errors) {
				submitSetStatus(response.data?.errors);
			} else if (response.data?.user) {
				dispatch(setNewUserInfo(response?.data));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const changeUserPhoto =
	(values: { photo_file: File | null }) => async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.changeUserPhoto(values);
			if (response.data?.errors) {
				console.log(response.data.errors);
			} else if (response.data?.user) {
				dispatch(setNewUserInfo(response?.data));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const getRestorePass =
	(phone: string, captcha: string, submitSetStatus?: any) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.getForgotPassCode(phone, captcha);
			if (response.data?.errors) {
				submitSetStatus && submitSetStatus(response.data?.errors);
				if (response.data?.errors?.captcha) {
					// submitSetStatus(response.data.errors.captcha)
					dispatch(setResult(response.data.errors.captcha));
				}
			}
			if (response.data.phoneNotExist) {
				dispatch(setPhoneNotExist(true));
			}
			if (response.data.result) {
				if (response.data.sendType === "email")
					dispatch(setRestorePass(response?.data));
				else if (
					response.data.sendType === "sms" &&
					response.data.recovery_code
				) {
					dispatch(setRestorePass(response?.data));
				}
			}
			if (!response.data.result) {
				if (response.data.sendType === "email")
					dispatch(setRestorePass(response?.data));
				else if (response.data.sendType === "sms") {
					dispatch(setSmsLimitError(true));
				}
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const getSetPassword =
	(
		code: string,
		password_new: string,
		closeModal: any,
		sms_code?: string,
		submitSetStatus?: any
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.setRestoredPass(
				code,
				password_new,
				sms_code || ""
			);
			if (response.data?.errors) {
				submitSetStatus && submitSetStatus(response.data?.errors);
			} else if (response.data.token) {
				dispatch(setAuthUser(response?.data));
				sessionStorage.setItem("JWTBago", response.data.token);
				closeModal(true);
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const getActivateUserByEmail =
	(code: string) => async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.verifyEmail(code);
			if (response.data?.errors) {
				dispatch(setApproveEmailInfo(response.data.errors.error));
			} else if (response.data.token) {
				dispatch(setApproveEmailInfo(response.data.message));
				dispatch(setResult(response.data.message));
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const getDeleteBagoClient =
	(navigate: any, setStatus: any, password?: string) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.deleteBagoClient(password);
			if (response.data?.errors) {
				setStatus(response.data?.errors);
			} else if (response.data) {
				dispatch(setLogoutUser());
				navigate("/");
			}
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export const getAddBalance = (sum: string) => async (dispatch: any) => {
	try {
		const response: any = await userAPI.addBalance(sum);
		if (response.data?.errors) {
		} else if (response.data?.liqpay) {
			const data = response.data?.liqpay;
			window.LiqPayCheckoutCallback = function () {
				window.LiqPayCheckout.init({
					data: data.data,
					signature: data.signature,
					embedTo: "#liqpay_checkout",
					language: i18n.language,
					mode: "popup", // embed || popup
				})
					.on("liqpay.callback", function (data: any) {
						dispatch(getUserStaticView());
						console.log(data.status);
						console.log(data);
					})
					.on("liqpay.ready", function (data: any) {
						// ready
					})
					.on("liqpay.close", function (data: any) {
						// close
					});
			};

			window.LiqPayCheckoutCallback();
		}
	} catch (error: any) {
		const code = error.code;
		if (code === "ERR_NETWORK") {
			console.log(error.message);
		}
	}
};
export const getUserPaymentList =
	(data?: PaymentGetData) => async (dispatch: Dispatch) => {
		try {
			const response: any = await userAPI.paymentIndex(data);
			dispatch(setUserPaymentList(response?.data?.payments?.items));
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};

export const applyPromocode =
	(promocode: string) => async (dispatch: any) => {
		try {
			const response: any = await userAPI.applyPromocode(promocode);
			if (response.data?.errors) {
				console.log(response.data.errors);
				return response.data.errors;
			}
			dispatch(getUserStaticView());
			return response.data;
		} catch (error: any) {
			const code = error.code;
			if (code === "ERR_NETWORK") {
				console.log(error.message);
			}
		}
	};
export default user_Reducer;
