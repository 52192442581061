import { Field, Formik, FormikHelpers } from "formik";
import { t } from "i18next";
import { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import styles from "./SellerContacts.module.css";
import MessengersIcons from "../../../../../Components/PersonalCabinet/MessengersIcons/MessengersIcons";
import { AboutSellerField } from "../AboutSeller/AboutSellerField";
import { CheckboxesBlock } from "../CheckboxesBlock/CheckboxesBlock";
import DeliverySeller from "../DeliverySeller/DeliverySeller";
import Collapse from '../Collapse/Collapse'
import Checkbox from '../Checkbox/Checkbox'

export const SellerContacts = () => {
	const noticeDescriptionRef = useRef<HTMLDivElement>(null);
	const [status, setStatuss] = useState<string>("inactive");

	const [isEditAboutSeller, setIsEditAboutSeller] = useState(false);
	const [isEditContactsSeller, setIsEditContactsSeller] = useState(false);
	const [isEditDeliverySeller, setIsEditDeliverySeller] = useState(false);
	const [isEditPaymentSeller, setIsEditPaymentSeller] = useState(false);

	const initialValuesCreate = {
		company: "Company",
		contactPhone: "",
		viber: null,
		telegram: null,
		whatsapp: null,
		description: "Description",
	};

	const submitForm = (values: any, submitProps: FormikHelpers<any>) => {
		// dispatch(createNotice(values, submitProps.setStatus, type, navigate));
	};

	const handleCheckBox = () => {
		return;
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValuesCreate}
			// initialValues={noticeId ? initialValues : initialValuesCreate}
			onSubmit={(values, submitProps) => {
				submitForm(values, submitProps);
			}}
		>
			{({
				values,
				status: formikStatus,
				handleSubmit,
				setFieldValue,
				setStatus,
			}) => (
				<div className={styles.containerContacts}>
					<Collapse
						title="Про продавця"
						isEdit={isEditAboutSeller}
						onChangeEdit={setIsEditAboutSeller}
					>
						<div id="about" className={styles.formElement}>
							<div className={styles.nameSide}>Назва компанії</div>
							<div
								className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}
							>
								<div className={styles.emailInputWrapper}>
									{isEditAboutSeller ? (
										<Field
											type={"text"}
											name={"company"}
											placeholder="Введите Назву Компанії"
										/>
									) : (
										<p>{values.company}</p>
									)}
								</div>
							</div>
						</div>

						<AboutSellerField
							noticeDescriptionRef={noticeDescriptionRef}
							status={status}
							setStatus={setStatuss}
							values={values}
							setFieldValue={setFieldValue}
							isEdit={isEditAboutSeller}
						/>

						{isEditAboutSeller ? (
							<button
								type={"submit"}
								name={"save"}
								className={styles.button}
								// onClick={() => handlePerView(values)}
							>
								Зберегти
							</button>
						) : null}
					</Collapse>

					<Collapse
						title="Контакти"
						isEdit={isEditContactsSeller}
						onChangeEdit={setIsEditContactsSeller}
					>
						<div id="contacts" className={styles.formElement}>
							<div className={styles.nameSide}>Телефони</div>
							<div
								className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}
							>
								{isEditContactsSeller ? (
									<>
										<PhoneInput
											inputClass={styles.phoneInputStyle}
											country={"ua"}
											value={values.contactPhone}
											disabled={false}
										/>
										<MessengersIcons placement={"contacts"}
											values={initialValuesCreate as any}
											setFieldValue={setFieldValue}
										/>
									</>
								) : (
									<p>380671111111</p>
								)}
							</div>
						</div>
						<div className={styles.formElement}>
							<div className={styles.nameSide}>Email</div>
							<div
								className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}
							>
								<div className={styles.emailInputWrapper}>
									{isEditContactsSeller ? (
										<Field
											type={"email"}
											name={"email"}
											placeholder="Введите ваш контактный email "
										/>
									) : (
										<p>test@gmail.com</p>
									)}
								</div>
							</div>
						</div>
						<div className={styles.formElement}>
							<div className={styles.nameSide}>Сайт компании</div>
							<div
								className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}
							>
								<div className={styles.emailInputWrapper}>
									{isEditContactsSeller ? (
										<Field
											type={"text"}
											name={"site"}
											placeholder="https://example.com.ua"
										/>
									) : (
										<p>https://example.com.ua</p>
									)}
								</div>
							</div>
						</div>
						<div className={styles.formElement}>
							<div className={styles.nameSide}>Місто</div>
							<div
								className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}
							>
								<div className={styles.emailInputWrapper}>
									{isEditContactsSeller ? (
										<Field type={"text"} name={"site"} placeholder="Місто" />
									) : (
										<p>Місто</p>
									)}
								</div>
							</div>
						</div>
						<div className={styles.formElement}>
							<div className={styles.nameSide}>Адреса</div>
							<div
								className={`${styles.fieldSide} ${styles.numberPhoneMessenger}`}
							>
								<div className={styles.emailInputWrapper}>
									{isEditContactsSeller ? (
										<Field type={"text"} name={"site"} placeholder="Адреса" />
									) : (
										<p>Адреса</p>
									)}
								</div>
							</div>
						</div>

						{isEditContactsSeller ? (
							<button
								type={"submit"}
								name={"save"}
								className={styles.button}
								// onClick={() => handlePerView(values)}
							>
								Зберегти
							</button>
						) : null}
					</Collapse>

					<Collapse
						title="Оплата та гарантія"
						isEdit={isEditPaymentSeller}
						onChangeEdit={setIsEditPaymentSeller}
					>
						<div>
							<CheckboxesBlock
								title="Оплата"
								isEdit={isEditPaymentSeller}
								content={
									isEditPaymentSeller ? (
										<>
											<Checkbox
												checked={false}
												onChange={handleCheckBox}
												label={t("На карту Приват банка")}
											/>
											<Checkbox
												checked={false}
												onChange={handleCheckBox}
												label={t("Наложеный платёж")}
											/>
											<Checkbox
												label={t("Безопасная сделка BAGO")}
												checked={false}
												onChange={handleCheckBox}
											/>
											<Checkbox
												label={t("На карту Монобанка")}
												checked={false}
												onChange={handleCheckBox}
											/>
											<Checkbox
												label={t("По реквизитам  (счёт-фактура)")}
												checked={false}
												onChange={handleCheckBox}
											/>
										</>
									) : (
										<div>
											<p>На карту Приват банка</p>
											<p>Наложеный платёж</p>
										</div>
									)
								}
								bgColor="#FAFAFA"
							/>
							<CheckboxesBlock
								title="Умови гарантії"
								isEdit={isEditPaymentSeller}
								content={
									isEditPaymentSeller ? (
										<>
											<Checkbox
												checked={false}
												onChange={handleCheckBox}
												label={t("14 днів")}
											/>
											<Checkbox
												checked={false}
												onChange={handleCheckBox}
												label={t("1 мес")}
											/>
											<Checkbox
												label={t("2 года")}
												checked={false}
												onChange={handleCheckBox}
											/>
											<Checkbox
												label={t("Гарантийный срок указан в карточке товара")}
												checked={false}
												onChange={handleCheckBox}
											/>
											<Checkbox
												label=""
												checked={false}
												onChange={handleCheckBox}
											/>
										</>
									) : (
										<div>
											<p>2 роки</p>
										</div>
									)
								}
							/>
							<CheckboxesBlock
								title="Условия возврата"
								isEdit={isEditPaymentSeller}
								content={
									isEditPaymentSeller ? (
										<>
											<Checkbox
												checked={false}
												onChange={handleCheckBox}
												label={t("на протязі 14 днів ")}
											/>
											<Checkbox
												checked={false}
												onChange={handleCheckBox}
												label={t("зворотня доставка за рахунок покупця")}
											/>
											<Checkbox
												label={t("2 года")}
												checked={false}
												onChange={handleCheckBox}
											/>
											<Checkbox
												label={t(
													"зберігайте оригінальну упаковку, якщо така упаковка передбачена"
												)}
												checked={false}
												onChange={handleCheckBox}
											/>
											<Checkbox
												label=""
												checked={false}
												onChange={handleCheckBox}
											/>
										</>
									) : (
										<div>
											<p>в течение 14 дней </p>
											<p>обратная доставка за счёт покупателя</p>
											<p>
												сохраняйте оригинальную упаковку, если такая упаковка
												предусмотрена
											</p>
										</div>
									)
								}
								bgColor="#FAFAFA"
							/>
						</div>
					</Collapse>

					<Collapse
						title="Доставка"
						isEdit={isEditDeliverySeller}
						onChangeEdit={setIsEditDeliverySeller}
					>
						<div className={styles.delivery}>
							{isEditDeliverySeller ? (
								<h3>
									Выберите удобные вам способы доставки. Данные настройки будут
									применены ко всем вашим объявлениям.
								</h3>
							) : null}
							<DeliverySeller isEdit={isEditDeliverySeller} />
						</div>
						{isEditDeliverySeller ? (
							<button className={styles.button}>Зберегти</button>
						) : null}
					</Collapse>
				</div>
			)}
		</Formik>
	);
};
