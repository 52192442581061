import React from "react";
import s from "../../MyOrders/MyOrdersCardMobile/MyOrdersCardMobile.module.css";
import img from "../../../../images/cardItemHorizontal.jpg";

const ImageForCabinetCardMobile = () => {
	return (
		<div className={s.cardTop_image}>
			<div className={s.cardTop_image_Image}>
				<img src={img} alt="itemImage" />
			</div>
		</div>
	);
};

export default ImageForCabinetCardMobile;
