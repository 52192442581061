import { useState, useEffect, useCallback } from "react";

type CatIdsHandler = {
	catIds: number[];
	handleCatIds: (id: number) => void;
};

const useCatIdsHandler = (initialCatIds: number[] = []): CatIdsHandler => {
	const [catIds, setCatIds] = useState<number[]>(initialCatIds);

	const handleCatIds = useCallback(
		(id: number) => {
			if (id !== 0) {
				const updatedArr = catIds.filter((catId) => catId !== 0);
				const index = updatedArr.indexOf(id);

				if (index === -1) {
					// Элемента нет
					setCatIds([...updatedArr, id]);
				} else if (updatedArr.length === 0) {
					setCatIds([0]);
				} else {
					const tempArr = catIds.filter((e) => e !== id);

					if (tempArr.length !== 0) {
						setCatIds(tempArr);
					} else {
						setCatIds([0]);
					}
				}
			} else {
				setCatIds([0]);
			}
		},
		[catIds]
	);

	// Вызываем обработчик при каждом изменении catIds
	useEffect(() => {
		// Дополнительная логика или обновление состояния, если необходимо
	}, [catIds]);

	return { catIds, handleCatIds };
};

export default useCatIdsHandler;
