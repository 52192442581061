import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import SellerCardPopup from "../SellerCard/SellerCardPopup";
import s from "./OutputAdFooterInfoPanel.module.css";
import OutputADSvgSelector from "./OutputADSvgSelector";

const OutputAdFooterInfoPanel = () => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const noticeView = useSelector(
		(state: StateType) => state.publicNotice.noticeView
	);
	const { t } = useTranslation();

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogOpen = () => {
		document.body.classList.add("dialog-open");
		setDialogOpen(true);
	};

	useEffect(() => {
		if (noticeView?.ownerContacts.ownerPhone) {
			setPhoneNumber(noticeView?.ownerContacts.ownerPhone);
		}
	}, [noticeView?.ownerContacts.ownerPhone]);

	return (
		<>
			{dialogOpen && (
				<SellerCardPopup
					dialogOpen={dialogOpen}
					dialogClose={handleDialogClose}
				/>
			)}
			<div className={s.footerContainer}>
				<a href={`tel:${phoneNumber}`}>
					<OutputADSvgSelector id={"phoneBlack"} />
					<span>{t("Подзвонити")}</span>
				</a>
			</div>
		</>
	);
};

export default OutputAdFooterInfoPanel;
