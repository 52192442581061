import { Backdrop } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import img from "../../../../images/cardItemHorizontal.jpg";
import {
	buyService,
	getDeleteNotice,
	getEditNoticeAutoContinue,
	getEditOneNotice,
} from "../../../../redux/notice_Reducer";
import { StateType } from "../../../../redux/redux-store";
import { formattingPrice } from "../../../../supporting";
import { NoticeDataForModal, NoticeType } from "../../../../types/noticeTypes";
import AskApprovePopup from "../../../CommonPopups/AskApprovePopup/AskApprovePopup";
import SecondsToDate from "../../../TimeAndDate/SecondsToDate";
import ModalAutoRenewal from "../../ModalWindows/ModalAutoRenewal/ModalAutoRenewal";
import ModalPitchToTop from "../../ModalWindows/ModalPitchToTop/ModalPitchToTop";
import ModalRaiseAd from "../../ModalWindows/ModalRaiseAd/ModalRaiseAd";
import PersonalCabinetSvgSelector from "../../PersonalCabinetSvgSelector";
import PlayPauseSwitch from "../../PlayPauseSwitch";
import s from "./MyAdsCardMobile.module.css";
import MyAdsCardRightFuncPanel from "./MyAdsCardRightFuncPanel";

const useStyles = makeStyles(() =>
	createStyles({
		modalRenewal: {
			display: "flex",
			marginTop: "100px",
			justifyContent: "center",
			height: "50%",
			"@media (max-width: 1025px)": {
				height: "422px",
			},
		},
		modalPitchToTop: {
			display: "flex",
			marginTop: "100px",
			justifyContent: "center",
			height: "450px",
			"@media (max-width: 1025px)": {
				height: "419px",
			},
		},
	})
);

const MyAdsCardMobile = ({
	type,
	notice,
}: {
	type: string;
	notice: NoticeType;
}) => {
	const dispatch: any = useDispatch();
	const classes = useStyles();
	const deleteAD = useSelector(() => getDeleteNotice);
	const editAutoContinue = useSelector(() => getEditNoticeAutoContinue);
	const buy = useSelector(() => buyService);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [visibleAdsOption, setVisibleAdsOption] = useState(false);
	const [autoRenewalSettings, setOpenAutoRenewalSettings] = useState(false);
	const [pitchToTopSettings, setPitchToTopSettings] = useState(false);
	const [raiseAd, setRaideAD] = useState(false);
	const [visibleAutoContinue, setVisibleAutoContinue] = useState(false);
	const [priceValue, setPriceValue] = useState(
		formattingPrice(notice?.price || "")
	);
	const [priceError, setPriceError] = useState("");
	const [isActiveTab, setIsActiveTab] = useState(false);
	const createDate = new Date(
		notice?.created_at ? notice?.created_at * 1000 : 0
	);
	const editPrice = useSelector(() => getEditOneNotice);
	const navigate = useNavigate();
	const noticeTree = notice?.tree?.map((s: any) => s.alias);
	const [currentItemId, setCurrentItemId] = useState<string | null>(null);
	const noticeUrl = noticeTree?.join("/");
	const { t } = useTranslation();
	const autoRenewalData = useSelector(
		(state: StateType) => state.priceData.autoRenewal
	);
	const topData = useSelector((state: StateType) => state.priceData.top);
	const oneTimeUp = useSelector(
		(state: StateType) => state.priceData.oneTimeUp
	);
	const balance = useSelector(
		(state: StateType) =>
			+state.userData.userStatic.userBalance +
			parseFloat(state?.userData?.userStatic?.userBonus ?? "")
	);

	const noticeDataModal: NoticeDataForModal = {
		balance: balance,
		name: notice?.name,
		price: notice?.price,
		img: notice?.noticeImages ? notice.noticeImages[0].imageUrl : "",
		settlement: notice?.short_settlement_name,
		id: notice?.id ? notice.id.toString() : "",
	};

	const handleCloseRenewal = () => {
		setOpenAutoRenewalSettings(false);
	};
	const handleClosePitchToTop = () => {
		setPitchToTopSettings(false);
	};
	const handleCloseRaiseAD = () => {
		setRaideAD(false);
	};
	const handleOpenAutoRenewal = (data: NoticeDataForModal) => {
		setOpenAutoRenewalSettings(true);
	};
	const handleOpenVisibleOption = () => {
		setVisibleAdsOption(true);
	};
	const handlePayService = (id: number | null, value: number | null) => {
		if (id && value) dispatch(buy(+id, +value));
	};

	useEffect(() => {
		const value = notice?.price || "";
		const formatValue = formattingPrice(value);
		setPriceValue(formatValue);
	}, []);

	useEffect(() => {
		if (notice?.status === 30) {
			setIsActiveTab(true);
		} else {
			setIsActiveTab(false);
		}
	}, [notice]);

	const handleChangePriceValue = (e: string) => {
		setPriceValue(e);
	};
	useEffect(() => {
		const fetchData = async () => {
			if (notice?.categoryBlocs.length) {
				try {
					const result = notice?.categoryBlocs.filter(
						(item: any) => item.bloc_id == 24
					);

					result.length
						? setVisibleAutoContinue(true)
						: setVisibleAutoContinue(false);
				} catch (error) {
					// Handle error
					console.error("Error fetching data", error);
				}
			}
		};
		fetchData();
	}, [notice]);
	const handleAutoRenewal = (id: number, state: number) => {
		dispatch(editAutoContinue(id, state));
	};
	const handleDeleteAD = (id: string | null) => {
		setCurrentItemId(id);
		setIsDialogOpen(true);
	};
	const handleCancel = () => {
		setIsDialogOpen(false);
		setCurrentItemId(null);
	};
	const handleApprove = () => {
		setIsDialogOpen(false);
		if (currentItemId) {
			dispatch(deleteAD(currentItemId));
		}
	};
	if (notice) {
		return (
			<div className={s.mobileAdsCardContainer}>
				{isDialogOpen && (
					<div>
						<AskApprovePopup
							title={t("Ця дія видалить оголошення назавжди")}
							handleClickNo={handleCancel}
							handleClickYes={handleApprove}
						/>
					</div>
				)}
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modalRenewal}
					open={autoRenewalSettings}
					onClose={handleCloseRenewal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<ModalAutoRenewal
						noticeData={noticeDataModal}
						data={autoRenewalData}
						handleClose={handleCloseRenewal}
					/>
				</Modal>
				<Modal
					className={classes.modalPitchToTop}
					open={pitchToTopSettings}
					onClose={handleClosePitchToTop}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<ModalPitchToTop
						noticeData={noticeDataModal}
						data={topData}
						handleClose={handleClosePitchToTop}
					/>
				</Modal>
				<Modal
					className={classes.modalPitchToTop}
					open={raiseAd}
					onClose={handleCloseRaiseAD}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<ModalRaiseAd
						raisePrice={formattingPrice(oneTimeUp?.price ?? "")}
						noticeData={noticeDataModal}
						oneTimeUp={oneTimeUp}
						handleClose={handleCloseRaiseAD}
					/>
				</Modal>
				<div className={s.cardTop}>
					<div
						className={s.cardTop_image}
						onClick={() =>
							navigate(
								`${i18n.language === "ru" ? "" : "/ua"}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
							)
						}
					>
						<div className={s.cardTop_image_Image}>
							<img
								src={notice.noticeImages[0]?.imageUrl || img}
								alt="itemImage"
							/>
						</div>
					</div>
					<div className={s.cardTop__Main}>
						<div className={s.mainTitle}>
							<h4
								onClick={() =>
									navigate(
										`${i18n.language === "ru" ? "" : "/ua"}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
									)
								}
							>
								{notice?.name?.length > 10
									? notice?.name?.substring(0, 24) + "..."
									: notice?.name}
							</h4>
							<form
								className={s.priceInfo}
								onSubmit={(event) => {
									event.preventDefault();
									dispatch(
										editPrice(
											notice?.id || 0,
											"price",
											priceValue,
											setPriceError
										)
									);
								}}
							>
								{+notice?.price_type !== 2 && (
									<>
										{isActiveTab ? (
											<input
												className={s.priceSum}
												type="text"
												value={priceValue}
												onChange={(event) => {
													setPriceValue(
														formattingPrice(event.currentTarget.value)
													);
												}}
											/>
										) : (
											<div className={s.priceInfo}>
												<span>{priceValue}</span>
											</div>
										)}
									</>
								)}

								<div className={s.currencyPrice}>
									{+notice?.price_type === 2 ? t("Безкоштовно") : "грн"}
								</div>
								{formattingPrice(notice?.price) != priceValue &&
									isActiveTab && (
										<button className={s.saveNewPriceButton} type={"submit"}>
											{t("Зберегти")}
										</button>
									)}
								{priceError !== "" && (
									<div className={s.errorPrice}>{priceError}</div>
								)}
							</form>
							<div className={s.main_TopTPrice}></div>
						</div>
						<div className={s.mainLocation}>
							<span>{notice?.short_settlement_name || ""}</span>
						</div>
						{visibleAutoContinue && (
							<div className={s.mainAutoRenewal}>
								<div>{t("Автоподовження")}</div>
								<PlayPauseSwitch
									vipChecked={+notice?.auto_continue === 1}
									onSwitchChange={() =>
										handleAutoRenewal(
											notice?.id || 0,
											+notice.auto_continue === 0 ? 1 : 0
										)
									}
								/>
							</div>
						)}
					</div>
				</div>
				<div className={s.cardMiddleInfo}>
					<div className={s.date_info}>
						{isActiveTab && (
							<span>
								{t("З")}:{" "}
								<SecondsToDate
									full={false}
									seconds={notice?.created_at || null}
								/>{" "}
								по:{" "}
								<SecondsToDate
									full={false}
									seconds={notice?.finished_at || null}
								/>
							</span>
						)}
					</div>
					<div className={s.statistics_info}>
						<div className={s.statistics_info_views}>
							<PersonalCabinetSvgSelector id={"viewsSee"} />
							<span>{notice?.views || "0"}</span>
						</div>
					</div>
				</div>
				<div className={s.cardBottom}>
					{visibleAdsOption ? (
						<MyAdsCardRightFuncPanel
							notice={notice}
							type={"mobile"}
							setPitchToTopSettings={setPitchToTopSettings}
							setOpenAutoRenewalSettings={handleOpenAutoRenewal}
							setVisibleAdsOption={setVisibleAdsOption}
							setRaiseAd={setRaideAD}
						/>
					) : (
						<>
							{type === "active" && (
								<>
									<div className={s.bigButtons}>
										<button onClick={handleOpenVisibleOption}>
											{t("Рекламувати")}
										</button>
										<button
											onClick={
												() => setRaideAD(true)
												// handlePayService(notice.id, oneTimeUp?.id ?? null)
											}
										>
											{" "}
											{t("Підняти за") +
												" " +
												formattingPrice(oneTimeUp?.price ?? "") +
												" " +
												"грн"}
										</button>
									</div>
								</>
							)}
							{type === "rejected" && (
								<>
									<div className={s.rejectedContainer}>
										<div className={s.rejectedTitle}>
											{t("Причина відхилення")}:
										</div>
										<div className={s.rejectedInfoBlock}>
											<div className={s.rejectedInfoDescription}>
												<span>{notice?.moderation_description || ""}</span>
											</div>
											<div className={s.rejectedInfoDate}>
												Дата:{" "}
												<SecondsToDate
													full={false}
													seconds={notice?.created_at || null}
												/>{" "}
												{createDate.getFullYear()}
											</div>
										</div>
									</div>
									<div className={s.bigButtons}>
										<button
											onClick={() =>
												handleDeleteAD(notice?.id ? notice.id.toString() : null)
											}
										>
											{t("Видалити")}
										</button>
										<button
											onClick={() =>
												navigate(
													`${i18n.language === "ru" ? "/" : "/ua/"}create_ad/${notice.id}`
												)
											}
										>
											{t("Редагувати")}
										</button>
									</div>
								</>
							)}
							{type === "under_review" && (
								<>
									<div className={s.underReviewContainer}>
										<h5>На перевірці</h5>
										<div className={s.rejectedInfoDate}>
											Дата:{" "}
											<SecondsToDate
												full={false}
												seconds={notice?.created_at || null}
											/>{" "}
											{createDate.getFullYear()}
										</div>
									</div>
								</>
							)}
							{type === "no_active" && (
								<>
									<div className={s.bigButtons}>
										<button
											onClick={() =>
												handleDeleteAD(notice?.id ? notice.id.toString() : null)
											}
										>
											{t("Видалити")}
										</button>
										<button>{t("Активувати")}</button>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
	return <></>;
};

export default MyAdsCardMobile;
