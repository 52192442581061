import React from "react";
import s from "../../pages/PersonalCabinet/PersonalCabinet.module.css";

type Props = {
	checkedIds: number[];
	labelTxt: string;
	id: number;
	onChange: (id: number) => void;
};
const CheckBoxContentPanel: React.FC<Props> = ({
	labelTxt,
	id,
	checkedIds,
	onChange,
}) => {
	const checked = checkedIds.includes(+id);
	const idCheck = id ? id.toString() : "";
	return (
		<div className={s.checkBoxContainer}>
			<input
				className={s.checkboxInput}
				id={idCheck}
				checked={checked}
				type="checkbox"
				onChange={() => {
					onChange(+id);
				}}
			/>
			<label
				style={{ color: checked ? "#2B2A38" : "#727272" }}
				htmlFor={idCheck}
			>
				<span className={s.labelText}>{labelTxt}</span>
			</label>
		</div>
	);
};

export default CheckBoxContentPanel;
