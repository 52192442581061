import React from "react";
import s from "../CreateBoardAD.module.css";
import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import MessengersIcons from "../../../Components/PersonalCabinet/MessengersIcons/MessengersIcons";
import { useTranslation } from "react-i18next";

interface Props {
	setFieldValue: any;
	values: any;
}

const ContactsSellerBlockCreate: React.FC<Props> = ({
	setFieldValue,
	values,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<div className={s.formElement}>
				<div className={s.nameSide}>
					<span className={s.elementName}>{t(`Контактна особа`)}</span>
				</div>
				<div className={`${s.fieldSide} ${s.contactNameInput}`}>
					<Field
						name={"contactName"}
						value={values.contactName}
						disabled={true}
						style={{ cursor: "" }}
					/>
				</div>
			</div>
			<div className={s.formElement}>
				<div className={s.nameSide}>Номер телефона</div>
				<div className={`${s.fieldSide} ${s.numberPhoneMessenger}`}>
					<PhoneInput
						inputClass={s.phoneInputStyle}
						country={"ua"}
						value={values.contactPhone}
						disabled={true}
					/>
					<MessengersIcons
						placement={"contacts"}
						values={values}
						setFieldValue={setFieldValue}
					/>
				</div>
			</div>
			{/* <div className={style.formElement}>
                                    <div className={s.nameSide}>
                                <span className={s.elementName}>
                                    {t(`Контактна особа`)}
                                </span>
                                    </div>
                                    <div className={`${style.fieldSide}`}>
                                        <Field name={"contactName"}
                                               value={values.contactName}
                                               disabled={true}
                                               style={{cursor: ""}}
                                        />
                                    </div>
                                </div>
                                <div className={style.formElement}>
                                    <div className={s.nameSide}>
                                        Номер телефона
                                    </div>
                                    <div className={`${style.fieldSide} ${s.numberPhoneMessenger}`}>
                                        <PhoneInput inputClass={s.phoneInputStyle}
                                                    country={'ua'}
                                                    value={values.contactPhone}
                                                    disabled={true}/>
                                    </div>
                                </div>
                                <div className={style.messengersIconsblock}>
                                    <MessengersIcons values={values} setFieldValue={setFieldValue}/>
                                </div>*/}
		</>
	);
};

export default ContactsSellerBlockCreate;
