import React from "react";

const OnlyDay: React.FC<{ seconds: number | null }> = ({ seconds }) => {
	if (seconds) {
		const date = new Date(seconds && seconds * 1000); // Преобразование секунд в миллисекунды
		const day = date.getDate(); // Получение дня месяца
		return (
			<>
				<>{day}</>
			</>
		);
	}
	return <> </>;
};

export default OnlyDay;
