import React, { useState } from "react";
import Switch from "react-switch";
import s from "./PersonalCabinetPopup/PersonalCabinetPopup.module.css";

type Props = {
	vipChecked?: boolean;
	onSwitchChange?: () => void;
};

const PlayPauseSwitch = ({ vipChecked, onSwitchChange }: Props) => {
	const [checked, setChecked] = useState(false);
	return (
		<Switch
			onChange={onSwitchChange || setChecked}
			checked={vipChecked ? vipChecked : checked}
			width={25}
			height={10}
			handleDiameter={16}
			uncheckedIcon={false}
			checkedIcon={false}
			offColor={"#E1E1E1"}
			onColor={"#E1E1E1"}
			offHandleColor={"#C4C4C4"}
			onHandleColor={"#00AB3A"}
			className={s.switchContainer}
			activeBoxShadow={"0 0 0 0 #00AB3A"}
			checkedHandleIcon={
				/*<svg className={s.switchIconPlay} width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
                            </svg>*/
				/*<svg className={s.switchIconPlay} width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 4.5L0.25 8.39711L0.25 0.602885L7 4.5Z" fill="white"/>
                        </svg>*/
				<svg
					className={s.switchIconPlay}
					xmlns="http://www.w3.org/2000/svg"
					width="22"
					height="22"
					viewBox="0 0 22 22"
					fill="none"
				>
					<circle cx="11" cy="11" r="11" fill="#00AB3A" />
					<path d="M17 11L8 16.1962L8 5.80385L17 11Z" fill="white" />
				</svg>
				/*<svg className={s.switchIconPlay} width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
                    </svg>*/
				/*<svg className={s.switchIconPlay} width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
                    </svg>*/
			}
			uncheckedHandleIcon={
				<svg
					className={s.switchIconPause}
					xmlns="http://www.w3.org/2000/svg"
					width="22"
					height="22"
					viewBox="0 0 22 22"
					fill="none"
				>
					<circle cx="11" cy="11" r="11" fill="#C4C4C4" />
					<path d="M8 6V11V16" stroke="white" strokeWidth="2" />
					<path d="M14 6V16" stroke="white" strokeWidth="2" />
				</svg>
			}
		/>
	);
};

export default PlayPauseSwitch;
