import React from "react";
import s from "./ModalAutoRenewal/ModalAutoRenewal.module.css";
import { Desktop } from "../../../responsive";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";

const TitleModalCardComponent = ({
	title,
	handleClose,
}: {
	title: string;
	handleClose: (b: boolean) => void;
}) => {
	return (
		<div className={s.topTitle}>
			<h4>
				<Desktop>
					{title === "Налаштувати автопідняття" ? (
						<PersonalCabinetSvgSelector id={"autoRenewalBig"} />
					) : (
						<PersonalCabinetSvgSelector id={"settingsTop"} />
					)}
				</Desktop>

				<span>{title}</span>
			</h4>
			<span onClick={() => handleClose(false)}>
				<PersonalCabinetSvgSelector id={"closeCrossBig"} />
			</span>
		</div>
	);
};

export default TitleModalCardComponent;
