import React from "react";

interface Props {
	id: string;
}

function AuthSvgSelector({ id }: Props) {
	switch (id) {
		case "dialogClose":
			return (
				<svg
					width="15"
					height="15"
					viewBox="0 0 15 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M8.56535 7.483L14.7562 1.28213C14.8786 1.13904 14.9425 0.954975 14.9353 0.766723C14.928 0.57847 14.8501 0.399895 14.7171 0.266681C14.5841 0.133468 14.4058 0.0554274 14.2178 0.048156C14.0299 0.0408846 13.8461 0.104918 13.7033 0.227459L7.51237 6.42833L1.32148 0.219979C1.18085 0.0791288 0.990129 0 0.791257 0C0.592385 0 0.401659 0.0791288 0.261036 0.219979C0.120412 0.360829 0.0414109 0.551863 0.0414109 0.751055C0.0414109 0.950247 0.120412 1.14128 0.261036 1.28213L6.4594 7.483L0.261036 13.6839C0.18286 13.7509 0.119368 13.8334 0.0745432 13.9262C0.0297184 14.019 0.00452905 14.1201 0.000556502 14.2231C-0.00341605 14.3261 0.0139136 14.4289 0.0514581 14.5249C0.0890027 14.6208 0.145952 14.708 0.21873 14.7809C0.291508 14.8538 0.378545 14.9109 0.474377 14.9485C0.570209 14.9861 0.672767 15.0034 0.775614 14.9994C0.878462 14.9955 0.979377 14.9702 1.07203 14.9253C1.16468 14.8804 1.24706 14.8168 1.31401 14.7385L7.51237 8.53767L13.7033 14.7385C13.8461 14.8611 14.0299 14.9251 14.2178 14.9178C14.4058 14.9106 14.5841 14.8325 14.7171 14.6993C14.8501 14.5661 14.928 14.3875 14.9353 14.1993C14.9425 14.011 14.8786 13.827 14.7562 13.6839L8.56535 7.483Z" />
				</svg>
			);
		case "seePassword":
			return (
				<svg
					width="26"
					height="20"
					viewBox="0 0 26 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.03642 19.2238C7.87979 19.2238 7.723 19.1507 7.60368 19.0052L0.17944 10.555C-0.0598782 10.2633 -0.0598782 9.79169 0.17944 9.5C0.418647 9.20831 0.805603 9.20831 1.04492 9.5L8.03642 17.4225L24.5897 0.218532C24.829 -0.0731555 25.3504 -0.0726879 25.5897 0.218999C25.829 0.510823 25.829 0.925098 25.5897 1.21692L8.46927 19.0052C8.34995 19.1507 8.19305 19.2238 8.03642 19.2238Z"
						fill="#00CEEA"
					/>
				</svg>
			);
		case "facebookIcon":
			return (
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
						fill="#3B5998"
					/>
					<path
						d="M18.7698 15.5883H16.0933V25.394H12.0381V15.5883H10.1094V12.1422H12.0381V9.91217C12.0381 8.31746 12.7956 5.82031 16.1294 5.82031L19.1332 5.83288V9.17792H16.9537C16.5962 9.17792 16.0935 9.35653 16.0935 10.1173V12.1454H19.1241L18.7698 15.5883Z"
						fill="white"
					/>
				</svg>
			);
		case "googleIcon":
			return (
				<svg
					width="30"
					height="30"
					viewBox="0 0 30 30"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.03125 15.003C7.03125 13.5167 7.44076 12.1243 8.15221 10.9321V5.875H3.0951C1.08797 8.48172 0 11.6586 0 15.003C0 18.3474 1.08797 21.5243 3.0951 24.1311H8.15221V19.0739C7.44076 17.8817 7.03125 16.4894 7.03125 15.003Z"
						fill="#FBBD00"
					/>
					<path
						d="M15 22.9671L11.4844 26.4828L15 29.9984C18.3445 29.9984 21.5213 28.9104 24.128 26.9033V21.8516H19.0763C17.8737 22.5656 16.4754 22.9671 15 22.9671Z"
						fill="#0F9D58"
					/>
					<path
						d="M8.15281 19.0703L3.0957 24.1274C3.49309 24.6435 3.9258 25.1378 4.39402 25.606C7.22715 28.4391 10.994 29.9994 15.0006 29.9994V22.9681C12.0929 22.9681 9.54453 21.4025 8.15281 19.0703Z"
						fill="#31AA52"
					/>
					<path
						d="M30 15.0005C30 14.0879 29.9174 13.1737 29.7544 12.2831L29.6225 11.5625H15V18.5938H22.1163C21.4253 19.9684 20.3627 21.09 19.0762 21.8538L24.128 26.9055C24.6441 26.5081 25.1383 26.0754 25.6066 25.6072C28.4397 22.774 30 19.0072 30 15.0005Z"
						fill="#3C79E6"
					/>
					<path
						d="M20.6348 9.36521L21.2562 9.98666L26.2281 5.01486L25.6066 4.39342C22.7735 1.56029 19.0067 0 15 0L11.4844 3.51562L15 7.03125C17.1285 7.03125 19.1296 7.86012 20.6348 9.36521Z"
						fill="#CF2D48"
					/>
					<path
						d="M15.0007 7.03125V0C10.994 0 7.22721 1.56029 4.39402 4.39336C3.9258 4.86158 3.49309 5.35582 3.0957 5.87197L8.15281 10.9291C9.54459 8.59688 12.093 7.03125 15.0007 7.03125Z"
						fill="#EB4132"
					/>
				</svg>
			);
		case "appleLogo":
			return (
				<svg
					width="28"
					height="33"
					viewBox="0 0 28 33"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M23.3294 31.676C21.5202 33.4174 19.5448 33.1425 17.6434 32.3176C15.6311 31.4744 13.785 31.4377 11.662 32.3176C9.00359 33.4541 7.60055 33.1242 6.0129 31.676C-2.99608 22.4555 -1.66689 8.41394 8.56053 7.90067C11.0528 8.02899 12.7881 9.25716 14.2465 9.36715C16.4249 8.92721 18.511 7.66237 20.8371 7.82735C23.6247 8.04732 25.7293 9.14718 27.1139 11.1269C21.354 14.5548 22.7201 22.0889 28 24.1969C26.9477 26.9466 25.5816 29.6779 23.3109 31.6943L23.3294 31.676ZM14.0619 7.79068C13.785 3.70287 17.1264 0.329958 20.9663 0C21.5017 4.7294 16.6465 8.24896 14.0619 7.79068Z"
						fill="#232323"
					/>
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default AuthSvgSelector;
