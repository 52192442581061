import React, { useEffect, useState } from "react";
import s from "./MyFavorite.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import PersonalCabinetTopSection from "../PersonalCabinetTopSection";
import PersonalCabinetCheckBoxPanelFavorite from "./PersonalCabinetCheckBoxPanelFavorite";
import {
	getFilteredNoticeList,
	setCategoriesIdsList,
	setAllFavoriteList,
} from "../../../redux/publicNotice_Reducer";
import ProductCardGrid from "../../Cards/FavoriteSearchCard/ProductCardGrid";
import FavoriteSearchCardRow from "../../Cards/FavoriteSearchCard/FavoriteSearchCardRow";
import Paginator from "../../Paginator/Paginator";
import { scrollToTop } from "../../../supporting";
import useCatIdsHandler from "../../../CustomHooks/useCatIdsHandler";

const MyFavorite = () => {
	const { i18n, t } = useTranslation();
	const [status, setStatus] = useState(30);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [grid, setGrid] = useState(true);
	const [row, setRow] = useState(false);
	const dispatch: any = useDispatch();
	const favoriteCounts = useSelector(
		(state: StateType) => state.userData.userStatic.userCountFavorites
	);
	const favoriteList = useSelector(
		(state: StateType) => state.publicNotice.noticeListFavorite
	);
	const favoriteMeta = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const getFavoriteList = useSelector(() => getFilteredNoticeList);
	const setCatIds = useSelector(() => setCategoriesIdsList);
	const categories = useSelector(
		(state: StateType) => state.publicNotice.favoriteStatByCategory
	);
	const catIdsForHook = useSelector(
		(state: StateType) => state.publicNotice.favoriteStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);
	const favoriteAllList = useSelector(
		(state: StateType) => state.publicNotice.noticeAllListFavorite
	);

	const elements = [
		{
			link: "",
			name: t("Обраний товар"),
			elementData: favoriteCounts,
			status: 30,
		},
	];

	useEffect(() => {
		dispatch(
			getFavoriteList({
				onlyFavorite: "1",
				perPage: `${48}`,
				page: page.toString(),
				catIds: catIds,
			})
		);
		scrollToTop();
	}, [page, catIds, perPage, favoriteMeta.perPage]);

	useEffect(() => {
		if (favoriteList && favoriteList.length) {
			if (page == 0 || page == 1) {
				dispatch(setAllFavoriteList(favoriteList));
			} else {
				const newArr = [...favoriteAllList, ...favoriteList];
				const arrayLength = newArr.length;
				const limit = 150;
				if (arrayLength > limit) {
					const difference = arrayLength - limit;
					const slicedArr = newArr.slice(difference); // Зберігаємо результат виклику slice
					dispatch(setAllFavoriteList(slicedArr));
				} else {
					dispatch(setAllFavoriteList(newArr));
				}
			}
		}
	}, [favoriteList]);

	return (
		<div className={s.favoriteContainer}>
			<PersonalCabinetTopSection
				title={t("Обрані товари та оголошення")}
				headers={elements}
				status={status}
				setPage={setPage}
				setStatus={setStatus}
				setCatIds={setCatIds}
			/>
			<PersonalCabinetCheckBoxPanelFavorite
				row={row}
				categories={categories}
				grid={grid}
				catIds={catIds}
				handleCatIds={handleCatIds}
				setRow={setRow}
				setGrid={setGrid}
				type={"favorite"}
			/>
			<div
				className={s.cabinetContent_CardTemplate}
				style={{ flexDirection: row ? "column" : "row" }}
			>
				{favoriteList.map((i: any) => (
					<div className={s.cardItem} key={i.id}>
						{grid ? (
							<div className={s.border}>
								<ProductCardGrid notice={i} grid={"5"} hot={true} />
							</div>
						) : (
							<div className={s.borderRow}>
								<FavoriteSearchCardRow notice={i} />
							</div>
						)}
					</div>
				))}
				<div className={s.paginatorContainer}>
					<div className={s.expandButtonWrapper}>
						{/* {favoriteMeta.pageCount > favoriteMeta.currentPage &&
                        <ExpandModernButton onClickCallback={()=>{
                            setPage((prevState)=>prevState + 1)
                        }}/>} */}
					</div>
					{favoriteMeta.totalCount > 12 && (
						<Paginator
							page={page}
							setPage={setPage}
							count={favoriteMeta.pageCount}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyFavorite;
