import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getRestorePass, getSmsCode } from "../../../redux/user_Reducer";
import classes from "../../../Auth&RegNewPopups/ForgotPassPopup/ForgotPassPopup.module.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type Props = {
	setTime: (b: number) => void;
	time: number;
	phone: string;
	approve: boolean;
};

function Timer({ time, setTime, phone, approve }: Props) {
	const [changeButton, setChangeButton] = useState(true);
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const getSms = useSelector(() => getSmsCode);
	const forgotPass = useSelector(() => getRestorePass);
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [captcha, setCaptcha] = useState("");
	// Функция для форматирования времени в формат ММ:СС
	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
	};

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available");
			return;
		}
		const newCaptcha = await executeRecaptcha("yourAction");
		setCaptcha(newCaptcha);
		// Do whatever you want with the token
	}, [executeRecaptcha]);
	useEffect(() => {
		const timer = setInterval(() => {
			if (time > 0) {
				setTime(time - 1);
			} else {
				clearInterval(timer);
				setChangeButton(!changeButton);
				handleReCaptchaVerify();
				// Действия, которые нужно выполнить по истечении 4 минут
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [time]);
	useEffect(() => {
		handleReCaptchaVerify();
	}, [executeRecaptcha]);
	return (
		<div>
			{changeButton ? (
				formatTime(time) + ` ` + t("хв")
			) : (
				<button
					className={classes.timerButton}
					onClick={() => {
						if (approve) {
							handleReCaptchaVerify();
							dispatch(getSms(phone));
						} else {
							handleReCaptchaVerify();
							dispatch(forgotPass(phone, captcha));
						}

						setChangeButton(!changeButton);
						setTime(240);
					}}
				>
					{t("Відправити новий код")}
				</button>
			)}
		</div>
	);
}

export default Timer;
