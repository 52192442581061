import React, { useEffect, useState } from "react";
import {
	CounterType,
	Filter,
	FiltersValue,
	PropertyCross,
	PropertyValues,
} from "../../types/publicNoticeTypes";
import s from "./SearchFilters.module.css";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useTranslation } from "react-i18next";
import StandartCheckbox from "../StandartCheckbox/StandartCheckbox";
import FilterFrontHead from "./FilterFrontHead/FilterFrontHead";
import { match } from "assert";
import { log } from "util";
type Props = {
	filter: Filter;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	showOpened: number;
	changedElements: any[];
	setLastChangedFilter: (b: number) => void;
	lastChangedFilter: number;
};
type AssignItem = {
	id: number;
	property_id: number;
	name: string;
	name_ru: string;
	position: number;
	crossAssign: { name: string; name_ru: string; position: number }[];
};
const FilterFrontMatch = ({
	filter,
	filtersValues,
	setFiltersValues,
	showOpened,
	changedElements,
	setLastChangedFilter,
	lastChangedFilter,
}: Props) => {
	const checked = filtersValues.findIndex(
		(f: FiltersValue) => f.filter === filter.inputName
	);
	const [isNeedClear, setIsNeedClear] = useState(false);
	const [isExpanded, setExpanded] = useState(
		checked !== -1 || showOpened === 1
	);
	const [activeButton, setActiveButton] = useState(1);
	const { i18n } = useTranslation();

	const clearFilter = () => {
		const updateFiltersValues = [...filtersValues].filter((f: FiltersValue) => {
			return f.filter !== filter.inputName;
		});
		setFiltersValues(updateFiltersValues);
	};
	useEffect(() => {
		if (checked !== -1) {
			setIsNeedClear(true);
		} else {
			setIsNeedClear(false);
		}
	}, [checked]);
	type MyObject = {
		name: string;
		name_ru: string;
		position: number;
	};

	function combineArrays(arrays: [][]): MyObject[] {
		if (arrays.length < 2) {
			return arrays[0] || [];
		}
		return arrays[0].map((item: any, index) => {
			const combinedItem: any = { ...item };
			arrays.slice(1).forEach((array) => {
				const arrayItem: any = array[index];
				if (arrayItem) {
					// Указываем явный список свойств
					const propertiesToCombine: (keyof MyObject)[] = ["name", "name_ru"];
					propertiesToCombine.forEach((key: any) => {
						if (combinedItem[key]) {
							combinedItem[key] += `, ${arrayItem[key]}`;
						} else {
							combinedItem[key] = arrayItem[key];
						}
					});
				}
			});

			return combinedItem;
		});
	}

	return (
		<div
			className={s.filterContainer}
			style={{
				background: isExpanded ? "#F8F8F8" : "",
			}}
		>
			<FilterFrontHead
				name={filter.propertyName}
				isExpanded={isExpanded}
				setExpanded={setExpanded}
				isNeedClear={isNeedClear}
				clearFilter={clearFilter}
			/>
			{isExpanded && (
				<>
					<div className={`${s.filterContent} ${s.matchFilter}`}>
						<ul className={s.filterMatchButtonsList}>
							{filter.propertyCrossList.map((prCross: PropertyCross) => (
								<li
									key={prCross.position}
									onClick={() => {
										setActiveButton(prCross.position);
									}}
									className={`${s.filterMatchButton} ${prCross.position === activeButton && s.active}`}
								>
									<button>
										{i18n.language === "ru" ? prCross.name_ru : prCross.name}
									</button>
								</li>
							))}
						</ul>
						<ul className={s.dynamicChangedList}>
							{changedElements
								.filter(
									(changedElement: { id: string; assign: []; value: string }) =>
										changedElement.id === filter.inputName
								)
								.map(
									(changedElement: {
										id: string;
										assign: [];
										value: string;
									}) => {
										const combinedCrossAssign = combineArrays(
											changedElement.assign
												.filter((item: any) =>
													changedElement.value.includes(item.id)
												)
												.map((item: any) => item.crossAssign)
										);
										return combinedCrossAssign.map(
											(item: {
												name: string;
												name_ru: string;
												position: number;
											}) => {
												return (
													<li
														key={item.name}
														className={`${s.dynamicChangedElement} ${item.position === activeButton && s.active}`}
													>
														<span>
															{i18n.language === "ru"
																? item.name_ru
																: item.name}
														</span>
													</li>
												);
											}
										);
									}
								)}
						</ul>
					</div>
					<span></span>
					<div className={s.filerContent}>
						<ul
							className={`${s.sizeList} ${s.grid}`}
							style={{ gridTemplateColumns: `repeat(${filter.columns},1fr)` }}
						>
							{filter.propertyValues.map((f: any) => {
								/* if (+f.countItem.countItems > 0 || filter.id === lastChangedFilter)*/
								return (
									<li key={f.id}>
										{f.crossAssign.map((crEl: any) => {
											return (
												crEl.position === activeButton && (
													<div key={crEl.name} className={s.sizeListItem}>
														<StandartCheckbox
															isFilterMatch={true}
															setLastChangedFilter={setLastChangedFilter}
															property={{
																id: f.id,
																name: crEl.name,
																name_ru: crEl.name_ru,
																crossAssign: f.crossAssign,
																position: crEl.position,
																property_id: f.id,
																colorFileUrl: null,
																colorHex: null,
																countItem: f.countItem,
															}}
															filtersValues={filtersValues}
															filter={filter}
															setFiltersValues={setFiltersValues}
														/>
													</div>
												)
											);
										})}
									</li>
								);
							})}
						</ul>
					</div>
				</>
			)}
		</div>
	);
};

export default FilterFrontMatch;
