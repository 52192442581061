import React from "react";
import s from "../../MyOrders/MyOrdersCardMobile/MyOrdersCardMobile.module.css";
import PersonalCabinetSvgSelector from "../../PersonalCabinetSvgSelector";

const MainTitleForCabinetCardMobile = ({
	isOpenCard,
	setIsOpenCard,
}: {
	isOpenCard: boolean;
	setIsOpenCard: (b: boolean) => void;
}) => {
	return (
		<div className={s.mainTitle} onClick={() => setIsOpenCard(!isOpenCard)}>
			<h4>Гирлянда штора RD-085 120</h4>
			<div className={s.closeCross}>
				{isOpenCard ? (
					<PersonalCabinetSvgSelector id={"selectArrowRotate"} />
				) : (
					<PersonalCabinetSvgSelector id={"selectArrow"} />
				)}
			</div>
		</div>
	);
};

export default MainTitleForCabinetCardMobile;
