import s from "./BusinessPage.module.css";
import { NavigationTabs } from "./Components/NavigationTabs";
import { SellerContent } from "./Components/SellerContent/SellerContent";
import { TopBanner } from "./Components/TopBanner";

const tabs = [
	{ key: "all", label: "Все объявления" },
	{ key: "about", label: "О продавце" },
	{ key: "contacts", label: "Контакты" },
	{ key: "reviews", label: "Отзывы" },
	{ key: "portfolio", label: "Портфолио" },
	{ key: "articles", label: "Статьи" },
];

const BusinessPage = () => {
	return (
		<div className={s.wrapper}>
			<TopBanner />

			<div className="__container">
				<NavigationTabs tabs={tabs} />
				<SellerContent />
			</div>
		</div>
	);
};

export default BusinessPage;
