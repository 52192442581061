import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import useCatIdsHandler from "../../../CustomHooks/useCatIdsHandler";
import {
	getNoticeList,
	setNoticesAllList,
} from "../../../redux/notice_Reducer";
import { getPriceList } from "../../../redux/price_Reducer";
import { StateType } from "../../../redux/redux-store";
import ExpandModernButton from "../../ExpandButton/ExpandModernButton";
import CabinetRubricsSelectMobile from "../CabinetRubricsSelectMobile/CabinetRubricsSelectMobile";
import TopNavigateMobileCabinet from "../TopNavigateMobileCabinet/TopNavigateMobileCabinet";
import s from "./MyAdsActive.module.css";
import MyAdsCardMobile from "./MyAdsCard/MyAdsCardMobile";

const MyAdsMobile = () => {
	const dispatch: any = useDispatch();
	const getNotices = useSelector(() => getNoticeList);
	const notices = useSelector(
		(state: StateType) => state.noticeData?.noticeList
	);
	const noticesAllList = useSelector(
		(state: StateType) => state.noticeData?.noticeListAllList
	);
	const userStatic = useSelector(
		(state: StateType) => state.userData.userStatic
	);
	const staticInfo = userStatic?.userCountNotices[0] || {};
	const { t, i18n } = useTranslation();
	const [page, setPage] = useState(1);
	const [status, setStatus] = useState(30);
	const catIdsForHook = useSelector(
		(state: StateType) => state.noticeData.userStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);
	const noticesMeta = useSelector(
		(state: StateType) => state.noticeData.noticeListMeta
	);
	const categories = useSelector(
		(state: StateType) => state.noticeData.userStatByCategory
	);
	const location = useLocation();
	const lastSegment = location.pathname.substr(
		location.pathname.lastIndexOf("/") + 1
	);
	const giveMePriceList = useSelector(() => getPriceList);
	const elements = [
		{
			link: "active",
			name: t("Активні"),
			elementData: staticInfo.active || "0",
			status: 30,
		},
		{
			link: "under_review",
			name: t("На перевірці"),
			elementData: staticInfo.waitModer || "0",
			status: 10,
		},
		{
			link: "rejected",
			name: t("Відхилені"),
			elementData: staticInfo.rejected || "0",
			status: 20,
		},
		{
			link: "no_active",
			name: t("Неактивні"),
			elementData: staticInfo.noActive || "0",
			status: 40,
		},
	];

	useEffect(() => {
		if (notices && notices.length) {
			if (page == 0 || page == 1) {
				dispatch(setNoticesAllList(notices));
			} else {
				const newArr = [...noticesAllList, ...notices];
				dispatch(setNoticesAllList(newArr));
			}
		}
	}, [notices]);

	useEffect(() => {
		let status = 30;
		if (lastSegment == "active") {
			status = 30;
		} else if (lastSegment == "under_review") {
			status = 10;
		} else if (lastSegment == "rejected") {
			status = 20;
		} else if (lastSegment == "no_active") {
			status = 40;
		}

		dispatch(
			getNotices({ perPage: 48, status: status, page: page, catIds: catIds })
		);
	}, [status, page, noticesMeta.pageCount, catIds, i18n.language]);

	useEffect(() => {
		dispatch(setNoticesAllList(notices));
		// setPage(0);
	}, [lastSegment]);
	useEffect(() => {
		dispatch(giveMePriceList());
	}, []);

	return (
		<div className={s.myAdsMobileContainer}>
			<div className={s.topHead}>
				<TopNavigateMobileCabinet
					titleMobile={"Мої оголошення"}
					headers={elements}
					setStatus={setStatus}
				/>
			</div>
			<div className={s.rubricsSelectContainer}>
				<CabinetRubricsSelectMobile
					status={status}
					setPage={setPage}
					setStatus={setStatus}
					catIds={catIds}
					handleCatIds={handleCatIds}
					categories={categories}
				/>
			</div>
			<div className={s.cardsContainer}>
				{noticesAllList?.map((notice: any) => (
					<Routes key={notice.id}>
						<Route path={""} element={<Navigate to="active" />} />
						<Route
							path={"active"}
							element={
								<MyAdsCardMobile
									notice={notice?.status === 30 ? notice : null}
									type={"active"}
								/>
							}
						/>
						<Route
							path={"under_review"}
							element={
								<MyAdsCardMobile
									notice={notice?.status === 10 ? notice : null}
									type={"under_review"}
								/>
							}
						/>
						<Route
							path={"rejected"}
							element={
								<MyAdsCardMobile
									notice={notice?.status === 20 ? notice : null}
									type={"rejected"}
								/>
							}
						/>
						<Route
							path={"no_active"}
							element={
								<MyAdsCardMobile
									notice={notice?.status === 40 ? notice : null}
									type={"no_active"}
								/>
							}
						/>
					</Routes>
				))}
			</div>
			{noticesMeta.pageCount > noticesMeta.currentPage && (
				<div className={s.paginatorContainer}>
					<ExpandModernButton
						onClickCallback={() => {
							setPage((prevState) => prevState + 1);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default MyAdsMobile;
