import React from "react";
import s from "./SellerInfoForHiddenCardMobile.module.css";

const SellerInfoForHiddenCardMobile = ({ type }: { type?: string }) => {
	return (
		<div className={s.sellerInfoContainer}>
			<div className={s.sellerInfoName}>Виктор Викторович</div>
			<div className={s.sellerInfoPhone}>099 1234567</div>

			<div className={s.sellerChat}>
				{type && type === "insurance_amount" ? (
					<button>Залишити відгук</button>
				) : (
					<>
						<button>Чат з продавцем</button>
						<span>5</span>
					</>
				)}
			</div>
			<div className={s.sellerNumberOfOrder}>
				Номер замовлення: <span>0123456789</span>
			</div>
		</div>
	);
};

export default SellerInfoForHiddenCardMobile;
