import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router";
import "../../galleryStyles.css";
import img1 from "../../images/img1.jpg";
import { Image } from "../../types/noticeTypes";

type Props = {
	id: string;
	images?: Image[];
	imagesUrl?: any;
	type: string;
	alt: string;
};

function MyGallery({ images, imagesUrl, id, type, alt }: Props) {
	const navigate = useNavigate();
	let items;

	if (images) {
		items = images.map((img) => {
			let imageUrl;
			if (img.file instanceof File) {
				imageUrl = URL.createObjectURL(img?.file) || "";
			} else {
				imageUrl = img?.imageUrl || "";
			}
			return {
				original: imageUrl || img1,
				originalAlt: alt || "icon",
				thumbnailAlt: alt || "icon",
				thumbnail: imageUrl || img1,
				thumbnailLoading: "lazy",
				thumbnailTransitionTime: "100",
			};
		});
	} else {
		items = imagesUrl.map((i: any) => ({
			original: i.originalImageUrl || "",
			originalAlt: alt || "icon",
			thumbnailAlt: alt || "icon",
			/*thumbnail: i.imageUrl || "",*/
			thumbnail: i.originalImageUrl || "",
			thumbnailLoading: "lazy",
			thumbnailTransitionTime: "100",
		}));
	}

	return (
		<ImageGallery
			items={items}
			/*renderFullscreenButton={(onClick, isFullscreen) => (
            <Fullscreen isFullscreen={isFullscreen} onClick={onClick}/>
        )}*/
			onClick={async (event) => {
				if (type !== "perView") {
					const slideIndex = event.currentTarget.ariaLabel?.replace(/\D/g, "");
					navigate(`/o/${id}/gallery_chat/${slideIndex}`);
				}
			}}
			additionalClass={"meGallery"}
			useBrowserFullscreen={false}
			showIndex
			slideDuration={0}
			indexSeparator={" з "}
			showFullscreenButton={false}
			showPlayButton={false}
			useTranslate3D={false}
			lazyLoad={true}
		/>
	);
}

export default MyGallery;
