import React from "react";
import PersonalCabinetTopSection from "./PersonalCabinetTopSection";
import PersonalCabinetCheckBoxPanel from "./PersonalCabinetCheckBoxPanel";
import s from "../../pages/PersonalCabinet/PersonalCabinet.module.css";
import { Route, Routes } from "react-router";
import MyPurchases from "./MyPurchases/MyPurchases";
import { Navigate } from "react-router-dom";

const PersonalCabinetPurchases: React.FC = () => {
	const elements = [
		{
			link: "new",
			name: "Нові",
			elementData: "120",
		},
		{
			link: "archive",
			name: "Архів",
			elementData: "120",
		},
	];
	return (
		<div>
			<PersonalCabinetTopSection title={"Мої покупки"} headers={elements} />
			{/*<PersonalCabinetCheckBoxPanel categories={[]}/>*/}
			<div className={s.cabinetContent_CardTemplate}>
				<Routes>
					<Route path={""} element={<Navigate to="new" />} />
					<Route path={"new"} element={<MyPurchases type={"new"} />} />
					<Route path={"archive"} element={<MyPurchases type={"archive"} />} />
				</Routes>
			</div>
		</div>
	);
};

export default PersonalCabinetPurchases;
