import React from "react";
/*import s from "./FavoriteSearchCard.module.css";*/
import s from "./ProductCardGrid.module.css";
import SearchCardSvgSelector from "./SearchCardSvgSelector";

const FavoriteSearchCardPopup = () => {
	return (
		<div className={`${s.hiddenPopup} ${s.popup}`}>
			<div className={s.popupTop}>Убрать карточку из поиска.</div>
			<div className={s.popupCenter}>
				Чтобы вернуть всё обратно или отключить эту функцию, вам нужно нажать
				СБРОС, кнопка меню:
			</div>
			<div className={s.delCardButton}>
				<SearchCardSvgSelector id={"delCardButton"} />
			</div>
			<div className={s.popupBottom}>справа вверху страницы</div>
			<button>ok, понятно</button>
		</div>
	);
};

export default FavoriteSearchCardPopup;
