import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import AutomaticSelectionOfCategoriesModal from "../../../Components/AutomaticSelectionOfCategories/AutomaticSelectionOfCategoriesModal";
import AutomaticSelectionOfCategoriesTemplate from "../../../Components/AutomaticSelectionOfCategories/AutomaticSelectionOfCategoriesTemplate";
import s from "../CreateBoardAD.module.css";

interface Props {
	autoSelectionOne: any;
	isCloseAutoSelectionStore: any;
	autoSelectionChoosing: any;
	noticeId: any;
	setIsShowAutomaticSelection: any;
	status: any;
	setStatus: any;
	values: any;
	setFieldValue: any;
	isShowAutomaticSelection: any;
}

const BigRubricsChanger: React.FC<Props> = ({
	autoSelectionOne,
	status,
	setStatus,
	values,
	setFieldValue,
	isShowAutomaticSelection,
	isCloseAutoSelectionStore,
	autoSelectionChoosing,
	noticeId,
	setIsShowAutomaticSelection,
}) => {
	const { t } = useTranslation();

	return (
		<>
			{!!autoSelectionOne?.categoryId && isCloseAutoSelectionStore ? (
				<div className={s.formElementAutomaticSelection}>
					<div className={s.formElement} style={{ marginBottom: "25px" }}>
						<div className={s.nameSide}>
							<span className={s.elementName}>{t(`Автопідбір рубрики`)}:</span>
						</div>
					</div>
					<>
						{autoSelectionChoosing?.categoryId && !noticeId ? (
							<AutomaticSelectionOfCategoriesTemplate
								change={true}
								chose={false}
								handleAction={setIsShowAutomaticSelection}
								categoryName={autoSelectionChoosing?.categoryName}
								/*tree={autoSelectionChoosing?.categoryInfo?.tree.slice(0, 3)}*/
								names={autoSelectionChoosing?.categoryInfo?.names.slice(0, 3)}
								image={autoSelectionChoosing?.categoryInfo?.urlIconLevel2}
							/>
						) : (
							<AutomaticSelectionOfCategoriesTemplate
								change={true}
								chose={false}
								handleAction={setIsShowAutomaticSelection}
								categoryName={autoSelectionOne?.categoryName}
								/*tree={autoSelectionOne?.categoryInfo?.tree.slice(0, 3)}*/
								names={autoSelectionOne?.categoryInfo?.names.slice(0, 3)}
								image={autoSelectionOne?.categoryInfo?.urlIconLevel2}
							/>
						)}
					</>
					{status && status.category_id && (
						<span className={s.error}>{status.category_id}</span>
					)}
				</div>
			) : (
				<div className={s.formElement}>
					<div className={s.nameSide}>
						<span className={s.elementName}>
							{t(`Оберіть рубрику`)}
							<span className={s.required}> * </span>
						</span>
					</div>
					<div
						className={`${s.fieldSide} ${status?.category_id ? s.error : ""}`}
					>
						{noticeId ? (
							<Field
								onFocus={() => setStatus({})}
								name={"category_id_value"}
								disabled={true}
								placeholder={t("Оберіть рубрику")}
								autoComplete={"off"}
								value={values.category_id_label}
							/>
						) : (
							<Field
								onFocus={() => setStatus({})}
								name={"category_id_value"}
								onClick={() => setIsShowAutomaticSelection(true)}
								placeholder={t("Оберіть рубрику")}
								autoComplete={"off"}
								value={values.category_id_label}
							/>
						)}
						{/*{isShowAutomaticSelection && !noticeId &&
                                <AutomaticSelectionOfCategoriesModal
                                    setFieldValue={setFieldValue}
                                    setIsOpen={setIsShowAutomaticSelection}

                                />
                            }*/}
					</div>
					{status && status.category_id && (
						<span className={s.error}>{status.category_id}</span>
					)}
				</div>
			)}
			{isShowAutomaticSelection && !noticeId && (
				<AutomaticSelectionOfCategoriesModal
					setFieldValue={setFieldValue}
					setIsOpen={setIsShowAutomaticSelection}
				/>
			)}
		</>
	);
};

export default BigRubricsChanger;
