import React, { useState } from "react";
import s from "./Registration&AuthForms.module.css";
import { Field, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { getAuthUser, getRegistrationUser } from "../../redux/user_Reducer";
import { useDispatch, useSelector } from "react-redux";
import style from "../../GlobalStyles.module.css";
import { userRegisterType } from "../../types/userTypes";
import PasswordFormInput from "./PasswordFormInput";
import { useTranslation } from "react-i18next";
import { StateType } from "../../redux/redux-store";
import PopupForApprovePhoneAndSetNewPass from "../../Auth&RegNewPopups/ForgotPassPopup/PopupForApprovePhoneAndSetNewPass";
import RecaptchaComponent from "./RecaptchaComponent";
import { Navigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Mobile } from "../../responsive";

type Props = {
	isAuth: boolean;
	setIsAuth: (b: boolean) => void;
	errorApple: string;
	closeModal: (b: boolean) => void;
};

const FormRegAuth = ({ isAuth, setIsAuth, closeModal, errorApple }: Props) => {
	const dispatch: any = useDispatch();
	const getRegistration = useSelector(() => getRegistrationUser);
	const getAuth = useSelector(() => getAuthUser);
	const { t } = useTranslation();
	const [forgotPass, setForgotPass] = useState(false);
	const [captcha, setCaptcha] = useState("");
	const saveMe = useSelector((state: StateType) => state.userData.saveMe);
	const auth = useSelector((state: StateType) => state.userData.isAuth);
	const initialValues: userRegisterType = {
		name: "",
		phone: "",
		password: "",
		captcha: "",
	};
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleRecaptchaRefresh = async () => {
		try {
			if (executeRecaptcha) {
				const newCaptcha = await executeRecaptcha("yourAction");
				setCaptcha(newCaptcha);
			}
		} catch (error) {
			console.error("Error refreshing reCAPTCHA:", error);
		}
	};
	if (auth) {
		return <Navigate to={"/personal_cabinet/ads"} />;
	} else {
		return (
			<div className={s.mainFormContainer}>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={(values, submitProps) => {
						if (isAuth) {
							const cred = {
								phone: values.phone,
								password: values.password,
								captcha: captcha,
							};
							dispatch(
								getAuth(
									cred,
									submitProps.setStatus,
									closeModal,
									saveMe,
									setForgotPass,
									handleRecaptchaRefresh
								)
							);
							handleRecaptchaRefresh();
						} else {
							dispatch(
								getRegistration(
									values,
									submitProps.setStatus,
									closeModal,
									setForgotPass
								)
							);
						}
					}}
				>
					{({
						values,
						handleChange,
						handleBlur,
						handleSubmit,
						status,
						setFieldValue,
					}) => {
						return (
							<form onSubmit={handleSubmit}>
								{!isAuth && (
									<div
										className={`${s.mainFormElement} ${status && status.name ? s.error : ""}`}
									>
										<Field
											type="text"
											name="name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.name}
											placeholder={t("Ім'я")}
										/>
										{status && status.name && (
											<span className={style.error}>{status.name}</span>
										)}
									</div>
								)}
								<div className={`${s.mainFormElement}`}>
									<div className={`${s.phone}`}>
										<PhoneInput
											inputStyle={{
												borderBottom:
													status && status.phone ? "2px solid red" : "",
											}}
											country={"ua"}
											onlyCountries={["ua"]}
											value={values.phone}
											countryCodeEditable={false}
											onChange={(value, data: any, event, formattedValue) => {
												setFieldValue("phone", formattedValue);
											}}
										/>
										{status && status.phone && (
											<span className={style.error}>{status.phone}</span>
										)}
									</div>
								</div>
								<div
									className={`${s.mainFormElement} ${status && status.password ? s.error : ""}`}
								>
									<div className={s.passInput}>
										<PasswordFormInput
											values={values}
											isAuth={isAuth}
											setFieldValue={setFieldValue}
											setForgotPass={setForgotPass}
											status={status && status.password ? status.password : ""}
										/>
									</div>
								</div>
								{/*{errorApple !=="" &&
                                    <span className={s.errorApple}>{errorApple}</span>}*/}
								<div className={`${s.mainFormElement}`}>
									<RecaptchaComponent
										setFieldValue={setFieldValue}
										setCaptcha={setCaptcha}
									/>
									{status && status.captcha && (
										<span className={style.error}>{status.captcha}</span>
									)}
								</div>
								<Mobile>
									<div className={s.authInfoBlock}>
										<p className={s.authInfoBlockTitle}>
											{t("Ви погоджуєтесь та приймаєте")}
										</p>
										<div className={s.authInfoBlockLinkGroup}>
											<a
												className={s.authInfoBlockLink}
												href="https://bago.ua/static-page/politika-konfidentsialnosti"
											>
												{t("Політика конфіденційності")}
											</a>
											<a
												className={s.authInfoBlockLink}
												href="https://bago.ua/static-page/polzovatelskoye-soglasheniye"
											>
												{t("Угода користувача")}
											</a>
										</div>
									</div>
								</Mobile>
								<div
									className={s.formBottom}
									style={{ marginTop: isAuth ? "66px" : "26px" }}
								>
									<button disabled={status && status.captcha} type={"submit"}>
										{isAuth ? t("Вхід") : t("Зареєструватися")}
									</button>
								</div>
								{forgotPass && (
									<PopupForApprovePhoneAndSetNewPass
										setForgotPass={setForgotPass}
										phone={values.phone}
										closeModal={closeModal}
										handleToReg={setIsAuth}
									/>
								)}
							</form>
						);
					}}
				</Formik>
			</div>
		);
	}
};

export default FormRegAuth;
