import React, { useCallback, useEffect, useState } from "react";
import styleFile from "../../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import classes from "./ForgotPassPopup.module.css";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getSetPassword } from "../../redux/user_Reducer";
import style from "../../GlobalStyles.module.css";
import { StateType } from "../../redux/redux-store";
import { useNavigate } from "react-router";
import { stateUserType } from "../../types/reduxStoreTypes";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import PasswordFormInput from "../../Components/Registration&Auth/PasswordFormInput";
import BedResultInfoPopup from "../BedResultInfoPopup";
import SmsLimitErrorPopup from "../SmsLimitErrorPopup";
import Timer from "../../Components/CommonPopups/AttentionInfoPopup/Timer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type Props = {
	setForgotPass?: (b: boolean) => void;
	closeModal: any;
	/* captcha:string,*/
	phone: string;
	handleToReg: (b: boolean) => void;
};
const PopupForApprovePhoneAndSetNewPass = ({
	setForgotPass,
	closeModal,
	phone,
	handleToReg,
}: Props) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const setNewPass = useSelector(() => getSetPassword);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const sendMessage = useSelector((state: StateType) => state.userData.result);
	const sendType = useSelector((state: StateType) => state.userData.sendType);
	const sendRecoveryCode = useSelector(
		(state: StateType) => state.userData.recovery_code
	);
	const phoneNotExits = useSelector(
		(state: StateType) => state.userData.phoneNotExits
	);
	const smsLimitError = useSelector(
		(state: StateType) => state.userData.smsLimitError
	);
	const [time, setTime] = useState(240); // 4 минуты в секундах (4 минуты * 60 секунд)
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [captcha, setCaptcha] = useState("");

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available");
			return;
		}
		const newCaptcha = await executeRecaptcha("yourAction");
		setCaptcha(newCaptcha);
		// Do whatever you want with the token
	}, [executeRecaptcha]);
	useEffect(() => {
		handleReCaptchaVerify();
	}, [executeRecaptcha]);
	if (phoneNotExits) {
		return (
			<BedResultInfoPopup setClose={setForgotPass} handleToReg={handleToReg} />
		);
	} else if (smsLimitError) {
		return <SmsLimitErrorPopup setClose={setForgotPass} />;
	} else {
		return (
			<div className={styleFile.wrapper}>
				<div className={styleFile.container}>
					{/*<div className={classes.closeCross}>
                        <span onClick={()=> {
                            setForgotPass &&
                            setForgotPass(false)
                        }}>
                            <ArrowsAndCross id={"closeCross"} color={"#6D6D6D"} />
                        </span>
                    </div>
                    <div className={styleFile.info}>
                        {sendMessage}
                    </div>*/}
					<div className={styleFile.topNavigate}>
						<span
							onClick={() => {
								setForgotPass && setForgotPass(false);
							}}
						>
							<ArrowsAndCross id={"closeCross"} color={"#727272"} />
						</span>
					</div>
					<div className={`${styleFile.title} ${classes.title}`}>
						{t("Відновлення паролю")}
					</div>
					<div className={styleFile.info}>{sendMessage}</div>
					{sendType === "email" && (
						<>
							{/*<div className={styleFile.info}>
                        {sendMessage}
                    </div>*/}
							<div className={styleFile.button}>
								<button
									onClick={(e) => {
										e.preventDefault();
										setForgotPass && setForgotPass(false);
									}}
								>
									{t("OK")}
								</button>
							</div>
						</>
					)}
					{sendType === "sms" && (
						<>
							{/*<div className={styleFile.info}>
                    {sendMessage === " " &&
                    t('На вказаний телефон')+ " " +
                    <span className={classes.phoneStyle}>{"tyutyt"}</span> +
                    " " +t(' було надіслано повідомлення. Введіть код та придумайте пароль') }
                </div>*/}
							{/* <div className={styleFile.info}>
                        {sendMessage}
                    </div>*/}

							<Formik
								initialValues={{
									code: "",
									password: "",
									captcha: captcha,
									sms_code: "",
								}}
								onSubmit={(values, submitProps) => {
									dispatch(
										setNewPass(
											sendRecoveryCode,
											values.password,
											closeModal,
											values.sms_code,
											submitProps.setStatus
										)
									);
									handleReCaptchaVerify();
								}}
							>
								{({ values, handleSubmit, status, setFieldValue }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<div
												className={`${classes.timerContainer} ${classes.timerInApprove}`}
											>
												<Timer
													time={time}
													setTime={setTime}
													phone={phone}
													approve={false}
												/>
											</div>
											<div
												className={`${classes.enterEmailInput} ${status && status.error ? classes.error : ""} `}
											>
												<Field
													name={"sms_code"}
													value={values.sms_code}
													placeholder={t("Введіть код")}
												/>
												{status && status.sms_code && (
													<span className={style.error}>{status.sms_code}</span>
												)}
												{status && status.error && (
													<span className={style.error}>{status.error}</span>
												)}
											</div>
											<div className={`${styleFile.title} ${classes.title}`}>
												{t("Придумайте пароль")}
											</div>
											<div
												className={`${classes.enterEmailInput} ${status && status.error ? classes.error : ""} `}
											>
												{/* <Field name={'password_new'} value={values.password_new} placeholder={t('Придумайте пароль')} />
                                        {status && status.error &&
                                            <span className={style.error}>{status.error}</span>}*/}
												<PasswordFormInput
													values={{ password: values.password }}
													setFieldValue={setFieldValue}
													setForgotPass={setForgotPass}
													status={
														status && status.password ? status.password : ""
													}
												/>
											</div>
											{status && status.captcha && (
												<span className={styleFile.error}>
													{status.captcha}
												</span>
											)}
											<div className={styleFile.button}>
												<button
													onClick={(e) => {
														e.preventDefault();
														if (auth) {
															navigate("/personal_cabinet/settings");
														} else {
															handleSubmit();
														}
													}}
												>
													{t("Вхід")}
												</button>
											</div>
										</Form>
									);
								}}
							</Formik>
						</>
					)}
				</div>
			</div>
		);
	}
};

export default PopupForApprovePhoneAndSetNewPass;
