import React from "react";
import style from "./CompainModalWindow.module.css";
import CompainModalWindowSvg from "./CompainModalWindowSvg";
import { useTranslation } from "react-i18next";

type PropsType = {
	currentMainComplain: string;
	currentComplain: any;
	messageValue: string;
	error: string;
	setMessageValue: (complain: string) => void;
	setIsSendCompain: (value: boolean) => void;
	handleSendCompain: () => void;
};

const CompainModalSecondWindow: React.FC<PropsType> = ({
	currentMainComplain,
	currentComplain,
	messageValue,
	setMessageValue,
	setIsSendCompain,
	handleSendCompain,
	error,
}) => {
	const { t } = useTranslation();

	const handleSetMessage = (e: string) => {
		if (e.length <= 1000) {
			setMessageValue(e);
		}
	};
	return (
		<div className={`${style.secondWindowWrap} ${style.retreat}`}>
			<p className={style.secondWindow_mainCompain}>
				{t(`${currentMainComplain}`)}
			</p>
			<div className={style.secondWindow_subCompain_wrap}>
				<p className={style.secondWindow_subCompain}>
					{t(`${currentComplain.title}`)}
				</p>
				<CompainModalWindowSvg id={"checkMark"} />
			</div>
			<div className={style.secondWindow_textarea_wrap}>
				<textarea
					className={style.secondWindow_textarea}
					placeholder={t("Опишіть проблему детальніше...")}
					onChange={(e) => handleSetMessage(e.target.value)}
					value={messageValue}
				/>
				<div className={style.secondWindow_number_symbols_wrap}>
					<p className={style.secondWindow_number_symbols}>
						{messageValue.length}/1000
					</p>
				</div>
			</div>
			<div className={style.secondWindow_button_wrap}>
				<button
					className={style.secondWindow_button}
					onClick={() => handleSendCompain()}
				>
					{t("Відправити")}
				</button>
			</div>
			<p className={style.errorMessage}>{error}</p>
		</div>
	);
};

export default CompainModalSecondWindow;
