import React from "react";

type Props = {
	seconds: number;
};
const OnlyTime: React.FC<Props> = ({ seconds }) => {
	if (seconds) {
		const date = new Date(seconds && seconds * 1000); // Преобразование секунд в миллисекунды
		const hour = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, "0");

		return (
			<>
				<>{hour}</>:<>{minutes}</>
			</>
		);
	}
	return <> </>;
};
export default OnlyTime;
