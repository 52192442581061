import React from "react";
import styleFile from "../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import ArrowsAndCross from "../common/ArrowsAndCross/ArrowsAndCross";
import classes from "./ForgotPassPopup/ForgotPassPopup.module.css";
import redBago from "../images/LogoRedBago.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StateType } from "../redux/redux-store";

type Props = {
	setClose?: (b: boolean) => void;
};
const BedResultChangePhonePopup = ({ close }: { close: any }) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const error = useSelector((state: StateType) => state.userData.result);
	//const close = useSelector((state:StateType)=>setErrorApprovePhonePopup);
	return (
		<div className={styleFile.wrapper}>
			<div className={styleFile.container}>
				<div className={styleFile.topNavigate}>
					<span
						onClick={() => {
							dispatch(close(false));
						}}
					>
						<ArrowsAndCross id={"closeCross"} color={"#727272"} />
					</span>
				</div>
				<div className={`${styleFile.title} ${classes.title}`}>
					<img src={redBago} alt="bago" />
				</div>
				<div className={styleFile.info}>{error}</div>
				<div className={styleFile.button}>
					<button
						onClick={(e) => {
							e.preventDefault();
							dispatch(close(false));
						}}
					>
						{t("OK")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default BedResultChangePhonePopup;
