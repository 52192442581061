import React, { Fragment } from "react";
import s from "./RecentlyWatched.module.css";
import ProductCardHorizontal from "../Cards/ProductCard/ProductCardHorizontal";
import ProductCardHorizontalMobile from "../Cards/ProductCard/ProductCardHorizontalMobile";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { useTranslation } from "react-i18next";
import { Mobile, Desktop } from "../../responsive";

const RecentlyWatched = () => {
	const { t } = useTranslation();
	const notices = useSelector(
		(state: StateType) => state.publicNotice.recentlyWatchedNotices
	);

	return (
		<div className={`${s.recentlyWatchedContainer} __container`}>
			<h5>{t("Раніше переглянуті")}</h5>
			<Desktop>
				<div className={s.recentlyWatchedRow}>
					{notices?.map((i: any, index) => (
						<Fragment key={+i.notice_id + index}>
							<ProductCardHorizontal notice={i} />
						</Fragment>
					))}
				</div>
			</Desktop>
			<Mobile>
				<div className={s.recentlyWatchedMobileContainer}>
					{notices?.map((i: any, index) => (
						<Fragment key={i.notice_id + index}>
							<ProductCardHorizontalMobile notice={i} />
						</Fragment>
					))}
				</div>
			</Mobile>
		</div>
	);
};

export default RecentlyWatched;
