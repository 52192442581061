import React from "react";
import PersonalCabinetTopSection from "./PersonalCabinetTopSection";
import PersonalCabinetCheckBoxPanel from "./PersonalCabinetCheckBoxPanel";
import s from "../../pages/PersonalCabinet/PersonalCabinet.module.css";
import { Route, Routes } from "react-router";
import MyOrders from "./MyOrders/MyOrders";
import { Navigate } from "react-router-dom";

const PersonalCabinetOrders: React.FC = () => {
	const elements = [
		{
			link: "new",
			name: "Нові",
			elementData: "120",
		},
		{
			link: "sent",
			name: "Відправлені",
			elementData: "120",
		},
		{
			link: "closed",
			name: "Закриті",
			elementData: "120",
		},
	];
	return (
		<div>
			<PersonalCabinetTopSection title={"замовлення"} headers={elements} />
			{/* <PersonalCabinetCheckBoxPanel categories={[]}/>*/}
			<div className={s.cabinetContent_CardTemplate}>
				<Routes>
					<Route path={""} element={<Navigate to="new" />} />
					<Route path={"new"} element={<MyOrders type={"new"} />} />
					<Route path={"sent"} element={<MyOrders type={"sent"} />} />
					<Route path={"closed"} element={<MyOrders type={"closed"} />} />
				</Routes>
			</div>
		</div>
	);
};

export default PersonalCabinetOrders;
