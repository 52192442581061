import React from "react";
import s from "./ShoppingCart.module.css";

const ShoppingCart: React.FC = () => {
	return (
		<div className={`${s.shoppingCartContainer} __container`}>
			<div className={s.shoppingCartTopPanel}>
				<div className={s.shoppingCartTitle}>
					<h5>Корзина</h5>
					<span>Обрано 2 товара</span>
				</div>
				<div className={s.shoppingCartDeleteAll}>
					<p>Видалити все</p>
				</div>
			</div>
			<div className={s.shoppingCartContent}>
				<ShoppingCartCard />
			</div>
			<div className={s.shoppingCartBottomPanel}>
				<div className={s.bottomPanelContinue}>
					<button>Продовжити покупки</button>
				</div>
				<div className={s.bottomPanelOrderInfo}>
					<div className={s.bottomPanelSum}>
						<span>8400 грн.</span>
					</div>
					<div className={s.bottomPanelButton}>
						<button>Оформити замовлення</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShoppingCart;

const ShoppingCartCard = () => {
	return <div className={s.cardContainer}></div>;
};
