import React, { useEffect } from "react";
import s from "./FilterComponent.module.css";
import FilterTypeCheckbox from "./FilterTypeCheckbox";
import { valuesCreateAD } from "../../types/userTypes";
import FilterColor from "../SearchFilters/FilterColor";
import FilterTypeButton from "./FilterTypeButton";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import FilterTypeDropdownSelect from "./FilterTypeDropdownSelect";
import { useTranslation } from "react-i18next";
import FilterTypeInput from "./FilterTypeInput";
import FilterTypeDropdown from "./FilterTypeDropdown";
import FilterTypeInputWithSearch from "./FilterTypeInputWithSearch";

type Props = {
	filter: any;
	values: valuesCreateAD;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	status: any;
};

/*
const FILTER_TYPE_ONE_FROM_LIST = 1;
const FILTER_TYPE_MANY_FROM_LIST = 2;
const FILTER_TYPE_INPUT_NUMBER = 3;
const FILTER_TYPE_INPUT_TEXT = 4;

const FILTER_SEE_AS_CHECK = 1;
const FILTER_SEE_AS_BUTTON = 2;
const FILTER_SEE_AS_DROPDOWN = 3;
const FILTER_SEE_AS_DIAPASON = 4; при подаче нет
const FILTER_SEE_AS_INPUT = 5;*/
const FilterComponent = ({ filter, setFieldValue, values, status }: Props) => {
	const { i18n } = useTranslation();
	const filtersMany = useSelector(
		(state: StateType) => state.noticeData.filtersMany
	);
	const filtersOneIs = useSelector(
		(state: StateType) => state.noticeData.filtersOneIs
	);
	const filtersAll = useSelector(
		(state: StateType) => state.noticeData.filtersAll
	);

	useEffect(() => {
		if (values && setFieldValue) {
			setFieldValue("filters", [...filtersMany, ...filtersOneIs]);
			setFieldValue("notice_properties", [...filtersMany, ...filtersOneIs]);
		}
	}, [filtersMany, filtersOneIs, i18n.language]);
	if (filter.view_as === 1) {
		//checkbox
		const filterName = filter.inputName.replace(/\[|\]/g, "");
		return (
			<>
				<FilterTypeCheckbox
					filter={filter}
					values={values}
					filtersAll={filtersAll}
				/>
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.view_as === 2) {
		//buttons
		const filterName = filter.inputName.replace(/\[|\]/g, "");
		return (
			<>
				<FilterTypeButton
					filter={filter}
					filtersMany={filtersMany}
					filtersOneIs={filtersOneIs}
					filtersAll={filtersAll}
				/>
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.view_as === 3) {
		//dropdowns
		const filterName = filter.inputName.replace(/\[|\]/g, "");

		return (
			<>
				{filter.type !== 1 && ( //manyIs
					<FilterTypeDropdown
						filter={filter}
						filtersMany={filtersMany}
						filtersOneIs={filtersOneIs}
					/>
				)}
				{filter.type === 1 && filter.canUserAddValue !== "1" && (
					//oneIs
					<FilterTypeDropdownSelect
						filter={filter}
						filtersOneIs={filtersOneIs}
					/>
				)}

				{filter.view_as === 3 &&
					filter.type === 1 &&
					filter.canUserAddValue === "1" && (
						<FilterTypeInputWithSearch
							filter={filter}
							filtersOneIs={filtersOneIs}
						/>
					)}
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.propertyType === 5) {
		//color

		const filterName = filter.inputName.replace(/\[|\]/g, "");

		return (
			<>
				<FilterColor
					filter={filter}
					values={values}
					filtersMany={filtersMany}
					filtersOneIs={filtersOneIs}
				/>

				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.view_as === 5) {
		//input
		const filterName = filter.inputName.replace(/\[|\]/g, "");
		return (
			<>
				<FilterTypeInput filter={filter} filtersOneIs={filtersOneIs} />
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else {
		return <div></div>;
	}
};

export default FilterComponent;
