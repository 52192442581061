import React from "react";
import s from "./MyBalance.module.css";
import OnlyDay from "../../TimeAndDate/OnlyDay";
import PublishedMonthYear from "../../TimeAndDate/PublishedMonthYear";
import OnlyTime from "../../TimeAndDate/OnlyTime";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OnePaymentInfoElement } from "../../../types/userTypes";

interface Props {
	pay: OnePaymentInfoElement;
	link: string;
}

const PurposeOfPayment: React.FC<Props> = ({ pay, link }) => {
	const { t } = useTranslation();
	return (
		<>
			{pay?.additionalData ? (
				<div
					className={`${s.tableSubElement} ${s.tableInfoName} ${s.tableAdditionalInfo}`}
				>
					<div>
						{pay.additionalData.noticeTopEnd ? (
							<div className={`${s.additionalNoticTopRow}`}>
								ТОП до <OnlyDay seconds={pay.additionalData.noticeTopEnd} />
								&nbsp;
								<PublishedMonthYear seconds={pay.additionalData.noticeTopEnd} />
								, <OnlyTime seconds={pay.additionalData.noticeTopEnd} />, ID{" "}
								{pay.additionalData.noticeId}
							</div>
						) : (
							<div className={`${s.additionalNoticTopRow}`}>
								{t("Підняття")}, ID {pay.additionalData.noticeId}
							</div>
						)}
						<div className={`${s.additionalNoticeName}`}>
							<Link to={"/o/" + link + "/" + pay.additionalData.noticeAlias}>
								{pay.additionalData.noticeName}
							</Link>
						</div>
					</div>
				</div>
			) : (
				<div className={`${s.tableSubElement} ${s.tableInfoName}`}>
					{pay?.message}
				</div>
			)}
		</>
	);
};

export default PurposeOfPayment;
