import React from "react";
import s from "../MyOrders/MyOrders.module.css";
import img from "../../../images/imageForCabinetItem.jpg";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";

function PersonalCabinetCardLeft() {
	return (
		<div className={s.myOrdersCardLeft}>
			<div className={s.left_image}>
				<div className={s.left_image_Image}>
					<img src={img} alt="itemImage" />
					<PersonalCabinetSvgSelector id={"top100"} />
				</div>
			</div>
			<div className={s.cardLeftInfo}>
				<div>
					<div className={s.cardLeftInfo_Title}>
						<h3>Чехол для ноутбука</h3>
					</div>
					<div className={s.cardLeftInfo_Prise}>
						<p>600 грн</p>
					</div>
				</div>
				<div className={s.cardLeftInfo_Time}>
					<p>Сегодня в 12:45</p>
				</div>
			</div>
		</div>
	);
}

export default PersonalCabinetCardLeft;
