import React from "react";
import AppleSignin from "react-apple-signin-auth";
import s from "./Registration&AuthForms.module.css";
import AuthSvgSelector from "./AuthSvgSelector";
import { Desktop } from "../../responsive";
import { useDispatch, useSelector } from "react-redux";
import {
	getNetworkAuthUserApple,
	getNetworkAuthUserLinkApple,
} from "../../redux/user_Reducer";

const AppleAuth = ({
	setErrorApple,
	closeModal,
	type,
}: {
	setErrorApple: (b: string) => void;
	closeModal: (b: boolean) => void;
	type: string;
}) => {
	const bindAcc = useSelector(() => getNetworkAuthUserLinkApple);
	const dispatch: any = useDispatch();
	const getAuthApple = useSelector(() => getNetworkAuthUserApple);
	return (
		<AppleSignin
			/** Auth options passed to AppleID.auth.init() */
			authOptions={{
				/** Client ID - eg: 'com.example.com' */
				clientId: "ua.bago.client",
				/** Requested scopes, seperated by spaces - eg: 'email name' */
				scope: "email name",
				/** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
				redirectURI: process.env.REACT_APP_APPLE_AUTH || "",
				/** State string that is returned with the apple response */
				//state: '344FGGFfgghj56jj77u76656565555ghhjjj',
				/** Nonce */
				nonce: "nonce",
				/** Uses popup auth instead of redirection */
				usePopup: true,
			}} // REQUIRED
			/** General props */
			uiType="dark"
			/** className */
			className="apple-auth-btn"
			/** Removes default style tag */
			noDefaultStyle={false}
			/** Allows to change the button's children, eg: for changing the button text */
			buttonExtraChildren="Continue with Apple"
			/** Extra controlling props */
			/** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
			onSuccess={(response: any) => {
				const auth = response.authorization;
				const user = response?.user && JSON.stringify(response.user);
				setErrorApple("");
				if (type === "bind") {
					const params = {
						code: response.authorization.code,
						clientName: "apple",
					};
					dispatch(bindAcc(params, setErrorApple));
				} else {
					dispatch(
						getAuthApple(
							auth.code,
							auth.id_token,
							closeModal,
							setErrorApple,
							user
						)
					);
				}
			}} // default = undefined
			/** Called upon signin error */
			onError={(error: any) => {
				setErrorApple(error ? error.error : "");
				//console.error(error)
			}} // default = undefined
			/** Skips loading the apple script if true */
			skipScript={false} // default = undefined
			/** Apple image props */
			// iconProp={{ style: { marginTop: '10px' } }} // default = undefined
			/** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
			render={(props: any) => (
				<button {...props}>
					<span className={s.icon}>
						<AuthSvgSelector id={"appleLogo"} />
					</span>
					<Desktop>
						<span>Apple ID</span>
					</Desktop>
				</button>
			)}
		/>
	);
};

export default AppleAuth;
