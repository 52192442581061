import React from "react";
import s from "./ListOutputTemplateForProductCardMobile.module.css";
import CardTemplate from "./CardTemplate";
import { StateType } from "../../redux/redux-store";
import { useSelector, useDispatch } from "react-redux";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import { getTopInRubrics } from "../../redux/publicNotice_Reducer";

interface IProps {
	title: string;
	notices?: any[];
	pageCount?: any;
	setPageCount?: any;
}

const ListOutputTemplateForProductCardMobile = ({
	title,
	notices,
	pageCount,
	setPageCount,
}: IProps) => {
	const pagination = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const getTopInThisRubrics = useSelector(() => getTopInRubrics);
	// const noticeView = useSelector((state: StateType) => state.publicNotice.noticeView)
	const dispatch: any = useDispatch();
	// const [pageCount,setPageCount] = useState(0);
	return (
		<div className={`${s.newAdvertsContainer} __container`}>
			<h5>{title}</h5>
			<div className={s.bestsellersContainerTopPanel}>
				{notices?.map((i) => <CardTemplate notice={i} key={i.id} />)}
			</div>
			{title !== "Раніше переглянуті:" && (
				<>
					{pagination && pagination.totalCount > 8 && (
						<div className={s.newAdvertsBottomPanel}>
							<ExpandModernButton
								onClickCallback={() => {
									setPageCount((state: any) => state + 1);
									dispatch(
										getTopInThisRubrics({
											page: `${pageCount + 1}`,
											perPage: `${24}`,
											onlyTop: 1,
										})
									);
								}}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ListOutputTemplateForProductCardMobile;
