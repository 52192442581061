import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import s from "../../pages/SearchResults/SearchResult.module.css";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useTranslation } from "react-i18next";

const SearchResultStateSelect = ({
	setSortByState,
}: {
	setSortByState: (b: string) => void;
}) => {
	const { i18n, t } = useTranslation();
	const [value, setValue] = useState([
		{
			value: "0",
			label: t("Всі"),
		},
	]);
	const filterOptions = [
		{
			label: t("Всі"),
			value: "0",
			icon: "",
		},
		{
			label: t("Нові"),
			value: "1",
		},
		{
			label: t("Вживані"),
			value: "2",
		},
	];
	useEffect(() => {
		setValue([
			{
				value: "0",
				label: t("Всі"),
			},
		]);
	}, [i18n.language]);
	return (
		<Select
			isSearchable={false}
			components={{
				IndicatorSeparator: () => null,
				DropdownIndicator: ({ selectProps }) => (
					<span
						className={s.dropdownArrow}
						style={{
							transform: selectProps.menuIsOpen ? "rotate(180deg)" : "",
							marginTop: selectProps.menuIsOpen ? "5px" : "",
							transition: "400ms",
						}}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				),
			}}
			options={filterOptions}
			value={value}
			onChange={(newValue) => {
				setValue([
					{ value: newValue?.value || "", label: newValue?.label || "" },
				]);
				setSortByState(newValue?.value || "1");
			}}
			styles={{
				control: (baseStyles) => ({
					...baseStyles,
					minWidth: "110px",
					padding: "0 15px 0 0 ",
					borderColor: "white",
					boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.09)",
					borderRadius: "8px",
					cursor: "pointer",
					/*"& .controlIcon":{
                    paddingLeft:"10px"
                },*/
					"&:hover": {
						backgroundColor: "white",
						borderColor: "white",
					},
				}),
				option: (styles, { isFocused }) => {
					return {
						...styles,
						width: "90px",
						color: isFocused ? "black" : "#939393",
						background: isFocused ? "none" : "none",
						fontFamily: "var(--myMainFontRegular)",
						fontSize: "14px",
						marginLeft: "4px",
						cursor: "pointer",
					};
				},
				menu: () => ({
					position: "absolute",
					top: "45px",
					border: "1px solid white",
					zIndex: "99",
					backgroundColor: "white",
					width: "100%",
					boxShadow: " 0px 4px 30px rgba(0, 0, 0, 0.06)",
					borderRadius: "5px",
				}),
				menuList: (base) => ({
					...base,

					boxShadow: " 0px 4px 30px rgba(0, 0, 0, 0.06)",
					borderRadius: "5px",
					border: "1px solid white",
				}),
				singleValue: (styles) => {
					return {
						...styles,
						minWidth: "100px",
						display: "flex",
						alignItems: "center",
						fontFamily: "var(--myMainFontMedium)",
						fontSize: "14px",
						lineHeight: "20px",
						"& .selectText": {
							minWidth: "100px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						},
						"& svg": {
							marginRight: "15px",
						},
					};
				},
			}}
		/>
	);
};

export default SearchResultStateSelect;
