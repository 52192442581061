import React, { useState } from "react";
import s from "./MyOrders.module.css";
import PersonalCabinetCardLeft from "../CabinetCardComponents/PersonalCabinetCardLeft";
import PersonalCabinetCardRight from "../CabinetCardComponents/PersonalCabinetCardRight";
import PersonalCabinetCardCenterTop from "../CabinetCardComponents/PersonalCabinetCardCenterTop";

const MyOrders = ({ type }: { type: string }) => {
	const [approved, setApproved] = useState(false);
	const [ttnInput, setTtnInput] = useState("");
	return (
		<div className={s.myOrdersContainer}>
			{type === "new" && (
				<h5>
					У Вас є 2 дні на підтвердження замовлення, після чого замовлення буде
					анульовано. Якщо товар вже продан - просто натисніть хрестик
				</h5>
			)}
			{type === "sent" && (
				<h5>
					Після того, як товар заберуть на пошті та здійснять оплату, замовлення
					автоматично переміститься в закриті.
				</h5>
			)}
			{type === "closed" && (
				<h5>
					Через 14 днів, після отримання товару, Угода переміститься в Архів.
				</h5>
			)}
			<div className={s.myOrdersCardContainer}>
				<PersonalCabinetCardLeft />
				<div className={s.verticalLine} />
				<div className={s.myOrdersCardCenter}>
					<PersonalCabinetCardCenterTop />
					{type === "new" && (
						<>
							{!approved ? (
								<div className={s.cardCenter_bottom}>
									<button
										className={s.approvedButton}
										onClick={() => setApproved(true)}
									>
										Підтвердити замовлення
									</button>
								</div>
							) : (
								<div className={s.cardCenter_bottom}>
									<form action="submit">
										<input
											value={ttnInput}
											onChange={(event) =>
												setTtnInput(event.currentTarget.value)
											}
											type="text"
											placeholder={"Введіть ТТН..."}
										/>
										<button
											style={{
												background: ttnInput !== "" ? "#0BB82E" : "#FFFFFF",
												color: ttnInput === "" ? "#0BB82E" : "#FFFFFF",
												border: ttnInput === "" ? "1px solid #0BB82E" : "",
											}}
											className={s.submitButton}
											onClick={() => setApproved(false)}
											type="submit"
										>
											Відправити
										</button>
									</form>
								</div>
							)}
						</>
					)}
					{type === "sent" && (
						<>
							<div className={s.cardCenter_bottom}>
								<div className={s.cardCenter_bottomSent}>
									<div className={s.cardCenter_bottomDate}>
										Дата прибуття:<span> 25мар2023</span>
									</div>
									<div className={s.cardCenter_bottomTTN}>
										ТТH:<span> UUFLS65665FDKAD5</span>
									</div>
								</div>
							</div>
						</>
					)}
					{type === "closed" && (
						<>
							<div className={s.cardCenter_bottom}>
								<div className={s.cardCenter_bottomClosed}>
									<div className={s.bottomClosed_date}>
										Дата угоди: <span>25мар2023</span>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={s.verticalLine} />
				<PersonalCabinetCardRight />
			</div>
		</div>
	);
};

export default MyOrders;
