import React, { useEffect, useState } from "react";
import style from "./CompainModalWindow.module.css";

type PropsType = {
	name: string;
};

const TruncationOfTheName: React.FC<PropsType> = ({ name }) => {
	const [newName, setNewName] = useState("");

	useEffect(() => {
		if (name.length > 27) {
			const firstPart = name.slice(0, 18);
			const lastPartIndex = name.length - 9;
			const lastPart = name.slice(lastPartIndex);

			setNewName(`${firstPart}...${lastPart}`);
		} else {
			setNewName(name);
		}
	}, [name]);

	return (
		<div>
			<p className={style.productContainer_content_name}>{newName}</p>
		</div>
	);
};

export default TruncationOfTheName;
