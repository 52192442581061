export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const setNotification = (
	message: string,
	messageType: "success" | "error"
) => ({
	type: SET_NOTIFICATION,
	payload: { message, messageType },
});

export const clearNotification = () => ({
	type: CLEAR_NOTIFICATION,
});
export interface NotificationState {
	message: string;
	messageType: "success" | "error";
}

const initialState: NotificationState = {
	message: "",
	messageType: "success",
};

export const notificationReducer = (
	state = initialState,
	action: any
): NotificationState => {
	switch (action.type) {
		case SET_NOTIFICATION:
			return {
				message: action.payload.message,
				messageType: action.payload.messageType,
			};
		case CLEAR_NOTIFICATION:
			return initialState;
		default:
			return state;
	}
};
