import { Filter, FiltersValue } from "./types/publicNoticeTypes";
import { OnePrice } from "./redux/price_Reducer";

export const formattingPrice = (value: string) => {
	const formatIntegerPart = (integerPart: string) => {
		if (integerPart.length > 3) {
			const strPrice = integerPart.replace(/\s/g, "");
			let spacedNumber = "";
			for (let i = strPrice.length - 1; i >= 0; i--) {
				spacedNumber = strPrice[i] + spacedNumber;
				if ((strPrice.length - i) % 3 === 0 && i !== 0) {
					spacedNumber = " " + spacedNumber;
				}
			}
			return spacedNumber;
		} else {
			return integerPart;
		}
	};
	if (value) {
		const strValue = value
			.toString()
			.replace(/[^\d.,]/g, "")
			.replace(",", ".");
		const parts = strValue.split(".");
		if (parts.length === 2) {
			// Если есть дробная часть, проверим ее значение
			const integerPart = parts[0];
			const decimalPart = parts[1];
			const formattedInteger = formatIntegerPart(integerPart);
			if (decimalPart === "00") {
				return formattedInteger;
			} else {
				return formattedInteger + "." + decimalPart;
			}
		} else {
			// Если нет дробной части, форматируем всю строку
			return formatIntegerPart(strValue);
		}
	} else {
		return value;
	}
};
export const numberAddSpace = (value: number) => {
	const strValue = value.toString();
	// Удаляем все символы, кроме цифр и точки
	const sanitizedValue = strValue.replace(/[^\d.,]/g, "").replace(",", ".");

	// Разделяем ввод на целую и десятичную части (если есть)
	const parts = sanitizedValue.split(".");
	let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	// Если есть десятичная часть, добавляем её после целой части с точкой
	if (parts.length > 1) {
		integerPart += "." + parts[1];
	}
	return integerPart;
};
export const scrollToTop = (behavior?: ScrollBehavior | undefined) => {
	window.scrollTo({
		top: 0,
		behavior: behavior ? behavior : "auto", // Для плавной прокрутки
	});
};
export const handleClickSearchFilter = (
	id: number,
	filtersValues: FiltersValue[],
	filter: Filter,
	setFiltersValues: any
) => {
	const existingIndex = filtersValues?.findIndex(
		(item: any) =>
			item.value === id.toString() && item.filter === filter.inputName
	);
	if (existingIndex !== -1) {
		const updatedValue: any = [...filtersValues];
		updatedValue.splice(existingIndex, 1);
		setFiltersValues(updatedValue);
	} else {
		const newValue = {
			filter: filter?.inputName,
			value: id.toString(),
		};
		setFiltersValues([...filtersValues, newValue]);
	}
};

export const formattingOptionsForBuyService = (data: OnePrice[] | null) => {
	if (data) {
		return data?.map((el: OnePrice) => ({
			label: el.periodName,
			value: el.id.toString(),
			period: el.period,
			price: el.price,
			periodName: el.periodName,
		}));
	} else {
		return [];
	}
};

export const testActualityService = (time: number | null) => {
	const date = Date.now();
	return time && time * 1000 > date;
};
