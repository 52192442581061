import { reduxActionTypes } from "../types/reduxStoreTypes";
import { IAutomaticSelectionList } from "../types/autoSelectionTypes";
import { Dispatch } from "redux";
import { userComplaints } from "../api/api";
import { ICompainDataTypes } from "../types/CompainTypes";

const SET_CATEGORIES_LIST = "bago/userReducer/SET_CATEGORIES_LIST";
const SET_CAUSES_LIST = "bago/userReducer/SET_CAUSES_LIST";

const initialState: ICompainDataTypes = {
	ComplaintsCategoriesList: [],
	ComplaintsCausesList: [],
};

const complaints_Reducer = (state = initialState, action: reduxActionTypes) => {
	switch (action.type) {
		case SET_CATEGORIES_LIST:
			return {
				...state,
				ComplaintsCategoriesList: action.data,
			};
		case SET_CAUSES_LIST:
			return {
				...state,
				ComplaintsCausesList: action.data,
			};
		default:
			return state;
	}
};

export const setComplaintsCategories = (data: any) => ({
	type: SET_CATEGORIES_LIST,
	data,
});
export const setComplaintsCauses = (data: any) => ({
	type: SET_CAUSES_LIST,
	data,
});

export const getComplaints = () => async (dispatch: Dispatch) => {
	try {
		const response = await userComplaints.getComplaints();

		if (response?.data?.complaints) {
			dispatch(setComplaintsCategories(response?.data?.complaints?.categories));
			dispatch(setComplaintsCauses(response?.data?.complaints?.causes));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};

export const createComplaints =
	(
		mainId: number,
		category_id: number,
		cause_id: number,
		describe: string,
		users_id: string
	) =>
	async () => {
		try {
			const response = await userComplaints.createComplaints(
				mainId,
				category_id,
				cause_id,
				describe,
				users_id
			);

			return response;
		} catch (error: any) {
			console.log(error.message);
		}
	};

export default complaints_Reducer;
