import React from "react";
import s from "../CreateBoardAD.module.css";
import { useTranslation } from "react-i18next";

type Props = {
	noticeDescriptionRef: any;
	status: any;
	setStatus: any;
	values: any;
	setFieldValue: (a: string, b: string) => void;
};

const DescriptionField: React.FC<Props> = ({
	noticeDescriptionRef,
	status,
	setStatus,
	values,
	setFieldValue,
}) => {
	const { t } = useTranslation();
	return (
		<div className={`${s.formElement} ${s.descriptionField}`}>
			<div className={s.nameSide}>
				<span className={s.elementName}>
					{t(`Опис`)}
					<span className={s.required}> * </span>
				</span>
			</div>
			<div
				ref={noticeDescriptionRef}
				className={`${s.fieldSide} ${status?.description ? s.error : ""} ${s.textArea} `}
			>
				<textarea
					name={"description"}
					onFocus={() => setStatus({})}
					value={values.description}
					onChange={(e) => setFieldValue("description", e.currentTarget.value)}
					placeholder={`${t("Опишіть ваш товар, як би ви хотіли його піднести покупцю. Розкажіть про найкращі його якості.")}`}
				/>
				<div className={s.descriptionOptions}>
					{status && status.description ? (
						<div className={s.descriptionOptions_info}>
							{status.description}
						</div>
					) : (
						<div className={s.descriptionOptions_Size}>
							{values.description.length < 50 && t("Не менше 50 символів")}
						</div>
					)}
					<div className={s.descriptionOptions_Size}>
						{values.description.length}/8000
					</div>
				</div>
			</div>
		</div>
	);
};

export default DescriptionField;
