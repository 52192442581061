import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	colorArray,
	registerPhrasesArray,
} from "../../data_arrays/BannersData";
import s from "./AdCard.module.css";
import NoRegisterSvgSelector from "./NoRegisterSvgSelector";
import handleSortPhrases from "./sortPhrases";
interface InterfacePhrasesArray {
	text: string;
	author: string;
	advice: boolean;
	bagoTitle: boolean;
}

const RegisterAdCard = () => {
	const { t } = useTranslation();
	const [randomColor, setRandomColor] = useState("");
	const [randomPhrases, setRandomPhrases] =
		useState<InterfacePhrasesArray | null>(null);

	useEffect(() => {
		const sortResult = handleSortPhrases(
			registerPhrasesArray,
			"authBagoPhrasesArray"
		);
		setRandomPhrases(sortResult);

		const randomColorIndex = Math.floor(Math.random() * colorArray.length);
		setRandomColor(colorArray[randomColorIndex]);
	}, []);

	return (
		<>
			{randomColor && (
				<div className="__container">
					<div className={`${s.container}`}>
						<div
							style={{ background: `#${randomColor}` }}
							className={s.cardContainer}
						>
							<div className={s.logo}>
								<NoRegisterSvgSelector id={"logoBago"} />
							</div>
							<div className={s.contentRegister}>
								<div className={s.contentDescriptionWrap_register}>
									{!randomPhrases?.advice && !randomPhrases?.bagoTitle && (
										<p className={s.contentDescription}>
											{randomPhrases && t(`${randomPhrases?.text}`)}
										</p>
									)}
									{randomPhrases?.advice && (
										<p className={s.contentDescription}>
											<span className={s.boldText}>{t("Bago поради")}:</span>{" "}
											{randomPhrases && t(`${randomPhrases?.text}`)}
										</p>
									)}
									{randomPhrases?.bagoTitle && (
										<p className={s.contentDescription}>
											<span className={s.boldText}>{t("bago")} - </span>{" "}
											{randomPhrases && t(`${randomPhrases?.text}`)}
										</p>
									)}
								</div>
								<div className={s.contentAuthorWrap}>
									<p className={s.contentDescriptionAuthor}>
										{randomPhrases && t(`${randomPhrases?.author}`)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default RegisterAdCard;
