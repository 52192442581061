import React from "react";
import s from "./StandartCheckbox.module.css";
import {
	Filter,
	FiltersValue,
	PropertyValues,
} from "../../types/publicNoticeTypes";
import { useTranslation } from "react-i18next";
import { handleClickSearchFilter } from "../../supporting";
import style from "../SearchFilters/SearchFilters.module.css";

type Props = {
	property: PropertyValues;
	setFiltersValues: any;
	filter: Filter;
	filtersValues: FiltersValue[];
	setLastChangedFilter: (b: number) => void;
	isFilterMatch: boolean;
};
const StandartCheckbox = ({
	property,
	setFiltersValues,
	filter,
	filtersValues,
	setLastChangedFilter,
	isFilterMatch,
}: Props) => {
	const { i18n } = useTranslation();
	const checked = filtersValues.findIndex(
		(f: FiltersValue) =>
			f.filter === filter.inputName && f.value === property.id.toString()
	);

	return (
		<>
			<div className={s.checkBoxContainer}>
				<input
					id={property.id.toString()}
					className={`${s.createAdCheckbox}`}
					type="checkbox"
					checked={checked !== -1}
					onChange={() => {
						setLastChangedFilter(+filter.id);
						handleClickSearchFilter(
							+property.id,
							filtersValues,
							filter,
							setFiltersValues
						);
					}}
				/>
				<label htmlFor={property.id.toString()}>
					<span>
						{i18n.language === "ru" ? property.name_ru : property.name}
					</span>
				</label>
			</div>
			{isFilterMatch && checked === -1 && (
				<span className={style.buttonCounterCheckBox}>
					{property.countItem.countItems}
				</span>
			)}
		</>
	);
};

export default StandartCheckbox;
