import React from "react";
import s from "./ModalAutoRenewal/ModalAutoRenewal.module.css";
import { useTranslation } from "react-i18next";
import { formattingPrice } from "../../../supporting";

type Props = {
	sum: string;
	handleClick: () => void;
	totalBalance: number;
};

const BottomPayModalCardComponent: React.FC<Props> = ({
	sum,
	handleClick,
	totalBalance,
}) => {
	const { t } = useTranslation();
	const approve = totalBalance < +sum;
	return (
		<div className={`${s.topCardSelectPayBottomFunction}`}>
			<span>{t("До оплати")}:</span>
			<span className={`${s.bottomFunction_Sum} `}>
				{formattingPrice(sum)} грн
			</span>
			<button
				className={`${approve ? s.disabled : ""}`}
				disabled={approve}
				onClick={handleClick}
			>
				{t("Оплатити")}
			</button>
		</div>
	);
};

export default BottomPayModalCardComponent;
