import React from "react";
import s from "./MobileVerticalCard.module.css";
import SearchCardSvgSelector from "../FavoriteSearchCard/SearchCardSvgSelector";
import { Link } from "react-router-dom";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import { formattingPrice } from "../../../supporting";
import { useTranslation } from "react-i18next";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";

const MobileVerticalCard = ({ notice }: { notice?: publicOneNotice }) => {
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	//to={`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`}
	//navigate(`${currentLanguage}/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
	return (
		<div key={notice?.id} className={s.verticalCardContainer}>
			<div className={s.cardImage}>
				<Link
					to={`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`}
				>
					{notice?.noticeImages.length ? (
						<img
							src={notice?.noticeImages[0]?.imageUrl}
							alt={notice?.slug || "image"}
						/>
					) : (
						<></>
					)}
					{notice?.top_days && (
						<div className={s.topMobileFlag}>
							<SearchCardSvgSelector id={"topFlagLittle"} />
						</div>
					)}
				</Link>
			</div>
			<div className={s.infoContainer}>
				<div className={s.cardName}>
					<h4>{notice?.name || ""} </h4>
				</div>
				<div className={s.cardPrice}>
					{notice?.price
						? formattingPrice(notice?.price) + " грн"
						: t("Безкоштовно")}
				</div>
				<div className={s.cardLocationAndHeart}>
					<span>{notice?.settlement_name || ""}</span>
					<FavoriteHeartSelector
						isFavorite={notice?.isFavorite || 0}
						id={notice?.id || 0}
						userId={notice?.users_id || 0}
					/>
				</div>
			</div>
		</div>
	);
};

export default MobileVerticalCard;
