import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification } from "../../redux/notificationReducer";
import { StateType } from "../../redux/redux-store";
import styles from "./Notification.module.css";

const Notification: React.FC = () => {
	const dispatch = useDispatch();
	const { message, messageType } = useSelector(
		(state: StateType) => state.notificationData
	);

	useEffect(() => {
		if (message) {
			const timer = setTimeout(() => {
				dispatch(clearNotification());
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [message, dispatch]);

	if (!message) return null;

	const notificationClass = `${styles.notification} ${
		messageType === "success" ? styles.success : styles.error
	}`;

	return (
		<div
			className={notificationClass}
			onClick={() => dispatch(clearNotification())}
		>
			{message}
		</div>
	);
};

export default Notification;
