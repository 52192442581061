import React from "react";

interface Props {
	id: string;
}

const TopTitleBlockSvg = ({ id }: Props) => {
	switch (id) {
		case "arrowBack":
			return (
				<svg
					width="7"
					height="12"
					viewBox="0 0 7 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.69531 0.441406L1.0312 6.02061L5.75586 11.5684"
						stroke="#797979"
					/>
				</svg>
			);
		default:
			return <svg></svg>;
	}
};

export default TopTitleBlockSvg;
