import React from "react";
import styleFile from "../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import ArrowsAndCross from "../common/ArrowsAndCross/ArrowsAndCross";
import classes from "./ForgotPassPopup/ForgotPassPopup.module.css";
import redBago from "../images/LogoRedBago.jpg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneNotExist } from "../redux/user_Reducer";

type Props = {
	setClose?: (b: boolean) => void;
	handleToReg?: (b: boolean) => void;
};
const BedResultInfoPopup = ({ setClose, handleToReg }: Props) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const setNotExist = useSelector(() => setPhoneNotExist);
	return (
		<div className={styleFile.wrapper}>
			<div className={styleFile.container}>
				<div className={styleFile.topNavigate}>
					<span
						onClick={() => {
							setClose && setClose(false);
							dispatch(setNotExist(false));
						}}
					>
						<ArrowsAndCross id={"closeCross"} color={"#727272"} />
					</span>
				</div>
				<div className={`${styleFile.title} ${classes.title}`}>
					<img src={redBago} alt="bago" />
				</div>
				<div className={styleFile.info}>
					{t(
						"Данний номер не зареєстрований в системі Bago. Пропонуємо Вам зареєструватись"
					)}
				</div>
				<div className={styleFile.button}>
					<button
						onClick={(e) => {
							e.preventDefault();
							setClose && setClose(false);
							handleToReg && handleToReg(false);
							dispatch(setNotExist(false));
						}}
					>
						{t("Реєстрація")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default BedResultInfoPopup;
