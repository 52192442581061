import React from "react";
import { useTranslation } from "react-i18next";

const ExpandButton = ({
	onClick,
}: {
	isExpanded: boolean;
	onClick?: () => void;
	setExpanded: (b: boolean) => void;
}) => {
	const { t } = useTranslation();
	return (
		<button
			onClick={() => {
				onClick && onClick();
			}}
		>
			{t("Дивитися ще")}
			<svg
				style={{
					marginLeft: "10px",
					/*transform: isExpanded ? "rotate(180deg)" : "",*/
					transition: "0.5s",
				}}
				width="11"
				height="6"
				viewBox="0 0 11 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.778107 0.231231C1.08642 -0.0770772 1.58628 -0.0770771 1.89459 0.231231L5.54688 3.88352L9.19916 0.231232C9.50747 -0.0770771 10.0073 -0.077077 10.3156 0.231232C10.624 0.53954 10.624 1.03941 10.3156 1.34772L6.10512 5.55824C5.79681 5.86655 5.29694 5.86655 4.98863 5.55824L0.778106 1.34772C0.469798 1.03941 0.469798 0.53954 0.778107 0.231231Z"
					fill="#1754A6"
				/>
			</svg>
		</button>
	);
};

export default ExpandButton;
