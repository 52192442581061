import React, { useState } from "react";
import { useSelector } from "react-redux";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { AddListingCard } from "../../Components/Cards/AddListingCard/AddListingCard";
import { AddListingCardRow } from "../../Components/Cards/AddListingCard/AddListingCardRow";
import FavoriteSearchCardRow from "../../Components/Cards/FavoriteSearchCard/FavoriteSearchCardRow";
import ProductCardGrid from "../../Components/Cards/FavoriteSearchCard/ProductCardGrid";
import Paginator from "../../Components/Paginator/Paginator";
import PreLoaderComponent from "../../Components/PreLoaderComponent/PreLoaderComponent";
import FilterPrice from "../../Components/SearchFilters/FilterPrice";
import SearchLocationSelect from "../../Components/SearchResultComponents/SearchLocationSelect";
import SearchResultFiltersMain from "../../Components/SearchResultComponents/SearchResultFiltersMain";
import SearchResultSelect from "../../Components/SearchResultComponents/SearchResultSelect";
import SearchResultStateSelect from "../../Components/SearchResultComponents/SearchResultStateSelect";
import SearchResultSvgSelector from "../../Components/SearchResultComponents/SearchResultSvgSelector";
import Advertising from "../../Components/Сommercial/Advertising";
import { StateType } from "../../redux/redux-store";
import {
	CounterType,
	FiltersValue,
	publicOneNotice,
} from "../../types/publicNoticeTypes";
import { SearchCategory } from "../../types/searchTypes";
import s from "./SearchResult.module.css";
import { Desktop } from "../../responsive";

interface Props {
	category: boolean;
	handleClickBreadcrumbs: () => void;
	finalBreadcrumbs: any;
	breadcrumbsSearchingName: { name: string; alias: string };
	filtersValues: FiltersValue[];
	setFiltersValues: (filtersValues: FiltersValue[] | []) => void;
	setSort: (b: string) => void;
	setSettlement: (b: string) => void;
	filtersList: any;
	currentCategory: any;
	countersList: CounterType[];
	setSortByState: (b: string) => void;
	handleClickChoseCategory: (c: SearchCategory) => void;
	filteredNoticeList: publicOneNotice[];
	sliderPrice: number[] | number;
	categoriesSearch: SearchCategory[];
	categoryRange: {
		maxPrice: string;
		minPrice: string;
	};
	currentPage: number;
	setCurrentPage: (b: number) => void;
}

const SearchResultDesktop: React.FC<Props> = ({
	setSortByState,
	categoriesSearch,
	categoryRange,
	handleClickChoseCategory,
	filteredNoticeList,
	filtersList,
	currentCategory,
	countersList,
	handleClickBreadcrumbs,
	setSort,
	setSettlement,
	filtersValues,
	setFiltersValues,
	category,
	finalBreadcrumbs,
	currentPage,
	setCurrentPage,
	breadcrumbsSearchingName,
	sliderPrice,
}) => {
	const [grid3, setGrid3] = useState(true);
	const [grid4, setGrid4] = useState(false);
	const [row, setRow] = useState(false);
	const loader = useSelector(
		(state: StateType) => state.publicNotice.showLoader
	);
	const pagination = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const handleClick = (id: string) => {
		setGrid3(false);
		setGrid4(false);
		setRow(false);
		switch (id) {
			case "grid3":
				setGrid3(true);
				break;
			case "grid4":
				setGrid4(true);
				break;
			case "row":
				setRow(true);
		}
	};

	const isLastPage = pagination.currentPage === pagination.pageCount;

	return (
		<div className={s.wrapper}>
			<div className={`${s.searchResultContainer} __container`}>
				<div className={s.breadCrumbs}>
					<BreadCrumbs
						handleClick={handleClickBreadcrumbs}
						links={finalBreadcrumbs || [breadcrumbsSearchingName]}
					/>
				</div>
				<div className={s.contentContainer}>
					<div className={s.sideBar}>
						<div className={s.sideBarSelect}>
							<SearchLocationSelect setSettlement={setSettlement} />
						</div>
						{category &&
							Array.isArray(sliderPrice) &&
							sliderPrice.length > 0 && (
								<div className={`${s.filtersElement} ${s.filterPrice}`}>
									<FilterPrice
										setFiltersValues={setFiltersValues}
										filtersValues={filtersValues}
										sliderPrice={sliderPrice}
										categoryRange={categoryRange}
									/>
								</div>
							)}
						{category && filtersList && countersList && (
							<SearchResultFiltersMain
								setFiltersValues={setFiltersValues}
								filtersValues={filtersValues}
								filtersList={filtersList}
								currentCategory={currentCategory}
								countersList={countersList}
							/>
						)}
						{!category && categoriesSearch?.length > 0 && (
							<div className={s.sideBarMenu}>
								<ul className={s.sideBarMenuContent}>
									{loader ? (
										<PreLoaderComponent type={"circle"} size={"compact"} />
									) : (
										categoriesSearch.map((c: SearchCategory) => {
											return (
												<li
													onClick={() => handleClickChoseCategory(c)}
													className={s.contentItem}
													key={c.categoryId}
												>
													<span className={s.categoryName}>
														{c.categoryName}
													</span>
													<span className={s.counters}>{c.countItems}</span>
												</li>
											);
										})
									)}
								</ul>
							</div>
						)}
						<Desktop>
							<div className={s.sideBarCommercial}>
								<Advertising idAD={9} />
								<Advertising idAD={10} />
							</div>
						</Desktop>
					</div>
					<div className={s.result}>
						<div className={s.resultFilterPanel}>
							<div className={s.resultFilterPanelSelect}>
								<div className={s.panelSelectFilterAll}>
									<SearchResultSelect setSorting={setSort} />
									<span />
								</div>
								<div className={s.panelSelectFilterState}>
									<SearchResultStateSelect setSortByState={setSortByState} />
								</div>
							</div>

							<div className={s.resultFilterPanelViewButton}>
								{/*<button className={s.menuSVGButton}>
                                    <SearchResultSvgSelector id={"menuCircle"}/>
                                    <div className={s.hidden}>
                                        <div className={s.topRow}>
                                            <PlayPauseSwitch/>
                                            <span>Видалити оголошення</span>
                                        </div>
                                        <div className={s.bottomRow}>
                                            <SearchResultSvgSelector id={"reset"}/>
                                            <span>Скинути</span>
                                        </div>
                                    </div>
                                </button>*/}
								<button
									onClick={() => handleClick("grid3")}
									className={grid3 ? s.active : ""}
								>
									<SearchResultSvgSelector id={"gridButton"} />
								</button>
								<button
									onClick={() => handleClick("grid4")}
									className={grid4 ? s.active : ""}
								>
									<SearchResultSvgSelector id={"gridManuButton"} />
								</button>
								<button
									onClick={() => handleClick("row")}
									className={row ? s.active : ""}
								>
									<SearchResultSvgSelector id={"menu"} />
								</button>
							</div>
						</div>
						<div className={s.whiteFon}></div>
						{loader ? (
							<PreLoaderComponent type={"category"} size={"big"} />
						) : (
							<div
								className={`${s.resultCardsContainer} ${grid4 && s.grid4} ${
									row && s.row
								}`}
								style={{
									flexDirection: row ? "column" : "row",
									flexWrap: row ? "nowrap" : "wrap",
								}}
							>
								{grid3 && (
									<>
										{filteredNoticeList.length === 0 ? (
											<AddListingCard grid="3" />
										) : (
											<>
												{filteredNoticeList.map((notice: publicOneNotice) => (
													<div key={notice.id} className={s.grid3Container}>
														<ProductCardGrid grid={"3"} notice={notice} />
													</div>
												))}
												{isLastPage && <AddListingCard grid="3" />}
											</>
										)}
									</>
								)}

								{grid4 && (
									<>
										{filteredNoticeList.length === 0 ? (
											<AddListingCard grid="4" />
										) : (
											<>
												{filteredNoticeList.map((notice: publicOneNotice) => (
													<div key={notice.id} className={s.grid3Container}>
														<ProductCardGrid grid={"4"} notice={notice} />
													</div>
												))}
												{isLastPage && <AddListingCard grid="4" />}
											</>
										)}
									</>
								)}

								{row && (
									<>
										{filteredNoticeList.length === 0 ? (
											<AddListingCardRow
												isShowBorderTop={filteredNoticeList.length === 0}
											/>
										) : (
											<>
												{filteredNoticeList.map((notice: publicOneNotice) => (
													<div key={notice.id}>
														<FavoriteSearchCardRow notice={notice} />
													</div>
												))}
												{isLastPage && (
													<AddListingCardRow isShowBorderTop={false} />
												)}
											</>
										)}
									</>
								)}
								{/*  {row && <SaveSearchParamsCard/>}*/}
							</div>
						)}
						{/*{(currentSearchInfo?.city !== "" && currentSearchInfo?.city !== "Вся Україна") && (pagination.currentPage == pagination.pageCount) &&
                            <div className={s.resultOverRegionContainer}>
                                <div className={s.resultOverRegionLogo}>
                                    <LogoSvgSelector id={"logoForSearchParams"}/>
                                </div>
                                <div className={s.resultOverRegionText}>
                                    <span>Смотреть результаты поиска в других регионах</span>
                                </div>
                            </div>
                        }*/}
						{/* {pagination.totalCount > 24 &&
                            <div className={s.expandButtonWrapper}>
                                <ExpandModernButton onClickCallback={() => {
                                    // setPageCount((state) => state + 1)
                                    setCurrentPage((state) => state + 1)
                                }}
                                />
                            </div>} */}
						{/*Last Pagination for use*/}
						<div className={s.paginatorContainer}>
							{pagination.totalCount > 48 && (
								<Paginator
									page={currentPage == 0 ? 1 : currentPage}
									count={pagination?.pageCount || 1}
									setPage={setCurrentPage}
								/>
							)}
						</div>
						<Advertising idAD={11} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchResultDesktop;
