import React, { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type Props = {
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	setCaptcha: (b: string) => void;
};

const RecaptchaComponent = ({ setFieldValue, setCaptcha }: Props) => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available");
			return;
		}
		const token = await executeRecaptcha("yourAction");
		setFieldValue("captcha", token);
		setCaptcha(token);
		// Do whatever you want with the token
	}, [executeRecaptcha]);

	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	return <div></div>;
};

export default RecaptchaComponent;
