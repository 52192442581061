import React, { useEffect, useState } from "react";
import s from "./MyRecentlyWatched.module.css";
import style from "../My Favorite/MyFavorite.module.css";
import { useTranslation } from "react-i18next";
import PersonalCabinetTopSection from "../PersonalCabinetTopSection";
import PersonalCabinetCheckBoxPanelFavorite from "../My Favorite/PersonalCabinetCheckBoxPanelFavorite";
import { useDispatch, useSelector } from "react-redux";
import {
	getFilteredNoticeList,
	setCategoriesIdsList,
	setFilteredNoticesAllList,
} from "../../../redux/publicNotice_Reducer";
import { StateType } from "../../../redux/redux-store";
import ProductCardGrid from "../../Cards/FavoriteSearchCard/ProductCardGrid";
import FavoriteSearchCardRow from "../../Cards/FavoriteSearchCard/FavoriteSearchCardRow";
import Paginator from "../../Paginator/Paginator";
import { scrollToTop } from "../../../supporting";
import useCatIdsHandler from "../../../CustomHooks/useCatIdsHandler";

const MyRecentlyWatched = () => {
	const { t } = useTranslation();
	const [grid, setGrid] = useState(true);
	const [row, setRow] = useState(false);
	const dispatch: any = useDispatch();
	const getWatchedList = useSelector(() => getFilteredNoticeList);
	const [page, setPage] = useState(1);
	//const [perPage, setPerPage] = useState(36)
	const setCatIds = useSelector(() => setCategoriesIdsList);
	const viewedStat = useSelector(
		(state: StateType) => state.publicNotice.viewedStatistic
	);
	const catIdsForHook = useSelector(
		(state: StateType) => state.noticeData.viewedStatisticIds
	);
	const favoriteMeta = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const watchedList = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);
	const watchedAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const elements = [
		{
			link: "",
			name: t("Переглянутий товар"),
			elementData: favoriteMeta?.totalCount?.toString() || "",
			status: 30,
		},
	];
	useEffect(() => {
		dispatch(
			getWatchedList({
				onlyViewed: "1",
				perPage: `${48}`,
				page: page.toString(),
				catIds: catIds,
			})
		);
		scrollToTop();
	}, [page, catIds, favoriteMeta.perPage]);

	useEffect(() => {
		if (watchedList && watchedList.length) {
			if (page == 0 || page == 1) {
				dispatch(setFilteredNoticesAllList(watchedList));
			} else {
				const newArr = [...watchedAllList, ...watchedList];
				const arrayLength = newArr.length;
				const limit = 150;
				if (arrayLength > limit) {
					const difference = arrayLength - limit;
					const slicedArr = newArr.slice(difference); // Зберігаємо результат виклику slice
					dispatch(setFilteredNoticesAllList(slicedArr));
				} else {
					dispatch(setFilteredNoticesAllList(newArr));
				}
			}
		}
	}, [watchedList]);

	return (
		<div className={s.recentlyWatchedContainer}>
			<PersonalCabinetTopSection
				title={t("Переглянуті товари та оголошення")}
				headers={elements}
				setCatIds={setCatIds}
			/>
			<PersonalCabinetCheckBoxPanelFavorite
				row={row}
				categories={viewedStat}
				grid={grid}
				catIds={catIds}
				handleCatIds={handleCatIds}
				setRow={setRow}
				type={"recentlyWatched"}
				setGrid={setGrid}
			/>

			<div
				className={style.cabinetContent_CardTemplate}
				style={{ flexDirection: row ? "column" : "row" }}
			>
				{/* <div className={style.cabinetContent_CardTemplateFon}/>*/}
				{watchedList.map((i: any) => (
					<div className={style.cardItem} key={i.id}>
						{grid ? (
							<div className={style.border}>
								<ProductCardGrid notice={i} grid={"5"} hot={true} />
							</div>
						) : (
							<div className={style.borderRow}>
								<FavoriteSearchCardRow notice={i} />
							</div>
						)}
					</div>
				))}
				<div className={style.paginatorContainer}>
					{/* {favoriteMeta.pageCount > favoriteMeta.currentPage &&
                        <ExpandModernButton onClickCallback={()=>{
                            setPage((prevState)=>prevState + 1)
                        }}/> } */}
					{favoriteMeta.totalCount > 20 && (
						<Paginator
							page={page}
							setPage={setPage}
							count={favoriteMeta.pageCount}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyRecentlyWatched;
