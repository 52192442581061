import React from "react";

interface Props {
	id: string;
	color?: string;
}

function CabinetAccordionSvgSelector({ id, color = "#797979" }: Props) {
	switch (id) {
		case "expandIcon":
			return (
				<svg
					width="14"
					height="7"
					viewBox="0 0 14 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M1 1L7 6L13 1" stroke={color} />
				</svg>
			);

		case "checkPhoto":
			return (
				<svg
					width="130"
					height="130"
					viewBox="0 0 130 130"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="0.5"
						y="0.5"
						width="129"
						height="129"
						rx="9.5"
						fill="#FFF3C9"
						stroke="#FFD74A"
					/>
					<path
						d="M64.3333 52.6667C67.2052 52.6667 69.5333 50.4281 69.5333 47.6667C69.5333 44.9052 67.2052 42.6667 64.3333 42.6667C61.4615 42.6667 59.1333 44.9052 59.1333 47.6667C59.1333 50.4281 61.4615 52.6667 64.3333 52.6667Z"
						fill="white"
					/>
					<path
						d="M78.2 34.3333H75.8169C73.8296 34.3333 71.9288 33.5204 70.556 32.0833C70.233 31.7423 69.8395 31.4698 69.4008 31.2833C68.9621 31.0968 68.4877 31.0003 68.008 31H60.6587C59.688 31 58.752 31.4 58.0933 32.0833C56.7323 33.5197 54.8405 34.3333 52.8617 34.3333H50.4667C48.56 34.3333 47 35.8333 47 37.6667V57.6667C47 59.5 48.56 61 50.4667 61H78.2C80.1067 61 81.6667 59.5 81.6667 57.6667V37.6667C81.6667 35.8333 80.1067 34.3333 78.2 34.3333ZM64.3333 56C59.5493 56 55.6667 52.2667 55.6667 47.6667C55.6667 43.0667 59.5493 39.3333 64.3333 39.3333C69.1173 39.3333 73 43.0667 73 47.6667C73 52.2667 69.1173 56 64.3333 56Z"
						fill="white"
					/>
				</svg>
			);

		case "checkedSeller":
			return (
				<svg
					width="18"
					height="22"
					viewBox="0 0 18 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.8993 8.29106C11.7573 8.14912 11.5595 8.06771 11.3564 8.06771C11.1505 8.06771 10.9578 8.14697 10.8136 8.29106L8.12568 10.9799L7.19747 10.0512C7.05252 9.90666 6.8599 9.82703 6.65518 9.82703C6.45027 9.82703 6.25753 9.90678 6.11239 10.0518C5.96737 10.197 5.88746 10.3899 5.88746 10.595C5.88746 10.8003 5.96743 10.9932 6.11233 11.1381L7.58333 12.6097C7.72712 12.7533 7.91974 12.8323 8.12556 12.8323C8.33151 12.8323 8.52413 12.7532 8.6686 12.6092L11.8994 9.37732C12.1987 9.07797 12.1987 8.59078 11.8993 8.29106Z"
						fill="#31AA52"
					/>
					<path
						d="M17.9634 4.53402C17.9513 4.39411 17.8666 4.27086 17.7403 4.20948L9.17465 0.0402763C9.0644 -0.0134254 8.93554 -0.0134254 8.82528 0.0402763L0.259651 4.20941C0.133428 4.27086 0.0486656 4.39405 0.0365654 4.53396C0.0256323 4.65967 -0.219995 7.6553 0.726275 11.3141C1.28368 13.4693 2.142 15.4028 3.27732 17.0607C4.70127 19.14 6.56476 20.7868 8.81619 21.9551C8.87381 21.9851 8.93695 22 9.00003 22C9.06311 22 9.12625 21.9851 9.18387 21.9551C11.4352 20.7868 13.2988 19.1401 14.7228 17.0607C15.8581 15.4028 16.7164 13.4693 17.2738 11.3141C18.22 7.6553 17.9744 4.65974 17.9634 4.53402ZM8.99997 15.4655C6.19985 15.4655 3.92183 13.1866 3.92183 10.3855C3.92183 7.58445 6.19985 5.30563 8.99997 5.30563C11.8001 5.30563 14.0781 7.58445 14.0781 10.3855C14.0781 13.1866 11.8001 15.4655 8.99997 15.4655Z"
						fill="#31AA52"
					/>
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default CabinetAccordionSvgSelector;
