import React from "react";
import s from "./ControlPanelMobile.module.css";
import ControlPanelSvgSelector from "./ControlPanelSvgSelector";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

const ControlPanelMobile = () => {
	const location = useLocation();
	const lastSegment = location.pathname.substring(
		location.pathname.lastIndexOf("/") + 1
	);

	return (
		<div>
			<ul className={s.controlPanelButtons}>
				<li className={s.controlPanelButton}>
					<Link to={"/"}>
						<button>
							<ControlPanelSvgSelector id={"edit"} />
						</button>
						<span>Редагувати</span>
					</Link>
				</li>
				{lastSegment === "active" && (
					<>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"deactivate"} />
								</button>
								<span>Деактивувати</span>
							</Link>
						</li>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"pitchUp"} />
								</button>
								<span>Підняти за 10грн</span>
							</Link>
						</li>
					</>
				)}
				{lastSegment === "rejected" && (
					<>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"toAdvertise"} />
								</button>
								<span>Рекламувати</span>
							</Link>
						</li>

						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"delete"} />
								</button>
								<span>Видалити</span>
							</Link>
						</li>
					</>
				)}
				{lastSegment === "under_review" && (
					<>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"deactivate"} />
								</button>
								<span>Деактивувати</span>
							</Link>
						</li>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"toAdvertise"} />
								</button>
								<span>Рекламувати</span>
							</Link>
						</li>
					</>
				)}
				{lastSegment === "no_active" && (
					<>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"activate"} />
								</button>
								<span>Активувати</span>
							</Link>
						</li>
						<li className={s.controlPanelButton}>
							<Link to={"/"}>
								<button>
									<ControlPanelSvgSelector id={"delete"} />
								</button>
								<span>Видалити</span>
							</Link>
						</li>
					</>
				)}
			</ul>
		</div>
	);
};

export default ControlPanelMobile;
