import React, { forwardRef, ReactNode, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import ModalAutoRenewal from "../ModalWindows/ModalAutoRenewal/ModalAutoRenewal";
import { Backdrop } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ModalPitchToTop from "../ModalWindows/ModalPitchToTop/ModalPitchToTop";
import MyAdsCard from "./MyAdsCard/MyAdsCard";
import { NoticeDataForModal, NoticeType } from "../../../types/noticeTypes";
import { useDispatch, useSelector } from "react-redux";
import { setCreatedSuccessFalse } from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";

const useStyles = makeStyles(() =>
	createStyles({
		modalRenewal: {
			display: "flex",
			marginTop: "278px",
			justifyContent: "center",
			height: "404px",
		},
		modalPitchToTop: {
			display: "flex",
			marginTop: "278px",
			justifyContent: "center",
			height: "374px",
		},
	})
);

interface MyComponentProps {
	children: ReactNode;
}

const MyModal = forwardRef<HTMLDivElement, MyComponentProps>((props, ref) => {
	return <div ref={ref}>{props.children}</div>;
});

const MyAds = ({ type, notice }: { type: number; notice: NoticeType }) => {
	const [autoRenewalSettings, setOpenAutoRenewalSettings] = useState(false);
	const [pitchToTopSettings, setPitchToTopSettings] = useState(false);
	const dispatch: any = useDispatch();

	const successFalse = useSelector(() => setCreatedSuccessFalse);
	const classes = useStyles();
	const autoRenewalData = useSelector(
		(state: StateType) => state.priceData.autoRenewal
	);
	const topData = useSelector((state: StateType) => state.priceData.top);
	const balanceTotal = useSelector(
		(state: StateType) => state.userData.userStatic.totalBalance
	);

	const noticeDataModal: NoticeDataForModal = {
		name: notice.name,
		price: notice.price,
		img: notice?.noticeImages[0]?.imageUrl || "",
		settlement: notice.short_settlement_name,
		balance: balanceTotal,
		id: notice.id ? notice.id.toString() : "",
	};
	const handleCloseRenewal = () => {
		setOpenAutoRenewalSettings(false);
	};
	const handleClosePitchToTop = () => {
		setPitchToTopSettings(false);
	};
	const handleOpenAutoRenewal = () => {
		setOpenAutoRenewalSettings(true);
	};
	useEffect(() => {
		dispatch(successFalse());
	}, []);

	if (notice) {
		return (
			<>
				{autoRenewalData && (
					<MyModal>
						<div>
							<Modal
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								className={classes.modalRenewal}
								open={autoRenewalSettings}
								onClose={handleCloseRenewal}
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{
									timeout: 500,
								}}
							>
								<ModalAutoRenewal
									noticeData={noticeDataModal}
									data={autoRenewalData}
									handleClose={handleCloseRenewal}
								/>
							</Modal>
						</div>
					</MyModal>
				)}
				<MyModal>
					<div>
						<Modal
							className={classes.modalPitchToTop}
							open={pitchToTopSettings}
							onClose={handleClosePitchToTop}
							closeAfterTransition
							BackdropComponent={Backdrop}
							BackdropProps={{
								timeout: 500,
							}}
						>
							<ModalPitchToTop
								noticeData={noticeDataModal}
								data={topData}
								handleClose={handleClosePitchToTop}
							/>
						</Modal>
					</div>
				</MyModal>
				<MyAdsCard
					type={type}
					notice={notice}
					setOpenAutoRenewalSettings={handleOpenAutoRenewal}
					setPitchToTopSettings={setPitchToTopSettings}
				/>
			</>
		);
	}
	return <></>;
};

export default MyAds;
