import React from "react";
import s from "./NoOrdersCardMobile.module.css";
import NoOrdersCardBadge from "./NoOrdersCardBadge";
import { Link } from "react-router-dom";

const NoOrdersCardMobile = () => {
	return (
		<div className={s.noOrdersCardContainer}>
			<h4>У Вас поки що нема Замовлень</h4>
			<ul className={s.menu}>
				<li className={s.menuItem}>
					<div>Підвищте довіру Покупців:</div>
				</li>

				<li className={s.menuItem}>
					<NoOrdersCardBadge num={"1"} />
					<div>
						Пройдіть верифікацію та станьте
						<Link to={"/"}>
							<div className={s.verifiedSeller}>
								<svg
									width="18"
									height="22"
									viewBox="0 0 18 22"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.8993 8.29106C11.7573 8.14912 11.5595 8.06771 11.3564 8.06771C11.1505 8.06771 10.9578 8.14697 10.8136 8.29106L8.12568 10.9799L7.19747 10.0512C7.05252 9.90666 6.8599 9.82703 6.65518 9.82703C6.45027 9.82703 6.25753 9.90678 6.11239 10.0518C5.96737 10.197 5.88746 10.3899 5.88746 10.595C5.88746 10.8003 5.96743 10.9932 6.11233 11.1381L7.58333 12.6097C7.72712 12.7533 7.91974 12.8323 8.12556 12.8323C8.33151 12.8323 8.52413 12.7532 8.6686 12.6092L11.8994 9.37732C12.1987 9.07797 12.1987 8.59078 11.8993 8.29106Z"
										fill="#31AA52"
									/>
									<path
										d="M17.9634 4.53402C17.9513 4.39411 17.8666 4.27086 17.7403 4.20948L9.17465 0.0402763C9.0644 -0.0134254 8.93554 -0.0134254 8.82528 0.0402763L0.259651 4.20941C0.133428 4.27086 0.0486656 4.39405 0.0365654 4.53396C0.0256323 4.65967 -0.219995 7.6553 0.726275 11.3141C1.28368 13.4693 2.142 15.4028 3.27732 17.0607C4.70127 19.14 6.56476 20.7868 8.81619 21.9551C8.87381 21.9851 8.93695 22 9.00003 22C9.06311 22 9.12625 21.9851 9.18387 21.9551C11.4352 20.7868 13.2988 19.1401 14.7228 17.0607C15.8581 15.4028 16.7164 13.4693 17.2738 11.3141C18.22 7.6553 17.9744 4.65974 17.9634 4.53402ZM8.99997 15.4655C6.19985 15.4655 3.92183 13.1866 3.92183 10.3855C3.92183 7.58445 6.19985 5.30563 8.99997 5.30563C11.8001 5.30563 14.0781 7.58445 14.0781 10.3855C14.0781 13.1866 11.8001 15.4655 8.99997 15.4655Z"
										fill="#31AA52"
									/>
								</svg>
								<span>Перевірений продавець</span>
							</div>
						</Link>
					</div>
				</li>
				<li className={s.menuItem}>
					<NoOrdersCardBadge num={"2"} />
					<div>
						Збільште{" "}
						<Link to={"/"}>
							<b>Страхувальну суму</b>
						</Link>
					</div>
				</li>
				<li className={s.menuItem}>
					<NoOrdersCardBadge num={"3"} />
					<div>
						<Link to={"/"}>
							<b>Перенесіть репутацію</b>
						</Link>
						з іншого маркетплейсу.
					</div>
				</li>
				<li className={s.menuItem}>
					<NoOrdersCardBadge num={"4"} />
					<div>
						Підвищуйте свій рейтинг
						<Link to={"/"}>
							<b> здійснюйте більше покупок на bago</b>
						</Link>
					</div>
				</li>
				<li className={s.menuItem}>
					<NoOrdersCardBadge num={"5"} />
					<div>
						Використовуйте послугу
						<Link to={"/"}>
							<b> Замовлення з BAGO - </b>
							це зробить угоди для ваших покупців безпечними.
						</Link>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default NoOrdersCardMobile;
