import { useState } from "react";
import CheckboxForRubricsSelect from "../../../../../Components/PersonalCabinet/CabinetRubricsSelectMobile/CheckboxForRubricsSelect";
import PersonalCabinetSvgSelector from "../../../../../Components/PersonalCabinet/PersonalCabinetSvgSelector";
import { StatByCategory } from "../../../../../types/noticeTypes";
import s from "./SelectRubrics.module.css";

type PropsTypes = {
	setPage?: (b: number) => void;
	status?: number;
	setStatus?: (b: number) => void;
	catIds: number[];
	handleCatIds: (id: number) => void;
	categories: any;
};

export const SelectRubrics = ({
	setPage,
	status,
	setStatus,
	catIds,
	handleCatIds,
	categories,
}: PropsTypes) => {
	const [openOptions, setOpenOptions] = useState(false);

	return (
		<div className={s.selectContainer}>
			<div
				className={s.selectInput}
				style={{ borderRadius: openOptions ? "5px 5px 0 0" : "5px" }}
				onClick={() => setOpenOptions(!openOptions)}
			>
				<span>Всі рубрики</span>
				<span>
					{openOptions ? (
						<PersonalCabinetSvgSelector id={"selectArrow"} />
					) : (
						<PersonalCabinetSvgSelector id={"selectArrowRotate"} />
					)}
				</span>
			</div>
			{openOptions && (
				<div className={s.hiddenOptions}>
					<ul className={s.selectMenu}>
						<li className={s.selectMenuItem}>
							<CheckboxForRubricsSelect
								labelTxt={"Всі"}
								id={"0"}
								onChange={handleCatIds}
								checkedIds={catIds}
							/>
						</li>
						{categories?.map((c: StatByCategory) => (
							<li key={c.id} className={s.selectMenuItem}>
								<CheckboxForRubricsSelect
									labelTxt={c?.name || c?.name_ru}
									id={c.id.toString()}
									onChange={handleCatIds}
									checkedIds={catIds}
								/>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
