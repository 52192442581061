import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import s from "../../pages/SearchResults/SearchResult.module.css";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useTranslation } from "react-i18next";

const SearchResultSelect = ({
	setSorting,
}: {
	setSorting: (b: string) => void;
}) => {
	const { i18n, t } = useTranslation();
	const [value, setValue] = useState([
		{
			value: "1",
			label: t("Нові"),
		},
	]);
	const filterOptions = [
		{
			label: t("Нові"),
			value: "1",
			icon: "",
		},
		{
			label: t("За популярністю"),
			value: "4",
		},
		{
			label: t("Спочатку дешеві"),
			value: "2",
		},
		{
			label: t("Спочатку дорогі"),
			value: "3",
		},
	];
	const SingleValue = ({ children, ...props }: any) => (
		<components.SingleValue {...props}>
			<div className={"selectText"}>{children}</div>
		</components.SingleValue>
	);
	const Control = ({ children, ...props }: any) => (
		<components.Control {...props}>
			<div className={"controlIcon"}>
				<svg
					width="18"
					height="14"
					viewBox="0 0 18 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3.5 12.6094C3.5 12.8855 3.72386 13.1094 4 13.1094C4.27614 13.1094 4.5 12.8855 4.5 12.6094H3.5ZM4.35355 0.64789C4.15829 0.452628 3.84171 0.452628 3.64645 0.64789L0.464466 3.82987C0.269204 4.02513 0.269204 4.34172 0.464466 4.53698C0.659728 4.73224 0.976311 4.73224 1.17157 4.53698L4 1.70855L6.82843 4.53698C7.02369 4.73224 7.34027 4.73224 7.53553 4.53698C7.7308 4.34172 7.7308 4.02513 7.53553 3.82987L4.35355 0.64789ZM4.5 12.6094L4.5 1.00144H3.5L3.5 12.6094H4.5Z"
						fill="#383748"
					/>
					<path
						d="M13.248 1.39453C13.248 1.11839 13.4719 0.894531 13.748 0.894531C14.0242 0.894531 14.248 1.11839 14.248 1.39453L13.248 1.39453ZM14.1016 13.356C13.9063 13.5513 13.5898 13.5513 13.3945 13.356L10.2125 10.174C10.0173 9.97877 10.0173 9.66219 10.2125 9.46693C10.4078 9.27167 10.7244 9.27167 10.9196 9.46693L13.748 12.2954L16.5765 9.46693C16.7717 9.27167 17.0883 9.27167 17.2836 9.46693C17.4788 9.66219 17.4788 9.97877 17.2836 10.174L14.1016 13.356ZM14.248 1.39453L14.248 13.0025H13.248L13.248 1.39453L14.248 1.39453Z"
						fill="#383748"
					/>
				</svg>
			</div>

			{children}
		</components.Control>
	);
	useEffect(() => {
		setValue([
			{
				value: "1",
				label: t("Нові"),
			},
		]);
	}, [i18n.language]);
	return (
		<Select
			isSearchable={false}
			components={{
				Control,
				SingleValue,
				IndicatorSeparator: () => null,
				DropdownIndicator: ({ selectProps }) => (
					<span
						className={s.dropdownArrow}
						style={{
							transform: selectProps.menuIsOpen ? "rotate(180deg)" : "",
							marginTop: selectProps.menuIsOpen ? "5px" : "",
							transition: "400ms",
						}}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				),
			}}
			options={filterOptions}
			value={value}
			onChange={(newValue) => {
				setValue([
					{ value: newValue?.value || "", label: newValue?.label || "" },
				]);
				setSorting(newValue?.value || "1");
			}}
			styles={{
				control: (baseStyles) => ({
					...baseStyles,
					maxWidth: "180px",
					padding: "0 15px 0 0 ",
					borderColor: "white",
					boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.09)",
					borderRadius: "8px",
					cursor: "pointer",
					"& .controlIcon": {
						paddingLeft: "10px",
					},
					"&:hover": {
						backgroundColor: "white",
						borderColor: "white",
					},
				}),
				option: (styles, { isFocused }) => {
					return {
						...styles,
						width: "160px",
						color: isFocused ? "black" : "#939393",
						background: isFocused ? "none" : "none",
						fontFamily: "var(--myMainFontRegular)",
						fontSize: "14px",
						marginLeft: "4px",
						cursor: "pointer",
					};
				},
				menu: () => ({
					position: "absolute",
					top: "45px",
					border: "1px solid white",
					zIndex: "99",
					backgroundColor: "white",
					width: "100%",
					boxShadow: " 0px 4px 30px rgba(0, 0, 0, 0.06)",
					borderRadius: "5px",
				}),
				menuList: (base) => ({
					...base,

					boxShadow: " 0px 4px 30px rgba(0, 0, 0, 0.06)",
					borderRadius: "5px",
					border: "1px solid white",
				}),
				/*dropdownIndicator:(styles,state)=>({
                ...styles,
                transform:state.selectProps.menuIsOpen ?"": "rotate(180deg)",
            }),*/
				singleValue: (styles) => {
					return {
						...styles,
						display: "flex",
						alignItems: "center",
						fontFamily: "var(--myMainFontMedium)",
						fontSize: "14px",
						lineHeight: "20px",
						"& .selectText": {
							maxWidth: "450px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						},
						"& svg": {
							marginRight: "15px",
						},
					};
				},
			}}
		/>
	);
};

export default SearchResultSelect;
