import React from "react";
import s from "./ProductCard.module.css";
import productImage from "../../../images/cardItemHorizontal.jpg";
import { Desktop, Mobile } from "../../../responsive";
import { formattingPrice } from "../../../supporting";
import { RecentlyWatchedNotice } from "../../../types/userTypes";
import { useTranslation } from "react-i18next";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";

const ProductCardHorizontal = ({
	notice,
}: {
	notice?: RecentlyWatchedNotice;
}) => {
	const noticeTree = notice?.tree?.map((s: string) => s);
	const noticeUrl = noticeTree?.join("/");
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const navigate = useNavigate();
	const userId = useSelector((state: StateType) => state.userData.user.id);
	const navigateOnClick = () => {
		//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
		navigate(
			`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
		);
	};

	return (
		<div className={s.horizontal}>
			<div className={s.leftHorizontalBlock}>
				<div className={s.cardImage} onClick={navigateOnClick}>
					{notice?.imagePath ? (
						<img
							loading={"lazy"}
							src={notice?.imagePath}
							alt={notice?.slug || "image"}
						/>
					) : (
						<img loading={"lazy"} src={productImage} alt="product name" />
					)}
				</div>
			</div>
			<div className={s.rightHorizontalBlock}>
				<div className={s.rightHorizontalBlockTop} onClick={navigateOnClick}>
					<p>{notice?.name || ""}</p>
				</div>
				<Desktop>
					<div className={s.rightHorizontalBlockBottom}>
						<p>
							<b>
								{notice?.price
									? formattingPrice(notice?.price) + " грн"
									: t("Безкоштовно")}
							</b>
							{userId && notice?.ownerId !== userId.toString() && (
								<FavoriteHeartSelector
									id={notice?.notice_id || 0}
									isFavorite={notice?.isFavorite || 0}
								/>
							)}
						</p>
					</div>
				</Desktop>
				<Mobile>
					<div className={s.price} onClick={navigateOnClick}>
						<span>{formattingPrice(notice?.price || "")} грн</span>
					</div>
					<div className={s.rightHorizontalBlockBottom}>
						<div className={s.location} onClick={navigateOnClick}>
							{notice?.settlement_name || ""}
						</div>
						<div>
							<FavoriteHeartSelector
								id={notice?.notice_id || 0}
								isFavorite={notice?.isFavorite || 0}
							/>
						</div>
					</div>
				</Mobile>
			</div>
		</div>
	);
};

export default ProductCardHorizontal;
