import React, { useState } from "react";
import CabinetAccordionSvgSelector from "../PersonalCabinet/PersonalCabinetAccordion/CabinetAccordionSvgSelector";
import s from "./MyImageInput.module.css";
import { useTranslation } from "react-i18next";

type Props = {
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	value: string;
	photo?: string | null;
	id: string;
	blueContainer: boolean;
	changedPhoto?: boolean;
	setChangedPhoto?: (b: boolean) => void;
};

const MyImageInput = ({
	setFieldValue,
	value,
	photo,
	id,
	blueContainer,
	changedPhoto,
	setChangedPhoto,
}: Props) => {
	const [previewImage, setPreviewImage] = useState(null);
	const { t } = useTranslation();
	const handleImageClick = () => {
		const fileInput: any = document.getElementById("fileInput");
		fileInput.click();
	};
	const handleFileChange = (event: any) => {
		const selectedFile = event.target.files[0];
		setFieldValue(value, selectedFile);
		if (selectedFile) {
			const reader: any = new FileReader();
			reader.onloadend = () => {
				setPreviewImage(reader.result);
			};
			reader.readAsDataURL(selectedFile);
		} else {
			setPreviewImage(null);
		}
	};

	if (previewImage || photo) {
		return (
			<div
				className={`${s.wrapper} ${blueContainer && s.blueDragZone}`}
				onDrop={handleFileChange}
			>
				<div className={s.imageContainer}>
					<img
						className={s.image}
						src={previewImage ? previewImage : photo ? photo : ""}
						alt={"user image"}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "contain",
							cursor: "pointer",
						}}
					/>
					<input
						onClick={() => {
							if (setChangedPhoto) setChangedPhoto(true);
						}}
						type="file"
						accept={"image/*"}
						id={id}
						onChange={handleFileChange}
					/>
				</div>
				{blueContainer && (
					<div className={s.checkDocImgContainer_Text}>
						{t("Перетягніть фотографію сюди")}.
					</div>
				)}
			</div>
		);
	} else
		return (
			<div
				className={`${s.wrapper} ${blueContainer && s.blueDragZone}`}
				onDrop={handleFileChange}
			>
				<div className={s.imageContainer}>
					<span className={s.image} onClick={handleImageClick}>
						<CabinetAccordionSvgSelector id={"checkPhoto"} />
						<div className={s.svgDescription}>{t("Додати фото")}</div>
					</span>
					<input type="file" id={id} onChange={handleFileChange} />
				</div>
				{blueContainer && (
					<div className={s.checkDocImgContainer_Text}>
						{t("Перетягніть фотографії сюди.")}
					</div>
				)}
			</div>
		);
};

export default MyImageInput;
