import React from "react";
import s from "./PopularSectionOfBoard.module.css";
import style from "../../GlobalStyles.module.css";
import { Link } from "react-router-dom";
import { Desktop, Mobile } from "../../responsive";
import CategoryHomeMobile from "./CategoryHomeMobile";
import { useTranslation } from "react-i18next";
import { OneCategoryType } from "../../types/reduxStoreTypes";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import PreLoaderComponent from "../PreLoaderComponent/PreLoaderComponent";
import { setSomeCategoryList } from "../../redux/category_Reducer";
import { setSearchingNoticeName } from "../../redux/search_Reducer";
import i18n from "i18next";
type Props = {
	categories: [];
};
const PopularSectionOfBoard = ({ categories }: Props) => {
	const { t } = useTranslation();
	const loader = useSelector((state: StateType) => state.pagesData.showLoader);
	const isUserAdult = useSelector(
		(state: StateType) => state.userData.isUserAdult
	);
	const setList = useSelector(() => setSomeCategoryList);
	const dispatch = useDispatch();
	const setSearchName = useSelector(() => setSearchingNoticeName);

	return (
		<>
			<div className={s.popularSectionWrapper}>
				<Desktop>
					<div className={`${s.popularSectionContainer} __container`}>
						<Desktop>
							{/*<h1>{!loader && t("Популярнi рубрики")}</h1>*/}
							<h1>{!loader && t("Безкоштовна дошка оголошень")}</h1>
						</Desktop>
						<div className={s.boardWrapper}>
							<div className={s.boardContainer}>
								{loader ? (
									<div className={s.boardContainerRow}>
										<PreLoaderComponent type={"category"} size={"medium"} />
									</div>
								) : (
									<div className={s.boardContainerRow}>
										{categories
											?.filter((item: OneCategoryType) => item.plus_18 === 0)
											.map((item: OneCategoryType) => {
												return (
													<div key={item.id} className={s.catalogItem}>
														<Link
															to={
																item.filter_link === null
																	? `c/${item.alias}`
																	: ""
															}
															onClick={() => {
																if (item.filter_link !== null) {
																	window.open(item.filter_link, "_blank");
																} else {
																	dispatch(setSearchName(""));
																	dispatch(setList([]));
																}
															}}
														>
															<div className={s.imageContainer}>
																<div className={s.imageContainerFon} />
																<img
																	src={item?.icon || ""}
																	alt={`${item?.alias}_bago`}
																	className={
																		item.plus_18 === 1 && !isUserAdult
																			? style.blurEffect
																			: ""
																	}
																/>
															</div>
															<p>{item?.name || ""}</p>
														</Link>
													</div>
												);
											})}
									</div>
								)}
							</div>
						</div>
					</div>
				</Desktop>
				<Mobile>
					<CategoryHomeMobile categories={categories} />
				</Mobile>
			</div>
		</>
	);
};

export default PopularSectionOfBoard;
