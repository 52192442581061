import React, { useState } from "react";
import s from "./FilterComponent.module.css";
import { NoticeFilterType } from "../../types/noticeTypes";
import useFilterHandle from "../../CustomHooks/useFilterHandle";

type Props = {
	filter: NoticeFilterType | any;
	filtersOneIs: [];
};

const FilterTypeInput = ({ filter, filtersOneIs }: Props) => {
	const defaultValue: any = filtersOneIs.find(
		(e: { id: string; name: string; filter: string; value: string }) =>
			e.filter === filter.inputName
	);
	const { handleFilter } = useFilterHandle();
	const [value, setValue] = useState(defaultValue?.id || "");

	const handleInput = (value: string) => {
		if (filter.type === 3) {
			const sanitizedValue = value.replace(/[^\d]/g, "");
			setValue(sanitizedValue);
			handleFilter({
				element: { name: "", name_ru: "", id: sanitizedValue },
				filter,
			});
		} else {
			setValue(value);
			handleFilter({ element: { name: "", name_ru: "", id: value }, filter });
		}
	};
	return (
		<div className={`${s.container} ${s.inputCreateAd}`}>
			<div className={s.name}>
				{!filter?.needs ? (
					filter?.propertyName
				) : (
					<p>
						{filter?.propertyName} <span style={{ color: "red" }}>*</span>
					</p>
				)}
			</div>
			<input
				value={value}
				onChange={(event) => {
					handleInput(event.currentTarget.value);
				}}
			/>
		</div>
	);
};

export default FilterTypeInput;
