import React from "react";
import s from "./LocationDeliveryInfoForHiddenCardMobile.module.css";

const LocationDeliveryInfoForHiddenCardMobile = ({
	type,
}: {
	type: string;
}) => {
	return (
		<div className={s.cardLocationDeliveryInfo}>
			<div className={s.bottomHiddenLocation}>Харьков, Хар. обл.</div>
			<div className={s.postOffice}>
				Новая почта, №38.
				<br />
				<span>Просп Александровский 166</span>
			</div>
			{type === "insurance_amount" && (
				<div
					className={s.arrivalDate}
					style={{
						borderBottom: "1px solid #9D9D9D",
						paddingBottom: "20px",
					}}
				>
					Дата угоди: <span>25мар2023</span>
				</div>
			)}
			{type === "sent" && (
				<div className={s.bottomHiddenDelivery}>
					<div className={s.arrivalDate}>
						Дата прибуття: <span>25мар2023</span>
					</div>
					<div className={s.deliveryNumber}>
						ТТH: <span>UUFLS65665FDKAD5</span>
					</div>
				</div>
			)}
			{type === "closed" && (
				<div className={s.bottomHiddenDelivery}>
					<div className={s.arrivalDate}>
						Дата угоди: <span>25мар2023</span>
					</div>
					<div className={s.deliveryNumber}>
						ТТH: <span>UUFLS65665FDKAD5</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default LocationDeliveryInfoForHiddenCardMobile;
