import { useTranslation } from "react-i18next";
import SearchResultSvgSelector from "../../../../../Components/SearchResultComponents/SearchResultSvgSelector";
import s from "./FilterPanel.module.css";

export const FilterPanel = ({
	row,
	sorting,
	setSeeSorting,
	setRow,
	menuRef,
	setSorting,
	handleClickFilterButton,
}: {
	setRow: (b: boolean) => void;
	setSeeSorting: (b: boolean) => void;
	row: boolean;
	sorting: boolean;
	setSorting?: (b: string) => void;
	menuRef?: any;
	handleClickFilterButton?: () => void;
}) => {
	const { t } = useTranslation();
	const filterOptions = [
		{
			label: t("Нові"),
			value: "1",
			icon: "",
		},
		{
			label: t("За популярністю"),
			value: "4",
		},
		{
			label: t("Спочатку дешеві"),
			value: "2",
		},
		{
			label: t("Спочатку дорогі"),
			value: "3",
		},
	];

	return (
		<div className={s.container}>
			<div className={s.sortingArrow}>
				<span
					onClick={(event) => {
						event.stopPropagation();
						setSeeSorting(!sorting);
					}}
				>
					<SearchResultSvgSelector id={"sortingArrow"} />
				</span>
				{sorting && (
					<div className={s.sortingPopupContainer}>
						<ul className={s.popupMenu} ref={menuRef}>
							{filterOptions.map((item) => (
								<li
									key={item.value}
									onClick={() => {
										setSeeSorting(false);
										setSorting?.(item?.value || "1");
									}}
									className={s.popupMenuElement}
								>
									{item.label}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
			<div className={s.groupedRect}>
				{row ? (
					<span onClick={() => setRow(!row)}>
						<SearchResultSvgSelector id={"rowLine"} />
					</span>
				) : (
					<span onClick={() => setRow(!row)}>
						<SearchResultSvgSelector id={"groupedRect"} />
					</span>
				)}
			</div>
		</div>
	);
};
