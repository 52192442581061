import React, { useEffect, useRef, useState } from "react";
import s from "./NewMainSearch.module.css";
import MainSearchLocation from "./MainSearchLocation/MainSearchLocation";
import { MainSearchPopup } from "./MainSearchPopup";
import { Navigate, useSearchParams } from "react-router-dom";
import MainSearchSvgSelector from "./MainSearchSvgSelector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	getSearchCategories,
	setSearchingNoticeName,
} from "../../redux/search_Reducer";
import { StateType } from "../../redux/redux-store";
import { useDebouncedCallback } from "use-debounce";
import { useMediaQuery } from "react-responsive";
import { Desktop, Mobile } from "../../responsive";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";

type PropsType = {
	setOpen: (b: boolean) => void;
	open: boolean;
};

const MainSearch: React.FC<PropsType> = ({ open, setOpen }) => {
	const dispatch: any = useDispatch();
	const isDesktop = useMediaQuery({ minWidth: 1025 });
	const [params] = useSearchParams();
	const searchQuery = params.get("search");
	const { i18n, t } = useTranslation();
	const [submit, setSubmit] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState(
		searchQuery ? searchQuery : ""
	);
	const getSearch = useSelector(() => getSearchCategories);
	const notices = useSelector((state: StateType) => state.searchData.notices);
	const auth = useSelector((state: StateType) => state.userData.isAuth);
	const [popupType, setPopupType] = useState<"search" | "history">("history");
	const setSearchName = useSelector(() => setSearchingNoticeName);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const updateQueryParamsNative = (key: string, value: string) => {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);

		const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
		window.history.pushState(null, "", newUrl);
	};
	const debounced = useDebouncedCallback(
		// function
		(value) => {
			dispatch(getSearch(value));
		},
		// delay in ms
		300
	);
	useEffect(() => {
		if (searchInputRef.current && !isDesktop) {
			searchInputRef.current.focus();
		}
	}, []);
	if (submit) {
		return (
			<Navigate
				to={`${i18n.language === "uk" ? "/ua" : ""}/search_result?search=${searchInputValue.length !== 0 ? searchInputValue : ""}`}
			/>
		);
	}
	const handleClickClearCross = (event: any) => {
		event.preventDefault();
		setSearchInputValue("");
		setPopupType("history");
		updateQueryParamsNative("search", "");
	};

	const handleChangeInput = (letters: string) => {
		if (searchInputValue.length === 0 && letters === " ") {
			return;
		}
		setSearchInputValue(letters);
		dispatch(setSearchName(letters));

		if (letters.length >= 2) {
			setPopupType("search");
			debounced(letters);
			//dispatch(getSearch(letters))
		}
		if (letters.length < 2) {
			setPopupType("history");
		}
	};

	const handleFocusInput = (letters: string) => {
		if (letters.length < 2) {
			setPopupType("history");
		}
		setOpen(true);
		setOpenMenu(false);
	};

	const handleSubmitForm = (
		event: any,
		locationId?: string,
		query?: string
	) => {
		interface Search {
			query: string;
		}

		if (!auth && !query) {
			const MAX_HISTORY_LENGTH = 9;
			const actualSearchesHistoryString = sessionStorage.getItem(
				"bagoSearchesHistory"
			);
			const actualSearchesHistory: Search[] = actualSearchesHistoryString
				? JSON.parse(actualSearchesHistoryString)
				: [];
			const newSearch: Search = { query: event?.currentTarget[0].value };
			const isObjectInArray = actualSearchesHistory.some(
				(obj) => obj.query === newSearch.query
			);
			if (!isObjectInArray) {
				if (actualSearchesHistory.length > MAX_HISTORY_LENGTH) {
					const updatedSearchesHistory = [
						newSearch,
						...actualSearchesHistory?.slice(0, 0),
						...actualSearchesHistory?.slice(1),
					];
					sessionStorage.setItem(
						"bagoSearchesHistory",
						JSON.stringify(updatedSearchesHistory)
					);
				} else {
					const updatedSearchesHistory = [...actualSearchesHistory, newSearch];
					sessionStorage.setItem(
						"bagoSearchesHistory",
						JSON.stringify(updatedSearchesHistory)
					);
				}
			}
		}
		/* if (query && query !== "") {
               dispatch(setSearchName(query));
             } else {
               dispatch(setSearchName(event?.currentTarget[0].value));
             }*/
		if (searchQuery !== searchInputValue) {
			setSubmit(true);
		} else {
			setOpen(false);
		}
	};

	return (
		<form
			className={s.formWrapper}
			onSubmit={(event: any) => {
				event.preventDefault();
				handleSubmitForm(event);
			}}
		>
			<div
				className={`${s.mainSearchContainer} ${isDesktop ? "__container" : ""}`}
				style={{
					/*background: open ? "#9F9F9F" : inCategoryPage ? "none" : "",*/
					/* background: open ? "#9F9F9F" : "none",*/
					/*boxShadow:inCategoryPage ? "none" : "",
                                                                                                                      top:inCategoryPage ? "480px" : "73px",*/
					zIndex: open ? "59" : "2",
				}}
			>
				<div className={s.inputBlock}>
					<div className={s.inputBlockInput}>
						<Mobile>
							<button
								type={"button"}
								onClick={(e) => {
									e.preventDefault();
									setOpen(false);
								}}
								className={s.backArrowContainer}
							>
								<ArrowsAndCross
									id={"backArrowSearchMobile"}
									color={"#797979"}
								/>
							</button>
						</Mobile>
						<input
							ref={searchInputRef}
							onFocus={(event) => handleFocusInput(event.currentTarget.value)}
							value={searchInputValue}
							style={{ borderRadius: !open ? "8px 0 0 8px" : "" }}
							onChange={(event) => handleChangeInput(event.currentTarget.value)}
							type="text"
							placeholder={t(
								isDesktop ? "Пошук по всьому сайту" : "Шукати на BAGO"
							)}
						/>
					</div>
					<Mobile>
						<button
							type={"button"}
							className={s.backArrowContainer}
							onClick={handleClickClearCross}
						>
							<ArrowsAndCross id={"closeCross"} color={"#C2C2C2"} />
						</button>
					</Mobile>
					<Desktop>
						<div className={s.inputBlockLocation}>
							<MainSearchLocation
								setOpen={setOpen}
								openMenu={openMenu}
								setOpenMenu={setOpenMenu}
							/>
						</div>
						<button
							className={s.button}
							type={"submit"}
							onClick={() => setOpen(false)}
						>
							<MainSearchSvgSelector id={"lupa"} />
							<p>{t("Знайти")}</p>
						</button>
					</Desktop>
				</div>
				<div
					className={`${s.popupBlock} ${popupType === "search" ? s.popupSearch : ""}`}
				>
					{open && (
						<MainSearchPopup
							setOpen={setOpen}
							// handleSubmitForm={handleSubmitForm}
							type={popupType}
							// categories={categories}
							notices={notices}
							searchInputValue={searchInputValue}
							setSearchInputValue={setSearchInputValue}
						/>
					)}
				</div>
			</div>
		</form>
	);
};

export default MainSearch;
