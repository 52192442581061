import React, { SetStateAction } from "react";
import style from "../SearchFilters.module.css";
import s from "./FilterFrontHead.module.css";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";
import { useTranslation } from "react-i18next";

type Props = {
	name: string;
	isExpanded: boolean;
	isNeedClear?: boolean;
	setExpanded: SetStateAction<any>;
	clearFilter: () => void;
};
const FilterFrontHead = ({
	name,
	setExpanded,
	isExpanded,
	isNeedClear,
	clearFilter,
}: Props) => {
	const { t } = useTranslation();
	return (
		<div
			className={style.filterTitle}
			onClick={() => {
				setExpanded(!isExpanded);
			}}
		>
			<h5>
				{name}
				<span />
			</h5>
			<span>
				{!isNeedClear ? (
					<button
						onClick={() => {
							setExpanded(!isExpanded);
						}}
						style={{
							rotate: isExpanded && !isNeedClear ? "180deg" : "",
							transition: "300ms",
						}}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</button>
				) : (
					<button className={s.clearCrossButton} onClick={() => clearFilter()}>
						<ArrowsAndCross id={"closeCrossForFilterSelect"} />
					</button>
				)}
				{/*<span>{t('Скинути')}</span>*/}
			</span>
		</div>
	);
};

export default FilterFrontHead;
