import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import s from "../../pages/OutputBordAD/OutputBoardAD.module.css";
import { Desktop, Mobile } from "../../responsive";
import DeliveryBlock from "../DeliveryBlock/DeliveryBlock";
import { useTranslation } from "react-i18next";

type Props = {
	marginTop: string;
	scrollBarData?: {
		title: string;
		description: string;
		properties: any;
		state: string;
	};
	isShowStateProduct?: boolean;
	isShowDelivery?: boolean;
};

const VerticalScrollBar = ({
	marginTop,
	scrollBarData,
	isShowStateProduct,
	isShowDelivery,
}: Props) => {
	const swiperRef: any = useRef(null);
	const descriptionRef: any = useRef(null);
	const characteristicsRef: any = useRef(null);
	const deliveryRef: any = useRef(null);
	const [activeButton, setActiveButton] = useState(
		swiperRef.current?.swiper?.activeIndex || 0
	);
	const { t } = useTranslation();
	const property = scrollBarData?.properties;

	const handleNextClick = (slideNum: number) => {
		setActiveButton(slideNum);
	};

	return (
		<>
			<div className={s.adContentRightTabs}>
				<div style={{ marginTop: marginTop }} className={`${s.tabsButtons} `}>
					<Mobile>
						<button
							onClick={() => handleNextClick(0)}
							style={{
								color: activeButton === 0 ? "#000000" : "",
								/*borderBottom: activeButton === 0 ? "1px solid #2B2A38" : "",*/
							}}
						>
							{t("опис")}
						</button>
						<button
							onClick={() => handleNextClick(1)}
							style={{
								color: activeButton === 1 ? "#000000" : "",

								/*borderBottom: activeButton === 1 ? "1px solid #2B2A38" : "",*/
							}}
						>
							характеристики
						</button>
						{isShowDelivery && (
							<button
								onClick={() => handleNextClick(2)}
								style={{
									color: activeButton === 2 ? "#000000" : "",
									/*borderBottom: activeButton === 2 ? "1px solid #2B2A38" : "",*/
								}}
							>
								доставка
							</button>
						)}
					</Mobile>
					<Desktop>
						<div className={s.buttons}>
							<button
								onClick={() => handleNextClick(0)}
								style={{
									color: activeButton === 0 ? "#1a1a1a" : "",
									borderBottom: activeButton === 0 ? "1px solid #1a1a1a" : "",
								}}
							>
								{t("опис")}
							</button>
							<button
								onClick={() => handleNextClick(1)}
								style={{
									color: activeButton === 1 ? "#1a1a1a" : "",
									borderBottom: activeButton === 1 ? "1px solid #1a1a1a" : "",
								}}
							>
								характеристики
							</button>
							{isShowDelivery && (
								<button
									onClick={() => handleNextClick(2)}
									style={{
										color: activeButton === 2 ? "#1a1a1a" : "",
										borderBottom: activeButton === 2 ? "1px solid #1a1a1a" : "",
									}}
								>
									доставка
								</button>
							)}
						</div>
						{isShowStateProduct && (
							<div className={s.stateInfo}>
								{t("Стан")}:{" "}
								{scrollBarData?.state?.toString() === "1"
									? t("Нове")
									: t("б/в")}
							</div>
						)}
					</Desktop>
				</div>
			</div>
			<Desktop>
				<div className={s.scrollBarContainer} ref={swiperRef}>
					{activeButton === 0 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 0 && s.hide}`}
							ref={descriptionRef}
						>
							<div
								className={s.descriptionText}
								style={{ whiteSpace: "pre-line" }}
							>
								<span>{scrollBarData?.description || ""}</span>
							</div>
						</div>
					)}
					{activeButton === 1 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 1 && s.hide}`}
							ref={characteristicsRef}
						>
							<div className={s.characteristics}>
								<ul className={s.characteristicsTable}>
									{property &&
										property.length > 0 &&
										property.map((p: any) => {
											return (
												<div key={p.filterPosition + p.propertyValues}>
													<li className={s.characteristicsElement}>
														{p.propertyName}:
													</li>
													<li
														className={s.characteristicsElement}
														style={{ whiteSpace: "pre-line" }}
													>
														{p.propertyValues.replace(/,(?!\s)/g, ",\n")}
													</li>
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					)}
					{activeButton === 2 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 2 && s.hide}`}
							ref={deliveryRef}
						>
							<DeliveryBlock />
						</div>
					)}
				</div>
			</Desktop>
			<Mobile>
				<div className={s.scrollBarContainer} ref={swiperRef}>
					{activeButton === 0 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 0 && s.hide}`}
							ref={descriptionRef}
						>
							<div
								className={s.descriptionText}
								style={{ whiteSpace: "pre-line" }}
							>
								<span>{scrollBarData?.description || ""}</span>
							</div>
						</div>
					)}
					{activeButton === 1 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 1 && s.hide}`}
							ref={characteristicsRef}
						>
							<div className={s.characteristics}>
								<ul className={s.characteristicsTableMobile}>
									{property &&
										property.length > 0 &&
										property.map((p: any) => {
											return (
												<div
													key={p.filterPosition + p.propertyValues}
													className={s.characteristicsTableItemWrapMobile}
												>
													<li className={s.characteristicsTableItemNameMobile}>
														{p.propertyName}:
													</li>
													<li
														className={s.characteristicsTableItemValueMobile}
														style={{ whiteSpace: "pre-line" }}
													>
														{p.propertyValues.replace(/,(?!\s)/g, ",\n")}
													</li>
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					)}
					{activeButton === 2 && (
						<div
							className={`${s.swiperSlide} ${activeButton !== 2 && s.hide}`}
							ref={deliveryRef}
						>
							<DeliveryBlock />
						</div>
					)}
				</div>
			</Mobile>
		</>
	);
};

export default VerticalScrollBar;
