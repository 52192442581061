import React, { useEffect, useState } from "react";
import s from "./AutomaticSelectionOfCategories.module.css";
import AutomaticSelectionOfCategoriesTemplate from "./AutomaticSelectionOfCategoriesTemplate";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { useParams } from "react-router-dom";
import AutomaticSelectionOfCategoriesSvgSelector from "./AutomaticSelectionOfCategoriesSvgSelector";
import { getAllRubricsList } from "../../redux/category_Reducer";
import { setClearThirdCategoryList } from "../../redux/category_Reducer";
import { useTranslation } from "react-i18next";
import HeaderSvg from "../Header/HeaderSvg";
import { setOneAutoSelectionChoosing } from "../../redux/autoSelection_Reducer";
import NewRubricsBoardSelectorMobile from "../NewRubricsBoardSelector/NewRubricsBoardSelectorMobile";
import { Desktop, Mobile } from "../../responsive";
import NewRubricsBoardSelectorDesktop from "../NewRubricsBoardSelector/NewRubricsBoardSelectorDesktop";

interface Props {
	setIsOpen: (value: boolean) => void;
	setFieldValue: any;
}

const AutomaticSelectionOfCategoriesModal = ({
	setIsOpen,
	setFieldValue,
}: Props) => {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState("");
	const autoSelectionList = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionList
	);
	const clearThirdCategoryAlias = useSelector(() => setClearThirdCategoryList);
	const dispatch: any = useDispatch();
	const getArrayRubrics = useSelector(() => getAllRubricsList);
	const setOneAutoSelectionStore = useSelector(
		() => setOneAutoSelectionChoosing
	);
	const { noticeId } = useParams();

	useEffect(() => {
		dispatch(getArrayRubrics("0"));
		dispatch(clearThirdCategoryAlias());
	}, []);

	const handleKeyDown = () => {

		setFieldValue("name", searchValue);
		setSearchValue(searchValue);
	};
	const handleSendResoult = () => {

		setFieldValue("name", searchValue);
		setSearchValue(searchValue);
	};

	const handleChangeInput = (e: any) => {
		const value = e.target.value;
		setFieldValue("name", value);
		setSearchValue(value);
	};

	const handleChoseRubric = (e: any) => {

		dispatch(setOneAutoSelectionStore(e));
	};

	return (
		<div className={`${s.modalWrap}`}>
			<div className={s.modalContainer}>
				<div className={s.modalContainerTopBlock}>
					<div className={s.mobileHeaderSearchBlock}>
						<div onClick={handleSendResoult} onKeyDown={handleKeyDown}>
							<HeaderSvg id="search" />
						</div>
						<input
							// onFocus={(event) => handleFocusInput(event.currentTarget.value)}
							onKeyDown={handleKeyDown}
							style={{ borderRadius: !open ? "8px 0 0 8px" : "" }}
							onChange={(event) => handleChangeInput(event)}
							type="text"
							placeholder={t("Введіть назву оголошення")}
							value={searchValue}
						/>
					</div>
					<div
						style={{ cursor: "pointer" }}
						onClick={() => setIsOpen && setIsOpen(false)}
					>
						<AutomaticSelectionOfCategoriesSvgSelector id="close" />
					</div>
				</div>
				<div className={s.modalContainerContent}>
					{!!autoSelectionList.length && !noticeId && (
						<div className={s.modalContainerContentTop}>
							<div className={s.modalContainerContentTop_title}>
								<p>{t(`Автопідбір рубрики`)}:</p>
							</div>
							<div className={s.modalContainerContentTop_blocks}>
								{autoSelectionList.map((item) => (
									<div
										key={item?.categoryId}
										onClick={() => handleChoseRubric(item)}
									>
										<AutomaticSelectionOfCategoriesTemplate
											change={false}
											chose={true}
											categoryName={item?.categoryName}
											/*  tree={item?.categoryInfo?.tree.slice(0, 3)}*/
											names={item?.categoryInfo?.names.slice(0, 3)}
											categoryId={item?.categoryId}
											image={item?.categoryInfo?.urlIconLevel2}
											setFieldValue={setFieldValue}
											setIsOpen={setIsOpen}
										/>
									</div>
								))}
							</div>
						</div>
					)}
					<Desktop>
						<NewRubricsBoardSelectorDesktop
							setFieldValue={setFieldValue}
							setIsOpen={setIsOpen}
						/>
					</Desktop>
					<Mobile>
						<NewRubricsBoardSelectorMobile
							setFieldValue={setFieldValue}
							setIsOpen={setIsOpen}
						/>
					</Mobile>
				</div>
			</div>
		</div>
	);
};

export default AutomaticSelectionOfCategoriesModal;
