import React, { useState } from "react";
import styles from "./Checkbox.module.css";

interface CheckboxProps {
	label: string;
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
	label,
	checked = false,
	onChange,
	disabled = false,
}) => {
	const [isChecked, setIsChecked] = useState<boolean>(checked);

	const handleChange = () => {
		if (disabled) return;
		setIsChecked(!isChecked);
		if (onChange) {
			onChange(!isChecked);
		}
	};

	return (
		<div className={styles.checkBoxContainer}>
			<label className={styles.label}>
				<input
					className={styles.checkboxInput}
					type="checkbox"
					checked={isChecked}
					onChange={handleChange}
					disabled={disabled}
				/>
				<span className={styles.customCheckbox}></span>
				{label !== "" ? (
					<span className={styles.labelText}>{label}</span>
				) : (
					<div className={`${styles.fieldSide} ${styles.textArea} `}>
						<textarea
							name={"description"}
							// onFocus={() => setStatus({})}
							// value={values.description}
							// onChange={(e) =>
							// 	setFieldValue("description", e.currentTarget.value)
							// }
							placeholder="Опишите свои условия возврата"
						/>
						<div className={styles.descriptionOptions}>
							{/* {status && status.description ? (
								<div className={s.descriptionOptions_info}>
									{status.description}
								</div>
							) : (
								<div className={s.descriptionOptions_Size}>
									{values.description.length < 50 &&
										"Напишите текст не менее 200 символов."}
								</div>
							)} */}
							<div className={styles.descriptionOptions_Size}>
								{/* {values.description.length} */}
								0/8000
							</div>
						</div>
					</div>
				)}
			</label>
		</div>
	);
};

export default Checkbox;
