import React from "react";

const NoOrdersCardBadge = ({ num }: { num: string }) => {
	return (
		<div
			style={{
				minWidth: "20px",
				height: "20px",
				background: "#FFD74A",
				borderRadius: "10px",
				textAlign: "center",
				color: "#000000",
				fontSize: "12px",
			}}
		>
			{num}
		</div>
	);
};

export default NoOrdersCardBadge;
