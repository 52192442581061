import React from "react";
import { useTranslation } from "react-i18next";

type TitleType = "noInternet" | "400&500" | "noActiveAD";
type Props = {
	type: TitleType;
};
const ErrorPagesTitle = ({ type }: Props) => {
	const { t } = useTranslation();
	let title;
	switch (type) {
		case "noInternet":
			title = t("Перевірте з'єднання та перезавантажте сторінку");
			break;
		case "400&500":
			title = t("Ой щось пішло не так!");
			break;
		case "noActiveAD":
			title = t("Це оголошення більше недоступне!");
			break;
	}
	return <h1>{title}</h1>;
};

export default ErrorPagesTitle;
