import React from "react";
import s from "./TopNavigateMobileCabinet.module.css";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";
import { useNavigate } from "react-router";
import PersonalCabinetTopSection from "../PersonalCabinetTopSection";
import { MenuElements } from "../../../interfaces/interfaces";

const TopNavigateMobileCabinet = ({
	titleMobile,
	headers,
	type,
	setStatus,
}: {
	titleMobile: string;
	type?: string;
	headers: MenuElements[];
	setStatus?: any;
}) => {
	const navigate = useNavigate();
	return (
		<div className={s.topContainer}>
			<div className={s.topNavigateContainer}>
				<div className={s.topNavigateArrow}>
					<span onClick={() => navigate(`/personal_cabinet`)}>
						<PersonalCabinetSvgSelector id={"backArrow"} />
					</span>
				</div>
				<div className={s.topNavigateName}>{titleMobile}</div>
			</div>
			<div className={s.sliderContainer}>
				<PersonalCabinetTopSection
					headers={headers}
					type={type}
					setStatus={setStatus}
				/>
			</div>
		</div>
	);
};

export default TopNavigateMobileCabinet;
