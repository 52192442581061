import React from "react";
import s from "./AskApprovePopup.module.css";
import { useTranslation } from "react-i18next";
import LogoSvgSelector from "../../../common/LogoSVGSelectors/LogoSvgSelector";

type Props = {
	title: string;
	message?: string;
	handleClickYes?: (b?: any) => void;
	handleClickNo?: (b?: any) => void;
};

const AskApprovePopup: React.FC<Props> = ({
	title,
	message,
	handleClickYes,
	handleClickNo,
}) => {
	const { t } = useTranslation();

	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				<div className={s.title}>{title}</div>
				<div className={s.message}>{message}</div>
				<div className={s.logoContainer}>
					<LogoSvgSelector id={"logoMinor"} />
				</div>
				<div className={s.continue}>{t("Продовжити")}?</div>

				<div className={s.buttonsContainer}>
					<button
						className={`${s.button} ${s.buttonY}`}
						onClick={handleClickYes && handleClickYes}
					>
						{t("Так")}
					</button>
					<button
						className={`${s.button} ${s.buttonN}`}
						onClick={handleClickNo && handleClickNo}
					>
						{t("Ні")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default AskApprovePopup;
