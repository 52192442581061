import React from "react";
import categoryLoader from "../../common/Animations/CategoryLoader.json";
import imagesPreloader from "../../common/Animations/ImagesPreloader.json";
//import circleLoader from "../../common/Animations/circleLoad.json";
import Lottie from "lottie-react";
import s from "./preLoaderComponent.module.css";

type Props = {
	type: "category" | "gallery" | "image" | "circle";
	size: "compact" | "big" | "medium";
};

const PreLoaderComponent = ({ type, size }: Props) => {
	let elementSize;
	switch (size) {
		case "compact":
			elementSize = s.elementCompact;
			break;
		case "big":
			elementSize = s.elementBig;
			break;
		case "medium":
			elementSize = s.elementMedium;
			break;
		default:
			elementSize = s.elementCompact;
	}
	let animationData;
	switch (type) {
		case "category":
			animationData = categoryLoader;
			break;
		case "gallery":
			animationData = imagesPreloader;
			break;
		/*case "circle" :
            animationData = circleLoader
            break;*/
		default:
			animationData = imagesPreloader;
			break;
	}
	return (
		<div className={s.container}>
			<div className={elementSize}>
				<Lottie animationData={animationData} loop={true} />
			</div>
		</div>
	);
};

export default PreLoaderComponent;
