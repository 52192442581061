import React from "react";
import s from "../CreateBoardAD.module.css";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

interface Props {
	setFieldValue: any;
	values: any;
}
const AutoContinueCreate: React.FC<Props> = ({ setFieldValue, values }) => {
	const { t } = useTranslation();
	return (
		<div className={s.formElement}>
			<div className={`${s.autoSettings} ${s.autoContainer}`}>
				<div className={s.autoContainer_top}>
					<div className={s.autoContainer_Name}>{t("Автоподовження")}</div>
					{values.auto_continue === 1 ? (
						<div className={`${s.onOffTextHandler} ${s.active}`}>
							{t("Увімкнено")}
						</div>
					) : (
						<div className={`${s.onOffTextHandler}`}>{t("Вимкнено")}</div>
					)}
				</div>
				<div className={s.autoContainer_bottom}>
					<div>{t("Безкоштовно")}</div>
					<div className={s.switchContainer}>
						<Switch
							checked={values.auto_continue === 1}
							onChange={(checked) => {
								setFieldValue("auto_continue", checked ? 1 : 0);
							}}
							width={32}
							height={12}
							handleDiameter={22}
							uncheckedIcon={
								<svg
									className={s.switchIconPause}
									width="17"
									height="17"
									viewBox="0 0 17 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M6 5V9L6 13" stroke="white" strokeWidth="2" />
									<path d="M11 5V13" stroke="white" strokeWidth="2" />
								</svg>
							}
							checkedIcon={
								<svg
									className={s.switchIconPlay}
									width="10"
									height="12"
									viewBox="0 0 10 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9.17578 6.08824L0.0434279 11.3608L0.0434284 0.815669L9.17578 6.08824Z"
										fill="white"
									/>
								</svg>
							}
							offColor={"#E1E1E1"}
							onColor={"#E1E1E1"}
							offHandleColor={"#C4C4C4"}
							onHandleColor={"#00AB3A"}
							className={s.switchContainer}
							activeBoxShadow={"0 0 2px 3px #00AB3A"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AutoContinueCreate;
