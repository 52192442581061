import React, { useEffect, useRef, useState } from "react";
import s from "./Bestsellers.module.css";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { getSettlementIndex } from "../../redux/settlement_Reducer";
import { useTranslation } from "react-i18next";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";

type Props = {
	setSettlement: (b: string) => void;
	setArea: (b: string) => void;
};
const LocationSelectorBestsellers = ({ setSettlement, setArea }: Props) => {
	const dispatch: any = useDispatch();
	const { i18n, t } = useTranslation();
	const userLocation = useSelector(
		(state: StateType) => state.settlementData.settlementByLocation
	);
	const userDistrict = useSelector(
		(state: StateType) => state.settlementData.settlementByLocationOnlyDistrict
	);
	const allDistrict = useSelector(
		(state: StateType) => state.settlementData.settlementIndex
	);
	const getLocationIndex = useSelector(() => getSettlementIndex);
	const [value, setValue] = useState(userDistrict.district || "");
	const [isExpand, setIsExpand] = useState<boolean>(true);
	const menuRef: React.Ref<any> = useRef(null);
	const inputRef: React.Ref<any> = useRef(null);
	let changedLocation: any = null;

	const handleChangeLocation = (id: string, value: string) => {
		setArea(id);
		setSettlement(id);
		setValue(value);
		setIsExpand(true);
		const userLocation = { id: id, city: value };
		localStorage.setItem("userBagoLocation", JSON.stringify(userLocation));
	};
	useEffect(() => {
		if (!isExpand && inputRef.current) {
			inputRef.current.focus(); // Установка фокуса на поле input при открытии меню
		}
	}, [isExpand]);
	useEffect(() => {
		//dispatch(getLocationIndex(1, value)); changed 16/09/2024
		dispatch(getLocationIndex(1, value));
		const userBagoLocation = localStorage?.getItem("userBagoLocation");
		if (userBagoLocation !== null) {
			changedLocation = JSON.parse(userBagoLocation);
		}
	}, [value]);
	useEffect(() => {
		if (changedLocation !== null) {
			setValue(changedLocation.city);
			setArea(changedLocation.id);
		} else if (userDistrict.id) {
			setValue(userDistrict.district);
			setArea(userDistrict.id);
		}
	}, [userLocation, userDistrict, changedLocation]);
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			// Закрывать меню только если клик был снаружи области меню
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsExpand(true);
			}
		};
		// Добавить слушателя событий при монтировании компонента
		document.addEventListener("click", handleClickOutside);
		// Очистить слушателя событий при размонтировании компонента
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	/*setFilteredCities(allDistrict.filter(city => {
                city.description.toLowerCase().startsWith(value.toLowerCase())
            }))
    */
	useEffect(() => {
		dispatch(getLocationIndex(1, null, "1", "24"));
	}, []);
	return (
		<div className={s.locationSelectContainer}>
			{" "}
			{/*Повернув онклік там де він був. Якщо він на контейнері, то при обранні локації - менюшка не зачиняється*/}
			{isExpand ? (
				<div
					className={s.locationChanged}
					onClick={() => {
						if (allDistrict.length < 20) {
							dispatch(getLocationIndex(1, "", "1", "24"));
						}
						setIsExpand(false);
					}}
				>
					<div className={s.locationMainName}>
						<ArrowsAndCross
							id={"locationForBestSelect"}
							color={value === "" ? "#00A9CE" : ""}
						/>
						{value === "" ? t("Вибрати") : value}
					</div>
				</div>
			) : (
				<div className={s.locationSelectMenuList} ref={menuRef}>
					<div className={s.topSearch}>
						<ArrowsAndCross id={"locationForBestSelect"} color={"#00A9CE"} />
						<input
							ref={inputRef}
							className={s.input}
							type="text"
							onChange={(event) => {
								dispatch(getSettlementIndex(1, event.currentTarget.value));
							}}
						/>
					</div>
					<div className={s.selectMenuContainer}>
						<ul className={s.locationSelectMenu}>
							<li
								className={s.selectOption}
								onClick={() => handleChangeLocation("", t("Вся Україна"))}
							>
								{t("Вся Україна")}
							</li>
							{/*{userDistrict && userDistrict.id &&
                            <li className={s.selectOption} onClick={()=>handleChangeLocation(userDistrict.id,userDistrict.district)}>
                                {userDistrict.district}</li>}*/}
							{allDistrict.length > 0 &&
								allDistrict.map((l: any) => {
									return (
										<li
											key={l.id}
											className={s.selectOption}
											onClick={() => {
												handleChangeLocation(
													l.area_id?.toString() ?? "",
													i18n.language === "ru"
														? l.description_ru
														: l.description
												);
											}}
										>
											{i18n.language === "ru"
												? l.description_ru
												: l.description}
										</li>
									);
								})}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default LocationSelectorBestsellers;
