import React from "react";
import s from "./DeliveryBlock.module.css";
import OutputADSvgSelector from "../OutputBoardAdComponents/OutputADSvgSelector";

const DeliveryBlock = () => {
	return (
		<ul className={s.deliveryTable}>
			<li className={s.deliveryTableElement}>
				<button className={s.deliveryButton}>
					<div className={s.deliveryLogo}>
						<OutputADSvgSelector id={"ukrPost"} />
					</div>
					<div className={s.deliveryInfo}>
						<span className={s.deliveryName}>Укрпочта</span>
						<span className={s.deliveryTime}>Доставка от 40 грн</span>
					</div>
				</button>
			</li>
			<li className={s.deliveryTableElement}>
				<button className={s.deliveryButton}>
					<div className={s.deliveryLogo}>
						<OutputADSvgSelector id={"novaPost"} />
					</div>
					<div className={s.deliveryInfo}>
						<span className={s.deliveryName}>Новая почта</span>
						<span className={s.deliveryTime}>Доставка от 40 грн</span>
					</div>
				</button>
			</li>
			<li className={s.deliveryTableElement}>
				<button className={s.deliveryButton}>
					<div className={s.deliveryLogo}>
						<OutputADSvgSelector id={"meest"} />
					</div>
					<div className={s.deliveryInfo}>
						<span className={s.deliveryName}>Meest</span>
						<span className={s.deliveryTime}>Доставка от 40 грн</span>
					</div>
				</button>
			</li>
			<li className={s.deliveryTableElement}>
				<button className={s.deliveryButton}>
					<div className={s.deliveryLogo}>
						<OutputADSvgSelector id={"aloneDelivery"} />
					</div>
					<div className={s.deliveryInfo}>
						<span className={s.deliveryName}>Cамовывоз</span>
						<span className={s.deliveryTime}>На складе завтра</span>
					</div>
				</button>
			</li>
		</ul>
	);
};

export default DeliveryBlock;
