import React, { useState } from "react";
import s from "./MyPurchases.module.css";
import style from "../MyOrders/MyOrders.module.css";
import PersonalCabinetCardLeft from "../CabinetCardComponents/PersonalCabinetCardLeft";
import PersonalCabinetCardRight from "../CabinetCardComponents/PersonalCabinetCardRight";
import PersonalCabinetCardCenterTop from "../CabinetCardComponents/PersonalCabinetCardCenterTop";

const MyPurchases = ({ type }: { type: string }) => {
	const [purchasesStatus, setPurchasesStatus] = useState("confirmation");

	return (
		<div className={s.myPurchasesContainer}>
			{type === "new" && (
				<h5>
					У Вас є 2 дні на підтвердження замовлення, після чого замовлення буде
					анульовано. Якщо товар вже продан - просто натисніть хрестик
				</h5>
			)}
			<div className={s.myPurchasesCardContainer}>
				<PersonalCabinetCardLeft />
				<div className={style.verticalLine} />
				<div className={s.myPurchasesCardCenter}>
					<PersonalCabinetCardCenterTop />
					{type === "new" && (
						<div
							className={s.myPurchasesCardCenter_bottom}
							style={{
								background:
									purchasesStatus === "confirmation"
										? "#FFE898"
										: purchasesStatus === "confirm"
											? "#B5F4BF"
											: purchasesStatus === "TTH"
												? "#D2F7FF"
												: purchasesStatus === "rejected"
													? "#F4CCB5"
													: "",
							}}
						>
							<div className={s.cardCenter_bottomContent}>
								{purchasesStatus === "confirmation" && (
									<div onClick={() => setPurchasesStatus("confirm")}>
										Подтверждение заказа, осталось:
										<span>1 день 23 часа</span>
									</div>
								)}
								{purchasesStatus === "confirm" && (
									<div onClick={() => setPurchasesStatus("TTH")}>
										Ваш заказ ПОДТВЕРЖДЁН <br />
										ожидайте номер ТТХ Доставки
									</div>
								)}
								{purchasesStatus === "TTH" && (
									<div
										style={{
											fontSize: "18px",
											color: "#000000",
										}}
										onClick={() => setPurchasesStatus("rejected")}
									>
										ТТХ:12345678901234
									</div>
								)}
								{purchasesStatus === "rejected" && (
									<div onClick={() => setPurchasesStatus("confirmation")}>
										Ваш Заказ отклонён Продавцом
										<span>объявление больше не актуально</span>
									</div>
								)}
							</div>
						</div>
					)}
					{type === "archive" && (
						<div className={s.myPurchasesCardCenter_bottomDate}>
							Дата угоди: <span>25мар2023</span>
						</div>
					)}
				</div>
				<div className={style.verticalLine} />
				<PersonalCabinetCardRight seeFeedback={type === "archive" && true} />
			</div>
		</div>
	);
};

export default MyPurchases;
