import React from "react";
import { useTranslation } from "react-i18next";

const PublishedMonthYear = ({ seconds }: { seconds: number | null }) => {
	const { i18n } = useTranslation();
	const monthsRU = [
		"янвря",
		"февраря",
		"марта",
		"апреля",
		"майя",
		"июня",
		"июля",
		"августа",
		"сентября",
		"октября",
		"ноября",
		"декабря",
	];
	const monthsUa = [
		"січня",
		"лютого",
		"березня",
		"квітня",
		"травня",
		"червня",
		"липня",
		"серпня",
		"вересня",
		"жовтня",
		"листопада",
		"грудня",
	];
	const months = i18n.language === "ru" ? monthsRU : monthsUa;
	if (seconds) {
		const date = new Date(seconds && seconds * 1000); // Преобразование секунд в миллисекунды
		const month = months[date.getMonth()]; // Получение месяца (январь - 0, февраль - 1, ...)
		const year = date.getFullYear();

		return (
			<>
				<>{month}</> <>{year}</>
			</>
		);
	}
	return <> </>;
};
export default PublishedMonthYear;
