import React from "react";
import s from "./BreadCrumbs.module.css";
import { Link } from "react-router-dom";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

type Links = {
	name: string;
	alias: string;
	linksLength: number | null;
	active: boolean;
};
type Props = {
	links?: Links[];
	handleClick?: () => void;
};

const BreadCrumbs: React.FC<Props> = ({ links, handleClick }) => {
	const location = useLocation();
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	const generatePath = (
		currentLinks: Links[],
		currentIndex: number
	): string => {
		// Получаем все предыдущие alias до текущего индекса
		const previousAliases = currentLinks
			.slice(0, currentIndex + 1)
			.filter((link) => link.alias !== "") // Фильтруем пустые alias
			.map((link) => link.alias);
		// Добавляем '/c2' перед вторым элементом
		if (currentIndex !== 0) {
			previousAliases.splice(1, 0, "c2");
		}

		// Объединяем все предыдущие alias в строку, разделенную слэшами
		return `${currentLanguage}/c/${previousAliases.join("/")}`;
	};
	return (
		<div className={s.breadCrumbsContainer}>
			<div className={s.main}>
				<Link to={i18n.language === "ru" ? "/" : "/ua"}>{t("Головна")}</Link>
			</div>
			{links?.map((l: Links, index: number) => {
				// Определяем, какой линк будет неактивным в зависимости от длины массива или значения linksLength
				const inAD =
					location.pathname.startsWith("/o/") ||
					location.pathname.startsWith("/ua/o/");
				const disabledLink = inAD && links.length === 5 && index === 2;
				const isLinkInactive = inAD
					? links.length - 1 === index
					: (links.length >= 4 && index === 2) ||
						(l.linksLength && index === l.linksLength - 1);
				return (
					<div
						className={s.elements}
						key={l.alias + index + Math.floor(Math.random())}
					>
						{l.name !== "" && (
							<span>
								<ArrowsAndCross id={"arrowForBreadcrumbs"} color={"#727272"} />
							</span>
						)}
						{links?.length - 1 === index ? ( // Проверяем, является ли текущий элемент последним
							<a
								className={`${l.active ? s.active : ""} ${isLinkInactive ? s.inactive : ""}`}
							>
								{l.name}
							</a>
						) : inAD && disabledLink ? (
							<a className={`${disabledLink ? s.disabled : ""}`}>{l.name}</a>
						) : (
							<Link
								onClick={handleClick && handleClick}
								className={`${l.active ? s.active : ""} ${isLinkInactive ? s.inactive : ""}`}
								to={
									isLinkInactive
										? "#"
										: generatePath(links, index) || location.pathname
								}
							>
								{l.name}
							</Link>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default BreadCrumbs;
