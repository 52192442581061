import { t } from "i18next";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { applyPromocode } from "../../../../redux/user_Reducer";
import s from "./Promocode.module.css";

type Props = {
	setIsApplyPromocode: () => void;
};

export const Promocode = (props: Props) => {
	const { setIsApplyPromocode } = props;
	const [promocode, setPromocode] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const dispatch: any = useDispatch();

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value.length <= 255) {
			setPromocode(value);
			setError("");
			setSuccess("");
		}
	};

	const validatePromocode = (code: string) => {
		const trimmedCode = code.trim();
		if (!trimmedCode) {
			setError(t("Промокод не може бути порожнім."));
			return false;
		}
		if (trimmedCode.length > 255) {
			setError(t("Промокод не може перевищувати 255 символів."));
			return false;
		}
		return true;
	};

	const onActivePromocode = async () => {
		if (!validatePromocode(promocode)) return;

		const res = await dispatch(applyPromocode(promocode));
		setIsApplyPromocode();
		if (res.error) {
			setError(res.error);
		} else if (res.message) {
			setSuccess(res.message);
			setPromocode("");
		}
	};

	return (
		<div className={s.topUpAccount}>
			<span>{t("Промокод")}</span>
			<div className={s.inputContainer}>
				<div>
					<input
						value={promocode}
						type="text"
						placeholder={t("Введіть Ваш промокод")}
						onChange={handleInputChange}
						maxLength={255}
					/>
					<button
						type="button"
						onClick={onActivePromocode}
						className={s.activeButton}
						disabled={!promocode.trim()}
					>
						{t("Активувати")}
					</button>
				</div>
				{error && <span className={s.errorMessage}>{error}</span>}
				{success && <span className={s.successMessage}>{success}</span>}
			</div>
		</div>
	);
};
