import React, { useEffect, useState } from "react";
import s from "../../App.module.css";
import { Desktop, Mobile } from "../../responsive";
import HeaderProfile from "../NavBar/HeaderProfileMenu/HeaderProfile";
import { useSelector } from "react-redux";
import { stateUserType } from "../../types/reduxStoreTypes";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const HeaderAuthBlock = ({
	openEnterDialog,
}: {
	openEnterDialog: () => void;
}) => {
	const [showLogo, setShowLogo] = useState(true);
	const auth = useSelector((state: stateUserType) => state?.userData?.isAuth);
	const navigate = useNavigate();
	useEffect(() => {
		/*const timer = setTimeout(() => {
            setShowLogo(!showLogo);
        }, 3000);*/
		/*return () => {
             clearTimeout(timer);
         };*/
	}, [showLogo]);

	return (
		<div className={s.auth}>
			{/*<Mobile>
                {!showLogo ?
                        <LogoMobile />
                    :
                        <HeaderProfile />
                }
            </Mobile>*/}
			<Mobile>
				<HeaderProfile openEnterDialog={openEnterDialog} />
			</Mobile>
			<Desktop>
				{!auth ? (
					<div className={s.authBlock} onClick={openEnterDialog}>
						<HeaderProfile openEnterDialog={openEnterDialog} />
					</div>
				) : (
					<Link className={s.authBlock} to={"/personal_cabinet/ads"}>
						<HeaderProfile openEnterDialog={openEnterDialog} />
					</Link>
				)}
			</Desktop>
		</div>
	);
};

export default HeaderAuthBlock;
