import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { buyService } from "../../../../redux/notice_Reducer";
import { OnePrice } from "../../../../redux/price_Reducer";
import { Desktop } from "../../../../responsive";
import { NoticeDataForModal } from "../../../../types/noticeTypes";
import PersonalCabinetSvgSelector from "../../PersonalCabinetSvgSelector";
import BottomPayModalCardComponent from "../BottomPayModalCardComponent";
import TitleModalCardComponent from "../TitleModalCardComponent";
import TopModalCardComponent from "../TopModalCardComponent";
import s from "./ModalRaiseAd.module.css";

type Props = {
	handleClose: (b: boolean) => void;
	noticeData: NoticeDataForModal;
	raisePrice: string;
	oneTimeUp: OnePrice | null;
};

const ModalRaiseAd: React.FC<Props> = ({
	handleClose,
	noticeData,
	raisePrice,
	oneTimeUp,
}) => {
	const dispatch: any = useDispatch();
	const buy = useSelector(() => buyService);
	const { t } = useTranslation();

	const handlePayService = () => {
		dispatch(
			buy(
				+noticeData.id,
				oneTimeUp?.id ?? 0,
				handleClose,
				raisePrice,
				noticeData.name
			)
		);
	};

	return (
		<div className={s.modalContainer}>
			<TitleModalCardComponent
				title={`${t("Підняти оголошення за")} ${raisePrice} грн.`}
				handleClose={handleClose}
			/>
			<TopModalCardComponent noticeData={noticeData} />
			<div className={s.topCardSelectLine}>
				<Desktop>
					<div className={s.topCardSelectLine_Flag}>
						<PersonalCabinetSvgSelector id={"topFlag"} />
					</div>
				</Desktop>
			</div>
			<div className={s.topCardSelectBuyInfo}>
				<p>
					{t("Гроші знімуться з вашого балансу в розмірі")}
					<span className={s.topCardSelectPrice}>{raisePrice} грн.</span>
					{t(" 1 раз")}
				</p>
				<br />
			</div>
			<div className={s.topCardSelectPayTitle}>
				{t("Замовити та оплатити підняття")}
			</div>
			<BottomPayModalCardComponent
				totalBalance={noticeData.balance}
				handleClick={handlePayService}
				sum={raisePrice}
			/>
		</div>
	);
};

export default ModalRaiseAd;
