import { ActionType } from "../types/reduxStoreTypes";
import { Dispatch } from "react";
import { pagesSeo, staticPageAPI } from "../api/api";
import { pagesDataType } from "../types/pagesTypes";

const SET_SEO_PAGES = "bago/pages_Reducer/SET_SEO_PAGES";
const SET_LOADER_SHOW = "bago/categoryReducer/SET_LOADER_SHOW";
const SET_STATIC_PAGES_FOR_FOOTER =
	"bago/pages_Reducer/SET_STATIC_PAGES_FOR_FOOTER";
const SET_STATIC_ONE_PAGE = "bago/pages_Reducer/SET_STATIC_ONE_PAGE";
const SET_STATIC_PAGES_FOR_FOOTER_COLUMN =
	"bago/pages_Reducer/SET_STATIC_PAGES_FOR_FOOTER_COLUMN";

const initialState: pagesDataType = {
	seoPages: [
		{
			pageName: "",
			title_uk: "",
			description_uk: "",
			title_ru: "",
			description_ru: "",
			seo_ru: "",
			seo_uk: "",
		},
		{
			pageName: "",
			title_uk: "",
			description_uk: "",
			title_ru: "",
			description_ru: "",
			seo_ru: "",
			seo_uk: "",
		},
	],
	showLoader: false,
	staticPages: [],
	staticPagesColumns: {
		column1: [],
		column2: [],
		column3: [],
	},
	staticOnePage: {
		title: "",
		text: "",
	},
};
const pages_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_SEO_PAGES:
			return {
				...state,
				seoPages: action.data,
			};
		case SET_LOADER_SHOW:
			return {
				...state,
				showLoader: action.bool,
			};
		case SET_STATIC_PAGES_FOR_FOOTER:
			return {
				...state,
				staticPages: action.data,
			};
		case SET_STATIC_ONE_PAGE:
			return {
				...state,
				staticOnePage: action.data,
			};
		case SET_STATIC_PAGES_FOR_FOOTER_COLUMN:
			return {
				...state,
				staticPagesColumns: action.data,
			};
		default:
			return state;
	}
};
export const setSeoPages = (data: any) => ({ type: SET_SEO_PAGES, data });
export const setStaticPagesForFooter = (data: any) => ({
	type: SET_STATIC_PAGES_FOR_FOOTER,
	data,
});
export const setStaticPagesForFooterColumn = (data: any) => ({
	type: SET_STATIC_PAGES_FOR_FOOTER_COLUMN,
	data,
});
export const setStaticOnePage = (data: any) => ({
	type: SET_STATIC_ONE_PAGE,
	data,
});

//export const setLoaderShow = (bool: boolean) => ({type: SET_LOADER_SHOW, bool});

export const getSEOInfo = () => async (dispatch: Dispatch<any>) => {
	try {
		const response: any = await pagesSeo.getSeoPages();
		if (response.data.seoForPages) {
			dispatch(setSeoPages(response.data.seoForPages));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export const getStaticPageForFooter = () => async (dispatch: Dispatch<any>) => {
	try {
		const response: any = await staticPageAPI.getPageForFooter();
		if (response.data.pages) {
			const column1 = <any>[];
			const column2 = <any>[];
			const column3 = <any>[];

			response.data.pages.forEach((item: any) => {
				switch (item.column_number) {
					case 1: {
						column1.push(item);
						break;
					}
					case 2: {
						column2.push(item);
						break;
					}
					case 3: {
						column3.push(item);
						break;
					}
				}
			});

			const staticPagesColumns = {
				column1: column1,
				column2: column2,
				column3: column3,
			};

			dispatch(setStaticPagesForFooterColumn(staticPagesColumns));
			dispatch(setStaticPagesForFooter(response.data.pages));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export const getOneStaticPage =
	(slug: string) => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await staticPageAPI.getOnePage(slug);
			if (response.data.page) {
				dispatch(setStaticOnePage(response.data.page));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
/*export const getRecentlyWatchedNotices = (noticesId: userViewNotice[]) => async (dispatch) => {
    try {
        const recentlyNotices = []
        for (let i = 0; noticesId.length > i; i++) {
            const response: any = await publicNotice.getPublicNoticeView(noticesId[i]?.notice_id)
            recentlyNotices.push(response.data.notice)
        }
        dispatch(setRecentlyWatchNotices(recentlyNotices))

    } catch (error: any) {
        console.log(error.message)
    }
}*/
export default pages_Reducer;
