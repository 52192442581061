import { useTranslation } from "react-i18next";
import s from "./AboutSellerField.module.css";

type Props = {
	noticeDescriptionRef: any;
	status: any;
	setStatus: any;
	values: any;
	setFieldValue: (a: string, b: string) => void;
	isEdit: boolean;
};

export const AboutSellerField = ({
	noticeDescriptionRef,
	status,
	setStatus,
	values,
	setFieldValue,
	isEdit,
}: Props) => {
	const { t } = useTranslation();

	return (
		<div className={`${s.formElement} ${s.descriptionField}`}>
			<div className={s.nameSide}>
				<span className={s.elementName}>Про продавця</span>
			</div>
			{isEdit ? (
				<div
					ref={noticeDescriptionRef}
					className={`${s.fieldSide} ${status?.description ? s.error : ""} ${s.textArea} `}
				>
					<textarea
						name={"description"}
						onFocus={() => setStatus({})}
						value={values.description}
						onChange={(e) =>
							setFieldValue("description", e.currentTarget.value)
						}
						placeholder="Расскажите о вашей компании"
					/>
					<div className={s.descriptionOptions}>
						{status && status.description ? (
							<div className={s.descriptionOptions_info}>
								{status.description}
							</div>
						) : (
							<div className={s.descriptionOptions_Size}>
								{values.description.length < 50 &&
									"Напишите текст не менее 200 символов."}
							</div>
						)}
						<div className={s.descriptionOptions_Size}>
							{values.description.length}/8000
						</div>
					</div>
				</div>
			) : (
				<p className={s.description}>{values.description}</p>
			)}
		</div>
	);
};
