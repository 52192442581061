import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ActionCreator } from "redux";
import { getOneStaticPage } from "../../redux/pages_Reducer";
import { StateType } from "../../redux/redux-store";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import s from "./StaticPageTemplate.module.css";

const StaticPageTemplate = () => {
	const { slug } = useParams();
	const { i18n } = useTranslation();
	const dispatch = useDispatch();
	const pageData = useSelector(
		(state: StateType) => state.pagesData.staticOnePage
	);

	const getOnePage: ActionCreator<any> = useSelector(() => getOneStaticPage);

	useEffect(() => {
		if (slug) {
			dispatch(getOnePage(slug));
		}
		window.scrollTo(0, 0);
	}, [slug, dispatch, getOnePage, i18n.language]);

	const breadcrumbs = [
		{
			name: pageData?.title || "",
			alias: slug || "",
			active: true,
			linksLength: null,
		},
	];

	return (
		<>
			<div className={s.staticPageWrap}>
				<div className={s.container}>
					<div className={s.breadCrumbs}>
						<BreadCrumbs links={breadcrumbs} />
					</div>
					{pageData?.text && (
						<>
							<div
								className={s.content}
								dangerouslySetInnerHTML={{ __html: pageData?.text }}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default StaticPageTemplate;
