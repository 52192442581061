import React, { useState } from "react";
import style from "./CompainModalWindow.module.css";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import PublishedTime from "../TimeAndDate/PublishedTime";
import { useTranslation } from "react-i18next";
import CompainModalWindowSvg from "./CompainModalWindowSvg";
import TruncationOfTheName from "./TruncationOfTheName";
import CompainModalFirstWindow from "./CompainModalFirstWindow";
import CompainModalSecondWindow from "./CompainModalSecondWindow";
import ThankYouWindow from "./ThankYouWindow";
import { useSelector, useDispatch } from "react-redux";
import { createComplaints } from "../../redux/compain_Reducer";

type PropsType = {
	dialogOpen: boolean;
	dialogClose: () => void;
	sellerName: string;
	lastVisit: number;
	productImage: string;
	productName: string;
	productPrice: string;
	isShowProduct: boolean;
	ownerImage: string | null;
	idForCreate?: number;
	userId?: string;
};

const CompainModalWindow: React.FC<PropsType> = ({
	dialogClose,
	dialogOpen,
	sellerName,
	lastVisit,
	productImage,
	productName,
	productPrice,
	isShowProduct,
	ownerImage,
	idForCreate,
	userId,
}) => {
	const { t } = useTranslation();
	const [openList, setOpenList] = useState<number>(0);
	const [currentMainComplain, setCurrentMainComplain] = useState("");
	const [currentComplain, setCurrentComplain] = useState({
		categoryId: 0,
		id: 0,
		title: "",
	});
	const [messageValue, setMessageValue] = useState("");
	const [firstWindow, setFirstWindow] = useState(true);
	const [isSendCompain, setIsSendCompain] = useState(false);
	const [currentCategoryId, setCurrentCategoryId] = useState(0);
	const [currentCauseId, setCurrentCauseId] = useState(0);
	const [error, setError] = useState("");

	const dispatch: any = useDispatch();

	const createComplaintsStore = useSelector(() => createComplaints);

	const handleSetActiveList = (id: number, name: string) => {
		setCurrentCategoryId(id);
		setCurrentMainComplain(name);
		if (openList == id) {
			setOpenList(0);
		} else {
			setOpenList(id);
		}
	};

	const handleSetCurrentComplain = (complain: any) => {
		setCurrentCauseId(complain?.id);
		setCurrentComplain(complain);
		setTimeout(() => {
			setFirstWindow(false);
		}, 500);
	};

	const handlePrevWindow = () => {
		setFirstWindow(true);
		setMessageValue("");
	};

	const handleSendCompain = async () => {
		const response = await dispatch(
			createComplaintsStore(
				idForCreate || 0,
				currentCategoryId,
				currentCauseId,
				messageValue,
				userId || ""
			)
		);

		if (response.data.errors) {
			setError(response?.data?.errors?.to_notice_id);
		} else {
			setIsSendCompain(true);
		}
	};

	if (isSendCompain) {
		return <ThankYouWindow dialogClose={dialogClose} />;
	} else {
		return (
			<div
				className={style.modalWrapper}
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						dialogClose();
					}
				}}
			>
				<div className={style.modalContainer}>
					<div className={`${style.topContainer} ${style.retreat}`}>
						<div className={style.topContainer_secondWindow_wrap}>
							{!firstWindow && (
								<span onClick={handlePrevWindow} className={style.arrowBack}>
									<CompainModalWindowSvg id={"arrowBack"} />
								</span>
							)}
							<p className={style.topContainer_text}>{t("Поскаржитись")}</p>
						</div>
						<span onClick={dialogClose} className={style.buttonClose}>
							<ArrowsAndCross id={"closeCross"} color={"#3D3D3D"} />
						</span>
					</div>
					<div className={`${style.topAttentionText} ${style.retreat}`}>
						<p>
							{t(
								"Не турбуйтеся, цей користувач не дізнається, що Ви поскаржилися на нього."
							)}
						</p>
					</div>
					<div className={style.sellerContainer}>
						<div className={style.sellerContainer_content}>
							{ownerImage ? (
								<div className={style.ownerImage_wrap}>
									<img className={style.ownerImage} src={ownerImage} />
								</div>
							) : (
								<div>
									<CompainModalWindowSvg id={"noAvatar"} />
								</div>
							)}
							<div>
								<p className={style.sellerContainer_content_name}>
									{sellerName}
								</p>
								{/* <p>Вчера в 17:15</p> */}
								<span className={style.lastVisit_text}>
									<PublishedTime time={lastVisit || 0} />
								</span>
							</div>
						</div>
					</div>
					{isShowProduct && (
						<div className={style.productContainer}>
							<div className={style.productContainer_content}>
								<div className={style.productContainer_content_imageWrap}>
									<img
										src={productImage}
										className={style.productContainer_content_image}
									/>
								</div>
								<TruncationOfTheName name={productName} />
								<div>
									<p className={style.productContainer_content_price}>
										{productPrice} грн.
									</p>
								</div>
							</div>
						</div>
					)}
					{firstWindow ? (
						<CompainModalFirstWindow
							handleSetActiveList={handleSetActiveList}
							openList={openList}
							handleSetCurrentComplain={handleSetCurrentComplain}
							currentComplain={currentComplain}
						/>
					) : (
						<CompainModalSecondWindow
							currentMainComplain={currentMainComplain}
							currentComplain={currentComplain}
							messageValue={messageValue}
							setMessageValue={setMessageValue}
							setIsSendCompain={setIsSendCompain}
							handleSendCompain={handleSendCompain}
							error={error}
						/>
					)}
				</div>
			</div>
		);
	}
};

export default CompainModalWindow;
