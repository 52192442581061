import React from "react";
import s from "./Paginator.module.css";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination/";
import { scrollToTop } from "../../supporting";

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			"& .MuiPaginationItem-root": {
				width: "40px",
				height: "36px",
				borderRadius: "3px",
				color: "#939393",
				margin: "0 5px",
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "20px",
				"&:hover": {
					backgroundColor: "#F5F5F5",
				},
			},
			"& .Mui-selected": {
				backgroundColor: "white",
				color: "#00A9CE",
				borderColor: "#00A9CE",
			},
			"& .MuiPagination-prevButton": {
				backgroundColor: "#00A9CE",
			},
			"& li:first-child .MuiPaginationItem-root": {
				marginRight: "110px",
				backgroundColor: "#F5F5F5" /* изменение цвета фона кнопки */,
				border: "1px solid #C2C2C2",
				width: "70px",
				height: "36px",
			},
			"& li:last-child .MuiPaginationItem-root": {
				marginLeft: "110px",
				backgroundColor: "#F5F5F5" /* изменение цвета фона кнопки */,
				width: "70px",
				height: "36px",
				border: "1px solid #C2C2C2",
			},
		},
	})
);
type Props = {
	count: number;
	page: number;
	setPage: (b: number) => void;
};
const Paginator = ({ count, page, setPage }: Props) => {
	const classes = useStyles();
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		scrollToTop();
	};
	return (
		<div className={s.paginatorContainer}>
			<Pagination
				count={count}
				page={page}
				onChange={handleChange}
				variant="outlined"
				shape="rounded"
				className={classes.root}
			/>
		</div>
	);
};

export default Paginator;
