import React from "react";
import { useTranslation } from "react-i18next";
import SecondsToDate from "./SecondsToDate";
import { isToday, isYesterday } from "date-fns";

const PublishedTime = ({ time }: { time: number }) => {
	const { t } = useTranslation();
	const convertServerTime = time * 1000;
	const currentTime = new Date(convertServerTime); // текущее время
	const createHours = new Date(convertServerTime).getHours();
	const createMinutes = new Date(convertServerTime)
		.getMinutes()
		.toString()
		.padStart(2, "0");

	if (isToday(currentTime)) {
		return (
			<>
				{t("Сьогодні")} в {createHours + ":" + createMinutes}
			</>
		);
	} else if (isYesterday(currentTime)) {
		return (
			<>
				{t("Вчора")} в {createHours + ":" + createMinutes}
			</>
		);

		/*console.log('Число представляет вчерашний день.');*/
	} else {
		return (
			<>
				<SecondsToDate full={true} seconds={time} />{" "}
			</> /*в {createHours}:{createMinutes}*/
		);
	}
};

export default PublishedTime;
