import { ActionType } from "../types/reduxStoreTypes";
import { Dispatch } from "react";
import { PlacesSearchHistory, SearchDataType } from "../types/searchTypes";
import { mainSearch } from "../api/api";

const SET_CATEGORIES_LIST = "bago/search_Reducer/SET_CATEGORIES_LIST";
const SET_NOTICES_LIST = "bago/search_Reducer/SET_NOTICES_LIST";
const SET_CLEAR_ALL = "bago/search_Reducer/SET_CLEAR_ALL";
const SET_SEARCHING_NOTICE_NAME =
	"bago/search_Reducer/SET_SEARCHING_NOTICE_NAME";
const SET_COUNTERS_CATEGORIES = "bago/search_Reducer/SET_COUNTERS_CATEGORIES";
const SET_HISTORY_USER_SEARCHES =
	"bago/search_Reducer/SET_HISTORY_USER_SEARCHES";
const CLEAR_HISTORY_SEARCHES = "bago/search_Reducer/CLEAR_HISTORY_SEARCHES";
const CLEAR_HISTORY_PLACES = "bago/search_Reducer/CLEAR_HISTORY_PLACES";
const SET_HISTORY_PLACES = "bago/search_Reducer/SET_HISTORY_PLACES";
const SET_SUBMIT_SEARCH = "bago/search_Reducer/SET_SUBMIT_SEARCH";

const initialState: SearchDataType = {
	categories: [],
	notices: [],
	searchingNoticeName: "",
	categoryCounters: [],
	historyUserSearches: [],
	userPlacesHistory: [],
	submitSearch: false,
};
const search_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_CATEGORIES_LIST:
			const categoryArray = [];
			for (const key in action.data) {
				if (categoryArray.length >= 20) {
					break;
				}
				categoryArray.push(action.data[key]);
			}
			return {
				...state,
				categories: categoryArray,
			};
		case SET_NOTICES_LIST:
			return {
				...state,
				notices: action.data,
			};
		case SET_CLEAR_ALL:
			return {
				...state,
				notices: [],
				categories: [],
			};
		case SET_SEARCHING_NOTICE_NAME:
			return {
				...state,
				searchingNoticeName: action.data,
			};
		case SET_COUNTERS_CATEGORIES:
			return {
				...state,
				categoryCounters: action.data,
			};
		case SET_HISTORY_USER_SEARCHES:
			return {
				...state,
				historyUserSearches: action.data,
			};
		case CLEAR_HISTORY_SEARCHES:
			return {
				...state,
				historyUserSearches: [],
			};
		case SET_HISTORY_PLACES:
			const updatePlaceHistory = action.data.map(
				(item: PlacesSearchHistory) => {
					const parts = item.name.split(",").map((part) => part.trim());
					return {
						id: item.id,
						city: parts[0] || "",
						district: parts[1] || "",
					};
				}
			);
			return {
				...state,
				userPlacesHistory: updatePlaceHistory,
			};
		case CLEAR_HISTORY_PLACES:
			return {
				...state,
				userPlacesHistory: [],
			};
		case SET_SUBMIT_SEARCH:
			return {
				...state,
				submitSearch: action.data,
			};
		default:
			return state;
	}
};
export const setCategoriesList = (data: []) => ({
	type: SET_CATEGORIES_LIST,
	data,
});
export const setNoticesList = (data: []) => ({ type: SET_NOTICES_LIST, data });
export const setSubmitSearch = (data: boolean) => ({
	type: SET_SUBMIT_SEARCH,
	data,
});
export const setSearchingNoticeName = (data: string) => ({
	type: SET_SEARCHING_NOTICE_NAME,
	data,
});
//export const setClearList = () => ({ type: SET_CLEAR_ALL });
export const setCategoryCounters = (data: []) => ({
	type: SET_COUNTERS_CATEGORIES,
	data,
});
export const setHistoryUserSearches = (data: []) => ({
	type: SET_HISTORY_USER_SEARCHES,
	data,
});
export const setHistoryPlaces = (data: []) => ({
	type: SET_HISTORY_PLACES,
	data,
});
export const clearHistoryUserSearches = () => ({
	type: CLEAR_HISTORY_SEARCHES,
});
export const clearHistoryPlaces = () => ({ type: CLEAR_HISTORY_PLACES });

export const getSearchCategories =
	(letters: string) => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await mainSearch.getNoticesAndCatByWord(letters);
			if (response.data) {
				dispatch(setCategoriesList(response.data.categories));
				dispatch(setNoticesList(response.data.notices));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getHistoryUserSearches =
	(letters?: string) => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await mainSearch.getHistorySearches(letters);
			if (response.data.searchQueries) {
				dispatch(setHistoryUserSearches(response.data.searchQueries));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getHistoryPlaces = () => async (dispatch: Dispatch<any>) => {
	try {
		const response: any = await mainSearch.getSearchPlace();
		if (response.data.searchPlaceQueries) {
			dispatch(setHistoryPlaces(response.data.searchPlaceQueries));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export const getClearHistoryPlaces = () => async (dispatch: Dispatch<any>) => {
	try {
		const response: any = await mainSearch.clearHistoryPlaces();
		if (response.data) {
			dispatch(clearHistoryPlaces());
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export const getClearHistorySearches =
	() => async (dispatch: Dispatch<any>) => {
		try {
			const response: any = await mainSearch.clearHistorySearches();
			if (response.data) {
				dispatch(clearHistoryUserSearches());
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export default search_Reducer;
