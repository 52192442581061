import React from "react";
import { useTranslation } from "react-i18next";

const MonthYearFull = ({ seconds }: { seconds: number }) => {
	const { i18n } = useTranslation();
	const monthsRU = [
		"Январь",
		"Февраль",
		"Март",
		"Апрель",
		"Май",
		"Июнь",
		"Июль",
		"Август",
		"Сентябрь",
		"Октябрь",
		"Ноябрь",
		"Декабрь",
	];
	const monthsUa = [
		"Січень",
		"Лютий",
		"Березень",
		"Квітень",
		"Травень",
		"Червень",
		"Липень",
		"Серпень",
		"Вересень",
		"Жовтень",
		"Листопад",
		"Грудень",
	];
	const months = i18n.language === "ru" ? monthsRU : monthsUa;
	if (seconds) {
		const date = new Date(seconds && seconds * 1000); // Преобразование секунд в миллисекунды
		const month = months[date.getMonth()]; // Получение месяца (январь - 0, февраль - 1, ...)
		const year = date.getFullYear();

		return (
			<>
				<>{month}</> <>{year}</>
			</>
		);
	}
	return <> </>;
};
export default MonthYearFull;
