import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Desktop } from "../../responsive";
import s from "./Bestsellers.module.css";

type Props = {
	type: number;
	setType: (b: number) => void;
	setOnlyTop: (b: number) => void;
};

const BestsellersTabs = ({ setType, setOnlyTop, type }: Props) => {
	const { t } = useTranslation();

	return (
		<Tabs
			selectedIndex={type - 1}
			onSelect={(index: number) => {
				const type = index + 1;
				if (type < 3) {
					setType(type);
					setOnlyTop(1);
				} else {
					setType(3);
					setOnlyTop(1);
				}
			}}
			className={s.tabsContainer}
		>
			<TabList className={s.tabsPanel}>
				<Tab selectedClassName={s.active} className={s.tabsElement}>
					<button>
						<Desktop>{t("Бізнес та")} </Desktop>
						{t("Послуги")}
					</button>
				</Tab>
				<Tab selectedClassName={s.active} className={`${s.tabsElement}`}>
					<button>{t("Топ товари")}</button>
				</Tab>
				{/* <Tab  selectedClassName={s.active} className={s.tabsElement} >
                   <button >{t("Бестселери")}</button>
                </Tab> */}
				{/*<Tab selectedClassName={s.active} className={s.tabsElement}>
                    <button>{t("Акції")}</button>
                </Tab>*/}
			</TabList>
			<TabPanel></TabPanel>
			<TabPanel></TabPanel>
		</Tabs>
	);
};

export default BestsellersTabs;
