import React, { RefObject, useEffect, useRef } from "react";

type Props = {
	children: React.Component;
	close: (d: boolean) => void;
};
export const useClickOutside = <T extends HTMLElement>(
	callback: () => void
): RefObject<T> => {
	const ref = useRef<T>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [callback]);

	return ref;
};
