import React, { useState, useEffect } from "react";
import s from "./AdCardMobile.module.css";
import NoRegisterSvgSelector from "./NoRegisterSvgSelector";
import { useTranslation } from "react-i18next";
import handleSortPhrases from "./sortPhrases";
import {
	colorArray,
	registerPhrasesArray,
} from "../../data_arrays/BannersData";
interface InterfacePhrasesArray {
	text: string;
	author: string;
	advice: boolean;
	bagoTitle: boolean;
}

const RegisterAdCardAdaptive = () => {
	const { t } = useTranslation();
	const [randomPhrases, setRandomPhrases] =
		useState<InterfacePhrasesArray | null>(null);
	const [randomColor, setRandomColor] = useState("");

	useEffect(() => {
		const sortResoult = handleSortPhrases(
			registerPhrasesArray,
			"authBagoPhrasesArray"
		);
		setRandomPhrases(sortResoult);

		const randomColorIndex = Math.floor(Math.random() * colorArray.length);
		setRandomColor(colorArray[randomColorIndex]);
	}, []);

	return (
		<>
			{randomColor && (
				<div className={s.cardWrap}>
					<div
						className={s.cardContainer}
						style={{ background: `#${randomColor}` }}
					>
						<div className={s.contentRegister}>
							<div className={s.contentDescriptionWrap_register}>
								{!randomPhrases?.advice && !randomPhrases?.bagoTitle && (
									<p className={s.contentDescription}>
										{randomPhrases && t(`${randomPhrases?.text}`)}
									</p>
								)}
								{randomPhrases?.advice && (
									<p className={s.contentDescription}>
										<span className={s.boldText}>{t("Bago поради")}:</span>{" "}
										{randomPhrases && t(`${randomPhrases?.text}`)}
									</p>
								)}
								{randomPhrases?.bagoTitle && (
									<p className={s.contentDescription}>
										<span className={s.boldText}>{t("bago")} - </span>{" "}
										{randomPhrases && t(`${randomPhrases?.text}`)}
									</p>
								)}
							</div>
							<div className={s.contentAuthorWrap}>
								<p className={s.contentDescriptionAuthor}>
									{randomPhrases && t(`${randomPhrases?.author}`)}
								</p>
							</div>
						</div>
						<div className={s.logo}>
							<NoRegisterSvgSelector id={"logoBago"} />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default RegisterAdCardAdaptive;
