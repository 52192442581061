import {
	ActionType,
	categoryDataType,
	OneCategoryType,
} from "../types/reduxStoreTypes";
import { categoryAPI, priceAPI } from "../api/api";
import { Dispatch } from "redux";
import { SomeCategoriesApi } from "../types/categoriesTypes";
import { OneRubricType } from "../Components/NewRubricsBoardSelector/NewRubricsBoardSelector";
import { setFiltersManyAction, setFiltersOneIsAction } from "./notice_Reducer";
import { NavigateFunction } from "react-router";
import { setSliderPrice } from "./publicNotice_Reducer";

const SET_PRICES_LIST = "bago/priceReducer/SET_PRICES_LIST";

export type PriceStateType = {
	prices: OnePrice[] | null;
	types: string[] | null;
	autoRenewal: OnePrice[] | null;
	top: OnePrice[] | null;
	oneTimeUp: OnePrice | null;
};
export type OnePrice = {
	active: number;
	id: number;
	period: any | null;
	periodName: string;
	price: string;
	typeName: string;
	type_id: number;
	updated_at: number;
};
const initialState: PriceStateType = {
	prices: null,
	types: null,
	autoRenewal: null,
	top: null,
	oneTimeUp: null,
};

const price_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_PRICES_LIST:
			const autoRenewal = action.data?.prices.filter(
				(price: OnePrice) => price.type_id === 2
			);
			const top = action.data?.prices?.filter(
				(price: OnePrice) => price.type_id === 3
			);
			const oneTimeUp = action.data?.prices?.filter(
				(price: OnePrice) => price.type_id === 1
			);
			return {
				...state,
				prices: action.data.prices,
				types: action.data.types,
				autoRenewal: autoRenewal,
				top: top,
				oneTimeUp: oneTimeUp.length > 0 ? oneTimeUp[0] : null,
			};

		default:
			return state;
	}
};

export const setPriceList = (data: any) => ({ type: SET_PRICES_LIST, data });

export const getPriceList = () => async (dispatch: Dispatch) => {
	try {
		const response: any = await priceAPI.getIndexPrice();
		if (response?.data) {
			dispatch(setPriceList(response.data));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};

export default price_Reducer;
