import React, { useEffect, useState } from "react";
import s from "./SellerCard.module.css";
import SearchCardSvgSelector from "../Cards/FavoriteSearchCard/SearchCardSvgSelector";
import OutputADSvgSelector from "../OutputBoardAdComponents/OutputADSvgSelector";
import SellerCardPopup from "./SellerCardPopup";
import { formattingPrice } from "../../supporting";
import PublishedTime from "../TimeAndDate/PublishedTime";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";

type Props = {
	sellerCard?: {
		sellerName: string | null;
		sellerId: string;
		price?: string;
		location?: string | null;
		ownerData?: {
			online: boolean;
			time_last_visit: number | null;
			photoUrl: string | null;
		};
		views: number | null;
		isFavorite: number;
		ownerPhone: string | null;
		time_registration: number | null;
		viber: number;
		whatsapp: number;
		telegram: number;
		reputation: number | null;
		seeNow: number | null;
	} | null;
	isFavoriteHandler: (action: string) => void;
};

const SellerCard = ({ sellerCard, isFavoriteHandler }: Props) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { t } = useTranslation();
	const userView = useSelector((state: StateType) => state.userData.user);

	const handleDialogOpen = () => {
		/*document.body.classList.add('dialog-open');*/
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const sellerInfo = {
		name: sellerCard?.sellerName || "",
		sellerId: sellerCard?.sellerId || "",
		ownerPhone: sellerCard?.ownerPhone || "",
		time_registration: sellerCard?.time_registration || 0,
		viber: sellerCard?.viber || 0,
		whatsapp: sellerCard?.whatsapp || 0,
		telegram: sellerCard?.telegram || 0,
		time_last_visit: sellerCard?.ownerData?.time_last_visit || 0,
		reputation: sellerCard?.reputation || null,
		ownerImage: sellerCard?.ownerData?.photoUrl || "",
	};

	/*useEffect(() => {
        if (sellerCard?.ownerData?.time_last_visit){
            const date = new Date(sellerCard?.ownerData?.time_last_visit)
        }
    }, [sellerCard?.ownerData?.time_last_visit])*/ return (
		<div className={s.cardContainer}>
			{dialogOpen && (
				<SellerCardPopup
					sellerInfo={sellerInfo}
					dialogOpen={dialogOpen}
					dialogClose={handleDialogClose}
				/>
			)}
			<div className={s.leftSide}>
				<div className={s.leftSideTop}>
					<h4
						className={sellerCard?.ownerData?.online ? s.onLineIndicator : ""}
						onClick={handleDialogOpen}
					>
						{sellerCard?.sellerName || ""}
					</h4>
					{!sellerCard?.ownerData?.online && (
						<span>
							{t("Останній візит")}{" "}
							<PublishedTime
								time={sellerCard?.ownerData?.time_last_visit || 0}
							/>
						</span>
					)}
				</div>
				<div className={s.leftSideBottom}>
					<div className={s.leftSideBottomLocation}>
						<span>
							<SearchCardSvgSelector id={"location"} />
						</span>
						<span>{sellerCard?.location || ""}</span>
					</div>
					<div className={s.leftSideBottomButtons}>
						<button>
							<OutputADSvgSelector id={"message"} />
							<span>{t("Написати")}</span>
						</button>
						<button onClick={handleDialogOpen}>
							<OutputADSvgSelector id={"phone"} />
							<span>{t("Подзвонити")}</span>
						</button>
					</div>
				</div>
			</div>
			<div className={s.rightSide}>
				<div className={s.heart_Price}>
					{sellerCard?.sellerId && +sellerCard?.sellerId !== userView.id && (
						<>
							{sellerCard?.isFavorite.toString() === "1" ? (
								<button
									onClick={() => {
										isFavoriteHandler("remove");
									}}
								>
									<OutputADSvgSelector id={"yellowHeart"} />
								</button>
							) : (
								<button
									onClick={() => {
										isFavoriteHandler("add");
									}}
								>
									<OutputADSvgSelector id={"heart"} />
								</button>
							)}
						</>
					)}
					<div className={s.price}>
						{sellerCard?.price
							? formattingPrice(sellerCard?.price) + " грн"
							: t("Безкоштовно")}
					</div>
				</div>
				<div className={s.orderButton}>
					{/*<button>
                        <OutputADSvgSelector id={"cart"}/>
                        <span>{t("Замовити")}</span>
                    </button>*/}
				</div>
				<div className={s.seeInfoPanel}>
					<span>
						{t("Зараз дивляться") + ": " + sellerCard?.seeNow || ""} /
					</span>
					<span>
						&nbsp;{t("Переглядів")}: {sellerCard?.views}
					</span>
				</div>
			</div>
		</div>
	);
};

export default SellerCard;
