import React from "react";
import s from "./AttentionInfoPopup.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeUserInfoPhone } from "../../../redux/user_Reducer";
import PhoneInput from "react-phone-input-2";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";
import { Form, Formik } from "formik";
import { StateType } from "../../../redux/redux-store";
import LogoSvgSelector from "../../../common/LogoSVGSelectors/LogoSvgSelector";

type Props = {
	title?: string;
	closePopup: () => void;
};

const EnterPhonePopup = ({ title, closePopup }: Props) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const changeUserPhone = useSelector(() => changeUserInfoPhone);
	const isApprovePhone = useSelector(
		(state: StateType) => state.userData.successApprovePhone
	);

	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				<div className={s.topNavigate}>
					<span
						onClick={() => {
							closePopup();
						}}
					>
						<ArrowsAndCross id={"closeCross"} color={"#6D6D6D"} />
					</span>
				</div>
				{title && <div className={s.title}>{title}</div>}
				{/*<div className={s.info}> </div>*/}
				<Formik
					initialValues={{
						new_phone: "",
					}}
					onSubmit={(values, submitProps) => {
						dispatch(
							changeUserPhone(
								{ new_phone: values.new_phone },
								submitProps.setStatus
							)
						);
					}}
				>
					{({ handleSubmit, status, setFieldValue }) => {
						return (
							<>
								{isApprovePhone ? (
									<LogoSvgSelector id={"logoForPopup"} />
								) : (
									<Form onSubmit={handleSubmit}>
										<div className={s.phoneInputContainer}>
											<PhoneInput
												inputClass={s.phoneInput}
												country={"ua"}
												onChange={(value, data, event, formattedValue) => {
													setFieldValue("new_phone", formattedValue);
												}}
											/>
											{status && status.new_phone && (
												<span className={s.error}>{status.new_phone}</span>
											)}
											<div className={s.button}>
												<button>{t("Зберегти")}</button>
											</div>
										</div>
									</Form>
								)}
							</>
						);
					}}
				</Formik>
			</div>
		</div>
	);
};

export default EnterPhonePopup;
