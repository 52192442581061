import { CSSProperties, useState } from "react";
import SearchResultSvgSelector from "../../../Components/SearchResultComponents/SearchResultSvgSelector";
import { useSticky } from "../../../hooks/useSticky";
import styles from "./NavigationTabs.module.css";

interface Tab {
	key: string;
	label: string;
}

interface NavigationTabsProps {
	tabs: Tab[];
}

export const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabs }) => {
	const [navBarRef, sticky] = useSticky();

	const [activeTab, setActiveTab] = useState(tabs[0].key);
	const [grid3, setGrid3] = useState(true);
	const [row, setRow] = useState(false);

	const handleClick = (id: string) => {
		setGrid3(false);
		setRow(false);
		switch (id) {
			case "grid3":
				setGrid3(true);
				break;
			case "row":
				setRow(true);
		}
	};

	const style = {
		position: "sticky",
		top: "95px",
		display: "flex",
		flexDirection: "row",
		gap: "24px",
		width: "100%",
	} as CSSProperties;

	return (
		<nav className={styles.navContainer}>
			{tabs.map((tab) => (
				<a
					href={`#${tab.key}`}
					key={tab.key}
					className={`${styles.tabButton} ${activeTab === tab.key ? styles.activeTab : ""}`}
					onClick={() => setActiveTab(tab.key)}
				>
					{tab.label}
				</a>
			))}

			<div className={styles.resultFilterPanelViewButton}>
				<button
					onClick={() => handleClick("grid3")}
					className={grid3 ? styles.active : ""}
				>
					<SearchResultSvgSelector id={"gridButton"} />
				</button>
				<button
					onClick={() => handleClick("row")}
					className={row ? styles.active : ""}
				>
					<SearchResultSvgSelector id={"menu"} />
				</button>
			</div>
		</nav>
	);
};
