import { ReactNode } from "react";
import styles from "../SellerContacts/SellerContacts.module.css";

type Props = {
	title: string;
	onChange?: (checked: boolean) => void;
	content: ReactNode;
	bgColor?: string;
	isEdit: boolean;
};

export const CheckboxesBlock = (props: Props) => {
	const { onChange, title, content, bgColor, isEdit } = props;
	const handleCheckBox = () => {
		return;
	};

	return (
		<div
			className={styles.block}
			style={{ backgroundColor: bgColor ? bgColor : "white" }}
		>
			<div className={styles.container}>
				<div className={styles.top}>
					<p className={styles.title}>{title}</p>
					<div>{content}</div>
				</div>

				{isEdit ? <p className={styles.close}>Закрити</p> : null}
			</div>
			{isEdit ? <button className={styles.button}>Зберегти</button> : null}
		</div>
	);
};
