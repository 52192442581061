import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMeddleWare from "redux-thunk";
import { IAutomaticSelectionList } from "../types/autoSelectionTypes";
import { ICompainDataTypes } from "../types/CompainTypes";
import { noticeDataType } from "../types/noticeTypes";
import { pagesDataType } from "../types/pagesTypes";
import { publicNoticeType } from "../types/publicNoticeTypes";
import { categoryDataType } from "../types/reduxStoreTypes";
import { SearchDataType } from "../types/searchTypes";
import { settlementDataType } from "../types/settlementTypes";
import { userDataType } from "../types/userTypes";
import autoSelection_Reducer from "./autoSelection_Reducer";
import category_Reducer from "./category_Reducer";
import compain_Reducer from "./compain_Reducer";
import notice_Reducer from "./notice_Reducer";
import { notificationReducer, NotificationState } from "./notificationReducer";
import pages_Reducer from "./pages_Reducer";
import price_Reducer, { PriceStateType } from "./price_Reducer";
import publicNotice_Reducer from "./publicNotice_Reducer";
import search_Reducer from "./search_Reducer";
import settlement_Reducer from "./settlement_Reducer";
import user_Reducer from "./user_Reducer";

const reducers = combineReducers({
	userData: user_Reducer,
	categoryData: category_Reducer,
	settlementData: settlement_Reducer,
	noticeData: notice_Reducer,
	publicNotice: publicNotice_Reducer,
	pagesData: pages_Reducer,
	searchData: search_Reducer,
	autoSelection: autoSelection_Reducer,
	compain: compain_Reducer,
	priceData: price_Reducer,
	notificationData: notificationReducer,
});
export type StateType = {
	userData: userDataType;
	categoryData: categoryDataType;
	settlementData: settlementDataType;
	noticeData: noticeDataType;
	publicNotice: publicNoticeType;
	pagesData: pagesDataType;
	searchData: SearchDataType;
	autoSelection: IAutomaticSelectionList;
	compain: ICompainDataTypes;
	priceData: PriceStateType;
	notificationData: NotificationState;
};
export type Pagination = {
	currentPage: number;
	pageCount: number;
	perPage: number;
	totalCount: number;
};
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducers,
	composeWithDevTools(applyMiddleware(thunkMeddleWare))
);

export default store;
