import React from "react";
import style from "../CreateBoardADMobile.module.css";
import { useTranslation } from "react-i18next";

interface Props {
	setFieldValue: any;
	values: any;
}

const DontShowOtherAds: React.FC<Props> = ({ setFieldValue, values }) => {
	const { t } = useTranslation();
	return (
		<div className={style.otherAdsWrap}>
			<input
				id={"checkBox"}
				className={`${style.createAdCheckbox}`}
				checked={values.not_show_other === 1}
				type="checkbox"
				onChange={(e) => {
					setFieldValue("not_show_other", e.currentTarget.checked ? 1 : 0);
				}}
			/>
			<label className={style.otherAds} htmlFor={"checkBox"}>
				<span>{t("Не показувати з цим оголошенням ")}</span>
				<span className={style.otherAdsBold}>
					{t("Блок “Інші оголошення автора”")}
				</span>
			</label>
		</div>
	);
};

export default DontShowOtherAds;
