import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getSetPassword } from "../redux/user_Reducer";
import styleFile from "../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import ArrowsAndCross from "../common/ArrowsAndCross/ArrowsAndCross";
import classes from "./ForgotPassPopup/ForgotPassPopup.module.css";
import { Form, Formik } from "formik";
import PasswordFormInput from "../Components/Registration&Auth/PasswordFormInput";
import { useTranslation } from "react-i18next";

const RestoreForgottenPassByEmailPage = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const codeParam = searchParams.get("code");
	const dispatch: any = useDispatch();
	const setNewPass = useSelector(() => getSetPassword);
	//const approveInfo = useSelector((state:StateType)=>state.userData.approveEmailInfo)
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className={styleFile.wrapper}>
			<div className={styleFile.container}>
				<div className={styleFile.topNavigate}>
					<span onClick={() => navigate("/")}>
						<ArrowsAndCross id={"closeCross"} color={"#727272"} />
					</span>
				</div>

				<div className={styleFile.info}>
					<Formik
						initialValues={{
							code: "",
							password: "",
							sms_code: "",
						}}
						onSubmit={(values, submitProps) => {
							dispatch(
								setNewPass(
									codeParam || "",
									values.password,
									() => {
										navigate("/personal_cabinet/settings");
									},
									"",
									submitProps.setStatus
								)
							);
						}}
					>
						{({ values, handleSubmit, status, setFieldValue }) => {
							return (
								<Form onSubmit={handleSubmit}>
									<div className={`${styleFile.title} ${classes.title}`}>
										{t("Придумайте пароль")}
									</div>
									{status && status.error && (
										<div className={styleFile.info}>
											{" "}
											<span className={styleFile.error}>{status.error}</span>
										</div>
									)}
									<div
										className={`${classes.enterEmailInput} ${status && status.error ? classes.error : ""} `}
									>
										<PasswordFormInput
											values={{ password: values.password }}
											setFieldValue={setFieldValue}
											status={
												status && status.password_new ? status.password_new : ""
											}
										/>
									</div>
									<div className={styleFile.button}>
										<button type={"submit"}>{t("Вхід")}</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default RestoreForgottenPassByEmailPage;
