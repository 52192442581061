import React from "react";
import s from "./CabinetRubricsSelectMobile.module.css";

type Props = {
	checkedIds: number[];
	labelTxt: string;
	id: string;
	onChange: (id: number) => void;
};

const CheckboxForRubricsSelect = ({
	labelTxt,
	id,
	checkedIds,
	onChange,
}: Props) => {
	const checked = checkedIds.includes(+id);
	const idCheck = id ? id.toString() : "";
	return (
		<div className={s.checkBoxContainer}>
			<input
				className={s.checkboxInput}
				id={idCheck}
				checked={checked}
				type="checkbox"
				onChange={() => {
					onChange(+id);
				}}
			/>
			<label className={s.checkboxText} htmlFor={idCheck}></label>
			<p>{labelTxt}</p>
		</div>
	);
};
export default CheckboxForRubricsSelect;
