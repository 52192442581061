import s from "../MyOrders/MyOrders.module.css";
import React from "react";

function PersonalCabinetCardCenterTop() {
	return (
		<div className={s.cardCenter_top}>
			<div className={s.cardCenter_topLocation}>
				<span>Харьков, Хар. обл.</span>
			</div>
			<div className={s.cardCenter_topDelivery}>
				<span className={s.cardCenter_topDeliveryDepartment}>
					Нова Пошта №38
				</span>
				<span className={s.cardCenter_topDeliveryAddress}>
					Просп Александровский 166
				</span>
			</div>
		</div>
	);
}
export default PersonalCabinetCardCenterTop;
