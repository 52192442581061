import React, { useEffect, useRef, useState } from "react";
import s from "./CheckboxCategorySelect.module.css";
import CheckBoxContentPanel from "../PersonalCabinet/CheckBoxContentPanel";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { StatByCategory } from "../../types/noticeTypes";
import { useTranslation } from "react-i18next";

type Props = {
	categories: StatByCategory[];
	handleCatIds: (id: number) => void;
	catIds: number[];
};
const CheckboxCategorySelect = ({
	categories,
	handleCatIds,
	catIds,
}: Props) => {
	const [collapsed, setCollapsed] = useState(true);
	const { t } = useTranslation();
	const menuRef: React.Ref<any> = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			// Закрывать меню только если клик был снаружи области меню
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setCollapsed(true);
			}
		};
		// Добавить слушателя событий при монтировании компонента
		document.addEventListener("click", handleClickOutside);
		// Очистить слушателя событий при размонтировании компонента
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	const handleArrowButtonClick = (event: any) => {
		event.stopPropagation(); // Предотвращает всплытие события к документу
		setCollapsed(!collapsed);
	};

	return (
		<div className={s.container}>
			<div
				onClick={(event) => {
					handleArrowButtonClick(event);
					setCollapsed(!collapsed);
				}}
				className={`${s.selectInput} ${!collapsed && s.collapsedBorder}`}
			>
				{collapsed ? (
					<CheckBoxContentPanel
						id={0}
						labelTxt={t("Всі")}
						onChange={handleCatIds}
						checkedIds={catIds}
					/>
				) : (
					<div></div>
				)}
				<button
					className={s.selectArrow}
					onClick={() => setCollapsed(!collapsed)}
				>
					{collapsed ? (
						<ArrowsAndCross id={"arrowForSelect"} color={"#797979"} />
					) : (
						<ArrowsAndCross id={"closeCrossForSelect"} color={"#797979"} />
					)}
				</button>
			</div>
			{!collapsed && (
				<div className={s.selectMenuContainer} ref={menuRef}>
					<ul className={s.selectMenu}>
						<li className={s.selectMenuItem}>
							<CheckBoxContentPanel
								id={0}
								labelTxt={t("Всі")}
								onChange={handleCatIds}
								checkedIds={catIds}
							/>
						</li>
						{categories?.map((c: StatByCategory) => (
							<li key={c.id} className={s.selectMenuItem}>
								<CheckBoxContentPanel
									id={c.id}
									labelTxt={c?.name || c?.name_ru}
									onChange={handleCatIds}
									checkedIds={catIds}
								/>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default CheckboxCategorySelect;
