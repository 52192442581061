import React, { useEffect, useState } from "react";
import s from "./Advertising.module.css";
import { advertising } from "../../api/api";
/*   [1] => Головна Сторінка: 1 місце - між блоком ПП та Інфобанером 970х250
    [2] => Головна Сторінка: 2 місце між блоком Нові оголошення та переглянуті товари 970х250
    [3] => Сторінка Рубрики: 1 місце Між блоком ПП та Нові оголошення
    [4] => Сторінка Рубрики: 2 місце між блоком Нові оголошення та переглянуті товари 970х250
    [5] => Сторінка оголошення: 1 місце під блоком фото-прев'юшок 728*90 (зверніть увагу на макет)
    [6] => Сторінка оголошення: 2 місце відразу за блоком схожі оголошення 970*250
    [7] => ЛК Продавця: Місце 1
    [8] => ЛК Продавця: Місце 2
    [9] => Рубрика останнього рівня: Місце 1
    [10] => Рубрика останнього рівня: Місце 2
    [11] => Рубрика останнього рівня: Місце 3 під блоком пагінації 970*250*/

/*'ADVERT_MOBILE_PLACE_1'    => 'Главная страница: Между блоком ПП (популярный продукт) и Инфобаннером.',
    'ADVERT_MOBILE_PLACE_2'  => 'Главная страница: Между блоком Инфобанера и Новыми объявлениями.',
    'ADVERT_MOBILE_PLACE_3'  => 'Главная страница: Между блоком Новые объявления и Ранее просмотрены.',
    'ADVERT_MOBILE_PLACE_4'  => 'Главная страница рубрики: Между блоком ПП и Инфобаннером.',
    'ADVERT_MOBILE_PLACE_5'  => 'Главная страница рубрики: Между блоком Инфобанера и Новыми объявлениями.',
    'ADVERT_MOBILE_PLACE_6'  => 'Главная страница рубрики: Между блоком Новые объявления и Ранее просмотрены.',
    'ADVERT_MOBILE_PLACE_7'  => 'Конечная страница рубрики: Между горизонтальным меню и первыми карточками товара.',
    'ADVERT_MOBILE_PLACE_8'  => 'Конечная страница рубрики: После 12-й, 24-й, 48-й и 72-й карточек товаров.',
    'ADVERT_MOBILE_PLACE_9'  => 'Страница результатов поиска: Между горизонтальным меню и первыми карточками товара.',
    'ADVERT_MOBILE_PLACE_10' => 'Страница результатов поиска: После 12-й, 24-й, 48-й и 72-й карт товаров.',*/
type Props = {
	idAD: number;
	isMobile?: 1;
};

type AdvertisingState = {
	active: number;
	created_at: number;
	id: number;
	place_id: number;
	script: string;
};

const initialState: AdvertisingState = {
	active: 0,
	created_at: 0,
	id: 0,
	place_id: 0,
	script: "",
};

const Advertising: React.FC<Props> = ({ idAD, isMobile }) => {
	const [advertState, setAdvertState] =
		useState<AdvertisingState>(initialState);

	useEffect(() => {
		const getAdvertising = async () => {
			try {
				const response = await advertising.getAdvertising(idAD, isMobile);
				if (response.data.blocs.length) {
					setAdvertState(response.data.blocs[0]);
				}
			} catch (error) {
				console.error("Error fetching advertising data: ", error);
			}
		};
		getAdvertising();
	}, [idAD]);

	useEffect(() => {
		if (advertState.script) {
			const script = document.createElement("script");
			script.async = true;
			script.src =
				"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
			script.setAttribute("data-ad-client", "ca-pub-2821118184831206");
			script.setAttribute("crossorigin", "anonymous");
			document.body.appendChild(script);

			try {
				(window.adsbygoogle = window.adsbygoogle || []).push({});
				// console.log("Adsbygoogle initialized");
			} catch (e) {
				//console.error("Adsbygoogle error: ", e);
			}
		}
	}, [advertState.script]);
	if (advertState.active === 1 && advertState.script.length !== 0) {
		return (
			<div className={s.advertisingContainer}>
				<div dangerouslySetInnerHTML={{ __html: advertState.script }} />
			</div>
		);
	} else return null;
};

export default Advertising;
