import React, { useEffect } from "react";
import s from "../../pages/PersonalCabinet/PersonalCabinet.module.css";
import { NavLink } from "react-router-dom";
import { MenuElements } from "../../interfaces/interfaces";
import "../../pages/PersonalCabinet/PersonalCabinet.css";
import { useLocation } from "react-router";
import { Desktop, Mobile } from "../../responsive";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";

type PropsTypes = {
	headers: MenuElements[];
	title?: string;
	type?: string;
	setPage?: (b: number) => void;
	status?: number;
	setStatus?: (b: number) => void;
	setCatIds?: any;
};

const PersonalCabinetTopSection: React.FC<PropsTypes> = ({
	headers,
	title,
	type,
	status,
	setStatus,
	setPage,
	setCatIds,
}) => {
	const location = useLocation();
	const lastSegment = location.pathname.substr(
		location.pathname.lastIndexOf("/") + 1
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (lastSegment == "active") {
			setStatus && setStatus(30);
		} else if (lastSegment == "under_review") {
			setStatus && setStatus(10);
		} else if (lastSegment == "rejected") {
			setStatus && setStatus(20);
		} else if (lastSegment == "no_active") {
			setStatus && setStatus(40);
		}
	}, [lastSegment]);
	return (
		<div className={s.cabinetContent_Top}>
			<div>
				<h4>{title}</h4>
			</div>

			<ul className={s.cabinetContent_Top_LinkPanel}>
				<Desktop>
					{headers.map((e) => (
						<li
							key={e.name}
							className={`${s.linkPanel_element} linkPanel_element`}
						>
							<NavLink
								onClick={() => {
									setStatus && setStatus(e?.status || 30);
									setPage && setPage(1);
									setCatIds && dispatch(setCatIds([0]));
								}}
								to={e?.link || ""}
								className={status === e.status ? "active" : ""}
							>
								<span className={s.elementName}>{e.name}</span>
								<span className={s.caretElement} />
								<span className={s.elementData}>{e.elementData}</span>
							</NavLink>
						</li>
					))}{" "}
				</Desktop>

				<Mobile>
					<Swiper
						modules={[Navigation]}
						spaceBetween={10}
						slidesPerView={"auto"}
						className={s.cabinetMobileSwiper}
						style={{ marginRight: type !== "balance" ? "" : "15px" }}
					>
						{headers.map((tab, index) => (
							<SwiperSlide key={tab.name} className={s.slideMobile}>
								<li className={`${s.linkPanel_element} linkPanel_element`}>
									<NavLink
										to={tab?.link || ""}
										className={lastSegment === tab.link ? "active" : ""}
										onClick={() => {
											setStatus && setStatus(tab?.status || 30);
											setPage && setPage(1);
											setCatIds && dispatch(setCatIds([0]));
										}}
									>
										<span className={s.elementName}>{tab.name}</span>
										<span className={s.caretElement} />
										<span className={s.elementData}>{tab.elementData}</span>
									</NavLink>
								</li>
							</SwiperSlide>
						))}
					</Swiper>
				</Mobile>
			</ul>
		</div>
	);
};

export default PersonalCabinetTopSection;
