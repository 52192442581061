import React, { useState } from "react";
import s from "./SellerCard.module.css";
import SellerCardSvgSelector from "./SellerCardSvgSelector";
import { Desktop } from "../../responsive";
import { useSelector } from "react-redux";
import { stateUserType } from "../../types/reduxStoreTypes";
import { useTranslation } from "react-i18next";
import PublishedMonthYear from "../TimeAndDate/PublishedMonthYear";
import RegistrationAuthForms from "../Registration&Auth/Registration&AuthForms";
import CompainModalWindow from "../CompainModalWindow/CompainModalWindow";

type PropsType = {
	dialogOpen: boolean;
	dialogClose: () => void;
	sellerInfo?: {
		name: string;
		ownerPhone: string;
		time_registration: number;
		viber: number;
		whatsapp: number;
		telegram: number;
		time_last_visit: number;
		reputation: number | null;
		ownerImage: string | null;
		sellerId: string | null;
	};
};

const SellerCardPopup: React.FC<PropsType> = ({
	dialogClose,
	dialogOpen,
	sellerInfo,
}) => {
	const { t } = useTranslation();
	const [openRegAuthPopup, setOpenRegAuthPopup] = useState(false);
	const [openCompainModal, setOpenCompainModal] = useState(false);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);

	const openEnterDialog = () => {
		setOpenRegAuthPopup(true);
	};
	const handleClose = () => {
		setOpenRegAuthPopup(false);
	};

	const handleOpenLogin = () => {
		openEnterDialog();
	};

	const handleOpenCompainModal = () => {
		setOpenCompainModal(true);
	};
	const handleCloseCompainModal = () => {
		setOpenCompainModal(false);
	};

	const formattingPhone = (phone: string) => {
		return phone.replace(/[()]/g, "").replace(/\s/g, "").replace(/\+/g, "");
	};

	return (
		<div className={s.wrapper}>
			<div className={s.sellerCardPopupContainer}>
				<div className={s.sellerCardPopupTop}>
					<div className={s.titleTop}>
						<div>{t("Продавець")}</div>
						<span onClick={dialogClose}>
							<SellerCardSvgSelector id={"close"} />
						</span>
					</div>
					<h4>{sellerInfo?.name || ""}</h4>
				</div>
				<div className={s.sellerCardPopupContent}>
					<div className={s.aboutSellerContainer}>
						{/*<div className={s.verifiedSeller}>
                                <SellerCardSvgSelector id={"greenDone"}/>
                                <span>Перевірений продавець</span>
                            </div>*/}

						<div className={s.presentInService}>
							На
							<b> bago</b> {t("з")}{" "}
							<PublishedMonthYear
								seconds={sellerInfo?.time_registration || 0}
							/>
						</div>
						{/* <div className={s.deals}>Угоди <span>156</span></div>*/}
						{sellerInfo?.reputation !== null && (
							<div className={s.provenReputation}>
								{t(`Перевірена репутація з `)}
								{sellerInfo?.reputation}
								{/* <PublishedYear seconds={sellerInfo?.reputation || 0} />*/}
							</div>
						)}
						{/*<div className={s.useSafeDeal}>
                                <SellerCardSvgSelector id={"useSafeDeal"}/>
                                <span>Використовуйте <Link to={"/"}>Безпечну угоду</Link></span>
                            </div>*/}
						<div className={s.recommendYou}>{t("Радимо вам")}:</div>
						<div className={s.attention}>
							<SellerCardSvgSelector id={"attention"} />
							<span>{t("Не давайте нікому дані банківської картки")}</span>
						</div>
						<div className={s.contacts}>
							<ul className={s.phoneNumbers}>
								<li>{sellerInfo?.ownerPhone}</li>
							</ul>
							<Desktop>
								<ul className={s.socialLinks}>
									<li>
										{sellerInfo?.viber === 1 ? (
											<a
												href={`viber://chat?number=%2B${formattingPhone(sellerInfo?.ownerPhone || "")}`}
												target={"_blank"}
											>
												<SellerCardSvgSelector
													id={"viber"}
													fill={
														sellerInfo?.viber === 1
															? `#7D3DAF`
															: sellerInfo?.viber === 0
																? "#BDBDBD"
																: ""
													}
												/>
											</a>
										) : (
											<SellerCardSvgSelector
												id={"viber"}
												fill={
													sellerInfo?.viber === 1
														? `#7D3DAF`
														: sellerInfo?.viber === 0
															? "#BDBDBD"
															: ""
												}
											/>
										)}
										{sellerInfo?.telegram === 1 ? (
											<a
												href={`https://t.me/%2B${formattingPhone(sellerInfo?.ownerPhone || "")}`}
												target={"_blank"}
											>
												<SellerCardSvgSelector
													id={"telegram"}
													fill={
														sellerInfo?.telegram === 1
															? `#2da0d1`
															: sellerInfo?.telegram === 0
																? "#BDBDBD"
																: ""
													}
												/>
											</a>
										) : (
											<SellerCardSvgSelector
												id={"telegram"}
												fill={
													sellerInfo?.telegram === 1
														? `#2da0d1`
														: sellerInfo?.telegram === 0
															? "#BDBDBD"
															: ""
												}
											/>
										)}
										{sellerInfo?.whatsapp === 1 ? (
											<a
												href={`https://api.whatsapp.com/send?phone=%2B${formattingPhone(sellerInfo?.ownerPhone || "")}`}
												target={"_blank"}
											>
												<SellerCardSvgSelector
													id={"whatsApp"}
													fill={
														sellerInfo?.whatsapp === 1
															? `#17970f`
															: sellerInfo?.whatsapp === 0
																? "#BDBDBD"
																: ""
													}
												/>
											</a>
										) : (
											<SellerCardSvgSelector
												id={"whatsApp"}
												fill={
													sellerInfo?.whatsapp === 1
														? `#17970f`
														: sellerInfo?.whatsapp === 0
															? "#BDBDBD"
															: ""
												}
											/>
										)}
									</li>
									{/*<li>
                                            <SellerCardSvgSelector id={"viber"}/>
                                            <SellerCardSvgSelector id={"whatsApp"}/>
                                        </li>*/}
								</ul>
							</Desktop>
						</div>
						<div className={s.buttons}>
							{/*<div className={s.buttonAddVisit}>
                                    <button onClick={()=>setFavoriteSeller(!favoriteSeller)} className={s.addSellerToBusinessCards}>
                                        {favoriteSeller ?
                                            <SellerCardSvgSelector id={"yellowHeartSelected"}/> :
                                            <SellerCardSvgSelector id={"yellowHeart"}/>
                                        }
                                        <span>Додати продавця до візиток</span>
                                    </button>
                                </div>*/}
							{/*<div className={s.buttonAddTrack}>
                                    <button onClick={()=>setTrackSeller(!trackSeller)} className={s.trackNewItems}>
                                        {trackSeller ?
                                            <SellerCardSvgSelector id={"yellowHeartSelected"}/> :
                                            <SellerCardSvgSelector id={"yellowHeart"}/>
                                        }
                                        <SearchCardSvgSelector id={"heart"}/>
                                        <span>Відстежувати нові товари
                                               продавця </span>
                                    </button>
                                </div>*/}
						</div>
					</div>
				</div>
				<div className={s.sellerCardPopupBottom}>
					{auth ? (
						<div className={s.complain} onClick={handleOpenCompainModal}>
							<SellerCardSvgSelector id={"complain"} />
							<span>{t("Поскаржитись")}</span>
						</div>
					) : (
						<div className={s.complain} onClick={handleOpenLogin}>
							<SellerCardSvgSelector id={"complain"} />
							<span>{t("Поскаржитись")}</span>
						</div>
					)}
				</div>
			</div>
			{openRegAuthPopup && (
				<RegistrationAuthForms
					dialogClose={handleClose}
					dialogOpen={openRegAuthPopup}
					loginOrRegistration={""}
				/>
			)}
			{openCompainModal && (
				<CompainModalWindow
					dialogClose={handleCloseCompainModal}
					dialogOpen={openCompainModal}
					sellerName={sellerInfo?.name ?? ""}
					lastVisit={sellerInfo?.time_last_visit || 0}
					ownerImage={sellerInfo?.ownerImage ?? ""}
					userId={sellerInfo?.sellerId ?? ""}
					productImage={""}
					productName={""}
					productPrice={""}
					isShowProduct={false}
					idForCreate={0}
				/>
			)}
		</div>
	);
};

export default SellerCardPopup;
