import React, { useEffect, useState } from "react";
import s from "./MyFavoriteMobile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import {
	getFilteredNoticeList,
	setAllFavoriteList,
} from "../../../redux/publicNotice_Reducer";
import { scrollToTop } from "../../../supporting";
import ExpandModernButton from "../../ExpandButton/ExpandModernButton";
import useCatIdsHandler from "../../../CustomHooks/useCatIdsHandler";
import ProductCardHorizontalForSearchMobile from "../../Cards/ProductCard/ProductCardHorizontalForSearchMobile";
import MobileProductCard from "../../Cards/ProductCard/MobileProductCard";
import CabinetTopFiltersBlock from "../CabinetTopFiltersBlock/CabinetTopFiltersBlock";
const MyFavoriteMobile = () => {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [row, setRow] = useState(false);
	const dispatch: any = useDispatch();
	const favoriteList = useSelector(
		(state: StateType) => state.publicNotice.noticeListFavorite
	);
	const favoriteAllList = useSelector(
		(state: StateType) => state.publicNotice.noticeAllListFavorite
	);
	const favoriteMeta = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const getFavoriteList = useSelector(() => getFilteredNoticeList);
	const categories = useSelector(
		(state: StateType) => state.publicNotice.favoriteStatByCategory
	);
	const catIdsForHook = useSelector(
		(state: StateType) => state.publicNotice.favoriteStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);

	useEffect(() => {
		dispatch(
			getFavoriteList({
				onlyFavorite: "1",
				perPage: `${48}`,
				page: page.toString(),
				catIds: catIds,
			})
		);
		scrollToTop();
	}, [page, catIds, perPage, favoriteMeta.perPage]);

	useEffect(() => {
		if (favoriteList && favoriteList.length) {
			if (page == 0 || page == 1) {
				dispatch(setAllFavoriteList(favoriteList));
			} else {
				const newArr = [...favoriteAllList, ...favoriteList];
				const arrayLength = newArr.length;
				const limit = 150;
				if (arrayLength > limit) {
					const difference = arrayLength - limit;
					const slicedArr = newArr.slice(difference); // Зберігаємо результат виклику slice
					dispatch(setAllFavoriteList(slicedArr));
				} else {
					dispatch(setAllFavoriteList(newArr));
				}
			}
		}
	}, [favoriteList]);

	return (
		<div className={s.favoriteMobileProductContainer}>
			<div>
				<CabinetTopFiltersBlock
					row={row}
					setRow={setRow}
					type={"favorite"}
					handleCatIds={handleCatIds}
					catIds={catIds}
					categories={categories}
				/>
			</div>
			{!row && (
				<div className={s.cardsContainerRow}>
					{favoriteAllList.map((item) => (
						<ProductCardHorizontalForSearchMobile notice={item} key={item.id} />
					))}
				</div>
			)}
			{row && (
				<div className={s.cardsContainerColumn}>
					{favoriteAllList.map((item) => (
						<MobileProductCard notice={item} key={item.id} />
					))}
				</div>
			)}
			{favoriteMeta.pageCount > favoriteMeta.currentPage && (
				<div className={s.paginatorContainer}>
					<ExpandModernButton
						onClickCallback={() => {
							setPage((prevState) => prevState + 1);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default MyFavoriteMobile;
