import React from "react";
import Select, { components } from "react-select";

const SearchLocationMobileSelect = () => {
	const SingleValue = ({ children, ...props }: any) => (
		<components.SingleValue {...props}>
			<svg
				style={{ marginRight: "3px" }}
				width="8"
				height="10"
				viewBox="0 0 8 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4 9.5C3.9949 9.5 3.99115 9.4991 3.98906 9.49841C3.43698 8.7481 2.97057 8.12525 2.57606 7.59843C1.87748 6.66554 1.40435 6.03373 1.08017 5.52837C0.833025 5.14311 0.693362 4.86174 0.611869 4.60376C0.531815 4.35033 0.5 4.09449 0.5 3.75C0.5 1.98497 2.03611 0.5 4 0.5C5.96389 0.5 7.5 1.98497 7.5 3.75C7.5 4.09449 7.46819 4.35033 7.38813 4.60376C7.30664 4.86174 7.16697 5.14311 6.91983 5.52837C6.59565 6.03373 6.12252 6.66555 5.42394 7.59843C5.02943 8.12526 4.56302 8.7481 4.01093 9.49841C4.00885 9.4991 4.0051 9.5 4 9.5ZM4 5.8125C5.16573 5.8125 6.16667 4.919 6.16667 3.75C6.16667 2.581 5.16573 1.6875 4 1.6875C2.83427 1.6875 1.83333 2.581 1.83333 3.75C1.83333 4.919 2.83427 5.8125 4 5.8125Z"
					fill="#939393"
					stroke="#939393"
				/>
			</svg>
			{children}
		</components.SingleValue>
	);
	return (
		<Select
			components={{
				SingleValue,
				IndicatorSeparator: () => null,
				DropdownIndicator: () => null,
			}}
			options={[{ value: "2", label: "Хrельницкий" }]}
			defaultValue={[
				{
					value: "1",
					label: "Україна",
				},
			]}
			styles={{
				control: (baseStyles) => ({
					...baseStyles,
					borderColor: "#FFFFFF",
					paddingBottom: "3px",
					/*paddingRight:"6px",*/
					borderRadius: "5px",
					width: "88px",
					height: "22px",
					minHeight: "22px",
					boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.03)",
					caretColor: "transparent",
					"&:hover": {
						/*backgroundColor: "#3D3D3D",*/
						borderColor: "none",
					},
				}),
				singleValue: (styles) => {
					return {
						...styles,
						fontFamily: "var(--myMainFontRegular)",
						fontSize: "10px",
						width: "88px",
						height: "22px",
						color: "#727272",
						marginBottom: "4px",
						/*borderColor: state.isDisabled ? "none" : "none",*/
					};
				},
				valueContainer: (styles) => {
					return {
						...styles,
						width: "88px",
						height: "22px",
						color: "#727272",
						padding: "2px 3px",
						overflow: "initial",
					};
				},
				input: (styles) => {
					return {
						...styles,
						/*width: "88px",
                    height:"22px",*/
						/* marginRight:"",
                    paddingRight:"35px",
                    paddingLeft:"2px"*/
					};
				},

				option: (base) => ({
					...base,
					boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
					borderRadius: "5px",
				}),
			}}
		/>
	);
};

export default SearchLocationMobileSelect;
