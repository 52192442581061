import React, { useEffect } from "react";
import style from "./CompainModalWindow.module.css";
import CompainModalWindowSvg from "./CompainModalWindowSvg";
import CabinetAccordionSvgSelector from "../PersonalCabinet/PersonalCabinetAccordion/CabinetAccordionSvgSelector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getComplaints } from "../../redux/compain_Reducer";
import { StateType } from "../../redux/redux-store";
import {
	IComplaintsCategoriesItem,
	IComplaintsCausesItem,
} from "../../types/CompainTypes";

/*interface InterfacecompainsArry {
    name: string,
    id: string,
    list: string[]
}*/

type PropsType = {
	handleSetActiveList: (id: number, name: string) => void;
	handleSetCurrentComplain: (complain: any) => void;
	openList: number;
	currentComplain: IComplaintsCausesItem;
};

const CompainModalFirstWindow: React.FC<PropsType> = ({
	handleSetActiveList,
	openList,
	handleSetCurrentComplain,
	currentComplain,
}) => {
	const mainCategory = useSelector(
		(state: StateType) => state.compain.ComplaintsCategoriesList
	);
	const subCategory = useSelector(
		(state: StateType) => state.compain.ComplaintsCausesList
	);
	const getComplaintsList = useSelector(() => getComplaints);
	const { t } = useTranslation();
	const dispatch: any = useDispatch();

	useEffect(() => {
		dispatch(getComplaintsList());
	}, []);

	return (
		<div className={`${style.compainListWrap} ${style.retreat}`}>
			{mainCategory.map((item: IComplaintsCategoriesItem) => (
				<div key={item.id.toString()}>
					<div
						className={style.compainListItemWrap}
						onClick={() =>
							handleSetActiveList(Number(item.id), item.title.toString())
						}
					>
						<p className={style.compainListItem_text}>{t(`${item.title}`)}</p>
						<div
							className={`${style.arrowSvg} ${openList == item.id && style.arrowSvgActive}`}
						>
							<CabinetAccordionSvgSelector id={"expandIcon"} />
						</div>
					</div>
					{openList == item.id &&
						subCategory
							.filter(
								(listFilter: IComplaintsCausesItem) =>
									listFilter.categoryId === item.id
							)
							.map((list: IComplaintsCausesItem) => (
								<div
									key={list.id.toString()}
									className={style.openListItemWrap}
									onClick={() => handleSetCurrentComplain(list)}
								>
									<p className={style.openListItem}>{t(`${list.title}`)}</p>
									{currentComplain.id === list.id && (
										<CompainModalWindowSvg id={"checkMark"} />
									)}
								</div>
							))}
				</div>
			))}
		</div>
	);
};

export default CompainModalFirstWindow;
