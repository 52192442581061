import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getFilteredNoticeList,
	setFilteredNoticesAllList,
} from "../../redux/publicNotice_Reducer";
import { Pagination, StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { publicOneNotice } from "../../types/publicNoticeTypes";
import ProductCardGrid from "../Cards/FavoriteSearchCard/ProductCardGrid";
import MobileProductCard from "../Cards/ProductCard/MobileProductCard";
import ExpandButton from "../ExpandButton/ExpandButton";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import s from "./TopAdverts.module.css";

type Props = {
	title: string;
	noticesPag?: Pagination;
	onClick?: () => void;
	notices?: publicOneNotice[];
};

export const TopAdverts = ({ title, notices, onClick, noticesPag }: Props) => {
	const [isExpanded, setExpanded] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const getNotices: any = useSelector(() => getFilteredNoticeList);
	const noticesAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const dispatch: any = useDispatch();

	useEffect(() => {
		if (notices && notices.length) {
			if (currentPage === 0 || currentPage === 1) {
				dispatch(setFilteredNoticesAllList(notices));
			} else {
				const newArr = [...noticesAllList, ...notices];
				dispatch(setFilteredNoticesAllList(newArr));
			}
		}
	}, [notices]);

	// Get top products
	const topNotices = noticesAllList
		?.slice(0, 4)
		.filter((n) => n.top_days !== null);

	let noticesToDisplay = topNotices;

	if (!noticesToDisplay || noticesToDisplay.length === 0) {
		// No top products, get other products sorted by date added
		const otherNotices = noticesAllList?.filter((n) => n.top_days === null);
		if (otherNotices && otherNotices.length > 0) {
			noticesToDisplay = otherNotices.sort(
				(a, b) => (b.created_at || 0) - (a.created_at || 0)
			);
		} else {
			// No products to display
			noticesToDisplay = [];
		}
	}

	return (
		<div className='__container'>
			<div className={`${s.newAdvertsContainer} `}>
				<h5>{title}</h5>
				<div className={s.newAdvertsContent}>
					<Desktop>
						{noticesToDisplay.map((i) => (
							<div key={i.id}>
								<ProductCardGrid grid={"3"} notice={i} />
							</div>
						))}
					</Desktop>
				</div>
				<div className={s.bestsellersContainerTopPanel}>
					<Mobile>
						{noticesToDisplay.map((i) => (
							<MobileProductCard notice={i} key={i.id} />
						))}
					</Mobile>
				</div>
				{noticesPag && noticesPag.pageCount > 1 && (
					<div className={s.newAdvertsBottomPanel}>
						<Mobile>
							<ExpandModernButton
								onClickCallback={() => {
									dispatch(
										getNotices({
											page: `${currentPage + 1}`,
											perPage: "60",
											sortType: "1",
										})
									);
									setCurrentPage((state) => state + 1);
								}}
							/>
						</Mobile>
						<Desktop>
							<ExpandButton
								isExpanded={isExpanded}
								setExpanded={setExpanded}
								onClick={() => {
									dispatch(
										getNotices({
											page: `${currentPage + 1}`,
											perPage: "60",
											sortType: "1",
										})
									);
									setCurrentPage((state) => state + 1);
								}}
							/>
						</Desktop>
					</div>
				)}
			</div>
		</div>
	);
};
