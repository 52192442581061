import React from "react";
import s from "../../MyOrders/MyOrdersCardMobile/MyOrdersCardMobile.module.css";

const ApprovedForm = ({
	setOrderApproved,
	orderApproved,
}: {
	orderApproved: boolean;
	setOrderApproved: (b: boolean) => void;
}) => {
	return (
		<form
			className={s.approvedForm}
			onSubmit={() => setOrderApproved(!orderApproved)}
		>
			<input placeholder={"Введіть ТТН..."} type="text" />
			<button type={"submit"}>відправити</button>
		</form>
	);
};

export default ApprovedForm;
