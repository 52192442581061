import React, { useState } from "react";
import styleFile from "../Components/CommonPopups/AttentionInfoPopup/AttentionInfoPopup.module.css";
import classes from "./ForgotPassPopup/ForgotPassPopup.module.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import style from "../GlobalStyles.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../redux/redux-store";
import {
	approveNewPhoneAfterChange,
	getEnterSmsCode,
	setApprovePhonePopup,
	setSuccessApproveNumber,
} from "../redux/user_Reducer";
import Timer from "../Components/CommonPopups/AttentionInfoPopup/Timer";
import LogoSvgSelector from "../common/LogoSVGSelectors/LogoSvgSelector";
import ArrowsAndCross from "../common/ArrowsAndCross/ArrowsAndCross";
import s from "../Components/Registration&Auth/PasswordFormInput.module.css";
import { useNavigate } from "react-router";

const ApprovePhonePopup = ({
	closeModal,
	approveNewPhone,
}: {
	closeModal?: (b: boolean) => void;
	approveNewPhone?: string;
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch: any = useDispatch();
	const getEnterCode = useSelector(() => getEnterSmsCode);
	const approvePhone = useSelector(() => approveNewPhoneAfterChange);
	const successApproveNumber = useSelector(() => setSuccessApproveNumber);
	const handleApprovePhonePopup = useSelector(() => setApprovePhonePopup);
	const isApprovePhone = useSelector(
		(state: StateType) => state.userData.successApprovePhone
	);
	const tempPhone = useSelector(
		(state: StateType) => state.userData.tempRegPhone
	);
	const approvePhoneResultMessage = useSelector(
		(state: StateType) => state.userData.result
	);
	const [time, setTime] = useState(240); // 4 минуты в секундах (4 минуты * 60 секунд)
	const validate = (values: any) => {
		const errors: any = { code: "" };

		if (!values.code) {
			errors.code = t("Код не може бути пустий");
		} else if (/\s/.test(values.code)) {
			errors.code = t("Код не має містити пробіли");
		} else if (values.code.length < 6) {
			errors.code = t("Код має бути не меньше 6 символів");
		}
		// Другие правила валидации по необходимости

		return errors.code;
	};
	return (
		<div className={styleFile.wrapper}>
			<div className={styleFile.container}>
				<div className={classes.closeCross}>
					<span
						onClick={() => {
							dispatch(setApprovePhonePopup(false));
						}}
					>
						<ArrowsAndCross id={"closeCross"} color={"#6D6D6D"} />
					</span>
				</div>
				<div className={`${styleFile.title} ${classes.title}`}>
					{isApprovePhone
						? t("Телефон успішно підтверджено!")
						: t("Введіть код підтвердження")}
				</div>

				<Formik
					initialValues={{
						code: "",
						phone: tempPhone,
					}}
					onSubmit={(values, submitProps) => {
						if (approveNewPhone === "yes" || values.phone === "") {
							dispatch(approvePhone(values.code));
						} else {
							dispatch(
								getEnterCode(+values?.code, values.phone, submitProps.setStatus)
							);
						}
					}}
				>
					{({ values, handleSubmit, status }) => {
						return (
							<>
								{isApprovePhone ? (
									<div></div>
								) : (
									<>
										{approvePhoneResultMessage.length !== 0 && (
											<div className={styleFile.info}>
												{approvePhoneResultMessage}
												{/*<span className={classes.phoneStyle}>{values.phone}</span>*/}
											</div>
										)}

										<div className={classes.timerContainer}>
											<Timer
												time={time}
												setTime={setTime}
												phone={values.phone}
												approve={true}
											/>
										</div>
									</>
								)}
								<Form onSubmit={handleSubmit}>
									{isApprovePhone ? (
										<div>
											<LogoSvgSelector id={"logoForPopup"} />
										</div>
									) : (
										<>
											<div
												className={`${classes.enterEmailInput} ${status && status.error ? classes.error : ""} `}
											>
												<Field
													name={"code"}
													validate={() => validate(values)}
													value={values.code}
													placeholder={t("Введіть код")}
												/>
												{status && status.error && (
													<span className={style.error}>{status.error}</span>
												)}
												<ErrorMessage
													name="code"
													component="span"
													className={s.error}
												/>
											</div>
										</>
									)}
									<div className={styleFile.button}>
										<button
											onClick={(event) => {
												if (isApprovePhone) {
													event.preventDefault();
													closeModal && closeModal(true);
													dispatch(successApproveNumber(false));
													dispatch(handleApprovePhonePopup(false));
													navigate("/personal_cabinet/settings");
												}
											}}
										>
											{isApprovePhone ? t("Далі") : t("Відправити")}
										</button>
									</div>
								</Form>
							</>
						);
					}}
				</Formik>
			</div>
		</div>
	);
};

export default ApprovePhonePopup;
