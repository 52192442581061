import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { stateUserType } from "../types/reduxStoreTypes";

const ApprovedEmailPopup = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const codeParam = searchParams.get("code");
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const navigate = useNavigate();
	useEffect(() => {
		if (!auth && codeParam) {
		}

		if (auth) {
			navigate("/personal_cabinet/settings");
		}
	}, [auth]);

	return <div></div>;
};

export default ApprovedEmailPopup;
