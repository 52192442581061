import { Dispatch } from "redux";
import { userNotice } from "../api/api";
import {
	noticeDataType,
	NoticeType,
	TmpImage,
	UserNoticeListParams,
} from "../types/noticeTypes";
import { ActionType } from "../types/reduxStoreTypes";
import { valuesCreateAD } from "../types/userTypes";
import {
	setClearFiltersList,
	setFiltersList,
	setFiltersVariables,
} from "./category_Reducer";

import i18n from "i18next";
import { setNotification } from "./notificationReducer";
import { getUserStaticView } from "./user_Reducer";

const CREATE_NOTICE = "bago/noticeReducer/CREATE_NOTICE";
const SET_NOTICES_LIST = "bago/noticeReducer/SET_NOTICES_LIST";
const SET_NOTICES_All_LIST = "bago/noticeReducer/SET_NOTICES_All_LIST";
const SET_NOTICES_META = "bago/noticeReducer/SET_NOTICES_META";
const SET_NOTICE_VIEW = "bago/noticeReducer/SET_NOTICE_VIEW";

const SET_EDIT_NOTICE_PRICE = "bago/noticeReducer/SET_EDIT_NOTICE_PRICE";
const SET_EDIT_NOTICE_STATUS = "bago/noticeReducer/SET_EDIT_NOTICE_STATUS";

const SET_IMAGES_LIST = "bago/noticeReducer/SET_IMAGES_LIST";

const SET_TMP_IMAGE = "bago/noticeReducer/SET_TMP_IMAGES_LIST";
const SET_CLEAR_TMP = "bago/noticeReducer/SET_CLEAR_TMP";
const DELETE_TMP_IMAGE = "bago/noticeReducer/DELETE_TMP_IMAGE";

const SET_CATEGORIES_IDS = "bago/noticeReducer/SET_CATEGORIES_IDS";

const SET_CREATE_SUCCESSFUL_FALSE =
	"bago/noticeReducer/SET_CREATE_SUCCESSFUL_FALSE";
const SET_AUTO_CONTINUE = "bago/noticeReducer/SET_AUTO_CONTINUE";

const SET_NOTICE_PER_VIEW = "bago/noticeReducer/SET_NOTICE_PER_VIEW";
const SET_BACKEND_NOTICE_PER_VIEW =
	"bago/noticeReducer/SET_BACKEND_NOTICE_PER_VIEW";
const SET_ONE_IS_FILTERS_LIST = "bago/noticeReducer/SET_ONE_IS_FILTERS_LIST";
const SET_MANY_IS_FILTERS_LIST = "bago/noticeReducer/SET_MANY_IS_FILTERS_LIST";
const SET_FILTERS_ALL_LIST = "bago/noticeReducer/SET_FILTERS_ALL_LIST";
const SET_DELETE_NOTICE = "bago/noticeReducer/SET_DELETE_NOTICE";
const SET_BUY_SERVICE = "bago/noticeReducer/SET_BUY_SERVICE";
const SET_OFF_SERVICE = "bago/noticeReducer/SET_OFF_SERVICE";

const initialState: noticeDataType = {
	notice: {},
	noticeList: [],
	noticeListAllList: [],
	noticeListMeta: {
		currentPage: 0,
		pageCount: 0,
		perPage: 0,
		totalCount: 0,
	},
	createdSuccess: false,
	images: [],
	tmpImages: [],
	tmpFileDeleteResult: false,
	tmpNoticeForPerView: {
		title: "",
		sellerName: "",
		price: "",
		description: "",
		categoryId: "",
		categoryName: "",
		price_type: "",
		settlementId: "",
		settlementName: "",
		state: "",
		ownerData: { online: false, time_last_visit: null, photoUrl: null },
		filters: [],
	},
	tmpBackendNoticeForPerView: {
		amount: null,
		auto_continue: 0,
		auto_lift_at: null,
		auto_lift_days: null,
		category_id: null,
		category_name: "",
		created_at: null,
		created_by: null,
		delivery_ids: null,
		description: "",
		exchange: null,
		finished_at: null,
		id: null,
		image_dir: "",
		name: "",
		not_show_other: 0,
		noticeImages: [],
		phone_shows: null,
		price: "",
		price_type: "",
		price_typeName: "",
		settlement_id: null,
		settlement_name: "",
		start_at: null,
		state: "",
		stateName: "",
		status: null,
		statusName: "",
		top_at: null,
		top_days: null,
		trade: null,
		updated_at: null,
		updated_by: null,
		userName: "",
		users_id: null,
		views: null,
		moderation_description: "",
		tree: [],
		categoryBlocs: [],
		slug: "",
		short_settlement_name: "",
	},
	noticeView: {
		amount: null,
		auto_continue: 0,
		auto_lift_at: null,
		auto_lift_days: null,
		category_id: null,
		category_name: "",
		created_at: null,
		created_by: null,
		delivery_ids: null,
		description: "",
		exchange: null,
		finished_at: null,
		id: null,
		image_dir: "",
		name: "",
		not_show_other: 0,
		noticeImages: [],
		phone_shows: null,
		price: "",
		price_type: "",
		price_typeName: "",
		settlement_id: null,
		settlement_name: "",
		start_at: null,
		state: "",
		stateName: "",
		status: null,
		statusName: "",
		top_at: null,
		top_days: null,
		trade: null,
		updated_at: null,
		updated_by: null,
		userName: "",
		users_id: null,
		views: null,
		moderation_description: "",
		tree: [],
		categoryBlocs: [],
		slug: "",
		short_settlement_name: "",
	},
	filtersMany: [],
	filtersOneIs: [],
	filtersAll: [],
	userStatByCategory: [],
	userStatByCategoryIds: [0],
	viewedStatistic: [],
	viewedStatisticIds: [0],
	recentlyWatchedList: [],
};

const notice_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case CREATE_NOTICE:
			return {
				...state,
				notice: action?.data,
				createdSuccess: true,
			};
		case SET_NOTICES_LIST:
			return {
				...state,
				noticeList: action.data.data.items,
				userStatByCategory: action.data.userStatByCategory,
				viewedStatistic: action.data.viewedStatistic,
			};
		case SET_NOTICES_All_LIST:
			return {
				...state,
				noticeListAllList: action.data,
			};
		case SET_CATEGORIES_IDS:
			return {
				...state,
				userStatByCategoryIds: action.data,
				viewedStatistic: action.data,
			};
		case SET_EDIT_NOTICE_PRICE:
			return {
				...state,
				noticeList: state.noticeList.map((item) =>
					item.id === action.data.id
						? { ...item, price: action.data.price }
						: item
				),
			};
		case SET_NOTICES_META:
			return {
				...state,
				noticeListMeta: action.data,
			};
		case SET_IMAGES_LIST:
			return {
				...state,
				images: action.data,
			};

		case SET_TMP_IMAGE:
			return {
				...state,
				tmpImages: [...state.tmpImages, action.data.tmpFile],
			};
		case SET_CLEAR_TMP:
			return {
				...state,
				tmpImages: [],
			};
		case DELETE_TMP_IMAGE:
			return {
				...state,
				tmpFileDeleteResult: action.data.tmpFileDeleteResult,
				tmpImages: state.tmpImages.filter(
					(i: any) => i.tmpFileName !== action?.name
				),
			};
		case SET_CREATE_SUCCESSFUL_FALSE:
			return {
				...state,
				createdSuccess: false,
			};
		case SET_NOTICE_PER_VIEW:
			return {
				...state,
				tmpNoticeForPerView: action.data,
			};
		case SET_BACKEND_NOTICE_PER_VIEW:
			const notice_properties = action.data.filters;
			const images = action.data.images;
			const notice = action.data.notice;
			const oneNotice = { ...notice, notice_properties, noticeImages: images };
			return {
				...state,
				tmpBackendNoticeForPerView: oneNotice,
				perViewSuccess: true,
			};
		case SET_NOTICE_VIEW:
			return {
				...state,
				noticeView: action.data,
			};
		case SET_ONE_IS_FILTERS_LIST:
			return {
				...state,
				filtersOneIs: action.data,
			};
		case SET_MANY_IS_FILTERS_LIST:
			return {
				...state,
				filtersMany: action.data,
			};
		case SET_FILTERS_ALL_LIST:
			return {
				...state,
				filtersAll: action.data,
			};
		case SET_EDIT_NOTICE_STATUS:
			return {
				...state,
				noticeList: state.noticeList.filter(
					(item) => item.id !== action.data.id
				),
			};

		case SET_AUTO_CONTINUE:
			return {
				...state,
				noticeList: state.noticeList.map((item) =>
					item.id === action.data.id
						? { ...item, auto_continue: action.data.state }
						: item
				),
			};
		case SET_DELETE_NOTICE:
			return {
				...state,
				noticeListAllList: state.noticeListAllList.filter(
					(i: any) => i.id !== action.data
				),
			};
		case SET_BUY_SERVICE:
			const newBuyList = state.noticeList.map((el: NoticeType) =>
				el.id === +action.data.id ? action.data : el
			);
			return {
				...state,
				noticeList: newBuyList,
			};
		case SET_OFF_SERVICE:
			const newList = state.noticeList.map((el: NoticeType) =>
				el.id === +action.id
					? { ...el, auto_lift_days: null, auto_lift_at: null }
					: el
			);
			return {
				...state,
				noticeList: newList,
			};
		default:
			return state;
	}
};

export const setNewNotice = (data: any) => ({ type: CREATE_NOTICE, data });
export const setNoticesList = (data: any) => ({ type: SET_NOTICES_LIST, data });
export const setNoticesAllList = (data: any) => ({
	type: SET_NOTICES_All_LIST,
	data,
});
//export const setRecentlyWatchedList = (data: any) => ({type: SET_RECENTLY_WATCHED_LIST, data});
export const setNoticesMeta = (data: any) => ({ type: SET_NOTICES_META, data });
export const setCategoriesIdsList = (data: any) => ({
	type: SET_CATEGORIES_IDS,
	data,
});
export const setNoticesView = (data: any) => ({ type: SET_NOTICE_VIEW, data });
export const setTmpNotice = (data: any) => ({
	type: SET_NOTICE_PER_VIEW,
	data,
});
export const setBackendTmpNotice = (data: any) => ({
	type: SET_BACKEND_NOTICE_PER_VIEW,
	data,
});

export const setImagesList = (data: any) => ({ type: SET_IMAGES_LIST, data });

export const setTMPImage = (data: any) => ({ type: SET_TMP_IMAGE, data });
export const setClearTMP = () => ({ type: SET_CLEAR_TMP });
export const setDeleteTMPImage = (data: any, name: string) => ({
	type: DELETE_TMP_IMAGE,
	data,
	name,
});
export const setFiltersOneIsAction = (data: any) => ({
	type: SET_ONE_IS_FILTERS_LIST,
	data,
});
export const setFiltersManyAction = (data: any) => ({
	type: SET_MANY_IS_FILTERS_LIST,
	data,
});
//export const setFiltersAllAction = (data: any) => ({type: SET_FILTERS_ALL_LIST, data});

export const setCreatedSuccessFalse = () => ({
	type: SET_CREATE_SUCCESSFUL_FALSE,
});

export const setEditNoticePrice = (data: { id: number; price: string }) => ({
	type: SET_EDIT_NOTICE_PRICE,
	data,
});
export const setEditNoticeStatuses = (data: {
	id: number;
	status: number;
}) => ({ type: SET_EDIT_NOTICE_STATUS, data });
export const setAutoContinue = (data: { id: number; state: number }) => ({
	type: SET_AUTO_CONTINUE,
	data,
});
export const setDeleteNotice = (data: number) => ({
	type: SET_DELETE_NOTICE,
	data,
});
export const setBuyService = (data: NoticeType) => ({
	type: SET_BUY_SERVICE,
	data,
});
export const setOffService = (id: number) => ({ type: SET_OFF_SERVICE, id });

export const getNewNotice =
	(values: valuesCreateAD, submitSetStatus: any, type: string, navigate: any) =>
	async (dispatch: any, getState: any) => {
		const currentState = getState();
		const images = currentState.noticeData.images;
		const imagesTmp = currentState.noticeData.tmpImages;
		const imagesLoadedNow = images.map(
			(img: { file: File; name?: string }) => img?.file.name || img?.name
		);
		const imagesInTmp = imagesTmp.map((img: TmpImage) => img.tmpFileName);

		/* let noticeImagesString
     if (type !=="create"){

     }*/
		/* const noticeImagesString = imagesLoadedNow.map((firstImg: File, index: number) => ({
         name: firstImg,
         tmpName: imagesInTmp[index] || firstImg
     }))*/
		const noticeImagesString = imagesLoadedNow.map(
			(firstImg: File, index: number) => {
				const tmpIndex =
					index - Math.max(0, imagesLoadedNow.length - imagesInTmp.length);
				const tmpName = imagesInTmp[tmpIndex] || firstImg;
				return {
					name: firstImg,
					tmpName,
				};
			}
		);

		const formData = new FormData();
		formData.append("name", values.name);
		formData.append("description", values.description);
		formData.append("category_id", values.category_id_value);
		formData.append("price", values.price?.toString().replace(/\s/g, "") || "");
		formData.append(
			"price_type",
			values?.price_type !== "0" && values?.price_type !== ""
				? values?.price_type
				: ""
		);
		formData.append("settlement_id", values.settlement_value);
		formData.append("state", values.state);
		formData.append("not_show_other", values.not_show_other?.toString() || "");
		formData.append(
			"auto_lift_days",
			values?.auto_lift_days?.replace(/[^0-9]/g, "") || ""
		);
		formData.append("auto_continue", values.auto_continue.toString());
		formData.append("top_days", values.top_days);
		formData.append("publish", values.publish?.toString() || "0");
		formData.append("noticeImagesString", JSON.stringify(noticeImagesString));

		if (values.filters.length !== 0) {
			values.filters.forEach((filter: any) => {
				if (filter.id !== null) {
					const filterKey = filter.filter;
					formData.append(filterKey, filter.id);
				}
			});
		}

		try {
			const response: any = await userNotice.createUpdateNotice(formData, type);
			if (response.data?.errors) {
				const errors = response.data.errors;
				submitSetStatus(errors);
			} else if (response.data.notice && type === "create?onlyValidate=1") {
				dispatch(setBackendTmpNotice(response.data));
				navigate(
					`${i18n.language === "ru" ? "/" : "/ua/"}create_board_ad_prev_view`
				);
			} else if (
				response.data.notice &&
				type.startsWith(
					"update?onlyValidate=1" ||
						(response.data.notice && type === "create?onlyValidate=1")
				)
			) {
				dispatch(setBackendTmpNotice(response.data));
				navigate(
					`${i18n.language === "ru" ? "/" : "/ua/"}create_board_ad_prev_view`
				);
			} else if (response.data?.notice && type !== "create?onlyValidate=1") {
				dispatch(setNewNotice(response.data?.notice));
				dispatch(setImagesList([]));
				dispatch(setTmpNotice({}));
				dispatch(setClearTMP());
				dispatch(setClearFiltersList());
				dispatch(getUserStaticView());
				dispatch(setFiltersOneIsAction([]));
				dispatch(setFiltersManyAction([]));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getNoticeList =
	(props?: UserNoticeListParams) => async (dispatch: Dispatch) => {
		try {
			const response: any = await userNotice.getMeNotice(props || {});

			if (response.data?.data?.items) {
				dispatch(setNoticesList(response.data || []));
			}
			if (response.data?.data?._meta) {
				dispatch(setNoticesMeta(response.data?.data._meta));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getEditOneNotice =
	(
		id: number,
		fieldName: string,
		value: string,
		setPriceError: (b: string) => void
	) =>
	async (dispatch: Dispatch) => {
		const strPrice = value.replace(/\s/g, "").replace(",", ".");
		try {
			const editPrice: any = await userNotice.changeOneField(
				id,
				fieldName,
				strPrice
			);
			if (editPrice.data.errors) {
				setPriceError(editPrice.data.errors.price);
			}
			if (editPrice.data.notice) {
				dispatch(setEditNoticePrice({ id, price: value }));
				setPriceError("");
				// const response: any = await userNotice.getMeNotice({})
				/* if (response.data?.items) {

             }*/
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getEditNoticeAutoContinue =
	(id: number, state: number) => async (dispatch: Dispatch) => {
		try {
			const editPrice: any = await userNotice.editOnlyAutoContinue(id, state);
			if (editPrice.data.notice) {
				dispatch(setAutoContinue({ id, state }));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getNoticeByIdForEdit =
	(id: number) => async (dispatch: Dispatch) => {
		try {
			const response: any = await userNotice.getEditNoticeData(id);
			if (response.data?.notice) {
				const responseImages = response?.data?.notice?.noticeImages;
				dispatch(setNoticesView(response.data?.notice));
				const images = [];
				for (let i = 0; i < responseImages.length; i++) {
					try {
						const imageUrl =
							responseImages[i].originalImageUrl + "?not-from-cache-please";
						// Загружаем изображение с добавлением фиктивного параметра GET
						const imageResponse = await userNotice.getImagesByURL(imageUrl);

						// Получаем данные изображения в виде Blob
						const blob = await imageResponse.blob();

						// Создаем объект File для изображения
						const file: any = new File([blob], responseImages[i]?.tmpName, {
							type: imageResponse?.headers?.get("content-type") || "",
						});
						// Устанавливаем дополнительные свойства к объекту File
						file.crossOrigin = "Anonymous";
						file.src = imageUrl; // Необязательно устанавливать свойство src у объекта File
						file.tmpName = responseImages[i]?.tmpName;
						// Добавляем объект File в массив изображений
						images.push({
							id: new Date().getTime().toString() + Math.random(),
							file: file,
						});
					} catch (error) {
						console.error(
							`Ошибка при загрузке изображения ${responseImages[i].originalImageUrl}:`,
							error
						);
						// Продолжаем выполнение цикла в случае ошибки загрузки изображения
						continue;
					}
				}
				dispatch(setImagesList(images));
			}
			if (response?.data?.filters) {
				dispatch(setFiltersList(response?.data?.filters));
			}
			if (response?.data?.filterVariables) {
				const filtersAll = response.data.filterVariables;
				const keyValueArray = [];
				const nameIdArray: any = [];
				for (const filter in filtersAll) {
					if (filtersAll.hasOwnProperty(filter)) {
						const value = filtersAll[filter];
						if (Array.isArray(value)) {
							// Если значение ключа - массив, добавляем все его элементы в nameIdArray
							value.forEach((val, index) => {
								nameIdArray.push({ filter: `${filter}[]`, id: val });
							});
						} else {
							// Иначе, добавляем пару ключ-значение в keyValueArray
							keyValueArray.push({ filter, id: value });
						}
					}
				}
				dispatch(setFiltersOneIsAction(keyValueArray));
				dispatch(setFiltersManyAction(nameIdArray));
				dispatch(setFiltersVariables(response?.data?.filterVariables));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const sendImagesToTmp =
	(data: any, setError?: (b: string) => void, images?: any) =>
	async (dispatch: Dispatch) => {
		dispatch(setImagesList(data));
		try {
			// Фильтруем данные, чтобы отправить только новые изображения
			const newData = data.filter((item: any) => {
				// Проверяем, не было ли уже загружено изображение с таким же id
				return !images.some(
					(existingImage: any) => existingImage.id === item.id
				);
			});

			//dispatch(setImagesList(newData));

			for (let i = 0; i < newData.length; i++) {
				const response: any = await userNotice.sendPhoto(newData[i].file);
				if (response.data) {
					dispatch(setTMPImage(response.data));
					setError && setError("");
				}
			}
		} catch (error: any) {
			if (error.code !== "ERR_NETWORK") {
				dispatch(setImagesList(data)); // Восстанавливаем исходные данные в случае ошибки
			} else {
				dispatch(setImagesList([])); // Очищаем список изображений в случае ошибки сети
				setError && setError(error.message);
			}
		}
	};
export const removeImagesToTmp =
	(name: string, setError: (b: string) => void) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await userNotice.removePhotoTmp(name);
			if (response.data) {
				dispatch(setDeleteTMPImage(response.data, name));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export const getChangeNoticeStatus =
	(id: number, status: number) => async (dispatch: any) => {
		try {
			const response: any = await userNotice.changeUserNoticeStatus(id, status);
			if (response.data.notice) {
				const notice = response.data.notice;
				dispatch(
					setEditNoticeStatuses({ id: notice.id, status: notice.status })
				);
				dispatch(getUserStaticView());
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getDeleteNotice = (id: string) => async (dispatch: any) => {
	try {
		const response: any = await userNotice.deleteNotice(id);
		if (response.data?.deletedNoticeId) {
			const id = response.data?.deletedNoticeId;
			dispatch(setDeleteNotice(id));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export const buyService =
	(
		noticeId: number,
		serviceId: number,
		closeModal?: (b: boolean) => void,
		price?: string,
		adName?: string
	) =>
	async (dispatch: any) => {
		try {
			const response: any = await userNotice.buyService(noticeId, serviceId);

			if (response.data.errors) {
				const errorMessage = response.data.errors.error;
				dispatch(setNotification(errorMessage, "error"));
				closeModal && closeModal(true);
			} else if (response.data.notice) {
				dispatch(setBuyService(response.data.notice));
				dispatch(getUserStaticView());

				let successMessage = "";

				if (serviceId === 1) {
					successMessage = `Активовано Одноразове підняття, Вартість: ${price} грн, ${adName}`;
				} else if (serviceId === 5) {
					successMessage = `Додавання оголошення в ТОП, Вартість: ${price} грн, ${adName}`;
				} else if (serviceId === 4) {
					successMessage = `Активовано Автопідняття`;
				}

				dispatch(setNotification(successMessage, "success"));
				closeModal && closeModal(true);
			}
		} catch (error: any) {
			dispatch(setNotification(error.message, "error"));
		}
	};
export const offService = (noticeId: number) => async (dispatch: any) => {
	try {
		const response: any = await userNotice.offService(noticeId);
		if (response.data) {
			dispatch(setOffService(+response.data.id));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};
export default notice_Reducer;
