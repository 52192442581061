import React from "react";
import s from "../MyOrders/MyOrders.module.css";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";

type Props = {
	seeFeedback?: boolean;
};

function PersonalCabinetCardRight({ seeFeedback }: Props) {
	return (
		<div className={s.myOrdersCardRight}>
			<div className={s.sellerInfo}>
				<div className={s.sellerInfoPersonalInfo}>
					<div className={s.sellerInfoPersonalInfo_name}>Виктор Викторович</div>
					<div className={s.sellerInfoPersonalInfo_phone}>099 1234567</div>
				</div>
				<div>
					{seeFeedback && (
						<div className={s.sellerInfoFeedback}>Залишити відгук</div>
					)}
					<div className={s.sellerInfoChat}>
						<span className={s.sellerInfoChatText}>Чат с клиентом</span>
						<span className={s.sellerInfoChatNumber}>6</span>
					</div>
				</div>
			</div>
			<div className={s.sellerInfoCross}>
				<PersonalCabinetSvgSelector id={"closeCross"} />
			</div>
		</div>
	);
}

export default PersonalCabinetCardRight;
