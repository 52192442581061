import { useState } from "react";
import OutputADSvgSelector from "../../../../../Components/OutputBoardAdComponents/OutputADSvgSelector";
import s from "./DeliverySeller.module.css";

interface DeliveryOption {
	id: string;
	name: string;
	time: string;
}

const deliveryOptions: DeliveryOption[] = [
	{ id: "ukrPost", name: "Укрпочта", time: "Доставка від 40 грн" },
	{ id: "novaPost", name: "Нова пошта", time: "Доставка від 40 грн" },
	{ id: "meest", name: "Meest", time: "Доставка від 40 грн" },
	{ id: "aloneDelivery", name: "Самовивіз", time: "На складі завтра" },
];

type Props = {
	isEdit: boolean;
};

const DeliverySeller = (props: Props) => {
	const { isEdit } = props;
	const [selectedOptions, setSelectedOptions] = useState<
		Record<string, boolean>
	>(
		deliveryOptions.reduce<Record<string, boolean>>((acc, option) => {
			acc[option.id] = false;
			return acc;
		}, {})
	);

	const toggleOption = (id: string): void => {
		setSelectedOptions((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	return (
		<div className={s.container}>
			<ul className={s.deliveryTable}>
				{deliveryOptions.map((option) => (
					<li key={option.id} className={s.deliveryTableElement}>
						<button
							className={`${s.deliveryButton} ${selectedOptions[option.id] && isEdit ? s.selected : ""}`}
							onClick={() => (isEdit ? toggleOption(option.id) : null)}
						>
							<div className={s.deliveryLogo}>
								<OutputADSvgSelector id={option.id} />
							</div>
							<div className={s.deliveryInfo}>
								<span className={s.deliveryName}>{option.name}</span>
								<span className={s.deliveryTime}>
									{selectedOptions[option.id] ? "Включено" : "Виключено"}
								</span>
							</div>
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default DeliverySeller;
