import React from "react";
import s from "../../pages/PersonalCabinet/PersonalCabinet.module.css";
import { Route, Routes } from "react-router";
import MyBalance from "./My Balance/MyBalance";

const PersonalCabinetBalance = () => {
	return (
		<div>
			<div className={s.cabinetContent_Balance}>
				<Routes>
					<Route index element={<MyBalance type={"main"} />} />
					<Route
						path={"insurance_amount"}
						element={<MyBalance type={"insurance_amount"} />}
					/>
				</Routes>
			</div>
		</div>
	);
};

export default PersonalCabinetBalance;
