import React from "react";
import SearchCardSvgSelector from "../FavoriteSearchCard/SearchCardSvgSelector";
import { useDispatch, useSelector } from "react-redux";
import { favoriteHandler } from "../../../redux/publicNotice_Reducer";
import { StateType } from "../../../redux/redux-store";
import { useQueryClient } from "react-query"; // Импортируйте useQueryClient

type Props = {
	isFavorite: number;
	id: number;
	userId?: number;
	isOrange?: boolean;
};

const FavoriteHeartSelector = ({ isFavorite, id, userId, isOrange }: Props) => {
	const dispatch: any = useDispatch();
	const addDelInFavorite = useSelector(() => favoriteHandler);
	const userWId = useSelector((state: StateType) => state.userData.user.id);
	const queryClient = useQueryClient(); // Получаем queryClient

	const handleFavoriteClick = (action: "add" | "remove") => {
		dispatch(addDelInFavorite(id, action)).then(() => {
			queryClient.invalidateQueries("bestNotices"); // Инвалидация кэша после выполнения действия
		});
	};

	if (userWId !== userId) {
		if (isOrange) {
			return (
				<span
					onClick={(event) => {
						event.preventDefault();
					}}
				>
					{isFavorite === 1 ? (
						<button
							className="no-link"
							onClick={() => handleFavoriteClick("remove")}
						>
							<SearchCardSvgSelector id={"orangeHeartActive"} />
						</button>
					) : (
						<button onClick={() => handleFavoriteClick("add")}>
							<SearchCardSvgSelector id={"orangeHeart"} />
						</button>
					)}
				</span>
			);
		}

		return (
			<span
				onClick={(event) => {
					event.preventDefault();
				}}
			>
				{isFavorite === 1 ? (
					<button
						className="no-link"
						onClick={() => handleFavoriteClick("remove")}
					>
						<SearchCardSvgSelector id={"yellowHeart"} />
					</button>
				) : (
					<button onClick={() => handleFavoriteClick("add")}>
						<SearchCardSvgSelector id={"heart"} />
					</button>
				)}
			</span>
		);
	} else {
		return null;
	}
};

export default FavoriteHeartSelector;
