import React, { useEffect, useState, Ref } from "react";
import {
	Droppable,
	DroppableProps,
	DroppableProvided,
	DroppableStateSnapshot,
} from "react-beautiful-dnd";

type StrictModeDroppableProps = DroppableProps & {
	innerRef?: Ref<HTMLDivElement>;
};

const StrictModeDroppable = React.forwardRef<
	HTMLDivElement,
	StrictModeDroppableProps
>((props, ref) => {
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true));
		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, []);

	if (!enabled) {
		return null;
	}

	return (
		<Droppable {...props}>
			{(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
				<div ref={ref || provided.innerRef} {...provided.droppableProps}>
					{props.children(provided, snapshot)}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
});

export default StrictModeDroppable;
