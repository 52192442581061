import React from "react";
import SearchResultSvgSelector from "../../SearchResultComponents/SearchResultSvgSelector";
import s from "./CabinetTopFiltersBlock.module.css";
import { useDispatch, useSelector } from "react-redux";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";
import {
	deleteAllFromFavorite,
	deleteAllFromRecentlyWatched,
} from "../../../redux/publicNotice_Reducer";
import { useTranslation } from "react-i18next";
import CabinetRubricsSelectMobile from "../CabinetRubricsSelectMobile/CabinetRubricsSelectMobile";
import { StatByCategory } from "../../../types/noticeTypes";
interface Props {
	setRow: (b: boolean) => void;
	row: boolean;
	type: string;
	catIds: number[];
	handleCatIds: (id: number) => void;
	categories: StatByCategory[];
}

const CabinetTopFiltersBlock = ({
	row,
	setRow,
	type,
	catIds,
	handleCatIds,
	categories,
}: Props) => {
	const dispatch: any = useDispatch();
	const deleteAllRecentlyWatched = useSelector(
		() => deleteAllFromRecentlyWatched
	);
	const deleteAllFavorite = useSelector(() => deleteAllFromFavorite);
	const { t } = useTranslation();
	const handleDeleteAll = () => {
		if (type === "recentlyWatched") {
			dispatch(deleteAllRecentlyWatched());
		} else if (type === "favorite") {
			dispatch(deleteAllFavorite());
		}
	};
	return (
		<div className={s.filtersWrap}>
			<CabinetRubricsSelectMobile
				catIds={catIds}
				handleCatIds={handleCatIds}
				categories={categories}
			/>
			<div className={s.rightBlock}>
				{row ? (
					<span
						className={s.changeVisibleIconBlock}
						onClick={() => setRow(!row)}
					>
						<SearchResultSvgSelector id={"rowLine"} />
					</span>
				) : (
					<span
						className={s.changeVisibleIconBlock}
						onClick={() => setRow(!row)}
					>
						<SearchResultSvgSelector id={"groupedRect"} />
					</span>
				)}
				<button onClick={handleDeleteAll} className={s.buttonDeleteBlock}>
					{t("Видалити все")}
					<ArrowsAndCross id={"closeCrossForSelect"} />
				</button>
			</div>
		</div>
	);
};

export default CabinetTopFiltersBlock;
