import React, { useState } from "react";
import s from "./MyOrdersCardMobile.module.css";
import SellerInfoForHiddenCardMobile from "../../CabinetCardComponents/Mobile/SellerInfoForHiddenCardMobile";
import LocationDeliveryInfoForHiddenCardMobile from "../../CabinetCardComponents/Mobile/LocationDeliveryInfoForHiddenCardMobile";
import ProductPrice from "../../CabinetCardComponents/Mobile/ProductPrice";
import ApprovedForm from "../../CabinetCardComponents/Mobile/ApprovedForm";
import MainDateCardMobile from "../../CabinetCardComponents/Mobile/MainDateCardMobile";
import ImageForCabinetCardMobile from "../../CabinetCardComponents/Mobile/ImageForCabinetCardMobile";
import MainTitleForCabinetCardMobile from "../../CabinetCardComponents/Mobile/MainTitleForCabinetCardMobile";

const MyOrdersCardMobile = ({ type }: { type: string }) => {
	const [isOpenCard, setIsOpenCard] = useState(false);
	const [orderApproved, setOrderApproved] = useState(false);
	return (
		<div className={s.myOrdersCardMobileContainer}>
			<div
				className={s.cardTop}
				style={{
					borderBottom: isOpenCard ? "1px solid #9D9D9D" : "",
					paddingBottom: isOpenCard ? "15px" : "",
				}}
			>
				<ImageForCabinetCardMobile />
				<div className={s.cardTop_Main}>
					<MainTitleForCabinetCardMobile
						isOpenCard={isOpenCard}
						setIsOpenCard={setIsOpenCard}
					/>
					<div className={s.mainLocation}>
						{!isOpenCard && <> Харьков, Хар. обл.</>}
					</div>
					<div className={s.mainDeliveryAndPrice}>
						{type === "closed" || type === "sent" ? (
							isOpenCard ? (
								<>
									<div className={s.arrivalDate}>12 марта 2023 в 12:45</div>
									<ProductPrice />
								</>
							) : (
								<></>
							)
						) : (
							""
						)}
						{!isOpenCard && (
							<>
								<div className={s.delivery}>
									{type === "sent" && (
										<div className={s.arrivalDate}>
											Дата прибуття: <span>25мар2023</span>
										</div>
									)}
									{type === "new" && <>Новая почта, №38.</>}
									{type === "closed" && (
										<div className={s.arrivalDate}>12 марта 2023 в 12:45</div>
									)}
								</div>
								<ProductPrice />
							</>
						)}
					</div>
					{type === "new" && (
						<div className={s.mainDateAndSubmit}>
							{orderApproved ? (
								<>
									<MainDateCardMobile />

									<div className={s.mainSubmit}>
										{!isOpenCard ? (
											<button onClick={() => setOrderApproved(!orderApproved)}>
												Підтвердити
											</button>
										) : (
											<ProductPrice />
										)}
									</div>
								</>
							) : (
								<>
									{!isOpenCard ? (
										<ApprovedForm
											orderApproved={orderApproved}
											setOrderApproved={setOrderApproved}
										/>
									) : (
										<>
											<MainDateCardMobile />
											<ProductPrice />
										</>
									)}
								</>
							)}
						</div>
					)}
				</div>
			</div>
			{isOpenCard && (
				<div className={s.cardHiddenBottom}>
					<LocationDeliveryInfoForHiddenCardMobile type={type} />
					<div className={s.middleChangedWrapper}>
						{type === "new" && (
							<div className={s.middleChangedContainer}>
								{orderApproved ? (
									<button
										onClick={() => setOrderApproved(!orderApproved)}
										className={s.approvedButton}
									>
										Підтвердити замовлення
									</button>
								) : (
									<ApprovedForm
										orderApproved={orderApproved}
										setOrderApproved={setOrderApproved}
									/>
								)}
							</div>
						)}
					</div>
					<div className={s.sellerInfoWrapper}>
						<SellerInfoForHiddenCardMobile />
					</div>
				</div>
			)}
		</div>
	);
};

export default MyOrdersCardMobile;
