import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useCatIdsHandler from "../../../../../CustomHooks/useCatIdsHandler";
import { StateType } from "../../../../../redux/redux-store";
import { Desktop, Mobile } from "../../../../../responsive";
import { FilterPanel } from "../FilterPanel/FilterPanel";
import { SelectRubrics } from "../SelectRubrics/SelectRubrics";
import styles from "./SellerRubrics.module.css";

interface Subcategory {
	name: string;
	count: number;
}

interface Category {
	id: number;
	name: string;
	name_ru: string;
	count: number;
	subcategories?: Subcategory[];
}

const categories: Category[] = [
	{
		id: 1,
		name: "Строительство и ремонт",
		name_ru: "Строительство и ремонт",
		count: 0,
		subcategories: [
			{ name: "Инструмент", count: 0 },
			{ name: "Электропилы", count: 3 },
		],
	},
	{
		id: 2,
		name: "Зоотовары",
		name_ru: "Зоотовары",
		count: 9,
	},
	{
		id: 3,
		name: "Красота и здоровье",
		name_ru: "Красота и здоровье",
		count: 9,
	},
];

export const SellerRubrics: React.FC = () => {
	const { t } = useTranslation();
	const [sorting, setSeeSorting] = useState(false);
	const [row, setRow] = useState(false);
	const menuRef: React.Ref<any> = useRef(null);
	const [sort, setSort] = useState("1");
	const [openFilters, setOpenFilters] = useState<boolean>(false);

	const catIdsForHook = useSelector(
		(state: StateType) => state.publicNotice.favoriteStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);

	const handleClickFilterButton = () => {
		setOpenFilters(!openFilters);
	};

	return (
		<>
			<Mobile>
				<div className={styles.mobileRubrics}>
					<SelectRubrics
						catIds={[1, 2]}
						handleCatIds={handleCatIds}
						categories={categories}
					/>
					<FilterPanel
						sorting={sorting}
						row={row}
						setRow={setRow}
						setSeeSorting={setSeeSorting}
						menuRef={menuRef}
						setSorting={setSort}
						handleClickFilterButton={handleClickFilterButton}
					/>
				</div>
			</Mobile>
			<Desktop>
				<div className={styles.categoryList}>
					{categories.map((category, index) => (
						<div key={index} className={styles.categoryItem}>
							<p className={styles.category}>
								{category.name}
								{category.count > 0 && (
									<span className={styles.count}>{category.count}</span>
								)}
							</p>
							{category.subcategories && (
								<div className={styles.subcategories}>
									{category.subcategories.map((subcategory, subIndex) => (
										<p key={subIndex} className={styles.subcategory}>
											{subcategory.name}
											{subcategory.count > 0 && (
												<span className={styles.count}>
													{subcategory.count}
												</span>
											)}
										</p>
									))}
								</div>
							)}
						</div>
					))}
				</div>
			</Desktop>
		</>
	);
};
