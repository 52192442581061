import React from "react";

interface Props {
	id: string;
}

function AutomaticSelectionOfCategoriesSvgSelector({ id }: Props) {
	switch (id) {
		case "search":
			return (
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.49547 0C3.36472 0 0 3.36472 0 7.49547C0 11.6262 3.36472 14.9909 7.49547 14.9909C9.29177 14.9909 10.9414 14.3531 12.2343 13.2942L16.7096 17.7695C16.7787 17.8415 16.8614 17.8989 16.953 17.9385C17.0445 17.978 17.143 17.9989 17.2427 18C17.3425 18.001 17.4414 17.9821 17.5337 17.9444C17.626 17.9067 17.7099 17.851 17.7804 17.7804C17.851 17.7099 17.9067 17.626 17.9444 17.5337C17.9821 17.4414 18.001 17.3425 18 17.2427C17.9989 17.143 17.978 17.0445 17.9385 16.953C17.8989 16.8614 17.8415 16.7787 17.7695 16.7096L13.2942 12.2343C14.3531 10.9414 14.9909 9.29177 14.9909 7.49547C14.9909 3.36472 11.6262 0 7.49547 0ZM7.49547 1.49909C10.8161 1.49909 13.4919 4.17489 13.4919 7.49547C13.4919 10.8161 10.8161 13.4919 7.49547 13.4919C4.17489 13.4919 1.49909 10.8161 1.49909 7.49547C1.49909 4.17489 4.17489 1.49909 7.49547 1.49909Z"
						fill="#9A9A9A"
					/>
				</svg>
			);

		case "close":
			return (
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9.46584 7.99988L15.6959 14.2303C16.1014 14.6355 16.1014 15.2908 15.6959 15.6961C15.2907 16.1013 14.6354 16.1013 14.2302 15.6961L7.9999 9.46566L1.76983 15.6961C1.36438 16.1013 0.709335 16.1013 0.304082 15.6961C-0.101361 15.2908 -0.101361 14.6355 0.304082 14.2303L6.53416 7.99988L0.304082 1.76948C-0.101361 1.36422 -0.101361 0.708971 0.304082 0.303709C0.506044 0.101553 0.771594 0 1.03695 0C1.30231 0 1.56767 0.101553 1.76983 0.303709L7.9999 6.53411L14.2302 0.303709C14.4323 0.101553 14.6977 0 14.963 0C15.2284 0 15.4938 0.101553 15.6959 0.303709C16.1014 0.708971 16.1014 1.36422 15.6959 1.76948L9.46584 7.99988Z"
						fill="#727272"
					/>
				</svg>
			);
		case "closeBlock":
			return (
				<svg
					width="9"
					height="9"
					viewBox="0 0 9 9"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.32453 4.49993L8.82895 8.00453C9.05702 8.23249 9.05702 8.60107 8.82895 8.82903C8.601 9.05699 8.23243 9.05699 8.00447 8.82903L4.49995 5.32443L0.995528 8.82903C0.767466 9.05699 0.399001 9.05699 0.171046 8.82903C-0.0570154 8.60107 -0.0570154 8.23249 0.171046 8.00453L3.67547 4.49993L0.171046 0.995335C-0.0570154 0.767375 -0.0570154 0.398796 0.171046 0.170836C0.28465 0.0571232 0.434022 0 0.583287 0C0.732552 0 0.881817 0.0571232 0.995528 0.170836L4.49995 3.67543L8.00447 0.170836C8.11818 0.0571232 8.26745 0 8.41671 0C8.56598 0 8.71524 0.0571232 8.82895 0.170836C9.05702 0.398796 9.05702 0.767375 8.82895 0.995335L5.32453 4.49993Z"
						fill="#727272"
					/>
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default AutomaticSelectionOfCategoriesSvgSelector;
