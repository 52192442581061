import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryBlocks, getFiltersList } from "../redux/category_Reducer";

interface FormValues {
	category_id_value?: string;
	category_id_label?: string;
	// добавьте другие поля формы здесь
}

interface AutoSelectionOne {
	categoryId: string;
	categoryName: string;
}

const useAutoCategorySelection = (
	values: FormValues,
	autoSelectionOne: AutoSelectionOne,
	setFieldValue: (field: string, value: any) => void
) => {
	const dispatch: any = useDispatch();
	const getFilters = useSelector(() => getFiltersList);
	const getBlocks = useSelector(() => getCategoryBlocks);

	useEffect(() => {
		if (
			!values?.category_id_value &&
			autoSelectionOne?.categoryId &&
			(values.category_id_value !== autoSelectionOne?.categoryId ||
				values.category_id_label !== autoSelectionOne?.categoryName)
		) {
			const updateCategory = async () => {
				await dispatch(getFilters(autoSelectionOne.categoryId));
				await dispatch(getBlocks(autoSelectionOne.categoryId));

				setFieldValue("category_id_value", autoSelectionOne.categoryId);
				setFieldValue("category_id_label", autoSelectionOne.categoryName);
			};

			updateCategory();
		}
	}, [autoSelectionOne?.categoryId, values, setFieldValue, dispatch]);
};

export default useAutoCategorySelection;
