import React, { useState, useEffect } from "react";
import s from "./FilterComponent.module.css";
import { useTranslation } from "react-i18next";
import useFilterHandle from "../../CustomHooks/useFilterHandle";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { NoticeFilterType } from "../../types/noticeTypes";

type Props = {
	filter: NoticeFilterType | any;
	filtersMany: [];
	filtersOneIs: [];
	filtersAll: [];
};
const FilterTypeButton = ({
	filter,
	filtersMany,
	filtersOneIs,
	filtersAll,
}: Props) => {
	const { i18n } = useTranslation();
	const { handleFilter } = useFilterHandle();
	const [collapsed, setCollapsed] = useState(true);
	useEffect(() => {
		if (filter?.showOpened) {
			setCollapsed(false);
		}
	}, [filter]);
	return (
		<div className={s.container}>
			<div className={`${s.name} ${s.nameAndCross}`}>
				{!filter?.needs ? (
					filter?.propertyName
				) : (
					<p>
						{filter?.propertyName} <span style={{ color: "red" }}>*</span>
					</p>
				)}
				{filter && (
					<span
						style={{
							transform: collapsed ? "" : "rotate(180deg)",
							transition: "0.5s",
						}}
						className={s.colorFilterArrow}
						onClick={() => setCollapsed(!collapsed)}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				)}
			</div>
			{!collapsed && (
				<div className={s.buttons}>
					{filter?.propertyValues.map((button: any) => (
						<button
							type={"button"}
							key={button.id}
							onClick={() => {
								handleFilter({ element: button, filter });
							}}
							className={`${s.button} ${
								filtersMany.findIndex(
									(item: any) =>
										item.id === button.id && item.filter === filter.inputName
								) !== -1 ||
								filtersOneIs.findIndex(
									(item: any) =>
										item.id === button.id && item.filter === filter.inputName
								) !== -1
									? s.active
									: ""
							}`}
						>
							{i18n.language === "ru" ? button?.name_ru : button?.name}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default FilterTypeButton;
