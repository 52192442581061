import React from "react";
import CabinetNavBarMobile from "../../Components/PersonalCabinet/CabinetNavBarMobile/CabinetNavBarMobile";
import { Route, Routes } from "react-router";
import PersonalCabinetSettingsMobile from "../../Components/PersonalCabinet/PersonalCabinetSettings/PersonalCabinetSettingsMobile";
import MyAdsMobile from "../../Components/PersonalCabinet/My Ads/MyAdsMobile";
import MyOrdersMobile from "../../Components/PersonalCabinet/MyOrders/MyOrdersMobile";
import MyRecentlyWatchedMobile from "../../Components/PersonalCabinet/MyRecentlyWatchedMobile/MyRecentlyWatchedMobile";
import MyFavoriteMobile from "../../Components/PersonalCabinet/My Favorite/MyFavoriteMobile";
import PersonalCabinetBalance from "../../Components/PersonalCabinet/PersonalCabinetBalance";
const PersonalCabinetMobile = () => {
	return (
		<div>
			<Routes>
				<Route path={"/*"} element={<CabinetNavBarMobile />} />
				<Route path={"settings"} element={<PersonalCabinetSettingsMobile />} />
				<Route path={"ads/*"} element={<MyAdsMobile />} />
				<Route path={"orders/*"} element={<MyOrdersMobile />} />
				{/*<Route path={"balance/*"} element={<MyBalanceMobile/>}/>*/}
				<Route path={"balance/*"} element={<PersonalCabinetBalance />} />
				<Route
					path={"recently_watched/*"}
					element={<MyRecentlyWatchedMobile />}
				/>
				{/* <Route path={"recently_watched/*"} element={<MyRecentlyWatchedMobile />}/> */}
				<Route path={"favorite/*"} element={<MyFavoriteMobile />} />
			</Routes>
		</div>
	);
};

export default PersonalCabinetMobile;
