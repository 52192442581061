import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SearchCardSvgSelector from "../../../Components/Cards/FavoriteSearchCard/SearchCardSvgSelector";
import DEFAULT_BANER from "../../../images/default-banner.png";
import styles from "../BusinessPage.module.css";

const ImageUpload: React.FC = () => {
	const [bannerUrl, setBannerUrl] = useState<string>(DEFAULT_BANER);
	const [isUploading, setIsUploading] = useState<boolean>(false); // Стан для індикації завантаження
	const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false); // Стан для відстеження завантаження
	const [errorMessage, setErrorMessage] = useState<string | null>(null); // Повідомлення про помилки
	const [successMessage, setSuccessMessage] = useState<string | null>(null); // Повідомлення про успіх

	const fileUploadRef = useRef<HTMLInputElement | null>(null);

	// Функція для відкриття діалогу завантаження файлу
	const handleImageUpload = (
		event: React.MouseEvent<HTMLImageElement | HTMLDivElement>
	) => {
		event.preventDefault();
		fileUploadRef.current?.click();
	};

	// Функція для обробки завантаження файлу
	const uploadImageDisplay = async (event: ChangeEvent<HTMLInputElement>) => {
		const uploadedFile = event.target.files?.[0];
		if (!uploadedFile) return;

		// Валідація типу файлу
		if (!uploadedFile.type.startsWith("image/")) {
			setErrorMessage("Будь ласка, завантажуйте лише зображення.");
			return;
		}

		// Валідація розміру файлу (не більше 5MB)
		const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
		if (uploadedFile.size > maxSizeInBytes) {
			setErrorMessage("Розмір файлу перевищує 5MB.");
			return;
		}

		// Показати попередній перегляд перед завантаженням
		const previewUrl = URL.createObjectURL(uploadedFile);
		setBannerUrl(previewUrl);
		setErrorMessage(null); // Скидання попередніх помилок
		setSuccessMessage(null); // Скидання попередніх повідомлень про успіх

		try {
			setIsUploading(true); // Початок завантаження

			const formData = new FormData();
			formData.append("file", uploadedFile);

			const response = await fetch(
				"https://api.escuelajs.co/api/v1/files/upload",
				{
					method: "POST",
					body: formData,
				}
			);

			if (response.status === 201) {
				const data = await response.json();
				// Припускаємо, що сервер повертає URL зображення у полі 'location'
				setBannerUrl(data.location || previewUrl); // Використовуємо previewUrl, якщо 'location' відсутнє
				setIsImageUploaded(true); // Встановлюємо стан, що зображення завантажено
				setSuccessMessage("Зображення успішно завантажено!");
			} else {
				console.error("Failed to upload image. Status:", response.status);
				setBannerUrl(DEFAULT_BANER); // Повертаємо стандартне зображення у випадку помилки
				setIsImageUploaded(false); // Скидаємо стан завантаження
				setErrorMessage("Не вдалося завантажити зображення. Спробуйте ще раз.");
			}
		} catch (error) {
			console.error("Error uploading image:", error);
			setBannerUrl(DEFAULT_BANER); // Повертаємо стандартне зображення у випадку помилки
			setIsImageUploaded(false); // Скидаємо стан завантаження
			setErrorMessage("Сталася помилка при завантаженні зображення.");
		} finally {
			setIsUploading(false); // Завершення завантаження
		}
	};

	useEffect(() => {
		// Очистка об'єктного URL при зміні або розмонтуванні
		return () => {
			if (bannerUrl && bannerUrl !== DEFAULT_BANER) {
				URL.revokeObjectURL(bannerUrl);
			}
		};
	}, [bannerUrl]);

	return (
		<div className={styles.bannerContainer}>
			<img
				onClick={handleImageUpload}
				src={bannerUrl}
				alt="Banner"
				className={styles.banner}
			/>
			{isUploading && (
				<div className={styles.uploadingOverlay}>Завантаження...</div>
			)}
			{!isImageUploaded && (
				<div className={styles.bannerText}>
					<p className={styles.bannerTextDownload}>
						Перетягніть сюди фото банера або натисніть на нього, щоб відкрити
						вікно завантаження
					</p>
					<p className={styles.bannerTextRecommend}>
						Рекомендований розмір банера: 1506 х 250 px
					</p>
				</div>
			)}
			{!isImageUploaded ? (
				<div className={styles.needHelp}>
					<p>Потрібна допомога дизайнера?</p>
					<Link to="">Напишіть в чат</Link>
				</div>
			) : (
				<div className={styles.mainBanner} onClick={handleImageUpload}>
					Головний банер
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="white"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.4283 10C16.0338 10 15.714 10.3198 15.714 10.7143V17.8571C15.714 18.2516 15.3942 18.5714 14.9998 18.5714H2.14283C1.74833 18.5714 1.42854 18.2516 1.42854 17.8571V3.57143C1.42854 3.17693 1.74833 2.85713 2.14283 2.85713H10.7141C11.1086 2.85713 11.4284 2.53733 11.4284 2.14283C11.4284 1.74832 11.1086 1.42857 10.7141 1.42857H2.14283C0.959376 1.42857 0 2.38796 0 3.57143V17.8571C0 19.0406 0.959376 20 2.14283 20H14.9998C16.1832 20 17.1426 19.0406 17.1426 17.8571V10.7143C17.1426 10.3198 16.8228 10 16.4283 10Z"
							fill="white"
						/>
						<path
							d="M19.1993 0.800988C18.6865 0.288042 17.9908 -7.24047e-05 17.2655 1.13007e-05C16.5397 -0.00208134 15.8434 0.286535 15.332 0.801449L5.9235 10.2093C5.84544 10.2879 5.78656 10.3835 5.75136 10.4886L4.32282 14.7743C4.19814 15.1486 4.4005 15.553 4.77478 15.6777C4.84739 15.7019 4.92344 15.7142 4.99995 15.7143C5.07662 15.7142 5.15283 15.7019 5.22565 15.6779L9.51131 14.2493C9.61657 14.2142 9.7122 14.155 9.79059 14.0765L19.199 4.66789C20.2669 3.60014 20.267 1.86886 19.1993 0.800988ZM18.1891 3.65857L8.90345 12.9443L6.1292 13.8707L7.05275 11.1L16.3419 1.81428C16.8526 1.3046 17.6798 1.30544 18.1895 1.81613C18.4329 2.06009 18.5701 2.39035 18.5712 2.735C18.572 3.0815 18.4345 3.41398 18.1891 3.65857Z"
							fill="white"
						/>
					</svg>
				</div>
			)}

			{/* {errorMessage && (
				<div className={styles.errorMessage}>
					<p>{errorMessage}</p>
				</div>
			)}
			{successMessage && (
				<div className={styles.successMessage}>
					<p>{successMessage}</p>
				</div>
			)} */}

			<div className={styles.bannerInfoSeller}>
				<p>
					<SearchCardSvgSelector id={"phone"} />
					(093) 545-33-99
				</p>
				<p>
					<SearchCardSvgSelector id={"location_white"} />
					Владимир-Волинський
				</p>
			</div>
			<input
				type="file"
				id="file"
				ref={fileUploadRef}
				onChange={uploadImageDisplay}
				accept="image/*"
				hidden
			/>
		</div>
	);
};

export default ImageUpload;
