import React from "react";
import s from "./MyFavorite.module.css";
import SearchResultSvgSelector from "../../SearchResultComponents/SearchResultSvgSelector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteAllFromFavorite,
	deleteAllFromRecentlyWatched,
} from "../../../redux/publicNotice_Reducer";
import PersonalCabinetCheckBoxPanel from "../PersonalCabinetCheckBoxPanel";
import { StatByCategory } from "../../../types/noticeTypes";
type Props = {
	grid: boolean;
	row: boolean;
	setGrid: (b: boolean) => void;
	setRow: (b: boolean) => void;
	catIds: number[];
	handleCatIds: (id: number) => void;
	categories: StatByCategory[];
	type: string;
};
function PersonalCabinetCheckBoxPanelFavorite({
	grid,
	row,
	setGrid,
	setRow,
	catIds,
	handleCatIds,
	categories,
	type,
}: Props) {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const deleteAllRecentlyWatched = useSelector(
		() => deleteAllFromRecentlyWatched
	);
	const deleteAllFavorite = useSelector(() => deleteAllFromFavorite);
	const handleClick = (id: string) => {
		setGrid(false);
		setRow(false);
		switch (id) {
			case "grid":
				setGrid(true);
				break;
			case "row":
				setRow(true);
		}
	};
	return (
		<div className={s.cabinetContent_CheckBoxPanel}>
			<div className={s.cabinetContent_CheckBoxPanel_Column}>
				<PersonalCabinetCheckBoxPanel
					catIds={catIds}
					handleCatIds={handleCatIds}
					categories={categories}
				/>
			</div>
			<div
				className={`${s.cabinetContent_CheckBoxPanel_Column} ${s.buttonsRight}`}
			>
				{/*<div className={s.searchContainer}>
                <input className={s.searchContainerInput} type="text" placeholder={t("Пошук")+"..."} />
                <span className={s.searchLupa}>
                   <ArrowsAndCross id={"searchLittleLupa"} />
                </span>
            </div>*/}
				<button
					onClick={() => handleClick("grid")}
					className={grid ? s.active : ""}
				>
					<SearchResultSvgSelector id={"gridButton"} />
				</button>
				<button
					onClick={() => handleClick("row")}
					className={row ? s.active : ""}
				>
					<SearchResultSvgSelector id={"menu"} />
				</button>
				<button
					className={s.clearAllButton}
					onClick={() => {
						if (type === "recentlyWatched") {
							dispatch(deleteAllRecentlyWatched());
						} else if (type === "favorite") {
							dispatch(deleteAllFavorite());
						}
					}}
				>
					{t("Очистити")}
					{/*<ArrowsAndCross id={"closeCross"} />*/}
				</button>
			</div>
		</div>
	);
}

export default PersonalCabinetCheckBoxPanelFavorite;
