import React from "react";
import { useTranslation } from "react-i18next";

const PublishedYear = ({ seconds }: { seconds: number }) => {
	const { t } = useTranslation();

	if (seconds) {
		const date = new Date(seconds && seconds * 1000); // Преобразование секунд в миллисекунды
		const year = date.getFullYear();

		return <>{year + " " + t("р") + ""} </>;
	}
	return <> </>;
};
export default PublishedYear;
