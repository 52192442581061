import React from "react";
import s from "./MyBalanceTint.module.css";
import { useTranslation } from "react-i18next";
import SellerCardSvgSelector from "../../../SellerCard/SellerCardSvgSelector";
import kaliakaMaliaka from "../../../../pages/StaticPage/b2.svg";

type Props = {
	close: (b: boolean) => void;
};
const MyBalanceTint: React.FC<Props> = ({ close }) => {
	const { t } = useTranslation();
	return (
		<div className={s.wrapper}>
			<div className={s.tintContainer}>
				<div className={s.closeCrossContainer}>
					<button className={s.closeCross} onClick={() => close(false)}>
						<SellerCardSvgSelector id={"close"} />
					</button>
				</div>
				<ul className={s.textList}>
					<li className={s.textElement}>
						<div className={s.titleWithKaliaka}>
							<div className={s.geniusAddKaliaky}>
								<img src={kaliakaMaliaka} alt={"icon"} />
							</div>
						</div>
						<b>{t("Ваш Баланс")} - </b>
						<span className={s.descriptionText}>
							{t(
								"це сукупна сума коштів, що складається із коштів, що знаходяться на Особовому рахунку та Бонусному рахунку користувача."
							)}
						</span>
					</li>
					<li className={s.textElement}>
						<b>{t("Особистий рахунок")} - </b>
						<span className={s.descriptionText}>
							{t(
								"це рахунок, що користувач може поповнити через платіжну систему"
							)}{" "}
						</span>
						<b>LiqPay.</b>
						<br />
						<b>LiqPay - </b>
						<span className={s.descriptionText}>
							{t("є єдиним платіжним рішенням від ")} <b>{t("Приватбанку")}.</b>
						</span>
					</li>
					<li className={s.textElement}>
						<b>{t("Бонусний рахунок")}. </b>
						<span className={s.descriptionText}>
							{t(
								"Бонуси (скорочене позначення - бнс) нараховуються за деякі дії користувачів на сайті Bago.ua та є платіжним еквівалентом в інформаційно-розрахунковій системі в рамках Сайту Bago.ua"
							)}
						</span>
					</li>
					<li className={s.textElement}>
						<b>{t("Кошти на Балансі можна використовувати")} </b>
						<span className={s.descriptionText}>
							{t("тільки для оплати Платних послуг із просування оголошення")}.
						</span>
					</li>
					<li className={s.textElement}>
						<b>{t("Кошти на Балансі не можна використовувати")} </b>
						<span className={s.descriptionText}>
							{t(
								"для оплати сторонніх сервісів або оплати за товари або послуги, представлені в оголошеннях на сайті Продавцями або Магазинами"
							)}
							.
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default MyBalanceTint;
