import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import {
	getCategoryView,
	getFiltersList,
	setErrorShow,
} from "../../redux/category_Reducer";
import {
	getFilteredNoticeList,
	setClearFilteredNotices,
	setFilteredNoticesAllList,
} from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";
import { setCategoriesList } from "../../redux/search_Reducer";
import { Desktop, Mobile } from "../../responsive";
import { CounterType, FiltersValue } from "../../types/publicNoticeTypes";
import { OneCategoryType, stateUserType } from "../../types/reduxStoreTypes";
import { SearchCategory } from "../../types/searchTypes";
import NewPage404 from "../Page404/NewPage404";
import SearchResultDesktop from "./SearchResultDesktop";
import SearchResultMobile from "./SearchResultMobile";

interface Props {
	category: boolean;
}

const SearchResult: React.FC<Props> = ({ category }) => {
	const error = useSelector((state: StateType) => state.categoryData.showError);
	const { i18n, t } = useTranslation();
	const filtersList = useSelector(
		(state: StateType) => state.categoryData.filters
	);
	const countersList: CounterType[] = useSelector(
		(state: StateType) => state.publicNotice.countersForNotices
	);
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const { categorySlag } = useParams();
	const location = useLocation();
	const getNoticeList = useSelector(() => getFilteredNoticeList);
	const getFilters = useSelector(() => getFiltersList);
	const getCategory = useSelector(() => getCategoryView);
	const filteredNoticeList = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const filteredNoticeListAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const currentCategory: OneCategoryType = useSelector(
		(state: StateType) => state.categoryData.view
	);
	const pagination = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);
	const categoryRange = useSelector(
		(state: StateType) => state.publicNotice.notice_price_diapason
	);
	const [sort, setSort] = useState("1");
	const [settlement, setSettlement] = useState("");
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const { search } = useLocation();
	const [params] = useSearchParams();
	const searchQuery = params.get("search");

	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const queryParams = new URLSearchParams(search);
	const filtersFromUrl = Array.from(queryParams.entries()).map(
		([filter, value]) => ({
			filter,
			value,
		})
	);
	const [filtersValues, setFiltersValues] =
		useState<FiltersValue[]>(filtersFromUrl);

	const sliderPriceFilter = useSelector(
		(state: StateType) => state.publicNotice.priceRangeFilter
	);
	const [sliderPriceMin, sliderPriceMax] = sliderPriceFilter;
	const [showSearchCategory, setShowSearchCategory] = useState(true);
	const setClearNoticeList = useSelector(() => setClearFilteredNotices);
	const setError = useSelector(() => setErrorShow);
	const setCategory = useSelector(() => setCategoriesList);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentAction, setCurrentAction] = useState("");
	const [firstEntry] = Array.from(queryParams.entries());
	const [sortByState, setSortByState] = useState("0");
	const [searchData, setSearchData] = useState({
		searchKey: "",
		searchValue: "",
	});
	const currentActions = {
		search: "Search",
		category: "Category",
	};
	const price_diapason = useSelector(
		(state: StateType) => state.categoryData.view.notice_price_diapason
	);
	const sliderPrice = [
		price_diapason?.minPrice ? +price_diapason.minPrice : 0,
		price_diapason?.maxPrice ? +price_diapason.maxPrice : 0,
	];
	const categoriesSearch = useSelector(
		(state: StateType) => state.searchData.categories
	);

	useEffect(() => {
		if (firstEntry) {
			const [key, value] = firstEntry;
			setSearchData({ searchValue: value, searchKey: key });
		} else {
			setSearchData({ searchValue: "", searchKey: "" });
		}
	}, [location.search]);

	useEffect(() => {
		if (currentPage == 0 || currentPage == 1) {
			dispatch(setFilteredNoticesAllList(filteredNoticeList));
		} else {
			const newArr = [...filteredNoticeListAllList, ...filteredNoticeList];
			dispatch(setFilteredNoticesAllList(newArr));
		}
	}, [filteredNoticeList]);

	useEffect(() => {
		const filtersRangeQuery = filtersValues
			.filter((item) => item.filter === "filtersRange[]")
			.map((item) => `filtersRange%5B%5D=${item.value}`)
			.join("&");
		const filtersAllQuery = filtersValues
			.filter((item) => item.filter.startsWith("filter_"))
			.map((f: FiltersValue) => +f.value);
		const commonParams = {
			sortType: sort,
			sortByState: sortByState,
			settlement_id: settlement,
			page: `${currentPage !== 0 ? currentPage : 1}`,
			filters: filtersAllQuery,
			filtersRange: filtersRangeQuery,
			//name: searchData.searchKey === "search" ? searchData.searchValue : "",
			name: searchQuery ? searchQuery : "",
			/*saveQuery:auth && !category ? "1" : "",*/
			saveQuery: auth ? "1" : "",
			minPrice: sliderPriceMin,
			maxPrice: sliderPriceMax,
			isSearch: !category,
			perPage: "48",
			// perPage: currentPage == 0 ? '24' : '96',
		};
		if (category) {
			if (segmentsArray) {
				const getPreviousElementBeforeSearchResult = (arr: string[]) => {
					const index = arr.indexOf("search_result"); // Находим индекс ключевого слова
					if (index > 0 && index < arr.length) {
						return arr[index - 1]; // Возвращаем предыдущий элемент перед ключевым словом
					}
					return null; // Если ключевое слово не найдено или это первый элемент в массиве
				};
				const searchingCategoryElement =
					getPreviousElementBeforeSearchResult(segmentsArray);
				dispatch(
					getCategory(
						"",
						searchingCategoryElement || segmentsArray[segmentsArray.length - 1],
						["notice_price_diapason", "tree"]
					)
				);
			}
			const categoryParams = {
				category_id: +currentCategory.id,
			};
			if (currentCategory.id) {
				dispatch(
					getNoticeList({
						...categoryParams,
						...commonParams,
					})
				);
				dispatch(getFilters(currentCategory.id.toString()));
				if (currentActions.category != currentAction) {
					setCurrentAction(currentActions.category);
					setCurrentPage(0);
				}
			}
		} else {
			dispatch(getNoticeList(commonParams));
			if (currentActions.search != currentAction) {
				setCurrentAction(currentActions.search);
				setCurrentPage(0);
			}
			setCurrentAction(currentActions.search);
		}
		return () => {
			dispatch(setCategory([]));
			dispatch(setError(false));
			dispatch(setClearNoticeList());
		};
	}, [
		category,
		i18n.resolvedLanguage,
		searchData.searchKey,
		categorySlag,
		currentCategory.id,
		sort,
		settlement,
		pagination.totalCount, //page,
		filtersValues,
		sliderPriceFilter,
		sortByState,
		currentPage,
		params,
	]);

	/* const remainder = filteredNoticeList.length % 3;
       if (remainder !== 0) {
           const cardsToAdd = 3 - remainder;
           for (let i = 0; i < cardsToAdd; i++) {
               // Создайте новую карточку или скопируйте существующую
               /!* filteredNoticeList.push(newCard);*!/
           }
       }*/
	if (error) {
		return <NewPage404 />;
	}
	const categoryBreadcrumbsLinks = currentCategory?.tree?.map(
		(
			l: {
				alias: string;
				name: string;
				name_ru: string;
			},
			index: number
		) => ({
			name: l.name ? l.name : l.name_ru || "",
			alias: l?.alias || "",
			linksLength: currentCategory.tree.length,
			active: index === currentCategory?.tree?.length - 1,
		})
	);

	const breadcrumbsSearchingName = {
		name:
			searchData.searchKey === "search"
				? `${t("Пошук")} "${searchData.searchValue}"`
				: "",
		alias: "",
		linksLength: null,
		active: true,
	};

	const handleClickBreadcrumbs = () => {
		//так как истиной по работе с фильтрами, которые считываются с query является массив filtersValues
		//Эта функция нужна на клик по категории в breadcrumbs, чтобы не меняя логику работы фильтров убрать параметры поиска и перейти в категорию
		const filtered = filtersValues.filter((i: any) => i.filter !== "search");
		setFiltersValues(filtered);
	};
	const finalBreadcrumbs = Boolean(categoryBreadcrumbsLinks) && [
		...categoryBreadcrumbsLinks,
		breadcrumbsSearchingName,
	];

	const handleClickChoseCategory = (c: SearchCategory) => {
		const noticeUrl = c.categoryTree
			.map((s, index) => {
				if (index === 0) {
					return `${s}/c2`; // Добавляем '/c2' ко второму элементу
				} else {
					return s; // Возвращаем остальные элементы без изменений
				}
			})
			.join("/");
		navigate(
			`${i18n.language === "uk" ? "/ua" : ""}/c/${noticeUrl}?search=${
				searchData.searchValue
			}`
		);
	};

	return (
		<>
			<Desktop>
				<SearchResultDesktop
					category={category}
					setSort={setSort}
					filtersList={filtersList}
					currentCategory={currentCategory}
					countersList={countersList}
					filteredNoticeList={filteredNoticeList}
					breadcrumbsSearchingName={breadcrumbsSearchingName}
					setFiltersValues={setFiltersValues}
					finalBreadcrumbs={finalBreadcrumbs}
					handleClickBreadcrumbs={handleClickBreadcrumbs}
					filtersValues={filtersValues}
					setSettlement={setSettlement}
					setSortByState={setSortByState}
					sliderPrice={sliderPrice}
					categoryRange={categoryRange}
					categoriesSearch={categoriesSearch}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					handleClickChoseCategory={handleClickChoseCategory}
				/>
			</Desktop>
			<Mobile>
				<SearchResultMobile
					category={category}
					setShowSearchCategory={setShowSearchCategory}
					showSearchCategory={showSearchCategory}
					setFiltersValues={setFiltersValues}
					filtersValues={filtersValues}
					setSort={setSort}
					categoryRange={categoryRange}
					handleClickChoseCategory={handleClickChoseCategory}
				/>
			</Mobile>
		</>
	);
};

export default SearchResult;
