import React from "react";
import s from "../../../pages/SearchResults/SearchResultMobile.module.css";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";
import FilterPrice from "../FilterPrice";
import SearchResultFiltersMain from "../../SearchResultComponents/SearchResultFiltersMain";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import { CounterType, FiltersValue } from "../../../types/publicNoticeTypes";
import { useTranslation } from "react-i18next";
import { OneCategoryType } from "../../../types/reduxStoreTypes";
import { setPriceRangeFilter } from "../../../redux/publicNotice_Reducer";

interface Props {
	handleClickFilterButton: () => void;
	filtersValues: FiltersValue[];
	setFiltersValues: (filters: FiltersValue[]) => void;
	sliderPrice: number | number[];
	categoryRange: any;
}

const MobileFiltersTemplate: React.FC<Props> = ({
	handleClickFilterButton,
	categoryRange,
	filtersValues,
	setFiltersValues,
	sliderPrice,
}) => {
	const dispatch = useDispatch();
	const currentCategory: OneCategoryType = useSelector(
		(state: StateType) => state.categoryData.view
	);
	const filtersList = useSelector(
		(state: StateType) => state.categoryData.filters
	);
	const countersList: CounterType[] = useSelector(
		(state: StateType) => state.publicNotice.countersForNotices
	);
	const { t } = useTranslation();
	const setPriceToSend = useSelector(() => setPriceRangeFilter);
	return (
		<div>
			<div className={s.topBackBlock}>
				<button onClick={handleClickFilterButton}>
					<ArrowsAndCross id={"backArrow"} color={"#000000"} />
				</button>
				<h3>{t("Фільтри")}</h3>
				<button
					className={s.clearAllButton}
					onClick={() => {
						dispatch(setPriceToSend([]));
						setFiltersValues([]);
					}}
				>
					{t("Очистити")} все
				</button>
			</div>
			<div className={`${s.filterPriceContainer}`}>
				{Array.isArray(sliderPrice) && sliderPrice.length > 0 && (
					<FilterPrice
						sliderPrice={sliderPrice}
						setFiltersValues={setFiltersValues}
						filtersValues={filtersValues}
						categoryRange={categoryRange}
					/>
				)}
			</div>
			<SearchResultFiltersMain
				handleClickFilterButton={handleClickFilterButton}
				setFiltersValues={setFiltersValues}
				filtersValues={filtersValues}
				filtersList={filtersList}
				currentCategory={currentCategory}
				countersList={countersList}
			/>
		</div>
	);
};

export default MobileFiltersTemplate;
