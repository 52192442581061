import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getNoticeView } from "../../../redux/publicNotice_Reducer";
import { StateType } from "../../../redux/redux-store";
import OutputAdFooterInfoPanel from "../OutputADFooterInfoPanel";
import s from "./OutputAdMobileGallery.module.css";

const OutputAdMobileGallery = () => {
	const navigate = useNavigate();
	const { itemId } = useParams();
	const dispatch: any = useDispatch();
	const noticeView = useSelector(
		(state: StateType) => state.publicNotice.noticeView
	);

	const getNotice = useSelector(() => getNoticeView);

	useEffect(() => {
		if (itemId) {
			dispatch(getNotice(itemId));
		}
	}, []);

	const items = noticeView?.noticeImages?.map((i: any) => ({
		original: i.originalImageUrl || "",
		thumbnail: i.imageUrl || "",
	}));

	return (
		<div className={s.mobileGalleryContainer}>
			<div className={s.topTitle} onClick={() => navigate(-1)}>
				<span>
					<svg
						width="12"
						height="21"
						viewBox="0 0 12 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.0568 2L2 10.5556L9.6328 19.8889"
							stroke="white"
							strokeWidth="3"
						/>
					</svg>
				</span>
				<h1>{noticeView?.name}</h1>
			</div>
			<div className={s.galleryWrapper}>
				<ImageGallery
					items={items}
					additionalClass={"meMobileGallery"}
					showIndex
					showBullets={false}
					indexSeparator={" з "}
					showFullscreenButton={false}
					showPlayButton={false}
					useBrowserFullscreen={true}
					showThumbnails={true}
					showNav
					thumbnailPosition="bottom"
				/>
			</div>
			<div className={s.bottomFooterPanel}>
				<OutputAdFooterInfoPanel />
			</div>
		</div>
	);
};

export default OutputAdMobileGallery;
