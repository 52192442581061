import React from "react";
import style from "./ThankYouWindow.module.css";
import s from "./CompainModalWindow.module.css";
import CompainModalWindowSvg from "./CompainModalWindowSvg";
import { useTranslation } from "react-i18next";
type PropsType = {
	dialogClose: () => void;
};
const ThankYouWindow: React.FC<PropsType> = ({ dialogClose }) => {
	const { t } = useTranslation();
	return (
		<div
			className={s.modalWrapper}
			onClick={(e) => {
				if (e.target === e.currentTarget) {
					dialogClose();
				}
			}}
		>
			<div className={style.modalContainer}>
				<p className={style.mainText}>
					{t("Ми розглянемо Ваше звернення позачергово!")}
				</p>
				<CompainModalWindowSvg id={"bago"} />
				<div>
					<p className={style.secondTetx}>
						{t(
							"Безпека та актуальність даних дуже важлива для нас. Ми перевіримо інформацію на відповідність до Угоди користувача та вживемо необхідних заходів."
						)}{" "}
					</p>
				</div>
				<div className={s.secondWindow_button_wrap}>
					<button className={s.secondWindow_button} onClick={dialogClose}>
						{t("Закрити")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ThankYouWindow;
