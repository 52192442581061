import React from "react";
import styles from "./Input.module.css";
import HeaderSvg from "./HeaderSvg";

interface InputProps {
	placeholder: string;
	value: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

export const InputComponent: React.FC<InputProps> = ({
	placeholder,
	value,
	onChange,
	onClick,
	onFocus,
	onKeyDown,
}) => {
	return (
		<div className={styles.uiInputContainer}>
			<input
				placeholder={placeholder}
				className={styles.uiInput}
				type="text"
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				onClick={onClick}
			/>
			<div className={styles.uiInputUnderline}></div>
			<div className={styles.uiInputHighlight}></div>
			<div className={styles.uiInputIcon}>
				<HeaderSvg id={"searchIconMobile"} />
			</div>
		</div>
	);
};
