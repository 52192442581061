import React, { useEffect, useState } from "react";
import s from "./MainCategoryCatalogMobile.module.css";
import TopTitleBlock from "../TopTitleBlockMobileAllPages/TopTitleBlock";
import FilterAndSortPanel from "../SearchResultComponents/FilterAndSortPanel";
import ProductCardHorizontal from "../Cards/ProductCard/ProductCardHorizontal";
import MobileVerticalCard from "../Cards/ProductCard/MobileVerticalCard";
import useCollapse from "react-collapsed";
import ExpandButton from "../ExpandButton/ExpandButton";
import NewAdverts from "../NewAdverts/NewAdverts";
import { Desktop, Mobile } from "../../responsive";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllNoticeList } from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";

const MainCategoryCatalogMobile = () => {
	const [isExpanded, setExpanded] = useState(false);
	const [type, setType] = useState(0);
	const { getCollapseProps } = useCollapse({
		isExpanded,
	});
	const [row, setRow] = useState(true);
	const [sorting, setSeeSorting] = useState(false);
	const dispatch: any = useDispatch();
	const getNotices = useSelector(() => getAllNoticeList);
	const noticesBest = useSelector(
		(state: StateType) => state.publicNotice.bestNotices
	);
	useEffect(() => {
		dispatch(getNotices(type + 1));
	}, [type]);
	return (
		<div className={s.catCatContainerMobile}>
			<TopTitleBlock
				background={"#F2F2F2"}
				title={`топовые товары ${"Детский мир"}`}
			/>
			<div className={s.filterSortPanelContainer}>
				<FilterAndSortPanel
					sorting={sorting}
					row={row}
					setRow={setRow}
					setSeeSorting={setSeeSorting}
				/>
			</div>
			{!row && (
				<div className={s.cardsContainerRow}>
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<ProductCardHorizontal />
					<div className={s.advertisingContainer}></div>
				</div>
			)}
			{row && (
				<div className={s.cardsContainerVertical}>
					{noticesBest.map((i) => (
						<MobileVerticalCard notice={i} />
					))}

					<div className={s.advertisingContainer}></div>

					{noticesBest.map((i) => (
						<MobileVerticalCard notice={i} />
					))}

					<div
						{...getCollapseProps({
							style: {
								display: "flex",
								flexWrap: "wrap",
								columnGap: "20px",
								rowGap: "15px",
								padding: "0 5px",
								justifyContent: "center",
							},
						})}
					></div>
					<div className={s.expandButtonContainer}>
						<Desktop>
							<ExpandButton isExpanded={isExpanded} setExpanded={setExpanded} />
						</Desktop>
						<Mobile>
							<ExpandModernButton
								onClickCallback={() => {
									console.log("");
								}}
							/>
						</Mobile>
					</div>
					<div className={s.smallAdvertisingContainer}></div>
					<div>
						<NewAdverts title={"Бестселлеры детский мир"} />
					</div>
				</div>
			)}
		</div>
	);
};

export default MainCategoryCatalogMobile;
